import React from 'react'
import APIMerchandisingFlight from './Flight/APIMerchandisingFlight';

const APIMerchandising = ({ useradd, useredit, userexport }) => {
    return (
        <>
            <div className="container-fluid my-2">
                <div className='container mt-3 mb-2 tab_section px-0'>
                    <ul className="nav nav-pills nav-justified mb-3 pt-3 col-lg-4 col-12 mx-auto" id="pills-tab" role="tablist">
                        <li className="nav-item mt-1 mx-1" role="presentation">
                            <button className="nav-link active" id="pills-Flights-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-Flights" type="button" role="tab" aria-controls="pills-Flights"
                                aria-selected="true"><strong>Flights</strong></button>
                        </li>
                        {/* <li className="nav-item mt-1 mx-1" role="presentation" >
                            <button className="nav-link" id="pills-Hotel-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-Hotel" type="button" role="tab" aria-controls="pills-Hotel"
                                aria-selected="false"><strong >Hotel</strong></button>
                        </li> */}
                    </ul>
                </div>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-Flights" role="tabpanel"
                        aria-labelledby="pills-Flights-tab">
                        <APIMerchandisingFlight useradd={useradd} useredit={useredit} userexport={userexport} />
                    </div>
                    <div className="tab-pane fade show" id="pills-Hotel" role="tabpanel"
                        aria-labelledby="pills-Hotel-tab">
                    </div>
                </div>
            </div>
        </>
    )
}

export default APIMerchandising