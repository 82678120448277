export const userConstatnt = {
    USERDATA: 'USERDATA',
    MENUID: 'MENUID',
}

export const clearable = {
    CLEARTRUE: "CLEARTRUE",
    CLEARFALSE: "CLEARFALSE",
    CLEARERRORTRUE: 'CLEARERRORTRUE',
    CLEARERRORFALSE: 'CLEARERRORFALSE',
}
export const tripSupplierCollection = {
    YES: "YES",
    NO: "NO",
}

export const hotelRateInventory = {
    HOTELRATEINVENTORYOPEN: "HOTELRATEINVENTORYOPEN",
    HOTELRATEINVENTORYCLOSE: "HOTELRATEINVENTORYCLOSE"
}

export const bookingConstant = {
    UPDATEBOOKING: 'UPDATEBOOKING',
    UPDATEBOOKINGEND: 'UPDATEBOOKINGEND'
}