import React, { useMemo } from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../../../assets/css/master.css"
import { getAllData } from '../../../../services/ApiCallingUserServices';
import { useDispatch, useSelector } from 'react-redux';
import { errorActions } from '../../../../_actions/error.action';


const VendorExclusion = (props) => {
    const errorstatus = useSelector(state => state.errorStatus.isError);
    // const submitstatus = useSelector(state=>state.errorStatus.isSubmitting);
    const exclusionstatus = useSelector(state=>state.errorStatus.isExclusion);
    
    const dispatch = useDispatch();
    const {
        handleExclusionAdd,
        handleExclusionDisable,
        retriveflag,
        exclusiondatalist,
        editableflag
    } = props

    const [exclusionvalue, setExclusion] = useState([]);
    const [exclusionid, setExclusionId] = useState(0);
    const [exclusionSeqNo, setExclusionSeqNo] = useState(0);
    const [dataexclusiondetails, setExclusionDetails] = useState([]);
    const [exclusionlist, setExclusionList] = useState([]);
    const [exclusionerror, setExclusionError] = useState('')
    const getExclusionList = async () => {
        await getAllData('/master/vendor/v1/vengetpackageexclusion').then((response) => {
            setExclusionList(response.data?.map((value) => ({
                label: value.ExclusionName,
                value: value.ExclusionId,
                IsActive: value?.IsActive
            })))
        });
        // setExclusionList(listofexclusiondata.data);
    }

    const handleexclusionchange = (e) => {
        // formvalue.ExclusionD = e.label
        setExclusionError('');
        const Exclusiontype = [
            { value: e.value, label: e.label }
        ]
        setExclusion(Exclusiontype);
        setExclusionId(e.value);
        handleExclusionDisable(false);
        // dispatch(errorActions.end());
        // dispatch(errorActions.exclusionend());
        // dispatch(errorActions.inclusionend());
        // dispatch(errorActions.overviewend());
        dispatch(errorActions.resetallstatusflag());
        // reseterrormessage();
        // setEditDisabled(false);
        //setInclusion(current => [...current , {vehichaltype}]);
        //vehicaltypevalue
    }

    const ExclusionClick = (e) => {
        e.preventDefault();
        let index = dataexclusiondetails?.find((obj) => obj.ExclusionId === exclusionid) || []
        if (index.ExclusionId > 0) {
            dispatch(errorActions.start())
            setExclusionError('You can not add duplicate value')
            return;
        }
        else if (exclusionvalue.length > 0) {
            setExclusionSeqNo(exclusionSeqNo + 1)
            handleExclusionAdd([...dataexclusiondetails, { SeqNo: exclusionSeqNo + 1, Exclusion: exclusionvalue[0].label, ExclusionId: exclusionvalue[0].value }])
            setExclusionDetails([...dataexclusiondetails, { SeqNo: exclusionSeqNo + 1, Exclusion: exclusionvalue[0].label, ExclusionId: exclusionvalue[0].value }]);
            setExclusionId(-1)
            dispatch(errorActions.exclusionincrement());
            dispatch(errorActions.resetallstatusflag());
        }
        handleExclusionDisable(false);
        setExclusion([]);
    }
    useEffect(() => {
        getExclusionList()
    }, [])
    const exclusionremovev = (id, e) => {
        e.preventDefault();
        handleExclusionAdd((oldState) => oldState?.filter((item) => item.SeqNo !== id));
        setExclusionDetails((oldState) => oldState?.filter((item) => item.SeqNo !== id));
        setExclusionError('');
        setExclusionId(-1);
        handleExclusionDisable(false);
        
        dispatch(errorActions.exclusiondecrement());
        dispatch(errorActions.resetallstatusflag());
        // reseterrormessage();
        // setEditDisabled(false);
    }
    useEffect(() => {
        if (!errorstatus) {
            setExclusionError('')
        }
    }, [errorstatus])
    useEffect(() => {
        if (retriveflag) {
            setExclusionDetails(exclusiondatalist)
            setExclusionSeqNo(exclusiondatalist.length)
        }

    }, [exclusiondatalist, retriveflag])
    const validation = () => {
        // const exclsuioncount = dataexclusiondetails?.filter((obj) => obj.SeqNo > 0);
        // if (exclusioncnt === 0) {
            setExclusionError('Please select at least one Exclusion');
            let incllopased = document?.getElementById("ExclusionError")
            if (incllopased.ariaExpanded === "false")
                document.querySelector("#ExclusionError").click();
            // dispatch(errorActions.submitend());
            dispatch(errorActions.start())
        // }
        
     }
     useEffect(()=>{
        if(exclusionstatus)
        {
            validation()
        }
    },[exclusionstatus])
    return (
        <>
            <div class="accordion-item mb-3">
                <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                    <button class="accordion-button collapsed" id="ExclusionError" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                        Exclusion
                    </button>
                </h2>
                <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                    <div class="accordion-body">
                        <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                            <div class="row">
                                {/* <label for="inputPassword6" class="col-form-label">Exclusion</label> */}
                                <div class="row">
                                    <div className='col-lg-12 col-sm-12 '>
                                        <div className='row'>
                                            {/* {formerror.ExclusionError && <p className='text-center text-danger'>{formerror.ExclusionError}</p>}*/}
                                            {exclusionerror && <p className='text-center text-danger'>{exclusionerror}</p>}
                                            <div class="col-lg-2 col-md-3 mb-1">
                                                <label class="col-form-label required">Add Exclusion</label>
                                            </div>
                                            <div class="col-lg-4 mb-1 col-md-4">
                                                <Select
                                                    className="dropdown select_dropdown"

                                                    isDisabled={editableflag ? true : false}
                                                    name='ExclusionD'
                                                    value={exclusionlist?.filter(({ value }) => value === exclusionid)}
                                                    options={exclusionlist.filter((op)=>op.IsActive)}
                                                    onChange={handleexclusionchange}
                                                    isSearchable
                                                />
                                                {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                            </div>
                                            <div class="col-lg-4 mb-1 col-md-4">
                                                <button type="button" onClick={ExclusionClick}
                                                    disabled={editableflag ? true : false}
                                                    class="btn btn-primary mx-1" id="btnAdd">Add</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mb-1 col-lg-12 col-md-12 mt-3'>
                                        <table id="data" className="table table-hover" >
                                            <thead>
                                                <tr>
                                                    <th>Exclusion
                                                    </th>
                                                    {/* <th className='d-none'>Inclusion Type Id
                                                                    </th> */}
                                                    <th className='text-end pe-4'>Remove
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody id="location-data">
                                                {dataexclusiondetails?.map((element, index) => {
                                                    // if (dataexclusioncounter > 0) {
                                                    return (
                                                        <tr class="ReactTable" key={index}>
                                                            <td >{element.Exclusion}</td>

                                                            <td className='text-end'><button type="button"
                                                                onClick={(e) => { exclusionremovev(element.SeqNo, e) }}
                                                                class="btn btn-primary mx-1" id="btnAdd"
                                                                disabled={editableflag ? true : false}
                                                            >Remove</button></td>
                                                        </tr>
                                                    );
                                                    // }
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VendorExclusion