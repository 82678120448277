import React, { useEffect } from 'react'
import { FormControl, FormControlLabel, FormLabel, Switch, TextField } from '@mui/material';
import { alphaNumeric, alphaNumericspace, onlyAlphabets, onlyNumeric } from '../../../../../../commonClass/CommonValidation';

const Finance = (props) => {

    const {
        formError,
        setFormError,
        formValue,
        setFormValue,
        hotelId,
        disabled,
        setSave,
        removerError } = props;
    const handleChange = (name, val) => {
        removerError('legalfinance-tab-1')
        setFormError({});
        setSave(false)
        setFormValue(prevState => ({ ...prevState, Finance: { ...prevState.Finance, [name]: val, } }));
    }
    return (
        <div className='mt-3'>
            <div className='mt-3'>
                <h5>Bank Details</h5>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-12'>
                        <div className='align-content-center d-flex'>
                            <FormLabel className='text-dark me-3'>Account Status ?</FormLabel>
                            <FormControlLabel
                                control={
                                    <Switch
                                        name='IsAccountStatus'
                                        value={formValue?.IsAccountStatus}
                                        onChange={(e) => handleChange(e.target.name, e.target.checked)}
                                        checked={formValue?.IsAccountStatus}
                                        disabled={disabled}
                                    />
                                } label="Active"
                            />
                        </div>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-lg-4 col-md-6 col-12'>
                        <FormControl className='w-100'>
                            <TextField id='CompanyName' label='Company Name'
                                className={`w-100  ${formValue?.CompanyName.trim()?.length <= 0 ? 'red_border' : ''}`}
                                type="text"
                                name='CompanyName'
                                inputProps={{ maxLength: 150 }}
                                value={formValue?.CompanyName}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                error={formError?.CompanyName ? true : false}
                                helperText={formError?.CompanyName}
                                disabled={disabled}

                            />
                        </FormControl>
                    </div>
                    <div className='col-lg-4 col-md-6 col-12'>
                        <FormControl className='w-100'>
                            <TextField id='AccountEmail' label='Account Email'
                                className={`w-100  ${formValue?.AccountEmail.trim()?.length <= 0 ? 'red_border' : ''}`}
                                type="text"
                                name='AccountEmail'
                                inputProps={{ maxLength: 150 }}
                                value={formValue?.AccountEmail}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                error={formError?.AccountEmail ? true : false}
                                helperText={formError?.AccountEmail}
                                disabled={disabled}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-lg-4 col-md-6 col-12'>
                        <FormControl className='w-100'>
                            <TextField id='AccountNumber' label='Account Number'
                                className={`w-100  ${formValue?.AccountNumber?.trim()?.length <= 0 ? 'red_border' : ''}`}
                                type="text"
                                name='AccountNumber'
                                inputProps={{ maxLength: 25 }}
                                value={formValue?.AccountNumber}
                                onChange={(e) => handleChange(e.target.name, onlyNumeric(e.target.value) === '' ? "" : onlyNumeric(e.target.value))}
                                error={formError?.AccountNumber ? true : false}
                                helperText={formError?.AccountNumber}
                                disabled={disabled}
                            />
                        </FormControl>
                    </div>
                    <div className='col-lg-4 col-md-6 col-12'>
                        <FormControl className='w-100'>
                            <TextField id='AccountName' label='Account Name'
                                className={`w-100  ${formValue?.AccountName?.trim()?.length <= 0 ? 'red_border' : ''}`}
                                type="text"
                                name='AccountName'
                                inputProps={{ maxLength: 150 }}
                                value={formValue?.AccountName}
                                onChange={(e) => handleChange(e.target.name, alphaNumericspace(e.target.value))}
                                error={formError?.AccountName ? true : false}
                                helperText={formError?.AccountName}
                                disabled={disabled}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-lg-4 col-md-6 col-12'>
                        <FormControl className='w-100'>
                            <TextField id='BankName' label='Bank Name'
                                className={`w-100  ${formValue?.BankName?.trim()?.length <= 0 ? 'red_border' : ''}`}
                                type="text"
                                name='BankName'
                                inputProps={{ maxLength: 150 }}
                                value={formValue?.BankName}
                                onChange={(e) => handleChange(e.target.name, onlyAlphabets(e.target.value))}
                                error={formError?.BankName ? true : false}
                                helperText={formError?.BankName}
                                disabled={disabled}
                            />
                        </FormControl>
                    </div>
                    <div className='col-lg-4 col-md-6 col-12'>
                        <FormControl className='w-100'>
                            <TextField id='IFSCCode' label='IFSC Code'
                                className={`w-100  ${formValue?.IFSCCode?.trim()?.length <= 0 ? 'red_border' : ''}`}
                                type="text"
                                name='IFSCCode'
                                inputProps={{ maxLength: 11 }}
                                value={formValue?.IFSCCode}
                                onChange={(e) => handleChange(e.target.name, alphaNumeric(e.target.value))}
                                error={formError?.IFSCCode ? true : false}
                                helperText={formError?.IFSCCode}
                                disabled={disabled}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-lg-4 col-md-6 col-12'>
                        <FormControl className='w-100'>
                            <TextField id='BranchName' label='Branch Name'
                                className={`w-100  ${formValue?.BranchName?.trim()?.length <= 0 ? 'red_border' : ''}`}
                                type="text"
                                name='BranchName'
                                inputProps={{ maxLength: 150 }}
                                value={formValue?.BranchName}
                                onChange={(e) => handleChange(e.target.name, alphaNumericspace(e.target.value))}
                                error={formError?.BranchName ? true : false}
                                helperText={formError?.BranchName}
                                disabled={disabled}
                            />
                        </FormControl>
                    </div>
                    <div className='col-lg-4 col-md-6 col-12'>
                        <FormControl className='w-100'>
                            <TextField id='SwiftCode' label='Swift Code'
                                className={`w-100`}
                                type="text"
                                name='SwiftCode'
                                inputProps={{ maxLength: 12 }}
                                value={formValue?.SwiftCode}
                                onChange={(e) => handleChange(e.target.name, alphaNumeric(e.target.value))}
                                error={formError?.SwiftCode ? true : false}
                                helperText={formError?.SwiftCode}
                                disabled={disabled}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-lg-4 col-md-6 col-12'>
                        <FormControl className='w-100'>
                            <TextField id='PANNumber' label='PAN Number'
                                className={`w-100  ${formValue?.PANNumber?.trim()?.length <= 0 ? 'red_border' : ''}`}
                                type="text"
                                name='PANNumber'
                                inputProps={{ maxLength: 10 }}
                                value={formValue?.PANNumber}
                                onChange={(e) => handleChange(e.target.name, alphaNumeric(e.target.value))}
                                error={formError?.PANNumber ? true : false}
                                helperText={formError?.PANNumber}
                                disabled={disabled}
                            />
                        </FormControl>
                    </div>
                    <div className='col-lg-4 col-md-6 col-12'>
                        <FormControl className='w-100'>
                            <TextField id='MICRNumber' label='MICR Number'
                                className={`w-100`}
                                type="text"
                                name='MICRNumber'
                                inputProps={{ maxLength: 20 }}
                                value={formValue?.MICRNumber}
                                onChange={(e) => handleChange(e.target.name, alphaNumeric(e.target.value))}
                                error={formError?.MICRNumber ? true : false}
                                helperText={formError?.MICRNumber}
                                disabled={disabled}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-lg-4 col-md-6 col-12'>
                        <FormControl className='w-100'>
                            <TextField id='VATNumber' label='VAT Number'
                                className={`w-100`}
                                type="text"
                                name='VATNumber'
                                inputProps={{ maxLength: 15 }}
                                value={formValue?.VATNumber}
                                onChange={(e) => handleChange(e.target.name, alphaNumeric(e.target.value))}
                                error={formError?.VATNumber ? true : false}
                                helperText={formError?.VATNumber}
                                disabled={disabled}
                            />
                        </FormControl>
                    </div>
                    <div className='col-lg-4 col-md-6 col-12'>
                        <FormControl className='w-100'>
                            <TextField id='MobileNumber' label='Mobile Number'
                                className={`w-100`}
                                type="text"
                                name='MobileNumber'
                                inputProps={{ maxLength: 20 }}
                                value={formValue?.MobileNumber}
                                onChange={(e) => handleChange(e.target.name, onlyNumeric(e.target.value))}
                                error={formError?.MobileNumber ? true : false}
                                helperText={formError?.MobileNumber}
                                disabled={disabled}
                            />
                        </FormControl>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Finance