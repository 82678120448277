import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { DatePicker, TimePicker } from 'antd';
import dayjs from 'dayjs';
import { ClearLocalStorage, GetLocalStorage } from '../../../../commonClass/Session';
import { getAllData, postUsersData, verifytoken } from '../../../../../services/ApiCallingUserServices';
import { loaderActions } from '../../../../../_actions/loader.actions';
import { onlyNumeric, onlyNumericwithminus } from '../../../../commonClass/CommonValidation';
import CustomTable from '../../../../../customControl/CustomTable';

const { RangePicker } = DatePicker;

const APIConfigurationMarkupFlight = ({ useradd, useredit, userexport }) => {
    const dispatch = useDispatch();
    const userId = GetLocalStorage();

    const [dropdownData, setDropdownList] = useState([]);
    const [getDataList, setGetListData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [editDisable, setEditDisable] = useState(false);
    const [formError, setFormError] = useState({});

    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('100');
    const [serchdisable, setSerachDisable] = useState(false);
    const [clrshow, setsclshow] = useState(false);

    const [formValue, setFormValue] = useState({
        MarkupDetailsId: 0,
        APIConfigurationId: 0,
        AirLineId: [],
        BookingClassId: [],
        CabinClassId: [],
        ApplicationTypeId: [],
        MarkUpFor: 0,
        ConfigureDate: 0,
        BookingStartDate: null,
        BookingEndDate: null,
        BookingStartTime: null,
        BookingEndTime: null,
        TravelStartDate: null,
        TravelEndDate: null,
        TravelStartTime: null,
        TravelEndTime: null,
        MarkUpApplyOn: 0,
        IsConvinienceFee: null,
        IsConvinienceFeePercentage: null,
        ConvenienceFeesWeb: '',
        ConvenienceFeesMobileWeb: '',
        IsConfigureMarkUpPercentage: null,
        ConfigureMarkUpWeb: '',
        ConfigureMarkUpMobileWeb: '',
        BookingDaysId: [],
        TravelDaysId: [],
        IsActive: true,
        UserId: 0,
        type: 'add'
    })

    const getData = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const data = await getAllData('/master/v1/getmarkuppageconfiguration');
            if (data.status) {
                setDropdownList({
                    apiConfigList: data?.data?.apiConfigList?.map(el => ({
                        label: el?.SupplierName,
                        value: el?.APIConfigurationId
                    })),
                    apiAirlineList: data?.data?.apiAirlineList?.map(el => ({
                        label: el?.AirlineName,
                        value: el?.AirlineID,
                        APIConfigurationId: el?.APIConfigurationId
                    })),
                    bookingClassList: data?.data?.bookingClassList?.map(el => ({
                        label: el?.Name,
                        value: el?.BookingClassId
                    })),
                    cabinClassList: data?.data?.cabinClassList?.map(el => ({
                        label: el?.Code,
                        value: el?.CabinClassId,
                        BookingClassId: el?.BookingClassId
                    })),
                    applicationTypeList: data?.data?.applicationTypeList?.map(el => ({
                        label: el?.Name,
                        value: el?.ApplicationTypeId
                    })),
                    markupForOptiosList: data?.data?.markupForOptiosList,
                    configureDateOptionsList: data?.data?.configureDateOptionsList,
                    weekDays: data?.data?.weekDays,
                    markupApplyOnOPtionsList: data?.data?.markupApplyOnOPtionsList
                });
            }
            else {
                dispatch({ type: 'ALERTING', message: data.message })
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
        dispatch(loaderActions.end())
    }

    const apiAirlineList = useMemo(() => dropdownData?.apiAirlineList?.filter((el) => el?.APIConfigurationId === formValue?.APIConfigurationId), [formValue?.APIConfigurationId]);
    const cabinClassList = useMemo(() => dropdownData?.cabinClassList?.filter((el) => formValue?.BookingClassId?.includes(el?.BookingClassId)), [formValue?.BookingClassId]);

    const getDataAll = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const data = await getAllData(`/master/v1/getmarkupdetailslist`);
            if (data?.status) {
                setGetListData(data?.data)
                setFilterData(data?.data)
            }
            else {
                setGetListData([])
                setFilterData([])
            }
            dispatch(loaderActions.end());
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
        dispatch(loaderActions.end())
    }

    const onChange = (name, value, ismulti) => {
        setFormError({});
        setEditDisable(false);
        if (ismulti) {
            if (name === 'BookingClassId') {
                setFormValue({ ...formValue, [name]: value?.map(val => val.value), CabinClassId: [] });
            }
            else {
                setFormValue({ ...formValue, [name]: value?.map(val => val.value) });
            }
        }
        else {
            if (name === 'APIConfigurationId') {
                setFormValue({ ...formValue, [name]: value, AirLineId: [] });
            }
            else if (name === 'ConfigureDate') {
                setFormValue({
                    ...formValue, [name]: value,
                    BookingStartDate: value === 2 ? null : formValue?.BookingStartDate,
                    BookingEndDate: value === 2 ? null : formValue?.BookingEndDate,
                    BookingStartTime: value === 2 ? null : formValue?.BookingStartTime,
                    BookingEndTime: value === 2 ? null : formValue?.BookingEndTime,
                    BookingDaysId: value === 2 ? [] : formValue?.BookingDaysId,
                    TravelStartDate: value === 1 ? null : formValue?.TravelStartDate,
                    TravelEndDate: value === 1 ? null : formValue?.TravelEndDate,
                    TravelStartTime: value === 1 ? null : formValue?.TravelStartTime,
                    TravelEndTime: value === 1 ? null : formValue?.TravelEndTime,
                    TravelDaysId: value === 1 ? [] : formValue?.TravelDaysId
                });
            }
            else if (name === 'IsConvinienceFee') {
                setFormValue({
                    ...formValue, [name]: value,
                    IsConvinienceFeePercentage: value ? formValue?.IsConvinienceFeePercentage : null,
                    ConvenienceFeesWeb: value ? formValue?.ConvenienceFeesWeb : '',
                    ConvenienceFeesMobileWeb: value ? formValue?.ConvenienceFeesMobileWeb : '',
                });
            }
            else if (name === 'IsConvinienceFeePercentage') {
                setFormValue((form) => ({
                    ...form, [name]: value,
                    ConvenienceFeesWeb: '',
                    ConvenienceFeesMobileWeb: '',
                }));
            }
            else if (name === 'IsConfigureMarkUpPercentage') {
                setFormValue((form) => ({
                    ...form, [name]: value,
                    ConfigureMarkUpWeb: '',
                    ConfigureMarkUpMobileWeb: '',
                }));
            }
            else if (name === 'ConvenienceFeesWeb' || name === 'ConvenienceFeesMobileWeb') {
                if (formValue?.IsConvinienceFeePercentage && value <= 100 && value >= -100) {
                    setFormValue({ ...formValue, [name]: value });
                }
                else if (!formValue?.IsConvinienceFeePercentage) {
                    setFormValue({ ...formValue, [name]: value });
                }
            }
            else if (name === 'ConfigureMarkUpWeb' || name === 'ConfigureMarkUpMobileWeb') {
                if (formValue?.IsConfigureMarkUpPercentage && value <= 100 && value >= -100) {
                    setFormValue({ ...formValue, [name]: value });
                }
                else if (!formValue?.IsConfigureMarkUpPercentage) {
                    setFormValue({ ...formValue, [name]: value });
                }
            }
            else {
                setFormValue({ ...formValue, [name]: value });
            }
        }
    }

    const onChangeCheckbox = (name, value, ismulti) => {
        setFormError({});
        setEditDisable(false);
        if (ismulti) {

        }
        else {

            if (name === 'BookingDaysId') {
                if (!formValue?.BookingDaysId?.includes(value)) {
                    if (value === 1) {
                        setFormValue((prevFormValue) => ({
                            ...prevFormValue,
                            BookingDaysId: [1, 2, 3, 4, 5, 6, 7, 8]
                        }))
                    }
                    else {
                        if (formValue?.BookingDaysId?.length === 6 && !formValue?.BookingDaysId?.includes(value)) {
                            setFormValue((prevFormValue) => ({
                                ...prevFormValue,
                                BookingDaysId: [1, 2, 3, 4, 5, 6, 7, 8]
                            }))
                        }
                        else {
                            setFormValue((prevFormValue) => ({
                                ...prevFormValue,
                                BookingDaysId: [...prevFormValue.BookingDaysId, value]
                            }))
                        }
                    }
                }
                else {
                    if (value === 1) {
                        setFormValue((prevFormValue) => ({
                            ...prevFormValue,
                            BookingDaysId: []
                        }))
                    }
                    else {
                        if (formValue?.BookingDaysId?.includes(1)) {
                            setFormValue((prevFormValue) => ({
                                ...prevFormValue,
                                BookingDaysId: prevFormValue.BookingDaysId?.filter(id => id !== value && id !== 1)
                            }))
                        }
                        else {
                            setFormValue((prevFormValue) => ({
                                ...prevFormValue,
                                BookingDaysId: prevFormValue.BookingDaysId?.filter(id => id !== value)
                            }))
                        }
                    }
                }
            }
            if (name === 'TravelDaysId') {
                if (!formValue?.TravelDaysId?.includes(value)) {
                    if (value === 1) {
                        setFormValue((prevFormValue) => ({
                            ...prevFormValue,
                            TravelDaysId: [1, 2, 3, 4, 5, 6, 7, 8]
                        }))
                    }
                    else {
                        if (formValue?.TravelDaysId?.length === 6 && !formValue?.TravelDaysId?.includes(value)) {
                            setFormValue((prevFormValue) => ({
                                ...prevFormValue,
                                TravelDaysId: [1, 2, 3, 4, 5, 6, 7, 8]
                            }))
                        }
                        setFormValue((prevFormValue) => ({
                            ...prevFormValue,
                            TravelDaysId: [...prevFormValue.TravelDaysId, value]
                        }))
                    }
                }
                else {
                    if (value === 1) {
                        setFormValue((prevFormValue) => ({
                            ...prevFormValue,
                            TravelDaysId: []
                        }))
                    }
                    else {
                        if (formValue?.TravelDaysId?.includes(1)) {
                            setFormValue((prevFormValue) => ({
                                ...prevFormValue,
                                TravelDaysId: prevFormValue.TravelDaysId?.filter(id => id !== value && id !== 1)
                            }))
                        }
                        else {
                            setFormValue((prevFormValue) => ({
                                ...prevFormValue,
                                TravelDaysId: prevFormValue.TravelDaysId?.filter(id => id !== value)
                            }))
                        }
                    }
                }
            }
        }
    }


    const checkValidation = () => {
        let errorMessages = {};
        let isError = true;

        if (formValue?.APIConfigurationId === 0) {
            errorMessages['APIConfigurationId'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        else if (formValue?.APIConfigurationId > 0 && filterData?.some((val) => val?.APIConfigurationId === formValue?.APIConfigurationId) && formValue?.type === 'add') {
            errorMessages['APIConfigurationId'] = 'Duplicate API. Please enter a unique API.'
            isError = false;
        }
        if (formValue?.AirLineId?.length === 0 && formValue?.APIConfigurationId > 0) {
            errorMessages['AirLineId'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (formValue?.BookingClassId?.length === 0) {
            errorMessages['BookingClassId'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (formValue?.CabinClassId?.length === 0 && formValue?.BookingClassId?.length > 0) {
            errorMessages['CabinClassId'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (formValue?.ApplicationTypeId?.length === 0) {
            errorMessages['ApplicationTypeId'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (formValue?.MarkUpFor === 0) {
            errorMessages['MarkUpFor'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (formValue?.ConfigureDate === 0) {
            errorMessages['ConfigureDate'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if ((formValue?.BookingStartDate === '' || formValue?.BookingStartDate === null) && (formValue?.ConfigureDate === 2 ? false : formValue?.ConfigureDate === 0 ? false : true)) {
            errorMessages['BookingStartDate'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if ((formValue?.BookingEndDate === '' || formValue?.BookingEndDate === null) && (formValue?.ConfigureDate === 2 ? false : formValue?.ConfigureDate === 0 ? false : true)) {
            errorMessages['BookingEndDate'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if ((formValue?.BookingStartTime === '' || formValue?.BookingStartTime === null) && (formValue?.ConfigureDate === 2 ? false : formValue?.ConfigureDate === 0 ? false : true)) {
            errorMessages['BookingStartTime'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if ((formValue?.BookingEndTime === '' || formValue?.BookingEndTime === null) && (formValue?.ConfigureDate === 2 ? false : formValue?.ConfigureDate === 0 ? false : true)) {
            errorMessages['BookingEndTime'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (formValue?.BookingDaysId?.length === 0 && (formValue?.BookingStartDate !== '' && formValue?.BookingStartDate !== null && formValue?.BookingEndDate !== '' && formValue?.BookingEndDate !== null)) {
            errorMessages['BookingDaysId'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if ((formValue?.TravelStartDate === '' || formValue?.TravelStartDate === null) && (formValue?.ConfigureDate === 1 ? false : formValue?.ConfigureDate === 0 ? false : true)) {
            errorMessages['TravelStartDate'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if ((formValue?.TravelEndDate === '' || formValue?.TravelEndDate === null) && (formValue?.ConfigureDate === 1 ? false : formValue?.ConfigureDate === 0 ? false : true)) {
            errorMessages['TravelEndDate'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if ((formValue?.TravelStartTime === '' || formValue?.TravelStartTime === null) && (formValue?.ConfigureDate === 1 ? false : formValue?.ConfigureDate === 0 ? false : true)) {
            errorMessages['TravelStartTime'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if ((formValue?.TravelEndTime === '' || formValue?.TravelEndTime === null) && (formValue?.ConfigureDate === 1 ? false : formValue?.ConfigureDate === 0 ? false : true)) {
            errorMessages['TravelEndTime'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (formValue?.TravelDaysId?.length === 0 && (formValue?.TravelStartDate !== '' && formValue?.TravelStartDate !== null && formValue?.TravelEndDate !== '' && formValue?.TravelEndDate !== null)) {
            errorMessages['TravelDaysId'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (formValue?.MarkUpApplyOn === 0) {
            errorMessages['MarkUpApplyOn'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (formValue?.IsConvinienceFee === null) {
            errorMessages['IsConvinienceFee'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (formValue?.IsConvinienceFeePercentage === null && formValue?.IsConvinienceFee === true) {
            errorMessages['IsConvinienceFeePercentage'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (formValue?.IsConvinienceFeePercentage !== null && (formValue?.ConvenienceFeesWeb?.toString()?.trim()?.length === 0 || formValue?.ConvenienceFeesWeb === null)) {
            errorMessages['ConvenienceFeesWeb'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (formValue?.IsConvinienceFeePercentage !== null && (formValue?.ConvenienceFeesMobileWeb?.toString()?.trim()?.length === 0 || formValue?.ConvenienceFeesMobileWeb === null)) {
            errorMessages['ConvenienceFeesMobileWeb'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (formValue?.IsConfigureMarkUpPercentage === null) {
            errorMessages['IsConfigureMarkUpPercentage'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (formValue?.IsConfigureMarkUpPercentage !== null && (formValue?.ConfigureMarkUpWeb?.toString()?.trim()?.length === 0 || formValue?.ConfigureMarkUpWeb === null)) {
            errorMessages['ConfigureMarkUpWeb'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (formValue?.IsConfigureMarkUpPercentage !== null && (formValue?.ConfigureMarkUpMobileWeb?.toString()?.trim()?.length === 0 || formValue?.ConfigureMarkUpMobileWeb === null)) {
            errorMessages['ConfigureMarkUpMobileWeb'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        setFormError(errorMessages);
        return isError;
    }

    const onSubmitData = async () => {
        const isValid = checkValidation();
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            if (isValid) {
                dispatch(loaderActions.start());
                formValue.ConvenienceFeesWeb = formValue.ConvenienceFeesWeb === '' ? null : formValue.ConvenienceFeesWeb
                formValue.ConvenienceFeesMobileWeb = formValue.ConvenienceFeesMobileWeb === '' ? null : formValue.ConvenienceFeesMobileWeb
                formValue.ConfigureMarkUpWeb = formValue.ConfigureMarkUpWeb === '' ? null : formValue.ConfigureMarkUpWeb
                formValue.ConfigureMarkUpMobileWeb = formValue.ConfigureMarkUpMobileWeb === '' ? null : formValue.ConfigureMarkUpMobileWeb
                const data = await postUsersData('/master/v1/insertorupdatemarkupdetails', formValue);
                if (data.status) {
                    onClearButton();
                    getDataAll();
                    dispatch({ type: 'ALERTING', message: data.message })
                    dispatch(loaderActions.end());
                }
                else {
                    dispatch(loaderActions.end());
                    dispatch({ type: 'ALERTING', message: data.message })
                }
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
        dispatch(loaderActions.end());
    }
    const onClearButton = () => {
        setFormError({});
        setEditDisable(false);
        setSerachDisable(false);
        setFormValue(
            {
                MarkupDetailsId: 0,
                APIConfigurationId: 0,
                AirLineId: [],
                BookingClassId: [],
                CabinClassId: [],
                ApplicationTypeId: [],
                MarkUpFor: 0,
                ConfigureDate: 0,
                BookingStartDate: null,
                BookingEndDate: null,
                BookingStartTime: null,
                BookingEndTime: null,
                TravelStartDate: null,
                TravelEndDate: null,
                TravelStartTime: null,
                TravelEndTime: null,
                MarkUpApplyOn: 0,
                IsConvinienceFee: null,
                IsConvinienceFeePercentage: null,
                ConvenienceFeesWeb: '',
                ConvenienceFeesMobileWeb: '',
                IsConfigureMarkUpPercentage: null,
                ConfigureMarkUpWeb: '',
                ConfigureMarkUpMobileWeb: '',
                BookingDaysId: [],
                TravelDaysId: [],
                IsActive: true,
                UserId: userId?.userMasterID,
                type: 'add'
            }
        )
    }
    const onEditorView = async (data, type) => {
        if (type === 'view') {
            setSerachDisable(true)
        }
        setEditDisable(true)
        dispatch(loaderActions.start());
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const el = await getAllData(`/master/v1/getmarkupdetailsbyid?markupDetailsId=${data?.MarkupDetailsId}`);
            setFormError({});
            if (el?.status) {
                setFormValue(
                    {
                        ...formValue,
                        MarkupDetailsId: el?.data?.markupDetails?.[0]?.MarkupDetailsId,
                        APIConfigurationId: el?.data?.markupDetails?.[0]?.APIConfigurationId,
                        AirLineId: el?.data?.airlineList?.length > 0 ?
                            el?.data?.markupDetails?.[0]?.APIConfigurationId > 0 ?
                                el?.data?.airlineList?.map(el => el.AirLineId)
                                : []
                            : [],
                        BookingClassId: el?.data?.bookingClassList?.length > 0 ? el?.data?.bookingClassList?.map(el => el.BookingClassId) : [],
                        CabinClassId: el?.data?.cabinClassList?.length > 0 ?
                            el?.data?.bookingClassList?.length > 0 ?
                                el?.data?.cabinClassList?.map(el => el.CabinClassId)
                                : []
                            : [],
                        ApplicationTypeId: el?.data?.applicationTypeList?.length > 0 ? el?.data?.applicationTypeList?.map(el => el.ApplicationTypeId) : [],
                        MarkUpFor: el?.data?.markupDetails?.[0]?.MarkUpFor,
                        ConfigureDate: el?.data?.markupDetails?.[0]?.ConfigureDate,
                        BookingStartDate: el?.data?.markupDetails?.[0]?.ConfigureDate === 2 ? null : formatDatefor(el?.data?.markupDetails?.[0]?.BookingStartDate) + 'T00:00:00',
                        BookingEndDate: el?.data?.markupDetails?.[0]?.ConfigureDate === 2 ? null : formatDatefor(el?.data?.markupDetails?.[0]?.BookingEndDate) + 'T00:00:00',
                        BookingStartTime: el?.data?.markupDetails?.[0]?.ConfigureDate === 2 ? null : '1900-02-01T' + formatTime(el?.data?.markupDetails?.[0]?.BookingStartTime),
                        BookingEndTime: el?.data?.markupDetails?.[0]?.ConfigureDate === 2 ? null : '1900-02-01T' + formatTime(el?.data?.markupDetails?.[0]?.BookingEndTime),
                        TravelStartDate: el?.data?.markupDetails?.[0]?.ConfigureDate === 1 ? null : formatDatefor(el?.data?.markupDetails?.[0]?.TravelStartDate) + 'T00:00:00',
                        TravelEndDate: el?.data?.markupDetails?.[0]?.ConfigureDate === 1 ? null : formatDatefor(el?.data?.markupDetails?.[0]?.TravelEndDate) + 'T00:00:00',
                        TravelStartTime: el?.data?.markupDetails?.[0]?.ConfigureDate === 1 ? null : '1900-02-01T' + formatTime(el?.data?.markupDetails?.[0]?.TravelStartTime),
                        TravelEndTime: el?.data?.markupDetails?.[0]?.ConfigureDate === 1 ? null : '1900-02-01T' + formatTime(el?.data?.markupDetails?.[0]?.TravelEndTime),
                        MarkUpApplyOn: el?.data?.markupDetails?.[0]?.MarkUpApplyOn,
                        IsConvinienceFee: el?.data?.markupDetails?.[0]?.IsConvinienceFee,
                        IsConvinienceFeePercentage: el?.data?.markupDetails?.[0]?.IsConvinienceFee === true ?
                            el?.data?.markupDetails?.[0]?.IsConvinienceFeePercentage
                            : null,
                        ConvenienceFeesWeb: el?.data?.markupDetails?.[0]?.IsConvinienceFee === true ?
                            el?.data?.markupDetails?.[0]?.ConvenienceFeesWeb
                            : '',
                        ConvenienceFeesMobileWeb: el?.data?.markupDetails?.[0]?.IsConvinienceFee === true ?
                            el?.data?.markupDetails?.[0]?.ConvenienceFeesMobileWeb
                            : '',
                        IsConfigureMarkUpPercentage: el?.data?.markupDetails?.[0]?.IsConfigureMarkUpPercentage,
                        ConfigureMarkUpWeb: el?.data?.markupDetails?.[0]?.ConfigureMarkUpWeb,
                        ConfigureMarkUpMobileWeb: el?.data?.markupDetails?.[0]?.ConfigureMarkUpMobileWeb,
                        BookingDaysId: el?.data?.bookingDays?.length > 0 ?
                            el?.data?.markupDetails?.[0]?.ConfigureDate !== 2
                                ? el?.data?.bookingDays?.includes(1) ? [1, 2, 3, 4, 5, 6, 7, 8] : el?.data?.bookingDays?.map(el => el.WeekDayId)
                                : []
                            : [],
                        TravelDaysId: el?.data?.travelDays?.length > 0
                            ? el?.data?.markupDetails?.[0]?.ConfigureDate !== 1 ?
                                el?.data?.travelDays?.includes(1) ? [1, 2, 3, 4, 5, 6, 7, 8] : el?.data?.travelDays?.map(el => el.WeekDayId)
                                : []
                            : [],
                        UserId: userId?.userMasterID,
                        type: type
                    }
                )
            }
        } else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            dispatch(loaderActions.end());
        }
        dispatch(loaderActions.end());
    }

    const columns = [
        {
            name: 'API Name',
            sortable: true,
            float: "left",
            selector: (data) => data?.SupplierName,
        },
        {
            name: 'Application',
            sortable: true,
            float: "left",
            selector: (data) => data?.ApplicationTypeId,
        },
        {
            name: 'Markup Apply On',
            sortable: true,
            float: "left",
            selector: (data) => data?.MarkupFor,
        },
        {
            name: 'Created Date',
            sortable: true,
            float: "left",
            selector: (data) => formatDate(data?.CreatedDate),
        },
        {
            name: 'Edit',
            selector: data => <button type='button' className='border-0 p-0 bg-body set_disableclr' onClick={() => onEditorView(data, 'edit')} disabled={!useredit}
            ><i className="fa fa-edit eye-icon"></i></button>
        },
        {
            name: 'View',
            selector: data => <button type='button' className='border-0 p-0 bg-body set_disableclr' onClick={() => onEditorView(data, 'view')} ><i className="fa fa-eye" ></i></button>
        }
    ]

    const serachoption = [
        {
            label: 'API Name',
            value: '1'
        },
        {
            label: 'Application',
            value: '2'
        },
        {
            label: 'Markup apply on',
            value: '3'
        },
        {
            label: 'Created Date',
            value: '4'
        },
        {
            label: 'All',
            value: '100'
        },
    ]

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }

    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getDataAll()
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    }

    const matchesSearchValueAll = (issec, fields) => {
        let search = searchvalue
        return fields?.some((data) =>
            issec[data]?.toString()?.toLowerCase()?.includes(search?.toLowerCase())
        );
    }

    const handleSearch = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            var filteritem = filterData;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec?.SupplierName?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec?.ApplicationTypeId?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            if (searchby === '3') {
                filterdata = filteritem?.filter(issec => issec?.MarkupFor?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            if (searchby === '4') {
                filterdata = filteritem?.filter(issec => formatDate(issec?.CreatedDate)?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            if (searchby === '100') {
                filterdata = filteritem?.filter((issec) => {
                    let keys = [
                        "SupplierName",
                        "ApplicationTypeId",
                        "MarkupFor",
                        "CreatedDate",
                    ]
                    return matchesSearchValueAll(issec, keys) && issec
                });
            }
            setGetListData(filterdata)
        }
    }


    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getDataAll();
    }


    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return date ? [day, month, year].join('/') : null;
    }
    function formatDatefor(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return date ? [year, month, day].join('-') : null;
    }

    function formatTime(date) {
        var d = new Date(date),
            hour = d.getHours(),
            minut = d.getMinutes(),
            second = d.getSeconds();

        if (hour.toString().length < 2)
            hour = '0' + hour;
        if (minut.toString().length < 2)
            minut = '0' + minut;
        if (second.toString().length < 2)
            second = '0' + second;

        return date ? [hour, minut, second].join(':') : null;
    }

    const parseIntegerWithSign = (numdata) => {
        try {
            const sanitizedInput = numdata.replace(/[^0-9-]/g, '');
            if (sanitizedInput === '' || sanitizedInput === '-') {
                throw new Error("Invalid input: must be a valid integer.");
            }
            const parsedValue = parseInt(sanitizedInput, 10);

            return parsedValue;
        } catch (error) {
            console.error(error.message);
            return null;
        }
    }
    const disabledTime = (type, startdate, enddate, time) => {
        return {
            disabledHours: () => {
                const hours = [];
                if (formatDate(startdate) === formatDate(enddate)
                    || (formatDate(startdate) !== null && formatDate(enddate)?.length === 0)
                    || (formatDate(startdate)?.length === 0 && formatDate(enddate) !== null)) {
                    if (new Date(time === null ? '' : time)?.getHours()) {
                        for (let i = (type === 'less' ? new Date(time)?.getHours() + 1 : 0); ((type === 'less' ? i > new Date(time)?.getHours() : i < new Date(time)?.getHours()) && i < 24); i++) {
                            hours.push(i);
                        }
                    }
                }
                return hours;
            },
            disabledMinutes: (hour) => {
                const minutes = [];
                if (formatDate(startdate) === formatDate(enddate)
                    || (formatDate(startdate) !== null && formatDate(enddate)?.length === 0)
                    || (formatDate(startdate)?.length === 0 && formatDate(enddate) !== null)) {
                    if (hour === new Date(time === null ? '' : time)?.getHours()) {
                        for (let i = (type === 'less' ? new Date(time)?.getMinutes() : 0); ((type === 'less' ? i >= new Date(time)?.getMinutes() : i <= new Date(time)?.getMinutes()) && i < 60); i++) {
                            minutes.push(i);
                        }
                    }
                }
                return minutes;
            },
        }
    };

    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            formValue.UserId = userId?.userMasterID
            getData();
            getDataAll();
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
    }, [])

    return (
        <>
            <form action="" >
                <div className="mb-3 row mx-1">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div className="col-lg-4 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" className="col-form-label required">Select API</label>
                                    </div>
                                    <div className="col-lg-8 mb-1 col-md-12">
                                        <Select
                                            id="SupplierId"
                                            className="dropdown select_dropdown"
                                            options={dropdownData?.apiConfigList}
                                            value={dropdownData?.apiConfigList?.filter((val) => (val.value === formValue?.APIConfigurationId))}
                                            onChange={(e) => onChange('APIConfigurationId', e?.value, false)}
                                            isDisabled={formValue?.type === 'edit' || formValue?.type === 'view' ? true : false}
                                            isSearchable
                                        />
                                        {formError?.APIConfigurationId && <p className='text-danger text-start mb-0'>{formError?.APIConfigurationId}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div className="col-lg-4 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" className="col-form-label required">Select Airline</label>
                                    </div>
                                    <div className="col-lg-8 mb-1 col-md-12">
                                        <Select
                                            id="SupplierId"
                                            isMulti
                                            className="dropdown select_dropdown"
                                            options={apiAirlineList}
                                            value={apiAirlineList?.filter((val) => formValue?.AirLineId?.includes(val.value)) || []}
                                            onChange={(e) => onChange('AirLineId', e, true)}
                                            isDisabled={formValue?.type === 'view' ? true : false || formValue?.APIConfigurationId ? false : true}
                                            isSearchable
                                        />
                                        {formError?.AirLineId && <p className='text-danger text-start mb-0'>{formError?.AirLineId}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div className="col-lg-4 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" className="col-form-label required">Booking Class</label>
                                    </div>
                                    <div className="col-lg-8 mb-1 col-md-12">
                                        <Select
                                            id="SupplierId"
                                            isMulti
                                            className="dropdown select_dropdown"
                                            options={dropdownData?.bookingClassList}
                                            value={dropdownData?.bookingClassList?.filter((val) => formValue?.BookingClassId?.includes(val.value)) || []}
                                            onChange={(e) => onChange('BookingClassId', e, true)}
                                            isDisabled={formValue?.type === 'view' ? true : false}
                                            isSearchable
                                        />
                                        {formError?.BookingClassId && <p className='text-danger text-start mb-0'>{formError?.BookingClassId}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div className="col-lg-4 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" className="col-form-label required">Cabin Class</label>
                                    </div>
                                    <div className="col-lg-8 mb-1 col-md-12">
                                        <Select
                                            id="SupplierId"
                                            isMulti
                                            className="dropdown select_dropdown"
                                            options={cabinClassList}
                                            value={cabinClassList?.filter((val) => formValue?.CabinClassId?.includes(val.value)) || []}
                                            onChange={(e) => onChange('CabinClassId', e, true)}
                                            isDisabled={formValue?.type === 'view' ? true : false || formValue?.BookingClassId?.length > 0 ? false : true}
                                            isSearchable
                                        />
                                        {formError?.CabinClassId && <p className='text-danger text-start mb-0'>{formError?.CabinClassId}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div className="col-lg-4 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" className="col-form-label required">Application Type</label>
                                    </div>
                                    <div className="col-lg-8 mb-1 col-md-12">
                                        <Select
                                            id="SupplierId"
                                            isMulti
                                            className="dropdown select_dropdown"
                                            options={dropdownData?.applicationTypeList}
                                            value={dropdownData?.applicationTypeList?.filter((val) => formValue?.ApplicationTypeId?.includes(val.value)) || []}
                                            onChange={(e) => onChange('ApplicationTypeId', e, true)}
                                            isDisabled={formValue?.type === 'view' ? true : false}
                                            isSearchable
                                        />
                                        {formError?.ApplicationTypeId && <p className='text-danger text-start mb-0'>{formError?.ApplicationTypeId}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div className="col-lg-4 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" className="col-form-label required">Markup For</label>
                                    </div>
                                    <div className="col-lg-8 mb-1 col-md-12 pt-2">
                                        {dropdownData?.markupForOptiosList && dropdownData?.markupForOptiosList?.map((ele, index) => {
                                            return (<div className="form-check-inline">
                                                <input className="form-check-input" type="radio" name="markupForOptiosList" id="markupForOptiosList" value={ele?.MarkupForId} key={index} onChange={(e) => onChange('MarkUpFor', ele?.MarkupForId, false)}
                                                    checked={formValue?.MarkUpFor === ele.MarkupForId} disabled={formValue?.type === 'view' ? true : false} />
                                                <label className="form-check-label ms-1">{ele?.Name}</label>
                                            </div>
                                            )
                                        })}
                                        {formError?.MarkUpFor && <p className='text-danger text-start mb-0'>{formError?.MarkUpFor}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-sm-12 '>
                                <div className='row'>
                                    <div className="col-lg-2 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" className="col-form-label required">Configure Date</label>
                                    </div>
                                    <div className="col-lg-10 mb-1 col-md-12 pt-2">
                                        {dropdownData?.configureDateOptionsList && dropdownData?.configureDateOptionsList?.map((ele, index) => {
                                            return (<div className="form-check-inline">
                                                <input className="form-check-input"
                                                    type="radio"
                                                    name="configureDateOptionsList"
                                                    id="configureDateOptionsList"
                                                    value={ele?.ConfigureDateId}
                                                    key={index}
                                                    onChange={(e) => onChange('ConfigureDate', ele?.ConfigureDateId, false)}
                                                    checked={formValue?.ConfigureDate === ele.ConfigureDateId}
                                                    disabled={formValue?.type === 'view' ? true : false} />
                                                <label className="form-check-label ms-1">{ele?.Name}</label>
                                            </div>
                                            )
                                        })}
                                        {formError?.ConfigureDate && <p className='text-danger text-start mb-0'>{formError?.ConfigureDate}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div className="col-lg-4 col-md-12 mb-1"></div>
                                    <div className="col-lg-8 mb-1 col-md-12">
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-6">
                                                <label htmlFor="inputPassword6" className="col-form-label required">Booking Start Date</label>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <label htmlFor="inputPassword6" className="col-form-label required">Booking End Date</label>
                                            </div>
                                            <div className="col-lg-6 mb-1 col-md-6">
                                                <DatePicker
                                                    className='antd_input w-100'
                                                    format='DD/MM/YYYY'
                                                    placeholder="DD/MM/YYYY"
                                                    minDate={dayjs(formatDatefor(new Date()), "YYYY-MM-DD")}
                                                    maxDate={dayjs(formatDatefor((formValue?.BookingEndDate !== null && formValue?.BookingEndDate !== '') ? formValue?.BookingEndDate : false, "YYYY-MM-DD"))}
                                                    value={
                                                        (formValue?.BookingStartDate !== null && formValue?.BookingStartDate !== '') ? dayjs(formatDatefor(formValue?.BookingStartDate), 'YYYY/MM/DD') : null
                                                    }
                                                    onChange={(e) => onChange('BookingStartDate', e ? `${formatDatefor(e?.$d)}T00:00:00` : null, false)}
                                                    disabled={formValue?.type === 'view' ? true : false || formValue?.ConfigureDate === 2 ? true : formValue?.ConfigureDate === 0 ? true : false}
                                                />
                                                {formError?.BookingStartDate && <p className='text-danger text-start mb-0'>{formError?.BookingStartDate}</p>}
                                            </div>
                                            <div className="col-lg-6 mb-1 col-md-6">
                                                <DatePicker
                                                    className='antd_input w-100'
                                                    format='DD/MM/YYYY'
                                                    placeholder="DD/MM/YYYY"
                                                    minDate={dayjs(formatDatefor((formValue?.BookingStartDate !== null && formValue?.BookingStartDate !== '') ? formValue?.BookingStartDate : false), "YYYY-MM-DD")}
                                                    value={
                                                        (formValue?.BookingEndDate !== null && formValue?.BookingEndDate !== '') ? dayjs(formatDatefor(formValue?.BookingEndDate), 'YYYY/MM/DD') : null
                                                    }
                                                    onChange={(e) => onChange('BookingEndDate', e ? `${formatDatefor(e?.$d)}T00:00:00` : null, false)}
                                                    disabled={formValue?.type === 'view' ? true : false || formValue?.ConfigureDate === 2 ? true : formValue?.ConfigureDate === 0 ? true : false}
                                                />
                                                {formError?.BookingEndDate && <p className='text-danger text-start mb-0'>{formError?.BookingEndDate}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div className="col-lg-8 mb-1 col-md-12">
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-6">
                                                <label htmlFor="inputPassword6" className="col-form-label required">Booking Start Time</label>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <label htmlFor="inputPassword6" className="col-form-label required">Booking End Time</label>
                                            </div>
                                            <div className="col-lg-6 mb-1 col-md-6">
                                                <TimePicker
                                                    className='antd_input w-100'
                                                    format='HH:mm:ss'
                                                    placeholder='HH:mm:ss'
                                                    disabledTime={() => disabledTime('less', formValue?.BookingStartDate, formValue?.BookingEndDate, formValue?.BookingEndTime)}
                                                    value={
                                                        (formValue?.BookingStartTime !== null && formValue?.BookingStartTime !== '') ? dayjs(formatTime(new Date(formValue?.BookingStartTime)), 'HH:mm:ss') : null
                                                    }
                                                    onChange={(e) => onChange('BookingStartTime', e ? `${formatDatefor(formValue?.BookingStartDate ? formValue?.BookingStartDate : new Date())}T${formatTime(e?.$d)}` : null, false)}
                                                    disabled={formValue?.type === 'view' ? true : false || formValue?.ConfigureDate === 2 ? true : formValue?.ConfigureDate === 0 ? true : false}
                                                />
                                                {formError?.BookingStartTime && <p className='text-danger text-start mb-0'>{formError?.BookingStartTime}</p>}
                                            </div>
                                            <div className="col-lg-6 mb-1 col-md-6">
                                                <TimePicker
                                                    className='antd_input w-100'
                                                    fformat='HH:mm:ss'
                                                    placeholder='HH:mm:ss'
                                                    disabledTime={() => disabledTime('more', formValue?.BookingStartDate, formValue?.BookingEndDate, formValue?.BookingStartTime)}
                                                    value={
                                                        (formValue?.BookingEndTime !== null && formValue?.BookingEndTime !== '') ? dayjs(formatTime(new Date(formValue?.BookingEndTime)), 'HH:mm:ss') : null
                                                    }
                                                    onChange={(e) => onChange('BookingEndTime', e ? `${formatDatefor(formValue?.BookingEndDate ? formValue?.BookingEndDate : new Date())}T${formatTime(e?.$d)}` : null, false)}
                                                    disabled={formValue?.type === 'view' ? true : false || formValue?.ConfigureDate === 2 ? true : formValue?.ConfigureDate === 0 ? true : false}
                                                />
                                                {formError?.BookingEndTime && <p className='text-danger text-start mb-0'>{formError?.BookingEndTime}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12 mb-1"></div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-sm-12 '>
                                <div className='row'>
                                    <div className="col-lg-2 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" className="col-form-label required">Select Days</label>
                                    </div>
                                    <div className="col-lg-10 mb-1 col-md-12 pt-2">
                                        {dropdownData?.weekDays && dropdownData?.weekDays?.map((ele, index) => {
                                            return (
                                                <div className="form-check-inline" key={index}>
                                                    <input className="form-check-input"
                                                        type="checkbox"
                                                        name="weekDays"
                                                        id="weekDays"
                                                        value={ele?.WeekDayId}
                                                        onChange={(e) => onChangeCheckbox('BookingDaysId', ele?.WeekDayId, false)}
                                                        checked={formValue?.BookingDaysId?.includes(ele?.WeekDayId)}
                                                        disabled={formValue?.type === 'view' ? true : false || (formValue?.BookingStartDate === '' || formValue?.BookingStartDate === null || formValue?.BookingEndDate === '' || formValue?.BookingEndDate === null)} />
                                                    <label className="form-check-label ms-1">{ele?.WeekDayName}</label>
                                                </div>
                                            )
                                        })}
                                        {formError?.BookingDaysId && <p className='text-danger text-start mb-0'>{formError?.BookingDaysId}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div className="col-lg-4 col-md-12 mb-1"></div>
                                    <div className="col-lg-8 mb-1 col-md-12">
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-6">
                                                <label htmlFor="inputPassword6" className="col-form-label required">Travel Start Date</label>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <label htmlFor="inputPassword6" className="col-form-label required">Travel End Date</label>
                                            </div>
                                            <div className="col-lg-6 mb-1 col-md-6">
                                                <DatePicker
                                                    className='antd_input w-100'
                                                    format='DD/MM/YYYY'
                                                    placeholder="DD/MM/YYYY"
                                                    minDate={dayjs(formatDatefor(new Date()), "YYYY-MM-DD")}
                                                    maxDate={dayjs(formatDatefor((formValue?.TravelEndDate !== null && formValue?.TravelEndDate !== '') ? formValue?.TravelEndDate : false, "YYYY-MM-DD"))}
                                                    value={
                                                        (formValue?.TravelStartDate !== null && formValue?.TravelStartDate !== '') ? dayjs(formValue?.TravelStartDate, 'YYYY/MM/DD') : null
                                                    }
                                                    onChange={(e) => onChange('TravelStartDate', e ? `${formatDatefor(e?.$d)}T00:00:00` : null, false)}
                                                    disabled={formValue?.type === 'view' ? true : false || formValue?.ConfigureDate === 1 ? true : formValue?.ConfigureDate === 0 ? true : false}
                                                />
                                                {formError?.TravelStartDate && <p className='text-danger text-start mb-0'>{formError?.TravelStartDate}</p>}
                                            </div>
                                            <div className="col-lg-6 mb-1 col-md-6">
                                                <DatePicker
                                                    className='antd_input w-100'
                                                    format='DD/MM/YYYY'
                                                    placeholder="DD/MM/YYYY"
                                                    minDate={dayjs(formatDatefor((formValue?.TravelStartDate !== null && formValue?.TravelStartDate !== '') ? formValue?.TravelStartDate : false), "YYYY-MM-DD")}
                                                    value={
                                                        (formValue?.TravelEndDate !== null && formValue?.TravelEndDate !== '') ? dayjs(formValue?.TravelEndDate, 'YYYY/MM/DD') : null
                                                    }
                                                    onChange={(e) => onChange('TravelEndDate', e ? `${formatDatefor(e?.$d)}T00:00:00` : null, false)}
                                                    disabled={formValue?.type === 'view' ? true : false || formValue?.ConfigureDate === 1 ? true : formValue?.ConfigureDate === 0 ? true : false}
                                                />
                                                {formError?.TravelEndDate && <p className='text-danger text-start mb-0'>{formError?.TravelEndDate}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div className="col-lg-8 mb-1 col-md-12">
                                        <div className='row'>
                                            <div className="col-lg-6 col-md-6">
                                                <label htmlFor="inputPassword6" className="col-form-label required">Travel Start Time</label>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <label htmlFor="inputPassword6" className="col-form-label required">Travel End Time</label>
                                            </div>
                                            <div className="col-lg-6 mb-1 col-md-6">
                                                <TimePicker
                                                    className='antd_input w-100'
                                                    format='HH:mm:ss'
                                                    placeholder='HH:mm:ss'
                                                    disabledTime={() => disabledTime('less', formValue?.TravelStartDate, formValue?.TravelEndDate, formValue?.TravelEndTime)}
                                                    value={
                                                        (formValue?.TravelStartTime !== null && formValue?.TravelStartTime !== '') ? dayjs(formatTime(new Date(formValue?.TravelStartTime)), 'HH:mm:ss') : null
                                                    }
                                                    onChange={(e) => onChange('TravelStartTime', e ? `1900-02-01T${formatTime(e?.$d)}` : null, false)}
                                                    disabled={formValue?.type === 'view' ? true : false || formValue?.ConfigureDate === 1 ? true : formValue?.ConfigureDate === 0 ? true : false}
                                                />
                                                {formError?.TravelStartTime && <p className='text-danger text-start mb-0'>{formError?.TravelStartTime}</p>}
                                            </div>
                                            <div className="col-lg-6 mb-1 col-md-6">
                                                <TimePicker
                                                    className='antd_input w-100'
                                                    format='HH:mm:ss'
                                                    placeholder='HH:mm:ss'
                                                    disabledTime={() => disabledTime('more', formValue?.TravelStartDate, formValue?.TravelEndDate, formValue?.TravelStartTime)}
                                                    value={
                                                        (formValue?.TravelEndTime !== null && formValue?.TravelEndTime !== '') ? dayjs(formatTime(new Date(formValue?.TravelEndTime)), 'HH:mm:ss') : null
                                                    }
                                                    onChange={(e) => onChange('TravelEndTime', e ? `1900-02-01T${formatTime(e?.$d)}` : null, false)}
                                                    disabled={formValue?.type === 'view' ? true : false || formValue?.ConfigureDate === 1 ? true : formValue?.ConfigureDate === 0 ? true : false}
                                                />
                                                {formError?.TravelEndTime && <p className='text-danger text-start mb-0'>{formError?.TravelEndTime}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12 mb-1"></div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-sm-12 '>
                                <div className='row'>
                                    <div className="col-lg-2 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" className="col-form-label required">Select Days</label>
                                    </div>
                                    <div className="col-lg-10 mb-1 col-md-12 pt-2">
                                        {dropdownData?.weekDays && dropdownData?.weekDays?.map((ele, index) => {
                                            return (
                                                <div className="form-check-inline" key={index}>
                                                    <input className="form-check-input"
                                                        type="checkbox"
                                                        name="weekDays"
                                                        id="weekDays"
                                                        value={ele?.WeekDayId}
                                                        onChange={(e) => onChangeCheckbox('TravelDaysId', ele?.WeekDayId, false)}
                                                        checked={formValue?.TravelDaysId?.includes(ele?.WeekDayId)}
                                                        disabled={formValue?.type === 'view' ? true : false || (formValue?.TravelStartDate === '' || formValue?.TravelStartDate === null || formValue?.TravelEndDate === '' || formValue?.TravelEndDate === null)} />
                                                    <label className="form-check-label ms-1">{ele?.WeekDayName}</label>
                                                </div>
                                            )
                                        })}
                                        {formError?.TravelDaysId && <p className='text-danger text-start mb-0'>{formError?.TravelDaysId}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-sm-12 '>
                                <div className='row'>
                                    <div className="col-lg-2 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" className="col-form-label required">Markup Apply On</label>
                                    </div>
                                    <div className="col-lg-8 mb-1 col-md-12 pt-2">
                                        {dropdownData?.markupApplyOnOPtionsList && dropdownData?.markupApplyOnOPtionsList?.map((ele, index) => {
                                            return (<div className="form-check-inline" key={index}>
                                                <input className="form-check-input" type="radio" name="markupApplyOnOPtionsList" id="markupApplyOnOPtionsList" value={ele?.MarkupApplyOnId} onChange={(e) => onChange('MarkUpApplyOn', ele?.MarkupApplyOnId, false)}
                                                    checked={formValue?.MarkUpApplyOn === ele.MarkupApplyOnId} disabled={formValue?.type === 'view' ? true : false} />
                                                <label className="form-check-label ms-1">{ele?.Name}</label>
                                            </div>
                                            )
                                        })}
                                        {formError?.MarkUpApplyOn && <p className='text-danger text-start mb-0'>{formError?.MarkUpApplyOn}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-sm-12 '>
                                <div className='row'>
                                    <div className="col-lg-2 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" className="col-form-label required">Add Convenience Fees</label>
                                    </div>
                                    <div className="col-lg-8 mb-1 col-md-12 pt-2">
                                        <div className="form-check-inline">
                                            <input className="form-check-input" type="radio" name="IsConvinienceFee" id="IsConvinienceFee" value={true} onChange={(e) => onChange('IsConvinienceFee', true, false)}
                                                checked={formValue?.IsConvinienceFee === true} disabled={formValue?.type === 'view' ? true : false} />
                                            <label className="form-check-label ms-1">Yes</label>
                                        </div>
                                        <div className="form-check-inline">
                                            <input className="form-check-input" type="radio" name="IsConvinienceFee" id="IsConvinienceFee" value={false} onChange={(e) => onChange('IsConvinienceFee', false, false)}
                                                checked={formValue?.IsConvinienceFee === false} disabled={formValue?.type === 'view' ? true : false} />
                                            <label className="form-check-label ms-1">No</label>
                                        </div>
                                        {formError?.IsConvinienceFee && <p className='text-danger text-start mb-0'>{formError?.IsConvinienceFee}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-sm-12 '>
                                <div className='row'>
                                    <div className="col-lg-2 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" className="col-form-label required">Convenience Fees</label>
                                    </div>
                                    <div className="col-lg-8 mb-1 col-md-12 pt-2">
                                        <div className="form-check-inline">
                                            <input className="form-check-input" type="radio" name="IsConvinienceFeePercentage" id="IsConvinienceFeePercentage" value={true} onChange={(e) => onChange('IsConvinienceFeePercentage', true, false)}
                                                checked={formValue?.IsConvinienceFeePercentage === true} disabled={formValue?.type === 'view' ? true : false || (formValue?.IsConvinienceFee ? false : true)} />
                                            <label className="form-check-label ms-1">Percentage</label>
                                        </div>
                                        <div className="form-check-inline">
                                            <input className="form-check-input" type="radio" name="IsConvinienceFeePercentage" id="IsConvinienceFeePercentage" value={false} onChange={(e) => onChange('IsConvinienceFeePercentage', false, false)}
                                                checked={formValue?.IsConvinienceFeePercentage === false} disabled={formValue?.type === 'view' ? true : false || (formValue?.IsConvinienceFee ? false : true)} />
                                            <label className="form-check-label ms-1">Fixed</label>
                                        </div>
                                        {formError?.IsConvinienceFeePercentage && <p className='text-danger text-start mb-0'>{formError?.IsConvinienceFeePercentage}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-12 '>
                                <div className='row'>
                                    <div className="col-lg-4 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" className="col-form-label"></label>
                                    </div>
                                    <div className="col-lg-8 mb-1 col-md-12">
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <div className="input-group mb-1">
                                                    <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa-solid fa-globe"></i></span>
                                                    <input type="text"
                                                        className="form-control"
                                                        aria-label="Sizing example input"
                                                        aria-describedby="inputGroup-sizing-default"
                                                        maxLength={formValue?.IsConvinienceFeePercentage ? 3 : 5}
                                                        value={formValue?.ConvenienceFeesWeb}
                                                        onChange={(e) => onChange('ConvenienceFeesWeb', onlyNumeric(e.target.value) === '' ? '' : Number(onlyNumeric(e.target.value)), false)}

                                                        disabled={formValue?.type === 'view' ? true : false || (formValue?.IsConvinienceFeePercentage !== null ? false : true)} />
                                                </div>
                                                {formError?.ConvenienceFeesWeb && <p className='text-danger text-start mb-0'>{formError?.ConvenienceFeesWeb}</p>}
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className="input-group mb-1">
                                                    <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa-solid fa-mobile-screen-button"></i></span>
                                                    <input type="text"
                                                        className="form-control"
                                                        aria-label="Sizing example input"
                                                        aria-describedby="inputGroup-sizing-default"
                                                        maxLength={formValue?.IsConvinienceFeePercentage ? 3 : 5}
                                                        value={formValue?.ConvenienceFeesMobileWeb}
                                                        onChange={(e) => onChange('ConvenienceFeesMobileWeb', onlyNumeric(e.target.value) === '' ? '' : Number(onlyNumeric(e.target.value)), false)}

                                                        disabled={formValue?.type === 'view' ? true : false || (formValue?.IsConvinienceFeePercentage !== null ? false : true)} />
                                                </div>
                                                {formError?.ConvenienceFeesMobileWeb && <p className='text-danger text-start mb-0'>{formError?.ConvenienceFeesMobileWeb}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-sm-12 '>
                                <div className='row'>
                                    <div className="col-lg-2 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" className="col-form-label required">Configure Markup</label>
                                    </div>
                                    <div className="col-lg-8 mb-1 col-md-12 pt-2">
                                        <div className="form-check-inline">
                                            <input className="form-check-input" type="radio" name="IsConfigureMarkUpPercentage" id="IsConfigureMarkUpPercentage" value={true} onChange={(e) => onChange('IsConfigureMarkUpPercentage', true, false)}
                                                checked={formValue?.IsConfigureMarkUpPercentage === true} disabled={formValue?.type === 'view' ? true : false} />
                                            <label className="form-check-label ms-1">Percentage</label>
                                        </div>
                                        <div className="form-check-inline">
                                            <input className="form-check-input" type="radio" name="IsConfigureMarkUpPercentage" id="IsConfigureMarkUpPercentage" value={false} onChange={(e) => onChange('IsConfigureMarkUpPercentage', false, false)}
                                                checked={formValue?.IsConfigureMarkUpPercentage === false} disabled={formValue?.type === 'view' ? true : false} />
                                            <label className="form-check-label ms-1">Fixed</label>
                                        </div>
                                        {formError?.IsConfigureMarkUpPercentage && <p className='text-danger text-start mb-0'>{formError?.IsConfigureMarkUpPercentage}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-12 '>
                                <div className='row'>
                                    <div className="col-lg-4 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" className="col-form-label"></label>
                                    </div>
                                    <div className="col-lg-8 mb-1 col-md-12">
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <div className="input-group mb-1">
                                                    <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa-solid fa-globe"></i></span>
                                                    <input type="text"
                                                        className="form-control"
                                                        aria-label="Sizing example input"
                                                        aria-describedby="inputGroup-sizing-default"
                                                        maxLength={formValue?.IsConfigureMarkUpPercentage ?
                                                            formValue?.ConfigureMarkUpWeb?.toString()?.includes('-') ? 4 : 3
                                                            : formValue?.ConfigureMarkUpWeb?.toString()?.includes('-') ? 6 : 5}
                                                        value={formValue?.ConfigureMarkUpWeb}
                                                        onChange={(e) => onChange('ConfigureMarkUpWeb', onlyNumericwithminus(e.target.value) === '' ?
                                                                ''
                                                                : /^-?\d*$/.test(e.target.value) ? parseIntegerWithSign(e.target.value) : '', false)}
                                                        disabled={formValue?.type === 'view' ? true : false || (formValue?.IsConfigureMarkUpPercentage !== null ? false : true)} />
                                                </div>
                                                {formError?.ConfigureMarkUpWeb && <p className='text-danger text-start mb-0'>{formError?.ConfigureMarkUpWeb}</p>}
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className="input-group mb-1">
                                                    <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa-solid fa-mobile-screen-button"></i></span>
                                                    <input type="text"
                                                        className="form-control"
                                                        aria-label="Sizing example input"
                                                        aria-describedby="inputGroup-sizing-default"
                                                        maxLength={formValue?.IsConfigureMarkUpPercentage ?
                                                            formValue?.ConfigureMarkUpMobileWeb?.toString()?.includes('-') ? 4 : 3
                                                            : formValue?.ConfigureMarkUpMobileWeb?.toString()?.includes('-') ? 6 : 5}
                                                        value={formValue?.ConfigureMarkUpMobileWeb}
                                                        onChange={(e) => onChange('ConfigureMarkUpMobileWeb', onlyNumericwithminus(e.target.value) === '' ?
                                                                ''
                                                                : /^-?\d*$/.test(e.target.value) ? parseIntegerWithSign(e.target.value) : '', false)}
                                                        disabled={formValue?.type === 'view' ? true : false || (formValue?.IsConfigureMarkUpPercentage !== null ? false : true)} />
                                                </div>
                                                {formError?.ConfigureMarkUpMobileWeb && <p className='text-danger text-start mb-0'>{formError?.ConfigureMarkUpMobileWeb}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-3 d-flex justify-content-center">
                    {
                        formValue?.type === 'edit'
                            ? (<button className="btn btn-primary mx-1" id="btnAdd" type='button'
                                onClick={() => onSubmitData()}
                                disabled={editDisable || !useredit}
                            >Update</button>
                            )
                            : (<button className="btn btn-primary mx-1" id="btnAdd" type='button'
                                onClick={() => onSubmitData()}
                                disabled={(formValue?.type === 'view' ? editDisable : false) || !useradd}
                            >Add</button>)
                    }
                    <button type="button" className="btn btn-primary mx-1" id="btnClear" onClick={onClearButton}>Clear</button>
                </div>
                <div className="row mt-3">
                    <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                        <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                            <Select
                                className="dropdown"
                                options={serachoption}
                                value={serachoption?.filter(function (serachoption) {
                                    return serachoption.value === searchby;
                                })}
                                onChange={handleSearchBy}
                                isDisabled={serchdisable ? true : false}
                                isSearchable={false}

                            />
                        </div>
                        <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                            <div className='clrbutton'>
                                <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" className="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue || ''} disabled={serchdisable ? true : false} />
                                <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                            </div>
                        </div>
                        <div className="p-2 text-center">
                            <button type="button" className="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleSearch} >Search</button>
                        </div>
                    </div>

                </div>
                <CustomTable
                    columns={columns}
                    data={getDataList}
                    PdfExport={false}
                    ExportToExcel={false}
                    disabled={!userexport}
                />
            </form>
        </>
    )
}

export default APIConfigurationMarkupFlight