import React from 'react'

export function PolicyTitleAndTabButton({ title, ChangeTab, value, disabledforpolicy, disabled }) {
    return (
        <div className='row align-items-center'>
            <div className='col-6'>
                <h6 className='fw-bold room_header '>{title}</h6>
            </div>
            <div className='col-6 d-flex justify-content-end '>
                <div className='plicies-tabs-btn d-flex'>
                    <button
                        type='button'
                        className={`btn plicies-tabs-in-btn me-2 curser-pointer ${value === 'Percent' ? 'tab-btn-white' : ''}`}
                        onClick={(e) => { ChangeTab('Percent') }}
                        disabled={disabled ? disabled : disabledforpolicy === null ? false : !disabledforpolicy}
                    >
                        % Percent
                    </button>
                    <button
                        type='button'
                        className={`btn plicies-tabs-in-btn ${value === 'FixedAmount' ? 'tab-btn-white' : ''}`}
                        onClick={(e) => { ChangeTab('FixedAmount') }}
                        disabled={disabled ? disabled : disabledforpolicy === null ? false : disabledforpolicy}
                    >
                        Fixed Amount
                    </button>
                </div>
            </div>
        </div>
    )
}

export function PaymentPolicyTitleAndTabButton({ title, ChangeTab, value, disabledforpayment, disabled }) {
    return (
        <div className='row align-items-center'>
            <div className='col-6'>
                <h6 className='fw-bold room_header '>{title}</h6>
            </div>
            <div className='col-6 d-flex justify-content-end '>
                <div className='plicies-tabs-btn d-flex'>
                    <button
                        type='button'
                        className={`btn plicies-tabs-in-btn me-2 curser-pointer ${value === 'Percent' ? 'tab-btn-white' : ''}`}
                        onClick={(e) => { ChangeTab('Percent') }}
                        disabled={disabled ? disabled : disabledforpayment === null ? false : !disabledforpayment}
                    >
                        % Percent
                    </button>
                    <button
                        type='button'
                        className={`btn plicies-tabs-in-btn ${value === 'FixedAmount' ? 'tab-btn-white' : ''}`}
                        onClick={(e) => { ChangeTab('FixedAmount') }}
                        disabled={disabled ? disabled : disabledforpayment === null ? false : disabledforpayment}
                    >
                        Fixed Amount
                    </button>
                </div>
            </div>
        </div>
    )
}