import React, { useEffect, useMemo, useState } from 'react'
import CustomTable from '../../customControl/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { loaderActions } from '../../_actions/loader.actions';
import { getAllData, postUsersData, verifytoken } from '../../services/ApiCallingUserServices';
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { updatebooking, updatebookingend } from '../../_actions/user.action';


const AllQueries = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [queriesList, setQueriesList] = useState([])
    const [filterItems, setFilterItems] = useState([])
    const [searchvalue, setSearchValue] = useState('');
    const [searchby, setSerachBy] = useState('All');
    const [serchdisable, setSerachDisable] = useState(false);
    const [clrshow, setsclshow] = useState(false);

    const user = useSelector(store => store.userstatus.tba);
    const [userRights, setUserRights] = useState({});

    //#region handle Rights
    useEffect(() => {
        let pagelink = user?.find(x => x?.PageLink?.toLowerCase() === window?.location?.pathname?.toLowerCase());
        let mid = user?.find(x => x?.MenuId === pagelink?.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserRights(obj);
        });
    }, [user])
    //#endregion

    function formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return date ? [day, month, year].join('/') : '';
    }

    const getallqueries = async () => {
        const allqueries = await getAllData('/master/v1/getallqueries')
        if (allqueries?.status) {
            allqueries?.data?.map((obj, index) => {
                obj['SrNo'] = index + 1;
                obj['JourneyDate'] = formatDate(obj?.StartDate);
                obj['StatusName'] = obj.QueryStatus ? 'Acknowledged' : 'Pending'
                obj['Traveler'] = obj.NoOfTraveler > 0 ? obj.NoOfTraveler : ''
                return obj;
            })
            setQueriesList(allqueries?.data ? allqueries?.data : []);
            setFilterItems(allqueries?.data ? allqueries?.data : []);
        }
        else {
            setQueriesList([]);
            setFilterItems([]);
        }
    }


    //#region Call All API
    async function fetchData() {
        dispatch(loaderActions.start());
        const statusoftoken = await verifytoken();
        if (statusoftoken) {
            await Promise.all([
                getallqueries()
            ])
            dispatch(loaderActions.end());
        }
        else {
            dispatch(loaderActions.end());
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' });
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    const getBookingStatus = (status) => {
        switch (status) {
            case true: return 'green-background';
            case false: return 'yellow-background';
            default: return '';
        }
    }

    //#region Table Columns
    const columns = [
        {
            name: 'Sr. No',
            value: 'SrNo',
            sortable: true,
            float: "left",
            minWidth: '50px',
            maxWidth: '50px',
            selector: (data) => data?.SrNo,
            format: (data) => <div title={data?.SrNo} >{data?.SrNo} </div>,
        },
        {
            name: 'Product',
            value: 'Product',
            sortable: true,
            float: "left",
            minWidth: '200px',
            selector: (data) => data?.Product,
            format: (data) => <div title={data?.Product} >{data?.Product} </div>,
        },
        {
            name: 'Name',
            value: 'FullName',
            sortable: true,
            float: "left",
            minWidth: '150px',
            selector: (data) => data?.FullName,
            format: (data) => <div title={data?.FullName} >{data?.FullName} </div>,
        },
        {
            name: 'Contact Number',
            value: 'MobileNo',
            sortable: true,
            float: "left",
            minWidth: '150px',
            selector: (data) => data?.MobileNo,
            format: (data) => <div title={data?.MobileNo} >{data?.MobileNo} </div>,
        },
        {
            name: 'Email ID',
            value: 'Email',
            sortable: true,
            float: "left",
            minWidth: '200px',
            selector: (data) => data?.Email,
            format: (data) => <div title={data?.Email} >{data?.Email} </div>,
        },
        {
            name: 'No. of Pax/traveler',
            value: 'Traveler',
            sortable: true,
            float: "left",
            minWidth: '50px',
            maxWidth: '50px',
            selector: (data) => data?.Traveler,
            format: (data) => <div title={data?.Traveler} >{data?.Traveler} </div>,
        },
        {
            name: 'journey date',
            value: 'JourneyDate',
            sortable: true,
            float: "left",
            selector: (data) => data?.JourneyDate,
            format: (data) => <div title={data?.JourneyDate} >{data?.JourneyDate} </div>,
        },
        {
            name: 'query',
            value: 'Query',
            sortable: true,
            float: "left",
            minWidth: '250px',
            selector: (data) => data?.Query,
            format: (data) => <div title={data?.Query} >{data?.Query} </div>,
        },
        {
            name: 'source',
            value: 'Source',
            sortable: true,
            float: "left",
            selector: (data) => data?.Source,
            format: (data) => <div title={data?.Source} >{data?.Source} </div>,
        },
        {
            name: 'status',
            value: 'StatusName',
            sortable: true,
            float: "left",
            selector: (data) => data?.StatusName,
            format: (data) =>
                <div className="d-flex bookingStatus " style={{ height: '60px' }} >
                    <button
                        type='button'
                        className={`btn border status ${getBookingStatus(data?.QueryStatus)}`}
                        style={{ opacity: 1, width: '150px' }}
                        title={data?.StatusName}
                        onClick={() => handleApprove(data?.QueryId, data?.PackageEnquiryId)}
                        disabled={data?.QueryStatus || (!userRights?.URightAdd && !userRights?.URightEdit)}
                    >
                        {data?.StatusName}
                    </button>
                </div>
        },
    ];
    const [tableColumns, setTbaleColumns] = useState(columns)
    //#endregion

    const handleApprove = async (queryid, packageenquiryid) => {
        const statusoftoken = await verifytoken();
        if (statusoftoken) {
            const userdata = GetLocalStorage()
            dispatch(loaderActions.start());
            let data = {
                TravellerQueryId: queryid > 0 ? queryid : 0, // TravellerQueryId>0 then PackageEnquiryId is 0 
                PackageEnquiryId: packageenquiryid > 0 ? packageenquiryid : 0,  // PackageEnquiryId > 0 them  TravellerQueryId is 0
                UserId: userdata?.userMasterID
            }
            const updatebookingstatus = await postUsersData('/master/v1/updatequerystatus', data)
            if (updatebookingstatus?.status) {
                fetchData();
                setSearchValue("");
                setSerachBy('All');
            }
            dispatch(loaderActions.end());
        }
        else {
            dispatch(loaderActions.end());
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' });
        }

    }


    //#region handle search value
    const serachoption = useMemo(() => {
        let searchdata = [];
        searchdata = tableColumns?.map((search) => ({
            label: search?.name,
            value: search?.value
        }));
        searchdata?.push({ label: 'All', value: 'All' });
        return searchdata;
    }, [tableColumns])

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }
    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            fetchData();
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    };

    const matchesSearchValueAll = (issec, fields) => {
        let search = searchvalue
        return fields.some((data) =>
            issec[data]?.toString().toLowerCase().includes(search.toLowerCase())
        );
    };

    const handleSearch = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby?.length > 0) {
            let filteritem = filterItems;
            let filterdata;
            if (searchby === 'All') {
                filterdata = filteritem?.filter((issec) => {
                    let keys = serachoption?.filter((fil) => fil?.value !== 'All' ? fil?.value : '')?.map((obj) => obj?.value)
                    return matchesSearchValueAll(issec, keys) && issec
                });
            }
            else {
                filterdata = filteritem?.filter(issec => issec?.[`${searchby}`]?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            filterdata?.map((obj, index) => {
                obj['SrNo'] = index + 1;
                return obj
            })
            setQueriesList(filterdata)
        }
    }

    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        fetchData();
    }
    //#endregion


    return (
        <>
            <div className="container-fluid mt-4 heading_color">
                <h3>All Queries</h3>
            </div>
            <div className="container-fluid form_border my-3">
                <div className="mt-5 mb-3 row mx-1">

                    <div className="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input
                                        type="text"
                                        onKeyDown={onKeyDownHandler}
                                        id="btnSearchText"
                                        className="form-control"
                                        placeholder='Enter keyword'
                                        name="SearchValue"
                                        onChange={handleChangeSearchValue}
                                        value={searchvalue || ''}
                                        disabled={serchdisable ? true : false}
                                        autoComplete='off'
                                    />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div className="p-2 text-center">
                                <button type="button" className="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleSearch}>Search</button>
                            </div>
                        </div>
                    </div>

                    <CustomTable
                        className={`queries`}
                        columns={columns}
                        data={queriesList}
                    />

                </div>
            </div>
        </>
    )
}

export default AllQueries
