import React, { useMemo } from 'react';
import { useEffect, useState, useRef } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../../../assets/css/master.css";
import { getAllData, patchUsersData, postUsersData, verifytoken } from '../../../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { isValidImage, NumericWithDecimal, onlyNumeric } from '../../../commonClass/CommonValidation';
import { json, useLocation, useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../../../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import PackageMenuMaster from './PackageMenuMaster';
import VendorInclusion from './VendorInclusion';
import VendorVisibility from './VendorVisibility';
import VendorPolicies from './VendorPolicies';
import VendorExclusion from './VendorExclusion';
import VendorOverview from './VendorOverview';
import VendorAbout from './VendorAbout';
import VendorItinerary from './VendorItinerary';
import VendorSummary from './VendorSummary'
import VendorPricing from './VendorPricing';
import { errorActions } from '../../../../_actions/error.action';
import { ImageDelete, ImageUpload } from '../../../../ImageUpload/ImageCommon';
import CustomSelection from '../../../../customControl/CustomSelection';
import { ConfirmationModal } from '../../../../customControl/ConfirmationModal';
import { loaderActions } from '../../../../_actions/loader.actions';



const VendorPackagemaster = () => {
    const dispatch = useDispatch();
    const exclusioncnt = useSelector(state => state.errorStatus.exclusionCount);
    const inclusioncnt = useSelector(state => state.errorStatus.inclusionCount);
    const policycnt = useSelector(state => state.errorStatus.policyCount);
    const aboutcnt = useSelector(state => state.errorStatus.aboutplaceCount);
    const itinerarycnt = useSelector(state => state.errorStatus.ItineraryCount);

    const overviewduration = useSelector(state => state.errorStatus.overviewduration);
    const overviewdurationfrom = useSelector(state => state.errorStatus.overviewdurationfrom);
    const overviewdurationto = useSelector(state => state.errorStatus.overviewdurationto);
    const overviewvisitfrom = useSelector(state => state.errorStatus.overviewvisitfrom);
    const overviewvisitto = useSelector(state => state.errorStatus.overviewvisitto);
    const overviewtheme = useSelector(state => state.errorStatus.overviewtheme);

    const overviewcitydetail = useSelector(state => state.errorStatus.overviewcitydetail);
    const overviewnightpending = useSelector(state => state.errorStatus.overviewnightpending);

    const pricingsupplier = useSelector(state => state.errorStatus.pricingSupplier)
    const pricingtransfer = useSelector(state => state.errorStatus.pricingTransfer)
    const pricingjoiningcost = useSelector(state => state.errorStatus.pricingJoiningCost)
    const pricingoperationcost = useSelector(state => state.errorStatus.pricingOperationCost)
    const pricingtour = useSelector(state => state.errorStatus.pricingTour)
    const pricingchildren = useSelector(state => state.errorStatus.pricingChildren)
    //#region  analysis state

    const [locationData, setLocationData] = useState([])
    const [tempStateId, setTempStateId] = useState([])

    const [dataretrivalflag, setDataRetrivalFlag] = useState(false)

    const [atFirstDisable, setAtFirstDisable] = useState(true)
    const [disabled, setDisabled] = useState(false);
    const [basicdraftdisabled, setBasicDraftDisabled] = useState(true);
    const [backdisable, setBackDisable] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [activeId, setActiveId] = useState(false);

    const [formerror, setFormError] = useState('');
    const [isRetrive, setIsRetrive] = useState(false);
    const [stateenable, setStateDisable] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);
    const [cleardisabled, setClearDisable] = useState(false);
    const [packageValidateStart, setPackageValidateStart] = useState(null);
    const [packageValidateEnd, setPackageValidateEnd] = useState(null);
    const packageD = useLocation();
    const navigate = useNavigate();

    const [bestpackage, setBestPackage] = useState(false);

    const [isbasicShown, setIsBasicShown] = useState(true);
    const [isAddShown, setIsAddShown] = useState(false);
    const [isAddDraftShown, setIsAddDraftShown] = useState(false);
    const [isDetailblockshow, SetIsDetailBlockShow] = useState(false);


    const [pkgfinalprice, setPkgFinalPrice] = useState(0);
    const [PkgSummary, setPkgSummary] = useState([]);

    const [richboxheight, setRichBoxHeight] = useState(false)
    const [dataitinerary, setDataItinerary] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [inventoryvalidation, setInventoryValidation] = useState({
        InventoryCount: 0,
        MinSoldoutDate: null,
        MaxSoldOutDate: null,
    })
    const [formvalue, setFormValue] = useState({
        VPackageId: 0,
        PackageName: "",
        PackageCode: "",
        PackageCategoryId: 0,
        StateId: [],
        LocationId: [],
        Country: 0,
        PackageTypeId: 0,
        PackageLevelId: 0,
        IsInterCity: true,
        IsBestPackage: false,
        ArrivalSlotId: 0,
        DepartureSlotId: 0,
        PackageValidateStart: null,
        PackageValidateEnd: null,

        PackageValidateStartD: '',
        PackageValidateEndD: '',

        PackageInventoryCountD: '',
        PackageCutOffCountD: '',
        PackageInventoryCount: 0,
        PackageCutOffCount: 0,
        PackageHighlights: "",
        IsActive: false,
        IsDraft: false,

        PackageBasicPrice: 0,
        PackageMarkup: "FIX",
        PackageMarkupValue: "0",
        GST: 0,
        PackageFinalValue: 0,


        PackageBanner: [],
        PackageGallery: [],

        PackageMenu: [],
        //PackageInclusion : [],
        InclusionD: '',
        datainclusiondetails: [],
        ExclusionD: '',
        dataexclusiondetails: [],
        PolicyD: '',
        datapolicydetails: [],
        CityD: '',
        CityName: '',
        CityDetailsD: '',
        datacitydetails: [],
        PackageInclusion: [],
        PackageExclusion: [],
        PackagePolicy: [],
        PackageOverview: [],
        PackagePlace: [],
        PackageItinerary: [],
        PackageThemeId: [],

        IsHotDeals: false,
        HotOfferPercentage: 0,
        IsInternationalDestination: false,
        IsBestDestination: false,
        IsTrendingHoliday: false,
        VenPkgsupplierId: 0,
        TransferPrice: null,
        TransferCategoryId: 0,
        DirectJoiningCostPrice: null,
        PricingTypeId: 0,
        FixOperationCostPrice: null,
        TourPricingTypeId: 0,
        TourManagementPrice: null,
        Day: '',
        DaySchedule: '',
        SightseeingId: 0,
        RoomPriceD: 0,
        PickUpPoint: '',
        DropPoint: '',
        Hours: '',
        ItineraryDetails: '',
        VehicalPrice: 0,
        AirrivalTime: null,
        DepartureTime: null,
        Duration: '',
        HotelPrice: '',

        FlightPrice: '',
        AttractionBasicId: [],

        ArrivalTimeD: '',
        DepartureTimeD: '',

        PackageFinalPrice: 0,
        PackageFixPrice: 0,
        PackageDiscount: 0,
        ChildrenPrice: null
    })

    //#endregion

    //#region handle Dropdown and radio loading part 
    const [statelistdate, setStateListData] = useState([]);
    const [flightvisible, setFlightVisibled] = useState(true);
    const [slotlist, setSlotList] = useState([]);
    const [bannerlist, setBannerList] = useState([]);
    const [gallerylist, setGalleryList] = useState([]);
    const [packagetypelist, setPackageTypeList] = useState([]);
    const [packagelevellist, setPackageLevelList] = useState([]);
    const [packagecategorylist, setPackageCategoryList] = useState([]);
    const [inclusionlist, setInclusionList] = useState([]);
    const [exclusionlist, setExclusionList] = useState([]);
    const [policylist, setPolicyList] = useState([]);
    const [menulist, setMenuList] = useState([]);
    // const [durationlist, setDurationList] = useState([]);
    // const [visitfromduration, setVisitFromDurationList] = useState([]);
    // const [visittoduration, setVisitToDurationList] = useState([]);
    // const [packagepoint, setPackageoint] = useState([]);
    // const [endpackagepoint, setEndPackageoint] = useState([]);
    // const [packagetheme, setPackageTheme] = useState([]);


    const [defcheckedvalueofpackagetype, setDefCheckedValueOfPackageType] = useState(0);
    const [defcheckedvalueofpackagelevel, setDefCheckedValueOfPackageLevel] = useState(0);
    const [checkedvalueofpackagetypevalue, setCheckedValueOfPackageTypeValue] = useState(0);
    const [checkedvalueofpackagelevelvalue, setCheckedValueOfPackageLevelValue] = useState(0);

    // const [hotelname, setHotelName] = useState('');
    // const [sightseeingname, setSightseeingName] = useState('');
    // const [hotelrating, setHotelRating] = useState();
    const [editdisabled, setEditDisabled] = useState(false);
    const [allbanner, setAllBanner] = useState([]);
    const [bannerdisabled, setBannerDisabled] = useState(false);
    // const [allstartpoint, setAllStartpoint] = useState([]);
    // const [startpointdisabled, setStartPointDisabled] = useState(false);
    // const [allendpoint, setAllEndpoint] = useState([]);
    // const [endpointdisabled, setEndPointDisabled] = useState(false);
    const [overviewdetails, setOverViewDetails] = useState([])
    const [pricingdetails, setPricingDetails] = useState([])
    const [nightallcity, setNightAllCity] = useState([]);


    const getStateData = async () => {
        // const statedatalist = await getAllData('/general/v1/getpackagestate');
        const statedatalist = await getAllData('/master/vendor/v1/vengetpackagestate');
        setStateListData(statedatalist.data);
    }
    const [overviewcity, setOverviewcity] = useState([])
    const [allCity, setallCity] = useState([]);
    const getalllocationData = async () => {
        // await getAllData('/general/v1/getgallerycity').then((response)=>{
        await getAllData('/master/vendor/v1/vengetpackagelocationcity').then((response) => {
            // setallCity(response.data?.filter(IsAct => IsAct.IsActive === true));
            if (packageD.state.PackageId <= 0 || packageD.state.PackageId == null) {
                setallCity(response.data?.filter(IsAct => IsAct.IsActive === true));
            }
            else {
                setallCity(response.data);
                setCityData(response.data?.map((value) => ({
                    label: value.City,
                    value: value.LocationId
                })));
                setNightAllCity(response.data)
            }
            // load city overview in live
            const overviewCity = response.data?.filter((value) => formvalue.LocationId?.includes(value.LocationId))?.map((ele) => ({
                label: ele.City,
                value: ele.LocationId
            }))
            setOverviewcity(overviewCity)
        });

    }

    const statelist = statelistdate?.map((value) => ({
        label: value.StateName,
        value: value.StateId
    }));

    const getslot = async () => {
        const arrivallistslot = await getAllData('/master/vendor/v1/vengetpackageslot');
        setSlotList(arrivallistslot.data);
    }

    const arrivalslotlist = slotlist?.map((value) => ({
        label: value.SlotName,
        value: value.SlotId
    }));

    const departureslotlist = slotlist?.map((value) => ({
        label: value.SlotName,
        value: value.SlotId
    }));

    const getbannerlist = async () => {
        const VPackageId = packageD.state.PackageId;
        const bannerlistD = await getAllData('/master/vendor/v1/vengetpackagebanner?VPackageId=' + VPackageId);
        setBannerList(bannerlistD.data?.filter(IsAct => IsAct.IsActive === true));
        setAllBanner(bannerlistD.data);
    }

    const bannerdatalist = bannerlist?.map((value) => ({
        label: value?.BannerName,
        value: value?.BannerId
    }));

    const getgallerylist = async () => {
        const VPackageId = packageD.state.PackageId;
        const gallerylistdata = await getAllData('/master/vendor/v1/vengetpackagegallery?VPackageId=' + VPackageId);
        setGalleryList(gallerylistdata.data);
    }

    const gallerydatalist = gallerylist?.map((value) => ({
        label: value.ImageDisplayName,
        value: value.GalleryId
    }));


    const getpackagetype = async () => {

        const packagetypelistdata = await getAllData('/master/vendor/v1/vengetpackagetype');
        setPackageTypeList(packagetypelistdata.data);
        packagetypelistdata.data?.map((value) => {
            if (value.IsDefault === true) {
                // setCheckedValueOfPackageType(true);
                // setDefCheckedValueOfPackageType(true);
                setDefCheckedValueOfPackageType(value.PackageTypeId);
                setCheckedValueOfPackageTypeValue(value.PackageTypeId);
                // formvalue.PackageTypeId = Number(value.PackageTypeId)
                setFormValue((form) => ({ ...form, PackageTypeId: Number(value.PackageTypeId) }))
            }
        });
    }


    const getpackagelevel = async () => {
        ///PackageLevelList
        const packagelevellistdata = await getAllData('/master/vendor/v1/vengetpackagelevel');
        setPackageLevelList(packagelevellistdata.data);
    }

    const IsInterCityRadio = [
        { id: 1, title: 'Intercity' },
        { id: 2, title: 'Intracity' }
    ]

    useEffect(() => {
        if (formvalue?.LocationId?.length > 1) {
            setFormValue((form) => ({ ...form, IsInterCity: true }));
        }
        else {
            setFormValue((form) => ({ ...form, IsInterCity: false }));
        }

    }, [formvalue?.LocationId])

    useEffect(() => {
        if (packagelevellist?.length > 0) {
            if (formvalue?.Country === 0) {
                setFormValue((form) => ({ ...form, PackageLevelId: 0 }));
            }
            else if (formvalue?.Country === 1) {
                setFormValue((form) => ({ ...form, PackageLevelId: 1 }));
            }
            else {
                setFormValue((form) => ({ ...form, PackageLevelId: 2 }));
            }
        }
    }, [formvalue?.Country, packagelevellist])

    const getpackagecategory = async () => {
        const packagecategorylist = await getAllData('/master/vendor/v1/vengetpackagecategory');
        setPackageCategoryList(packagecategorylist.data);
    }
    const packagecategorydatalist = packagecategorylist?.map((value) => ({
        label: value.PackageCategoryName,
        value: value.PackageCategoryId
    }));

    const getInclusionList = async () => {
        const listofinclusiondata = await getAllData('/master/vendor/v1/vengetpackageinclusion');
        setInclusionList(listofinclusiondata.data);
    }
    const inclusionlistdata = inclusionlist?.map((value) => ({
        label: value.InclusionName,
        value: value.InclusionId,
        ShortName:value.ShortName
    }));
    const getExclusionList = async () => {
        const listofexclusiondata = await getAllData('/master/vendor/v1/vengetpackageexclusion');
        setExclusionList(listofexclusiondata.data);
    }
    const exclusionistdata = exclusionlist?.map((value) => ({
        label: value.ExclusionName,
        value: value.ExclusionId
    })) || [];

    const getPolisylist = async () => {
        const listofpolicydata = await getAllData('/master/vendor/v1/vengetpackagepolicy');
        setPolicyList(listofpolicydata.data);
    }
    const policylistdata = policylist?.map((value) => ({
        label: value.PolicyName,
        value: value.PolicyId
    }));

    const getMenulist = async () => {
        const listofmenudata = await getAllData('/master/vendor/v1/vengetpackagemenu');
        setMenuList(listofmenudata.data);
    }

    // const getDurationdatallist = async () => {
    //     const durationlistD = await getAllData('/master/vendor/v1/vengetpackageduration');
    //     setDurationList(durationlistD.data);
    // }


    // const getdurationtovisit = async () => {
    //     const visitduration = await getAllData('/master/vendor/v1/vengetpackagemonth');
    //     setVisitFromDurationList(visitduration.data);
    //     setVisitToDurationList(visitduration.data);
    // }

    // const getpackagethemelist = async () => {
    //     const packagethemelistD = await getAllData('/master/vendor/v1/vengetpackagetheme');
    //     setPackageTheme(packagethemelistD.data);
    // }
    const getpackagepoint = async () => {
        await getAllData('/master/vendor/v1/vengetpackagelocationcity').then((packagepointlist) => {
            // setPackageoint(packagepointlist.data?.filter(IsAct => IsAct.IsActive === true));
            // setEndPackageoint(packagepointlist.data?.filter(IsAct => IsAct.IsActive === true));
            // setAllStartpoint(packagepointlist.data);
            // setAllEndpoint(packagepointlist.data);
            handleRetrive();
        });
    }

    const locationDropdown = useMemo(() =>

        locationData?.reduce((acc, curr) => {
            curr.countryData?.map((obj) => {
                acc.country.push({ value: obj.CountryId, label: obj.CountryName })
            })
            curr.stateData?.filter(state => state.CountryId === formvalue.Country)?.map((obj) => {
                acc.state.push({ value: obj.StateId, label: obj.StateNAme })
            })
            if (formvalue.Country !== 1) {
                let stateid = acc.state[0]?.value
                curr.cityData?.filter(city => city.CountryId === formvalue.Country)?.map((obj) => {
                    acc.city.push({ value: obj.LocationId, label: obj.City })
                })
                formvalue?.StateId?.push(stateid)
            }
            else {
                curr.cityData?.filter(city => tempStateId?.includes(city.StateId))?.map((obj) => {
                    acc.city.push({ value: obj.LocationId, label: obj.City })
                })
            }
            return acc
        }, { country: [], state: [], city: [] })
        , [locationData, formvalue.Country, tempStateId])


    const getcountrystatecity = async () => {
        // dispatch(loaderActions.start())
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const countrystatecity = await getAllData('/master/v1/getcountrystatecity')

            if (countrystatecity.status) {
                let dropdownData = []
                dropdownData.push(countrystatecity?.data)
                setLocationData(dropdownData)
            }
            else {
                dispatch({ type: 'ALERTING', message: countrystatecity.message })
                dispatch(loaderActions.end())
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            dispatch(loaderActions.end())
        }
    }

    const onSelectChange = (name, e) => {
        setBasicDraftDisabled(false);
        setFormError('');
        if (name === "Country") {
            setTempStateId([])
            setFormValue(form => ({ ...form, StateId: [], LocationId: [], Country: e?.value }))
        }
        else if (name === "StateId") {
            setTempStateId(e?.map(obj => obj?.value))
            setFormValue(form => ({ ...form, LocationId: [], StateId: e?.map(obj => obj?.value) }))
        }
        else if (name === "LocationId") {
            setFormValue(form => ({ ...form, LocationId: e?.map(obj => obj?.value) }))
        }
    }

    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            if (packageD.state != null) {
                getcountrystatecity()
                getalllocationData()
                getStateData();
                getslot();
                getbannerlist();
                getgallerylist();
                getpackagetype();
                getpackagelevel();
                getpackagecategory();
                getpackagepoint();
                getInclusionList();
                getExclusionList();
                getPolisylist();
                getMenulist();
                setEditDisabled(packageD.state.editdisabled);


                if (packageD.state.PackageId > 0) {

                    if (packageD.state.viewtype === 'View') {
                        setDisabled(packageD.state.editdisabled);

                    }
                    setAtFirstDisable(packageD.state.atfirstdisable)

                    setClearDisable(true);
                    // setTimeout(handleRetrive, 3000);
                    setIsBasicShown(false);
                    setIsAddShown(true);
                    setIsAddDraftShown(true);
                    SetIsDetailBlockShow(true);
                    setIsRetrive(true);
                    setStateDisable(true);

                }
            }
            else {
                navigate('/VendorPackagelistmaster')
            }
            // //if(Window.$name &&  Window.$userToken)        {
            // getalllocationData()
            // getStateData();
            // getslot();
            // getbannerlist();
            // getgallerylist();
            // getpackagetype();
            // getpackagelevel();
            // getpackagecategory();
            // getpackagepoint();
            // getInclusionList();
            // getExclusionList();
            // getPolisylist();
            // getMenulist();

            // // getDurationdatallist();
            // // getdurationtovisit();
            // // getpackagethemelist();

            // // getaboutplacecity();
            // //getpackageairline();
            // //getroomtype();
            // //getvehichaltypelist();
            // // gethotelnamelist();
            // // getsightseeinglistdata();
            // setEditDisabled(packageD.state.editdisabled);


            // if (packageD.state.PackageId > 0) {

            //     if (packageD.state.viewtype === 'View') {
            //         setDisabled(packageD.state.editdisabled);

            //     }

            //     setClearDisable(true);
            //     // setTimeout(handleRetrive, 3000);
            //     setIsBasicShown(false);
            //     setIsAddShown(true);
            //     setIsAddDraftShown(true);
            //     SetIsDetailBlockShow(true);
            //     setIsRetrive(true);
            //     setStateDisable(true);

            // }

        }
        else {
            ClearLocalStorage();
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
    }, []);

    //#endregion

    //#region handle dropdown change
    const [banneridlist, setBannerIdList] = useState({
        BannerId: 0
    });
    const [galleridlist, setGalleryIdList] = useState([{
        GalleryId: 0,
        Id: 0
    }]);
    const [themeidlist, setThemeIdList] = useState([{
        ThemeId: 0,
        Id: 0
    }]);
    const [bannerimgpath, setBannerImgPath] = useState('');
    const galleryimgpathlist = useState([{
        GalleryId: 0,
        GalleryPath: '',
        Id: 0
    }]);
    const themeimgpathlist = useState([{
        ThemeId: 0,
        ThemePath: '',
        Id: 0
    }]);
    const [themeimgpath, setThemeImgPath] = useState(themeimgpathlist);
    const [galleryimgpath, setGalleryImgPath] = useState(galleryimgpathlist);
    const [stateid, setStateId] = useState([]);
    const [arrivalslotid, setArrivalSlotId] = useState(0);
    const [departureslotid, setDepartureSlotId] = useState(0);
    const [bannerId, setBannerId] = useState(0);
    const [galleryid, setGalleryId] = useState(0);
    const [gallerycount, setGalleryCount] = useState(0);
    const [packagecategoryid, setPackageCategoryId] = useState(0);
    const [galleryimgerror, setGalleryError] = useState('');
    const [durationid, setDurationId] = useState(0);
    const [visitfromdurationid, setVisitFromDurationId] = useState(0);
    const [visittodurationid, setVisitToDurationId] = useState(0);
    const [visitpointfromid, setVisitFromId] = useState(0);
    const [visitpointtoid, setVisitToId] = useState(0);

    const [themebyid, setThemeById] = useState(0);
    const [themebycounter, setThemeCounter] = useState(0);
    const [hotelnameid, setHotelNameId] = useState(0);
    const [hotelroomtypeid, setHotelRoomTypeId] = useState(0);
    const [hotelroomprice, setHotelRoomPrice] = useState(0);
    const [noofpaxid, setNoOfPaxId] = useState(0);


    // const handleStateChange = (e) => {
    //     const states = e?.map(obj => obj.value)
    //     formvalue.StateId = states
    //     setStateId(states);
    //     if (states?.length === 0) {
    //         setSelectedCityValue([])
    //     }
    //     setBasicDraftDisabled(false);
    //     reseterrormessage();
    //     // setSelectedValue(Array.isArray(e) ? e?.map(x => x.value) : []);
    //     setSelectedValue(e);

    //     const cityAgain = allCity?.filter((obj) => states?.includes(obj.StateId))?.map((ele) => ({
    //         label: ele.City,
    //         value: ele.LocationId
    //     }))

    //     const cityAgainValue = cityAgain.map(object => object.value)
    //     const cityCheck = selectedCityValue?.filter((obj) => cityAgainValue?.includes(obj))

    //     setSelectedCityValue(selectedCityValue?.filter((obj) => cityAgainValue?.includes(obj)).map((ele) => {
    //         // setLocationId(ele)
    //         return ele
    //     }))


    //     if (cityCheck?.length === 0) {
    //         // setLocationId([])
    //         setSelectedCityValue([])
    //     }

    //     setCityData(allCity?.filter((obj) => states?.includes(obj.StateId))?.map((ele) => ({
    //         label: ele.City,
    //         value: ele.LocationId
    //     })))
    // }
    // useEffect(() => {
    //     const CityIds = selectedCityValue.map(obj => {
    //         return obj.value
    //     })
    //     // formvalue.LocationId = selectedCityValue
    //     formvalue.LocationId = CityIds
    // }, [selectedCityValue])
    // const handleCityChange = (e) => {
    //     // const cities = e?.map(obj => obj.value)

    //     // setLocationId(cities);
    //     setBasicDraftDisabled(false);
    //     reseterrormessage();
    //     // setSelectedCityValue(Array.isArray(e) ? e?.map(x => x.value) : []);
    //     setSelectedCityValue(e);
    // }

    const handleArrivalSlotChange = (e) => {
        formvalue.ArrivalSlotId = Number(e.value)
        setArrivalSlotId(e.value);
        reseterrormessage();
        setEditDisabled(false);
        dispatch(errorActions.resetallstatusflag());
    }
    const handleDepatureSlotChange = (e) => {
        formvalue.DepartureSlotId = Number(e.value)
        setDepartureSlotId(e.value);
        reseterrormessage();
        setEditDisabled(false);
        dispatch(errorActions.resetallstatusflag());
    }
    const handleBannerChange = (e) => {
        // {formvalue.Banne = Number(e.value)}
        setBannerId(e.value);
        banneridlist.BannerId = e.value
        formvalue.PackageBanner = [banneridlist]
        bannerlist?.filter((obj) => obj.BannerId === e.value)?.map((value) => {
            setBannerImgPath('https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + value.BannerPath);
        });
        reseterrormessage();
        setEditDisabled(false);
        dispatch(errorActions.resetallstatusflag());
    }
    const removeBannerImage = () => {
        setBannerId(0);
        banneridlist.BannerId = 0
        formvalue.PackageBanner = [banneridlist]
        setBannerImgPath('');
        reseterrormessage();
        setEditDisabled(false);
        dispatch(errorActions.resetallstatusflag());
    }

    const handleisActive = (e) => {
        if (!isAddShown) {
            formvalue.IsActive = e
            setActiveId(!activeId);
            reseterrormessage();
        }
        else {
            setModalShow(true);
        }
        dispatch(errorActions.resetallstatusflag());
    }

    const handleConfirmation = (e) => {

        //setYesNoConfirm(e)
        if (e === true) {
            formvalue.IsActive = e
            setActiveId(!activeId);
            reseterrormessage();
            setEditDisabled(false);
        }
        setModalShow(false);
    }
    const handleGalleryChange = (e) => {
        setGalleryId(e.value);
        reseterrormessage();
        setGalleryCount(gallerycount + 1);
        setEditDisabled(false);
        dispatch(errorActions.resetallstatusflag());
    }
    const GalleryImage = () => {

        const galleryimgrepeat = galleryimgpath?.filter((obj) => obj.GalleryId === galleryid);
        if (galleryid > 0) {
            if (galleryimgpath.length < 20) {
                if (galleryimgrepeat.length === 0) {
                    gallerylist?.filter((obj) => obj.GalleryId === galleryid)?.map((value) => {
                        if (gallerycount === 1) {
                            setGalleryIdList([{ GalleryId: galleryid, Id: gallerycount }]);
                            setGalleryImgPath(current => [{ GalleryId: value.GalleryId, GalleryPath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + value.ImagePath, Id: gallerycount }]);
                        }
                        else {
                            setGalleryIdList(current => [...current, { GalleryId: galleryid, Id: gallerycount }]);
                            setGalleryImgPath(current => [...current, { GalleryId: value.GalleryId, GalleryPath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + value.ImagePath, Id: gallerycount }]);
                        }

                    });
                }
                else {
                    setGalleryError('You can not enter duplicate gallery');
                }
            }
            else {
                setGalleryError('You can not enter more than 20 image');
                document.getElementById("GalleryCount").focus();
            }

        }

    }


    const removeGalleryImage = (id, e) => {

        setGalleryImgPath((oldState) => oldState?.filter((item) => item.Id !== id));
        setGalleryIdList((oldState) => oldState?.filter((item) => item.Id !== id));
        setGalleryId(0);
        reseterrormessage();
        setEditDisabled(false);
        dispatch(errorActions.resetallstatusflag());
    }

    const handlepackagecategory = (e) => {
        formvalue.PackageCategoryId = e.value
        setPackageCategoryId(e.value);
        reseterrormessage();
        setEditDisabled(false);
        dispatch(errorActions.resetallstatusflag());
    }

    useEffect(() => {
        if (gallerycount > 0)
            GalleryImage();
    }, [gallerycount]);


    //#endregion

    //#region  clear section 
    const handleclear = () => {
        clear();
    }

    function clear() {
        reseterrormessage();
        resetDisableStateofcontrol();
        setTempStateId([])
        setFormValue({
            ...formvalue, PackageName: "", PackageCode: "", StateId: [], LocationId: [], Country: 0, ChildrenPrice: null,
            PackageTypeId: defcheckedvalueofpackagetype, PackageLevelId: 0, IsInterCity: false, IsBestPackage: false,
            ArrivalSlotId: 0, DepartureSlotId: 0, PackageValidateStart: null, PackageValidateEnd: null,
            PackageValidateStartD: "", PackageValidateEndD: "",
            PackageInventoryCountD: '', PackageInventoryCount: 0, PackageCutOffCountD: '', PackageCutOffCount: 0,
            PackageHighlights: "", PackageOverview: [],
            IsActive: false, IsDraft: false, PackageBanner: [], PackageGallery: [],
            Day: '', AttractionBasicId: [], DaySchedule: '', AirrivalTime: null, DepartureTime: null,
            ArrivalTimeD: '', DepartureTimeD: '', HotelPrice: '', SightseeingId: 0,
            Duration: '', PickUpPoint: '', DropPoint: '', ItineraryDetails: '',
            Hours: '', FlightPrice: '', InclusionD: '', datainclusiondetails: [],
            ExclusionD: '', dataexclusiondetails: [], PolicyD: '', datapolicydetails: [],
            CityD: '', CityName: '', CityDetailsD: '', datacitydetails: [], PackageInclusion: [],
            PackageExclusion: [], PackagePlace: [], PackageItinerary: [], PackageThemeId: [], IsHotDeals: false,
            HotOfferPercentage: 0, IsInternationalDestination: false, IsBestDestination: false, IsTrendingHoliday: false
        });

        // {formvalue.PackageValidateStartD = ''}
        resetcontrolvalues();
    }
    const resetcontrolvalues = () => {
        setBannerImgPath('');
        setBannerIdList({ BannerId: 0 });
        setGalleryImgPath([]);
        setGalleryIdList([]);
        setCityData([])
        setStateId([]);
        // setLocationId([]);
        // setSelectedValue([])
        // setSelectedCityValue([])
        setArrivalSlotId(0);
        setDepartureSlotId(0);
        setBannerId(0);
        setGalleryId(0);
        setGalleryCount(0);
        setPackageCategoryId(0);
        setBestPackage(false);
        setActiveId(false);
        setDurationId(0);
        setVisitFromDurationId(0);
        setVisitToDurationId(0);
        setVisitFromId(0);
        setVisitToId(0);
        setCheckedValueOfPackageTypeValue(defcheckedvalueofpackagetype);
        setCheckedValueOfPackageLevelValue(defcheckedvalueofpackagelevel);
        // setInclusionDetails([]);
        setExclusionDetails([]);
        setPolicyDetails([]);

        // setVehicalTypeId(0);
        // setVehicalPrice(0);

        setHotelNameId(0);

        // setHotelRating(0);
        // setHotelName('Hotel Name');
        // setSightseeingName('Sightseeing Name');

        setHotelRoomTypeId(0);
        // setSightSeeingId(0);
        setNoOfPaxId(0);
        setHotelRoomPrice(0);
        // setDepartureTime("--:--");
        // setArrivalTime("--:--");
        // setHoursTime("--:--");
        setPackageValidateStart(null);
        setPackageValidateEnd(null);

        //document.getElementById("txtcityimages").value = "";
    }
    const reseterrormessage = () => {
        setGalleryError('');
        setFormError('');
        // setPolicyonDuplicateError('');
        // setInclusionDuplicateError('');
        // setExclusionDuplicateError('');

    }
    const resetDisableStateofcontrol = () => {
        setIsSubmit(false);
        if (packageD.state.PackgeId === 0) {
            setStateDisable(false);
        }
        setDisabled(false);
        setBasicDraftDisabled(true);
        setClearDisable(false);
        setBannerDisabled(false);
        // setStartPointDisabled(false);
        // setEndPointDisabled(false);

    }
    //#endregion

    //#region Back button 
    const handleBackButton = () => {
        reseterrormessage();
        resetDisableStateofcontrol();
        resetcontrolvalues();
        navigate('/VendorPackagelistmaster');

    }
    //#endregion


    //#region image upload

    // const inputRef = useRef();
    const [fileselected, setSelectedFile] = useState();
    const [placeimagename, setImageName] = useState('');
    const [placeimagepath, setImagePath] = useState('');
    // var elPreview = document.getElementById("preview");
    // var useBlob = false && window.URL;
    //window.URL = window.URL || window.webkitURL;
    // var elBrowse = document.getElementById("txtcityimages"),
    //     elPreview = document.getElementById("preview"),
    //     useBlob = false && window.URL;
    function getDateCombination() {
        var today = new Date();
        let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
        let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
        return date + '_' + time;
    }
    const [imageError, setImageError] = useState('');
    // let heightimg = 0;
    // let widthimg = 0;
    const handleUploadImageLoad = (e) => {
        reseterrormessage();
        //resetDisableStateofcontrol();
        setEditDisabled(false);
        const files = e.target.files;
        if (isValidImage(e.target.files[0].name)) {
            const files = e.target.files;
            const filecom = getDateCombination();
            const filename = filecom + '_' + '1' + '_' + (files[0].name.replace(/ /g, "_")).replace(/-/g, '');
            setImageName(filename);
            setImagePath('CMS/Package/Place/' + filename);
            setSelectedFile(e.target.files[0]);
            setImageError('');
            // { formvalue.BannerFileName = filename }
            // { formvalue.BannerPath = 'CMS/Banner/' + filename }
            //setEditDisabled(true);
            // setBannerPath('');
            // setBannerFileName('');
            // setBannerDuplicateErrorMessage('');
        }
        else {
            const input = document.getElementById('txtcityimages');
            if (input) {
                input.value = "";
            }
            setImageError("Please select Only Image");
            setIsSubmit(false);
            //setFormValue({ ...formValue, ['BannerFileName']: '', ['BannerPath']: '' });
            // setBannerPath('');
            // setFormErrors('');
            // setBannerFileName('');
            // setBannerDuplicateErrorMessage('');
            return;
        }
        // const filecom  = getDateCombination();
        // const filename = filecom  + '_' + '1' + '_' + (files[0].name.replace(/ /g,"_")).replace(/-/g, '');
        // { formvalue.HotelBannerFileName = filename }
        // { formvalue.HotelBannerPath = 'CMS/Banner/' + filename }

        // var elPreview = document.getElementById("preview");
        // var useBlob = false && window.URL;
        //var files  = this.files;
        // var errors = "";

        // if (!files) {
        //     errors += "File upload not supported by your browser.";
        // }

        // if (files && files[0]) {
        //     for (var i = 0; i < files.length; i++) {

        //         var file = files[i];

        //         if (!(/\.(png|jpeg|jpg|gif)$/i).test(file.name)) {
        //             errors += file.name + " Unsupported Image extension\n";
        //             // readImage(file);
        //         }
        //     }
        // }

    }

    // function readImage(file) {

    //     var reader = new FileReader();

    //     // Once a file is successfully readed:
    //     reader.addEventListener("load", function () {

    //         var image = new Image();
    //         image.addEventListener("load", function () {

    //             var imageInfo = file.name + ' ' + // get the value of `name` from the `file` Obj
    //                 image.width + '×' + // But get the width from our `image`
    //                 image.height + ' ' +
    //                 file.type + ' ' +
    //                 Math.round(file.size / 1024) + 'KB';


    //             elPreview.appendChild(this);
    //             elPreview.insertAdjacentHTML("beforeend", imageInfo + '<br>');

    //             heightimg = image.height;
    //             widthimg = image.width;
    //             alert ('hight' ,heightimg )
    //             // if (useBlob) {
    //             //   window.URL.revokeObjectURL(image.src);
    //             // }
    //         });

    //         image.src = useBlob ? window.URL.createObjectURL(file) : reader.result;

    //     });

    //     reader.readAsDataURL(file);
    // }

    useEffect(() => {
        //setSelectedFile(fileselected);
    }, [fileselected]);
    const removeImage = () => {


        //setUploadImage((oldState) => oldState.filter((item) => item.id !== id));
        setImageName('');
        setImagePath('');
        const input = document.getElementById('txtcityimages');
        if (input) {
            input.value = "";
        }

        setSelectedFile();
        // setHotelBannerFileName('');
        // { formvalue.HotelBannerPath = '' }
        // { formvalue.HotelBannerFileName = '' }
        // setHotelBannerPath('');
    };

    //#endregion 

    //#region  handle image upload to server and delete
    const handleimageupload = async (pathdirectory, filename, file) => {
        await ImageUpload(pathdirectory, filename, file);
    }
    const handleimageDelete = async (filename) => {
        await ImageDelete(filename);
    }
    //#endregion


    //#region 
    const [Shown, setShown] = useState({
        Overview: true,
        Itinerary: false,
        Inclusion: true,
        Exclusion: true,
        Policies: false,
        Summary: false,
        About: false,
        Visibility: true
    });

    //#endregion

    //#region submit

    const onDraftSubmit = (e) => {
        e.preventDefault();
        handledataforsubmit();
        setFormError(validateDraftData(formvalue));
    }
    const onSubmitData = (e) => {
        e.preventDefault();
        dispatch(errorActions.submitstart());
        // dispatch(errorActions.end());
        // setIsSubmit(false)
        // dispatch(errorActions.resetallstatusflag())
        // dispatch(errorActions.exclusionend());
        // dispatch(errorActions.inclusionend());
        // dispatch(errorActions.overviewend());
        // dispatch(errorActions.policyend());
        handledataforsubmit();
        setFormError(validateData(formvalue));
    }
    const handledataforsubmit = () => {

        formvalue.IsActive = activeId;

        formvalue.PackageGallery = galleryimgpath?.filter((obj) => obj.GalleryId > 0)?.map(obj => ({
            GalleryId: obj.GalleryId
        }))



        // formvalue.PackageInclusion = datainclusiondetails?.filter(obj => obj.SeqNo > 0)?.map(obj => ({
        //     InclusionId: obj.InclsuionId
        // }))
        formvalue.PackageInclusion = datainclusiondetails?.filter(obj => obj.SeqNo > 0)?.map(obj => ({
            InclusionId: obj.InclsuionId
        }))


        formvalue.PackageExclusion = dataexclusiondetails?.filter(obj => obj.SeqNo > 0)?.map(obj => ({
            ExclusionId: obj.ExclusionId
        }))

        formvalue.PackagePolicy = datapolicydetails?.filter(obj => obj.SeqNo > 0)?.map(obj => ({
            PolicyId: obj.PolicyId
        }))


        const menuidlistd = menulist?.filter(obj => obj.IsDefault === true)?.map((obj) => ({
            PackageMenuId: obj.PackageMenuId
        }));


        formvalue.PackageMenu = menuidlistd

        const themeidlistD = themeidlist?.filter(obj => obj.ThemeId > 0)?.map(obj => ({
            ThemeId: obj.ThemeId
        }));

        if (Object.keys(overviewdetails).length != 0) {
            formvalue.PackageOverview = [overviewdetails];
        }

        // formvalue.PackageOverview = [overviewdetails];
        const aboutplacedetail = datacitydetails?.map(obj => ({
            LocationId: obj.LocationId,
            CityDetail: obj.CityDetails,
            PlaceImageName: obj.PlaceImageName,
            // PlaceImagePath: 'CMS/Package/Place/' + obj.PlaceImageName
            PlaceImagePath: obj.PlaceImagePath
        }))
        formvalue.PackagePlace = aboutplacedetail;

        formvalue.PackageItinerary = dataitinerary?.filter(obj => obj.SeqNo > 0)

        // if (menuidlistd?.filter(obj => obj.PackageMenuId === 2).length === 0) {
        //     formvalue.PackageMarkup = 'FIX'
        //     formvalue.PackageMarkupValue = '0'
        //     formvalue.PackageFinalValue = 0
        //     formvalue.PackageFixPrice = 0
        //     formvalue.PackageMarkupValue = '0'
        //     formvalue.PackageBasicPrice = 0
        // }
        // else {

        formvalue.PackageMarkup = gsttype

        //{formvalue.PackageFinalValue= Number(formvalue.PackageFinalPrice)}
        formvalue.PackageFinalValue = Number(pkgfinalprice)
        // }
    }

    const onBasicDraftSubmit = (e) => {
        e.preventDefault();
        // const galleryimgrepeat = galleryimgpath?.filter((obj) => obj.GalleryId > 0);
        // if(galleryimgrepeat.length > 0)
        formvalue.PackageGallery = galleryimgpath?.filter((obj) => obj.GalleryId > 0)?.map(obj => ({
            GalleryId: obj.GalleryId
        }))
        setFormError(validateDraftData(formvalue));

    }

    const onSubmitBasicDraftForm = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            let status = false;
            let data = false;
            const vendorpackagebasic = await postUsersData('/master/vendor/v1/vendraftpackage', formvalue);
            status = vendorpackagebasic.status;
            data = vendorpackagebasic.data;

            if ((!status && data === null) || (!status && !data) || (status == null && data == null)) {
                dispatch({ type: 'ALERTINGMESSAGE', message: vendorpackagebasic.message })
                resetDisableStateofcontrol();
                return;
            }
            else {
                if (data > 0) {
                    dispatch({ type: 'ALERTINGMESSAGE', message: vendorpackagebasic.message })
                    clear();
                    // navigate('/Packagelist');
                }
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
    }
    const onSubmitForm = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            let status1 = false;
            let data1 = false;
            const packagebasic = await patchUsersData('/master/vendor/v1/venupdatepackage', formvalue);
            status1 = packagebasic.status;
            data1 = packagebasic.data;
            if ((!status1 && data1 === null) || (!status1 && !data1)) {
                dispatch({ type: 'ALERTING', message: packagebasic.message })
                // dispatch({ type: 'ALERTING', message: 'Try again later!' })
                // alert('Try again later!');
                resetDisableStateofcontrol();
                return;
            }
            else {
                if (status1 && data1 > 0) {
                    // try {
                    //     const deletefilelistlen = deletefilelist?.length || 0;
                    //     for (let i = 0; i < deletefilelistlen; i++) {
                    //         try {
                    //             // const path = 'CMS/Hotel/';

                    //             await handleimageDelete(deletefilelist[i].ImagePath);
                    //         }
                    //         catch (err) {
                    //             dispatch({ type: 'ALERTINGMESSAGE', message: err })
                    //             // dispatch({ type: 'ALERTING', message: err })
                    //             // alert(err)
                    //             resetDisableStateofcontrol();
                    //             return;
                    //         }

                    //     }

                    //     const uplimglist = datacitydetails?.filter((obj) => obj.PlaceImage !== '');
                    //     for (let i = 0; i < uplimglist.length; i++) {
                    //         try {
                    //             const path = 'CMS/Package/Place/';
                    //             await handleimageupload(path, uplimglist[i].PlaceImageName, uplimglist[i].PlaceImage);
                    //         }
                    //         catch (err) {
                    //             dispatch({ type: 'ALERTINGMESSAGE', message: err })
                    //             // dispatch({ type: 'ALERTING', message: err })
                    //             // alert(err)
                    //             resetDisableStateofcontrol();
                    //             return;
                    //         }

                    //     }

                    // }
                    // catch (err) {
                    //     dispatch({ type: 'ALERTINGMESSAGE', message: err })
                    //     // dispatch({ type: 'ALERTING', message: err })
                    //     // alert(err)
                    //     resetDisableStateofcontrol();
                    //     return;
                    // }
                    dispatch({ type: 'ALERTINGMESSAGE', message: packagebasic.message })

                    // dispatch({ type: 'ALERTING', message: 'Record updated successfully' })
                    // alert('Record updated successfully');
                    clear();
                    // navigate('/Packagelist');
                }
                else {
                    dispatch({ type: 'ALERTINGMESSAGE', message: packagebasic.message })

                    // dispatch({ type: 'ALERTING', message: 'Try again later!' })
                    // alert('Try again later!');
                    resetDisableStateofcontrol();
                    return;
                }
            }
        }
        else {
            ClearLocalStorage();
        }
    }

    const DepartureSlotid = useRef(null)
    const BannerImgPath = useRef(null)
    const GalleryCount = useRef(null)
    const ArrivalSlotId = useRef(null)
    const StateId = useRef(null)
    const CountryId = useRef(null)
    const CityId = useRef(null)
    const PackageCategory = useRef(null)

    useEffect(() => {
        if (Object.keys(formerror).length === 0 && isSubmit && !isRetrive) {
            setBasicDraftDisabled(true);
            setDisabled(true);
            setStateDisable(true);
            setBackDisable(true);
            setClearDisable(true);
            onSubmitBasicDraftForm();

        }
        if (Object.keys(formerror).length === 0 && isSubmit && isRetrive) {
            setBasicDraftDisabled(true);
            setDisabled(true);
            setStateDisable(true);
            setBackDisable(true);
            setClearDisable(true);
            onSubmitForm();
        }
        //    if (Object.keys(formerror).length === 0 && isSubmit && isRetrive && inclusionstatus && exclusionstatus) 
        //       {
        //         setBasicDraftDisabled(true);
        //         setDisabled(true);
        //         setStateDisable(true);
        //         setBackDisable(true);
        //         setClearDisable(true);
        //         onSubmitForm();
        //       }
        if (Object.keys(formerror).length > 0) {

            let id = Object.keys(formerror)[0];

            if (id === "DepartureSlotid" || id === "BannerImgPath" || id === "GalleryCount" || id === "ArrivalSlotId" || id === "StateId" || id === "PackageCategory") {
                eval(id).current.focus();
            }
            else if (id === "PackageValidateStart") {
                document.getElementById("startdate").focus();
            }
            else if (id === "PackageDateValidation" || id === "PackageValidateEnd") {
                document.getElementById("enddate").focus();
            }
            else {
                let doc = document.getElementById(id);
                if (doc !== null)
                    doc.focus();
            }
        }
    }

        , [formerror]);


    //#endregion

    //#region validate section
    const [policychecked, setPolicyCheck] = useState(false);
    const [overviewchecked, setOverViewCheck] = useState(false);
    const [pricingchecked, setPricingCheck] = useState(false);
    const [aboutplacechecked, setAboutPlaceChecked] = useState(false);


    const validateDraftData = (values) => {
        const error = {};
        formvalue.IsDraft = true
        setIsSubmit(true);

        if (values.Country === 0) {
            error.Country = 'Cannot leave blank, input is mandatory.';
            setIsSubmit(false);
        }
        if (values.Country === 1) {
            if (values?.StateId?.length === 0) {
                error.StateId = 'Please select State';
                setIsSubmit(false);
            }
            if (values?.StateId?.length > 15) {
                error.StateId = 'Maximum 15 states can be selected';
                setIsSubmit(false);
            }
        }
        if (values?.LocationId?.length === 0) {
            error.Locationid = 'Please select City';
            setIsSubmit(false);
        }
        if (values?.LocationId?.length > 15) {
            error.Locationid = 'Maximum 15 cities can be selected';
            setIsSubmit(false);
        }

        if (values.PackageName.trim().length === 0) {
            error.PackageName = 'Please enter package name';
            setIsSubmit(false);
        }
        if (values.PackageName.trim().length > 50) {
            error.PackageName = 'Maximum length of the package name is 50.';
            setIsSubmit(false);
        }
        return error;
    }
    const validateData = (values) => {
        const error = {};
        formvalue.IsDraft = false;
        setIsSubmit(true);

        if (values.PackageDiscount === null) {
            error.PackageDiscount = 'Kindly Enter Discount.'
            setIsSubmit(false);
        }
        if (values.Country === 0) {
            error.Country = 'Cannot leave blank, input is mandatory.';
            setIsSubmit(false);
        }
        if (values.Country === 1) {
            if (values?.StateId?.length === 0) {
                error.StateId = 'Please select State';
                setIsSubmit(false);
            }
            if (values?.StateId?.length > 15) {
                error.StateId = 'Maximum 15 states can be selected';
                setIsSubmit(false);
            }
        }
        if (values?.LocationId?.length === 0) {
            error.Locationid = 'Please select City';
            setIsSubmit(false);
        }
        if (values?.LocationId?.length > 15) {
            error.Locationid = 'Maximum 15 cities can be selected';
            setIsSubmit(false);
        }

        if (values.PackageName.trim().length === 0) {
            error.PackageName = 'Please enter package name';
            setIsSubmit(false);
        }
        if (values.PackageName.trim().length > 50) {
            error.PackageName = 'Maximum length of the package name is 50.';
            setIsSubmit(false);
        }
        if (packagecategoryid === 0) {
            error.PackageCategory = "Please select Package Category";
            setIsSubmit(false);
        }
        if (arrivalslotid === 0) {
            error.ArrivalSlotId = 'Please select Arrival Slot';
            setIsSubmit(false);
        }
        if (departureslotid === 0) {
            error.DepartureSlotid = 'Please select Departure Slot';
            setIsSubmit(false);
        }
        if (!values.PackageValidateStartD || values.PackageValidateStartD.trim().length === 0) {
            error.PackageValidateStart = 'Please select package start date';
            setIsSubmit(false);
        }
        if (!values.PackageValidateEndD || values.PackageValidateEndD.trim().length === 0) {
            error.PackageValidateEnd = 'Please select package end date';
            setIsSubmit(false);
        }
        if (values.PackageValidateStartD > values.PackageValidateEndD && values.PackageValidateStartD !== null && values.PackageValidateEndD !== null && values.PackageValidateStartD !== "" && values.PackageValidateEndD !== "") {
            error.PackageDateValidation = "Please enter valid End Date";
            setIsSubmit(false);
        }
        if (inventoryvalidation.MinSoldoutDate != null) {
            if (formatDate(inventoryvalidation.MinSoldoutDate) < formatDate(values.PackageValidateStartD)) {
                error.PackageValidateStart = 'You are not allowed to change the date as inventory is already in use';
                setIsSubmit(false);
            }
        }
        if (inventoryvalidation.MaxSoldOutDate != null) {
            if (formatDate(inventoryvalidation.MaxSoldOutDate) > formatDate(values.PackageValidateEndD)) {
                error.PackageValidateEnd = 'You are not allowed to change the date as inventory is already in use';
                setIsSubmit(false);
            }
        }
        if (inventoryvalidation.MaxSoldOutDate != null && inventoryvalidation.MinSoldoutDate != null) {
            if (values.PackageCutOffCountD != null && values.PackageCutOffCountD != 0) {
                var d = new Date(values.PackageValidateEndD);
                d.setDate(d.getDate() - values.PackageCutOffCountD);
                if (d <= new Date(inventoryvalidation.MaxSoldOutDate)) {
                    error.PackageCutOffCount = "You are not allowed to change the date as inventory is already in use";
                    setIsSubmit(false);
                }
            }
        }
        if (values.PackageInventoryCountD.toString() == '0' || values.PackageInventoryCountD.toString().trim().length === 0) {
            error.PackageInventoryCount = "Please enter Inventory Count";
            setIsSubmit(false);
        }
        if (inventoryvalidation.InventoryCount > 0) {
            if (values.PackageInventoryCountD < inventoryvalidation.InventoryCount) {
                error.PackageInventoryCount = "Inventory can not be decreased less than max inventory sold";
                setIsSubmit(false);
            }
        }
        if (values.PackageCutOffCountD === null) {
            error.PackageCutOffCount = "Please enter CutOff Count";
            setIsSubmit(false);
        }
        if (values.PackageCutOffCountD !== null) {
            if (values.PackageCutOffCountD > 100 || values.PackageCutOffCountD < 0) {
                error.PackageCutOffCount = "Package Cut off days should be between 0 to 100";
                setIsSubmit(false);
            }
        }
        if (values.PackageHighlights.trim().length === 0) {
            error.PackageHighlights = "Please enter Package Highlights";
            setIsSubmit(false);
        }
        // vendor package highlights field has max length i.e., infinite length //
        // if (values.PackageHighlights.trim().length > 250) {
        //     error.PackageHighlights = "Maximum length is 250 character.";
        //     setIsSubmit(false);
        // }
        //const gallerycount = galleridlist?.
        //const eqSet = (xs, ys) => [...galleryimgpath].every((x) => ys.includes(x));
        // const bannercount = bannerimgpath.filter(obj => obj.GalleryId !== 0)
        if (bannerimgpath === '') {
            error.BannerImgPath = "Please select Banner";
            setIsSubmit(false);
        }

        const galleridcount = galleridlist?.filter(obj => obj.Id);
        // const galleridcount = galleridlist?.filter(obj => obj.Id === 0);
        if (galleridcount.length === 0) {
            // if (galleridcount.length > 0) {
            error.GalleryCount = "Please select at least one Gallery";
            setIsSubmit(false);
        }

        if (inclusioncnt <= 0) {
            dispatch(errorActions.inclusionstart());
            // dispatch(errorActions.submitend());
            setIsSubmit(false);
        }

        if (exclusioncnt <= 0) {
            dispatch(errorActions.exclusionstart());
            // dispatch(errorActions.submitend());
            setIsSubmit(false);
        }
        const policymenuselection = menulist?.filter((obj) => obj.PackageMenuId === 5 && obj.IsDefault === true)
        policymenuselection.length === 1 ? setPolicyCheck(true) : setPolicyCheck(false);
        if (policymenuselection.length === 1) {
            if (policycnt <= 0) {
                dispatch(errorActions.policystart());
                setIsSubmit(false);
                // setPolicyCheck(false)
            }
        }
        // policymenuselection.length === 1 ? setPolicyCheck(true) : setPolicyCheck(false);
        const overviewcount = menulist?.filter((obj) => obj.PackageMenuId === 1 && obj.IsDefault === true);
        overviewcount.length === 1 ? setOverViewCheck(true) : setOverViewCheck(false);
        if (overviewcount.length === 1) {

            if (overviewduration === 0 || overviewdurationfrom === 0 || overviewdurationto === 0 || overviewvisitfrom === 0 || overviewvisitto === 0 || overviewtheme === 0 || overviewcitydetail === 0 || overviewnightpending === 0) {
                dispatch(errorActions.overviewstart());
                setIsSubmit(false);
            }

            // setOverViewCheck(true);
        }
        //pricing
        const pricingcount = menulist?.filter((obj) => obj.PackageMenuId === 9 && obj.IsDefault === true);
        pricingcount.length === 1 ? setPricingCheck(true) : setPricingCheck(false);
        if (pricingcount.length === 1) {

            if (pricingsupplier === 0 || pricingtransfer === 0 || pricingjoiningcost === 0 || pricingoperationcost === 0 || pricingtour === 0 || pricingchildren === 0) {
                dispatch(errorActions.pricingtart());
                setIsSubmit(false);
            }

            // setOverViewCheck(true);
        }

        //obj.SeqNo
        const aboutmenucount = menulist?.filter((obj) => obj.PackageMenuId === 7 && obj.IsDefault === true)
        aboutmenucount.length === 1 ? setAboutPlaceChecked(true) : setAboutPlaceChecked(false);
        // const aboutmenudatacount = datacitydetails?.filter((obj) => obj.SeqNo > 0);

        if (aboutmenucount.length === 1) {
            // error.AboutPlaceError = 'Please enter about place detail';
            if (aboutcnt <= 0) {
                dispatch(errorActions.aboutplacestart())
                setIsSubmit(false);
            }

        }

        const itinerarymenucount = menulist?.filter((obj) => obj.PackageMenuId === 2 && obj.IsDefault === true)
        if (itinerarymenucount.length === 1) {
            if (itinerarycnt <= 0) {
                // error.MarkupvalueError = 'Please enter MarkupValue';
                dispatch(errorActions.itinerarystart());
                setIsSubmit(false);
            }

        }
        // const itinerarymenucount = menulist?.filter((obj) => obj.PackageMenuId === 2 && obj.IsDefault === true)
        // const itinerarydatacount = dataitinerary?.filter((obj) => obj.SeqNo > 0)

        // if (itinerarymenucount.length === 1 && itinerarydatacount.length === 0) {
        //     error.ItineraryError = 'Please enter itinerary detail';
        //     setIsSubmit(false);
        // }

        if (gsttype === 'GST' && (values.PackageFixPrice < 1 || values.PackageFixPrice > 99.99)) {
            error.MarkupvalueError = 'Entered percentage should be 1 < value < 100';
            setIsSubmit(false);
        }
        // if(itinerarymenucount.length===1 && itinerarydatacount.length > 0 && (Number(formvalue.PackageMarkupValue) === 0 || formvalue.PackageMarkupValue === ''))
        // {
        //     error.MarkupvalueError = 'Please enter MarkupValue';
        //     setIsSubmit(false);
        // }
        return error;
    }
    //#endregion

    //#region retrive data


    const [retrivedata, setRetriveData] = useState([]);

    const handleRetrive = async () => {
        //setButtonAddEditText("Update");
        const vPackageId = packageD.state.PackageId;
        const PackageById = await postUsersData('/master/vendor/v1/vengetpackagebyid', { vPackageId });
        setRetriveData(PackageById.data);
    }

    useEffect(() => {
        if (retrivedata?.length === 1 && menulist?.length > 0 && locationData?.length > 0) {
            dispatch(errorActions.inclusionreset());
            dispatch(errorActions.exclusionreset());
            dispatch(errorActions.overviewdurationreset());
            dispatch(errorActions.overviewdurationfromreset());
            dispatch(errorActions.overviewdurationtoreset());
            dispatch(errorActions.overviewvisitfromreset());
            dispatch(errorActions.overviewvisittoreset());
            RetriveData();
        }
    }, [retrivedata, locationData]);

    const [deletefilelist, setDeleteFileList] = useState([]);
    const [datapolicydetails, setPolicyDetails] = useState([]);
    const [datainclusiondetails, setInclusionDetails] = useState([]);
    const [dataexclusiondetails, setExclusionDetails] = useState([]);
    const [datacitydetails, setCityDetails] = useState([]);

    const RetriveData = () => {
        //document.getElementById("txtcityimages").value = "";
        let locations = locationData[0]?.cityData?.filter((loc) => retrivedata[0]?.locationId?.includes(loc?.LocationId))
        formvalue.Country = locations[0]?.CountryId;
        retrivedata?.map(obj => {
            if (locations[0]?.CountryId === 1) {
                setTempStateId(obj.stateId);
            }
            formvalue.PackageDiscount = obj.packageDiscount
            formvalue.VPackageId = obj.vPackageId
            formvalue.PackageName = obj.packageName
            formvalue.PackageCode = obj.packageCode
            formvalue.PackageCategoryId = obj.packageCategoryId
            formvalue.StateId = obj.stateId
            formvalue.LocationId = obj.locationId
            formvalue.PackageTypeId = Number(obj.packageTypeId)
            formvalue.PackageLevelId = Number(obj.packageLevelId)
            formvalue.IsInterCity = obj?.isInterCity
            formvalue.IsBestPackage = obj.isBestPackage
            formvalue.ArrivalSlotId = obj.arrivalSlotId
            formvalue.DepartureSlotId = obj.departureSlotId
            formvalue.PackageValidateStart = obj.packageValidateStart
            formvalue.PackageValidateEnd = obj.packageValidateEnd
            const overviewCity = nightallcity?.filter((value) => obj.locationId?.includes(value.LocationId))?.map((ele) => ({
                label: ele.City,
                value: ele.LocationId,
                IsActive: ele.IsActive
            }))
            setOverviewcity(overviewCity)

            if (obj.packageValidateStart !== null) {
                formvalue.PackageValidateStartD = formatDate(obj.packageValidateStart)
                setPackageValidateStart(new Date(formatDate(obj.packageValidateStart)))
            }
            if (obj.packageValidateEnd !== null) {
                formvalue.PackageValidateEndD = formatDate(obj.packageValidateEnd)
                setPackageValidateEnd(new Date(formatDate(obj.packageValidateEnd)))
            }
            formvalue.PackageInventoryCount = obj.packageInventoryCount
            formvalue.PackageCutOffCount = obj.packageCutOffCount
            if (obj.packageInventoryCount === 0) {
                formvalue.PackageInventoryCountD = ''
            }
            else {
                formvalue.PackageInventoryCountD = obj.packageInventoryCount
            }
            if (obj.packageCutOffCount === null) {
                formvalue.PackageCutOffCountD = ''
            }
            else {
                formvalue.PackageCutOffCountD = obj.packageCutOffCount
            }


            formvalue.PackageHighlights = obj.packageHighlights
            // {formvalue.packageBasicPrice = obj.packageBasicPrice}
            formvalue.IsActive = obj.isActive
            formvalue.IsDraft = obj.isDraft
            formvalue.PackageBanner = obj.packageBanner
            formvalue.PackageGallery = obj.packageGallery

            formvalue.PackageMenu = obj.packageMenu
            formvalue.PackageInclusion = obj.packageInclusion
            formvalue.PackageExclusion = obj.packageExclusion
            formvalue.PackagePolicy = obj.packagePolicy
            formvalue.PackageOverview = obj.packageOverview || []
            formvalue.PackagePlace = obj.packagePlace
            formvalue.PackageSummary = obj.packageSummary

            formvalue.IsBestPackage = obj.isBestPackage

            formvalue.IsHotDeals = obj.isHotDeals
            formvalue.HotOfferPercentage = obj.hotOfferPercentage
            formvalue.IsInternationalDestination = obj.isInternationalDestination
            formvalue.IsBestDestination = obj.isBestDestination
            formvalue.IsTrendingHoliday = obj.isTrendingHoliday

            formvalue.PackageItinerary = obj.packageItinerary

            formvalue.PackageBasicPrice = obj.packageBasicPrice
            formvalue.PackageMarkup = obj.packageMarkup
            formvalue.PackageMarkupValue = (obj.packageMarkupValue === null ? "0" : obj.packageMarkupValue.toString())
            formvalue.PackageFixPrice = obj.packageMarkupValue
            formvalue.PackageFinalValue = obj.packageFinalValue
            formvalue.PackageFinalPrice = obj.packageFinalValue
            formvalue.GST = 1
            formvalue.PackageSummary = obj.packageSummary
            formvalue.VenPkgsupplierId = obj.venPkgsupplierId
            formvalue.TransferPrice = obj.transferPrice
            formvalue.TransferCategoryId = obj.transferCategoryId
            formvalue.DirectJoiningCostPrice = obj.directJoiningCostPrice
            formvalue.PricingTypeId = obj.pricingTypeId
            formvalue.FixOperationCostPrice = obj.fixOperationCostPrice
            formvalue.TourPricingTypeId = obj.tourPricingTypeId
            formvalue.TourManagementPrice = obj.tourManagementPrice
            setPricingDetails({
                ...pricingdetails,
                supplierId: obj.venPkgsupplierId,
                categoryid: obj.transferCategoryId,
                transferPrice: obj.transferPrice,
                directJoiningCostPrice: obj.directJoiningCostPrice,
                oprationcostId: obj.pricingTypeId,
                fixOperationCostPrice: obj.fixOperationCostPrice,
                tourTypeId: obj.tourPricingTypeId,
                tourManagementPrice: obj.tourManagementPrice,
                childrenPrice: obj.childrenPrice
            });
            setInventoryValidation({ ...inventoryvalidation, InventoryCount: obj.inventoryCount, MinSoldoutDate: obj.minSoldoutDate, MaxSoldOutDate: obj.maxSoldOutDate })
            //setGstType(obj.packageMarkup);

        });
        setPkgFinalPrice(formvalue.PackageFinalPrice);
        setGstId(1);
        setGst(5)
        if (formvalue.PackageMarkup && formvalue.PackageMarkup !== null) {
            setGstType(formvalue.PackageMarkup);
        }
        else {
            setGstType('FIX');
        }

        setBestPackage(formvalue.IsBestPackage);
        //setHotOfferPercentage(formvalue.HotOfferPercentage);
        // setIsInternationalDestination(formvalue.IsInternationalDestination);
        // setIsBestDestination(formvalue.IsBestDestination);
        // setIsTrendingHoliday(formvalue.IsTrendingHoliday);
        setStateId(formvalue.StateId);
        // setLocationId(formvalue.LocationId);
        setActiveId(formvalue.IsActive);
        setArrivalSlotId(formvalue.ArrivalSlotId);
        setDepartureSlotId(formvalue.DepartureSlotId);
        setPackageCategoryId(formvalue.PackageCategoryId);
        const bannerid = formvalue.PackageBanner?.map((obj) => {
            return obj.bannerId;
        }) || [];
        setBannerId(bannerid[0]);
        banneridlist.BannerId = bannerid[0]
        if (formvalue.PackageBanner?.length > 0) {
            let allbannerlist = allbanner?.filter(IsAct => IsAct.IsActive === true);
            setBannerList(allbanner?.filter(IsAct => IsAct.IsActive === true));
            setBannerDisabled(false);
            if (!allbannerlist?.find(IsBannerName => IsBannerName.BannerId === formvalue.PackageBanner[0].bannerId)) {
                setBannerDisabled(true)
                setBannerList((prvBannerdata) => prvBannerdata?.length > 0 ? [...prvBannerdata, allbanner?.find(isBanner => isBanner.BannerId === formvalue.PackageBanner[0].bannerId)] : [])

            }
            allbanner?.filter(isBanner => isBanner.BannerId === bannerid[0])?.map((value) => {
                setBannerImgPath('https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + value.BannerPath);
            })
        }

        if (formvalue.PackageGallery?.length > 0) {
            formvalue.PackageGallery?.map((val, index) => {
                const d = gallerylist?.filter((obj) => obj.GalleryId === val.galleryId)?.map((value) => { return value.ImagePath });
                setGalleryCount(index + 1);
                if (index === 0) {
                    let cnti = index + 1
                    setGalleryIdList([{ GalleryId: val.galleryId, Id: cnti }]);
                    // setGalleryImgPath(current => [{ GalleryId: val.galleryId, GalleryPath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + d[0], Id: cnti }]);
                    setGalleryImgPath(current => [{ GalleryId: val.galleryId, GalleryPath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + (d !== undefined ? d[0] : ''), Id: cnti }]);
                }
                else {
                    let cntz = index + 1
                    setGalleryIdList(current => [...current, { GalleryId: val.galleryId, Id: cntz }]);
                    // setGalleryImgPath(current => [...current, { GalleryId: val.galleryId, GalleryPath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + d[0], Id: cntz }]);
                    setGalleryImgPath(current => [...current, { GalleryId: val.galleryId, GalleryPath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + (d !== undefined ? d[0] : ''), Id: cntz }]);
                }
            });
        }
        formvalue.PackageMenu?.map((val, index) => {
            const newList = menulist;
            newList?.filter(e => e.PackageMenuId === val.packageMenuId)?.map((item) => {
                item.IsDefault = true;
                Shown[item.PackageMenuName] = true;
            });

            setMenuList(newList);
        });
        // setInclusionDetails(formvalue.PackageInclusion)
        formvalue.PackageInclusion?.filter((obj) => obj.inclusionId > 0)?.map((val, index) => {
            const d = inclusionlistdata?.filter((obj) => obj.value === val.inclusionId)?.map((obj) => { return obj})
            console.log("d..............",d)
            setInclusionDetails(current => [...current, {
                SeqNo: index + 1,
                Inclusion: d[0].label,
                InclsuionId: val.inclusionId,
                ShortName: d[0].ShortName
            }]);
            dispatch(errorActions.inclusionincrement());
        });
        formvalue.PackageExclusion?.filter((obj) => obj.exclusionId > 0)?.map((val, index) => {
            const d = exclusionistdata?.filter((obj) => obj.value === val.exclusionId)?.map((obj) => { return obj.label })
            setExclusionDetails(current => [...current, { SeqNo: index + 1, Exclusion: d[0], ExclusionId: val.exclusionId }]);
            dispatch(errorActions.exclusionincrement());
        });
        formvalue.PackagePolicy?.filter((obj) => obj.policyId > 0)?.map((val, index) => {
            const d = policylistdata?.filter((obj) => obj.value === val.policyId)?.map((obj) => { return obj.label });
            setPolicyDetails(current => [...current, { SeqNo: index + 1, Policy: d[0], PolicyId: val.policyId }]);
            dispatch(errorActions.ploicyincrement())

        });

        setCheckedValueOfPackageTypeValue(formvalue.PackageTypeId);
        setCheckedValueOfPackageLevelValue(formvalue.PackageLevelId);
        if (formvalue.PackageOverview?.length > 0) {
            setOverViewDetails(formvalue.PackageOverview)

        }
        if (formvalue.PackagePlace?.length > 0) {
            formvalue.PackagePlace?.map((val, index) => {
                setCityDetails(current => [...current, {
                    SeqNo: index + 1, LocationId: val.locationId, CityName: val.city,
                    CityDetails: val.cityDetail, PlaceImageName: val.placeImageName,
                    // PlaceImagePath: 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + val.placeImagePath
                    PlaceImagePath: val.placeImagePath
                }]);
                dispatch(errorActions.aboutplaceincrement())
            });

        }

        if (formvalue.PackageItinerary?.length > 0) {
            if (formvalue.PackageTypeId == 1) {
                setFlightVisibled(false);
            } else { setFlightVisibled(true) }
            formvalue.PackageItinerary?.map((val, index) => {

                setDataItinerary(current =>
                    [...current, {
                        SeqNo: index + 1,
                        Day: val.day,
                        DaySchedule: val.daySchedule,
                        FlightFromLocationId: val.flightFromLocationId,
                        FlightToLocationId: val.flightToLocationId,
                        FlightNo: val.flightNo,
                        ArrivalTime: (val.arrivalTime) !== '1900-01-01T00:00:00' ? val.arrivalTime : null,
                        DepartureTime: (val.departureTime) !== '1900-01-01T00:00:00' ? val.departureTime : null,
                        ArrivalTimeD: (val.arrivalTime) !== '1900-01-01T00:00:00' ? formatTime(val.arrivalTime) : '',
                        DepartureTimeD: (val.departureTime) !== '1900-01-01T00:00:00' ? formatTime(val.departureTime) : '',
                        ClassId: val.classId,
                        SupplierId: val.supplierId,
                        FlightPrice: Number(val.flightPrice),
                        HotelId: val.hotelId,
                        RoomTypeId: val.roomTypeId,
                        NoOfPax: val.noOfPax,

                        HotelPrice: val.hotelPrice,
                        SightseeingId: val.sightseeingId,
                        VehicleId: val.vehicleId,
                        SightseeingPrice: val.sightseeingPrice,
                        AirlineId: val.airlineId,
                        AttractionBasicId: val?.attractionBasicId,
                    }]);
                dispatch(errorActions.itineraryincrement());
                // setDataItineraryCounter(index + 1);
            })


        }
        if (formvalue.PackageSummary?.length > 0) {
            setPkgSummary(formvalue.PackageSummary)
        }
        setDataRetrivalFlag(true);
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return date === null ? null : [year, month, day].join('-');
    }
    function formatTime(date) {

        var d = new Date(date),

            hour = d.getHours(),
            minu = d.getMinutes();


        if (minu.toString().length < 2)
            minu = '0' + minu;
        if (hour.toString().length < 2)
            hour = '0' + hour;

        if (date === null) {
            minu = '';
            hour = '';
        }

        return [hour, minu].join(':');
    }

    //#endregion

    //#region  Package type and level change handle
    const handlePackageTypeChange = (id, e) => {
        // formvalue.PackageTypeId = Number(e.target.value)
        setFormValue((form) => ({ ...form, PackageTypeId: Number(e.target.value) }))
        //setCheckedValueOfPackageType(e.target.value);
        setEditDisabled(false);



        const newList = packagetypelist?.map((item) => {


            if (item.PackageTypeId === id) {
                const updatedItem = {
                    ...item,
                    IsDefualt: !item.IsDefualt,
                };
                //const list = handleotherradio(id,updatedItem);
                return updatedItem;
            }

            return item;
        });
        setPackageTypeList(newList);
        setCheckedValueOfPackageTypeValue(id);
        setpackagetypecounter(counterpackagetype + 1);

    }


    const [counterpackgelevel, setPackageLevelCounter] = useState(0);
    const [counterpackagetype, setpackagetypecounter] = useState(0);


    const handlepackagetyperadio = (id) => {
        const newList = packagetypelist?.map((item) => {


            if (item.PackageTypeId !== id) {
                const updatedItem = {
                    ...item,
                    IsDefualt: false,
                };
                //const list = handleotherradio(id,updatedItem);
                return updatedItem;
            }

            return item;
        });
        //alert(JSON.stringify(newList));
        setPackageTypeList(newList);
    }
    useEffect(() => {
        if (counterpackagetype > 0)
            handlepackagetyperadio(checkedvalueofpackagetypevalue);
    }, [counterpackagetype]);

    //#endregion

    //#region  gst count

    const [gstid, setGstId] = useState(1);
    const [gst, setGst] = useState(5);
    const [gsttype, setGstType] = useState('FIX');

    const gstlist = [
        { value: 1, label: '5' },
        { value: 2, label: '10' },
        { value: 3, label: '18' },
        { value: 4, label: '28' }
    ]
    const [gstamout, setGSTAmount] = useState(0);
    //const [netprice , setNetPrice] = useState(0);

    const handlegstonchange = (e) => {
        reseterrormessage();
        setEditDisabled(false);
        formvalue.GST = e.value
        //setGstId(e.value);
        setGst(e.label);

        handlegstcalculation();

    }
    //  useEffect(()=> {handlegstcalculation();},[gst]);
    const handlegsttypechange = (e) => {
        formvalue.GST = gstid
        formvalue.PackageFixPrice = 1
        formvalue.PackageMarkupValue = '1'
        setGstType('GST');
    }
    useEffect(() => {
        handlegstcalculation();
    }, [gsttype]);

    const handlefixtypetax = () => {
        //{formvalue.PackageFinalPrice = 0}
        // {formvalue.GST = 0}
        //setGstId(0);
        formvalue.GST = gstid
        formvalue.PackageFixPrice = 0
        formvalue.PackageMarkupValue = '0'
        setGstType('FIX');
        handlegstcalculation();
        setFormError('');
    }
    const handlegstcalculation = () => {
        let basicpricecalculation = 0;
        setGst(5);

        const result = (dataitinerary.reduce((total, currentValue) => total = total + (Number(currentValue.FlightPrice) + Number(currentValue.HotelPrice) + Number(currentValue.SightseeingPrice)), 0))
            + ((formvalue.DirectJoiningCostPrice === null ? 0 : formvalue.DirectJoiningCostPrice) + (formvalue.TransferPrice === null ? 0 : formvalue.TransferPrice) + (formvalue.FixOperationCostPrice === null ? 0 : formvalue.FixOperationCostPrice) + (formvalue.TourManagementPrice === null ? 0 : formvalue.TourManagementPrice));
        basicpricecalculation = result;

        //fixprice
        if (result > 0) {
            formvalue.PackageBasicPrice = result;
            //alert('fixprice',formvalue.PackageFixPrice);
            if (gsttype === 'GST') {
                let markupvalue = (Number(basicpricecalculation) * Number(formvalue.PackageFixPrice)) / 100;
                const GSTAmount = (((Number(basicpricecalculation) + Number(markupvalue)) * Number(gst)) / 100);
                setGSTAmount(GSTAmount);
                //const NetPrice = Number(basicpricecalculation) + GSTAmount
                formvalue.PackageFinalPrice = (Number(basicpricecalculation) + Number(markupvalue) + Number(GSTAmount)).toFixed(2)
                setPkgFinalPrice((Number(basicpricecalculation) + Number(markupvalue) + Number(GSTAmount)).toFixed(2));
            }
            else {
                let gstfixvalue = (((Number(basicpricecalculation) + Number(formvalue.PackageFixPrice)) * Number(gst)) / 100);
                ///formvalue.PackageFinalPrice = (Number(basicpricecalculation) + Number(formvalue.PackageFixPrice)+ Number(gstfixvalue)).toFixed(2)
                formvalue.PackageFinalPrice = (Number(basicpricecalculation) + Number(formvalue.PackageFixPrice) + Number(gstfixvalue)).toFixed(2)
                setPkgFinalPrice((Number(basicpricecalculation) + Number(formvalue.PackageFixPrice) + Number(gstfixvalue)).toFixed(2))
            }
        }
        else {
            formvalue.PackageBasicPrice = 0
            if (gsttype === 'GST') {
                setGSTAmount(0)
                formvalue.PackageFinalPrice = 0
                setPkgFinalPrice(0)

            }
            else {
                formvalue.PackageFinalPrice = 0
                setPkgFinalPrice(0)
            }

        }
    }
    //#endregion

    const onChange = (e) => {
        var result;

        if ([e.target.name][0] === 'IsBestPackage') {
            setBestPackage(e.target.checked);
            result = e.target.checked
        }


        else if ([e.target.name][0] === 'PackageInventoryCountD') {
            result = isNaN(e.target.value) ? 0 : Number(onlyNumeric(e.target.value));

            formvalue.PackageInventoryCount = result

        }
        else if ([e.target.name][0] === 'PackageCutOffCountD') {
            result = e.target.value === "" ? null : Number(onlyNumeric(e.target.value));
            if (result === null) {
                formvalue.PackageCutOffCount = null
            }
            else {
                formvalue.PackageCutOffCount = result
            }

        }
        else if ([e.target.name][0] === 'PackageValidateStartD') {

            formvalue.PackageValidateStart = e.target.value
            //{ formvalue.PackageValidateStartD = e.target.value }
            result = e.target.value;
        }
        else if ([e.target.name][0] === 'PackageValidateEndD') {
            formvalue.PackageValidateEnd = e.target.value
            //{ formvalue.PackageValidateEndD = e.target.value }
            result = e.target.value;
        }
        else if ([e.target.name][0] === 'PackageFixPrice') {
            formvalue.PackageFixPrice = ((e.target.value !== '' && !isNaN(e.target.value)) ? (NumericWithDecimal(e.target.value)) : '0')
            formvalue.PackageMarkupValue = ((e.target.value !== '' && !isNaN(e.target.value)) ? (NumericWithDecimal(e.target.value)) : '0')
            // setFixPrice(Number(e.target.value));
            result = ((e.target.value !== '' && !isNaN(e.target.value)) ? (NumericWithDecimal(e.target.value)) : '0');
            handlegstcalculation();

            // {formvalue.PackageFixPrice = Number(e.target.value)}
            // {formvalue.PackageMarkupValue = e.target.value}
            // {formvalue.PackageFinalPrice = Number(formvalue.PackageBasicPrice)+  Number(e.target.value)}
        }

        else if ([e.target.name][0] === 'FlightPrice') {
            result = isNaN(e.target.value) ? 0 : Number(onlyNumeric(e.target.value))
        }
        else if ([e.target.name][0] === 'PackageDiscount') {
            result = Number(onlyNumeric(e.target.value));
        }
        else {
            result = e.target.value;
        }

        setFormValue({ ...formvalue, [e.target.name]: result });
        if ([e.target.name][0] === 'PackageName') {
            setBasicDraftDisabled(false);
        }
        dispatch(errorActions.resetallstatusflag());
        reseterrormessage();
        setEditDisabled(false);
    }

    //#region textbox

    const config = useMemo(() => ({
        readonly: false, // all options from https://xdsoft.net/jodit/doc/,
        height: '100%',
        width: '100%',
        statusbar: false,
        removeButtons: ["link", "about"],
        toolbarAdaptive: false,
        height: 'auto',
        maxHeight: richboxheight === true ? 200 : 500,
        disablePlugins: ["source"],
        disabled: disabled ? true : false
    }), [disabled]);
    useEffect(() => {
        const richtexteboxheight = document.getElementById("layoutSidenav_content").clientWidth
        if (richtexteboxheight > 912) {
            setRichBoxHeight(true)
        }
        else {
            setRichBoxHeight(false)
        }
    }, []);

    const dayscheduleconfig = useMemo(() => ({
        readonly: false, // all options from https://xdsoft.net/jodit/doc/,
        height: '100%',
        width: '100%',
        statusbar: false,
        disablePlugins: ["source"],
        removeButtons: ["about", "image", "link", "select", "video"],
        height: 'auto',
        maxHeight: richboxheight === true ? 200 : 500,
        toolbarAdaptive: false,
        //autofocus: true,
        defaultMode: "1",
        disabled: disabled ? true : false
    }), [disabled]);
    //#endregion textbox


    const openDatePicker = (e) => {
        document.querySelector("#" + e).click();
    };
    //#region start input date type box

    const handleDateChange = (fieldname, e) => {

        if (fieldname === 'PackageValidateStartD') {
            // { formvalue.AirrivalTime = formatDate(e) + "T" + formvalue.AirrTime }
            formvalue.PackageValidateStartD = formatDate(e)
            formvalue.PackageValidateStart = formatDate(e)
            setPackageValidateStart(e);
        }
        else if (fieldname === 'PackageValidateEndD') {
            // { formvalue.DepartureTime = formatDate(e) + "T" + formvalue.DepTime }
            formvalue.PackageValidateEndD = formatDate(e)
            formvalue.PackageValidateEnd = formatDate(e)
            setPackageValidateEnd(e)
        }
        setEditDisabled(false);
        reseterrormessage();
        dispatch(errorActions.resetallstatusflag())
    }

    //#endregion end input date type box

    const handleMenuChange = (e) => {
        dispatch(errorActions.resetallstatusflag());
        setMenuList(e);
        if (formvalue.PackageTypeId == 1) {
            setFlightVisibled(false);
        } else { setFlightVisibled(true) }
    }
    const handlePolicyDetail = (e) => {
        if (e != null) {
            setPolicyDetails(e)
        }
    }
    const handleExclusion = (e) => {
        if (e != null) {
            setExclusionDetails(e)
        }
    }
    const handleInclusionDetail = (e) => {
        if (e != null) {
            setInclusionDetails(e)
        }
    }
    const handleOverView = (e) => {
        if (e != null) {
            setOverViewDetails(e)
        }
    }
    const handleDisableFlag = (e) => {
        setEditDisabled(e);
        setFormError('');
    }
    const handleAboutDetail = (e) => {
        setCityDetails(e)
    }
    const handleDeleteFileOfAbout = (e) => {
        if (e != null) {
            setDeleteFileList(e);
        }

    }
    const handleVisibilityDetail = (e) => {
        if (e != null) {
            formvalue.IsHotDeals = e.IsHotDeals != null ? e.IsHotDeals : false
            formvalue.HotOfferPercentage = e.HotOfferPercentage != null ? e.HotOfferPercentage : 0
            formvalue.IsInternationalDestination = e.IsInternationalDestination != null ? e.IsInternationalDestination : false
            formvalue.IsBestDestination = e.IsBestDestination != null ? e.IsBestDestination : false
            formvalue.IsTrendingHoliday = e.IsTrendingHoliday != null ? e.IsTrendingHoliday : false
        }
        else {
            formvalue.IsHotDeals = false
            formvalue.HotOfferPercentage = false
            formvalue.IsInternationalDestination = false
            formvalue.IsBestDestination = false
            formvalue.IsTrendingHoliday = false
        }
    }
    const handleItineraryDetail = (e) => {
        setDataItinerary(e);
    }
    useEffect(() => {

        handlegstcalculation();
    }, [dataitinerary])
    const handleSummary = (e) => {
        if (e != null) {
            setPkgSummary(e)
        }
    }
    const handlePricing = (e) => {
        if (e != null) {
            formvalue.VenPkgsupplierId = e.supplierId
            formvalue.TransferCategoryId = e.categoryid
            formvalue.DirectJoiningCostPrice = e.directJoiningCostPrice === '' ? null : e.directJoiningCostPrice
            formvalue.TransferPrice = e.transferPrice === '' ? null : e.transferPrice
            formvalue.PricingTypeId = e.oprationcostId
            formvalue.FixOperationCostPrice = e.fixOperationCostPrice === '' ? null : e.fixOperationCostPrice
            formvalue.TourPricingTypeId = e.tourTypeId
            formvalue.TourManagementPrice = e.tourManagementPrice === '' ? null : e.tourManagementPrice
            formvalue.ChildrenPrice = e.childrenPrice === '' ? null : Number(e.childrenPrice)
            setPricingDetails(e)
            handlegstcalculation();
        }
    }
    return (
        <>
            <div className="container-fluid mt-4 heading_color">
                <h3>Vendor Package</h3>
            </div>
            <div className="container-fluid form_border py-5 my-3">
                <form action="" >
                    <div className="mb-3 row mx-1">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                                {/* {flightduplicateerrorMessage && <p className='text-danger text-start'>{flightduplicateerrorMessage}</p>} */}

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className="col-form-label required">Select Country</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">
                                            <Select
                                                ref={CountryId}
                                                id="CountryId"
                                                className="dropdown select_dropdown"
                                                options={locationDropdown?.country}
                                                value={locationDropdown?.country?.filter(obj => obj?.value === formvalue?.Country)}
                                                onChange={(e) => onSelectChange('Country', e)} // assign onChange function
                                                isDisabled={stateenable ? true : false}
                                                isSearchable
                                            />
                                            {formerror.Country && <p className='text-danger text-start'>{formerror.Country}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className={`col-form-label ${formvalue.Country === 1 ? 'required' : ''} `}>Select State</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">
                                            <Select
                                                ref={StateId}
                                                id="StateId"
                                                className="dropdown select_dropdown"
                                                options={locationDropdown?.state}
                                                value={locationDropdown?.state?.filter(obj => tempStateId?.includes(obj?.value))}
                                                // onChange={handleStateChange} // assign onChange function
                                                onChange={(e) => onSelectChange('StateId', e)} // assign onChange function
                                                isDisabled={(stateenable ? true : false) || formvalue.Country !== 1}
                                                isSearchable
                                                isMulti
                                            />
                                            {formerror.StateId && <p className='text-danger text-start'>{formerror.StateId}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className="col-form-label required">Select City</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">
                                            <Select
                                                ref={CityId}
                                                id="CityId"
                                                className="dropdown select_dropdown"
                                                options={locationDropdown?.city}
                                                value={locationDropdown?.city?.filter(obj => formvalue?.LocationId?.includes(obj?.value))}
                                                // onChange={handleCityChange} // assign onChange function
                                                onChange={(e) => onSelectChange('LocationId', e)} // assign onChange function
                                                isDisabled={stateenable ? true : false}
                                                isSearchable
                                                isMulti
                                            />
                                            {formerror.Locationid && <p className='text-danger text-start'>{formerror.Locationid}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className="col-form-label required">Package Code</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">
                                            <input type="text" id="inputPassword6" className="form-control"
                                                name="PackageCode"
                                                value={formvalue.PackageCode}
                                                onChange={onChange}
                                                disabled={true}
                                                placeholder="Package Code"
                                            />
                                            {/* {formerror.PackageCode && <p className='text-danger text-start'>{formerror.PackageCode}</p>} */}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className="col-form-label required">Package Name</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">
                                            <input type="text" id="PackageName" className="form-control"
                                                name="PackageName"
                                                value={formvalue.PackageName}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder="Package Name"
                                            />
                                            {formerror.PackageName && <p className='text-danger text-start'>{formerror.PackageName}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className="col-form-label required">Package Type</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">
                                            {/* //checkedvalueofpackagetypevalue */}
                                            {packagetypelist && packagetypelist?.map((ele, index) => {
                                                return (<div className="form-check-inline">
                                                    <input className="form-check-input" type="radio" name="flexRadioType" id="flexRadioLevel4" value={ele.PackageTypeId} key={ele.PackageTypeId} onChange={(e) => handlePackageTypeChange(ele.PackageTypeId, e)}
                                                        checked={checkedvalueofpackagetypevalue === ele.PackageTypeId ? true : false} disabled={stateenable ? true : false} />
                                                    <label className="form-check-label ms-1">{ele.PackageTypeName}</label>
                                                    {/* <label className="form-check-label ms-1" htmlFor="flexRadioLevel4">{checkedvalueofpackagetypevalue}</label> */}
                                                </div>
                                                );

                                            })}
                                            {/* {formErrors.Classid && <p className='text-danger text-start'>{formErrors.Classid}</p>} */}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className="col-form-label required">Select Category</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">
                                            <Select
                                                ref={PackageCategory}
                                                id="PackageCategory"
                                                className="dropdown select_dropdown"
                                                options={packagecategorydatalist}
                                                value={packagecategorydatalist?.filter(function (packagecategorydatalist) {
                                                    return packagecategorydatalist.value === packagecategoryid;
                                                })}// set list of the data
                                                onChange={handlepackagecategory} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable
                                            />
                                            {formerror.PackageCategory && <p className='text-danger text-start'>{formerror.PackageCategory}</p>}
                                        </div>
                                    </div>
                                </div>


                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className="col-form-label required">Arrival Slot</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">
                                            <Select
                                                id="ArrivalSlotId"
                                                ref={ArrivalSlotId}
                                                className="dropdown select_dropdown"
                                                options={arrivalslotlist}
                                                value={arrivalslotlist?.filter(function (arrivalslotlist) {
                                                    return arrivalslotlist.value === arrivalslotid;
                                                })}// set list of the data
                                                onChange={handleArrivalSlotChange} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable
                                            />
                                            {formerror.ArrivalSlotId && <p className='text-danger text-start'>{formerror.ArrivalSlotId}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className="col-form-label required">Package Level</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">
                                            {packagelevellist && packagelevellist?.map((ele, index) => {
                                                return (
                                                    <div className="form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="flexRadioLevel"
                                                            id={ele?.PackageLevelName}
                                                            key={ele?.PackageLevelId}
                                                            checked={formvalue?.PackageLevelId === ele?.PackageLevelId}
                                                            disabled
                                                        />
                                                        <label className="form-check-label ms-1">{ele.PackageLevelName}</label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className="col-form-label required">Package Routing</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">
                                            {
                                                IsInterCityRadio?.map((obj) => {
                                                    return (
                                                        <div className="form-check-inline" key={obj?.title}>
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="PackageRouting"
                                                                id={obj?.title}
                                                                checked={obj?.id === 1 ? formvalue?.IsInterCity : !formvalue?.IsInterCity}
                                                                disabled
                                                            />
                                                            <label className="form-check-label ms-1" htmlFor={obj?.title}>
                                                                {obj?.title}
                                                            </label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>

                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className="col-form-label required">Package Validity Start</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">

                                            <div className="text-container">
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    className='form-control'
                                                    id='startdate'
                                                    selected={packageValidateStart}
                                                    onChange={(e) => handleDateChange('PackageValidateStartD', e)}
                                                    disabled={disabled ? true : false}
                                                    placeholderText="dd/mm/yyyy"
                                                    // onInputClick={true}
                                                    minDate={new Date()}
                                                    maxDate={new Date(new Date().getFullYear() + 20, 11, 30)}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode='select'
                                                    autoComplete='off'
                                                />
                                                <span className="example-custom-input calendarBtn" onClick={(e) => openDatePicker("startdate")} id="calendarBtn1" Disabled={disabled ? true : false} ><i className="fa fa-calendar"></i></span>
                                            </div>

                                            {formerror.PackageValidateStart && <p className='text-danger text-start'>{formerror.PackageValidateStart}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className="col-form-label required">Departure Slot</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">
                                            <Select
                                                id="DepartureSlotid"
                                                ref={DepartureSlotid}
                                                className="dropdown select_dropdown"
                                                options={departureslotlist}
                                                value={departureslotlist?.filter(function (departureslotlist) {
                                                    return departureslotlist.value === departureslotid;
                                                })}// set list of the data
                                                onChange={handleDepatureSlotChange} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable
                                            />
                                            {formerror.DepartureSlotid && <p className='text-danger text-start'>{formerror.DepartureSlotid}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className="col-form-label required">Package Inventory Count</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">
                                            <input type="text" id="PackageInventoryCount" className="form-control"
                                                name="PackageInventoryCountD"
                                                value={formvalue.PackageInventoryCountD}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder="Package Inventory Count"
                                            />
                                            {formerror.PackageInventoryCount && <p className='text-danger text-start'>{formerror.PackageInventoryCount}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className="col-form-label required">Package Validity End</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">

                                            <div className="text-container">
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    className='form-control'
                                                    id='enddate'
                                                    selected={packageValidateEnd}
                                                    onChange={(e) => handleDateChange('PackageValidateEndD', e)}
                                                    disabled={disabled ? true : false}
                                                    placeholderText="dd/mm/yyyy"
                                                    // onInputClick={true}
                                                    minDate={new Date()}
                                                    maxDate={new Date(new Date().getFullYear() + 20, 11, 30)}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode='select'
                                                    autoComplete='off'
                                                />
                                                <span className="example-custom-input calendarBtn" onClick={(e) => openDatePicker("enddate")} id="calendarBtn1" Disabled={disabled ? true : false} ><i className="fa fa-calendar"></i></span>
                                            </div>
                                            {formerror.PackageDateValidation && <p className='text-danger text-start'>{formerror.PackageDateValidation}</p>}
                                            {formerror.PackageValidateEnd && <p className='text-danger text-start'>{formerror.PackageValidateEnd}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 d-none'>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">

                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" name="IsBestPackage" value={formvalue.IsBestPackage} onChange={onChange}
                                                    id="flexCheckChecked" disabled={disabled ? true : false} checked={bestpackage ? true : false} />
                                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                                    Mark As Best Package
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className="col-form-label required">Package cut off Days</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">
                                            <div className='prefix_div'>
                                                <span className='prefix text-muted'>D-</span>
                                                <input type="text" id="PackageCutOffCount" className="form-control cutoffinput"
                                                    name="PackageCutOffCountD"
                                                    value={formvalue.PackageCutOffCountD}
                                                    onChange={onChange}
                                                    disabled={disabled ? true : false}
                                                    placeholder="Package cut off Days"
                                                />
                                            </div>
                                            {formerror.PackageCutOffCount && <p className='text-danger text-start'>{formerror.PackageCutOffCount}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-12 col-sm-12 '>
                                    <div className='row'>
                                        <div className="col-lg-2 col-md-12 mb-1">
                                            <label className="col-form-label required">Package Highlights</label>
                                        </div>
                                        <div className="col-lg-10 mb-1 col-md-12">
                                            <textarea type='text' id="PackageHighlights" className="form-control"
                                                name="PackageHighlights"
                                                value={formvalue.PackageHighlights}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                            ></textarea>
                                            {formerror.PackageHighlights && <p className='text-danger text-start'>{formerror.PackageHighlights}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className="col-form-label required">Package Banner</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">

                                            <CustomSelection
                                                selId="BannerImgPath"
                                                selRef={BannerImgPath}
                                                // bannerId="BannerImgPath"
                                                datalist={bannerdatalist}
                                                fieldid={bannerId}
                                                SelectionChange={handleBannerChange}
                                                // isdisable={disabled}
                                                isdisable={disabled || atFirstDisable}
                                                isactivedisable={bannerdisabled}
                                                issearchable={true}
                                            />
                                            {formerror.BannerImgPath && <p className='text-danger text-start'>{formerror.BannerImgPath}</p>}
                                        </div>

                                        <div className="col-lg-8 mb-1 col-md-12 mx-auto">
                                            {bannerimgpath &&
                                                (
                                                    <div className="col-lg-4 col-md-6 col-6 mx-auto"  >
                                                        {/* <img src={galleryimg.GalleryPath} key = {index} className="img-fluid" style={{width:"80%" ,height:"80%"}}/> */}
                                                        <img src={bannerimgpath} className="img_thumb w-100" alt='' />
                                                        <button className='closebtnimg' onClick={() => removeBannerImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }} disabled={disabled ? true : false} >&times;</button>

                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className="col-form-label required">Package Gallery</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">
                                            <Select
                                                ref={GalleryCount}
                                                id="GalleryCount"
                                                className="dropdown select_dropdown"
                                                options={gallerydatalist}
                                                value={gallerydatalist?.filter(function (gallerydatalist) {
                                                    return gallerydatalist.value === galleryid;
                                                })}// set list of the data
                                                onChange={handleGalleryChange} // assign onChange function
                                                // isDisabled={disabled ? true : false}
                                                isDisabled={disabled || atFirstDisable ? true : false}
                                                isSearchable
                                            />
                                            {galleryimgerror && <p className='text-danger text-start'>{galleryimgerror}</p>}
                                            {formerror.GalleryCount && <p className='text-danger text-start'>{formerror.GalleryCount}</p>}
                                        </div>
                                        <div className="col-lg-12 mb-1 col-md-12">
                                            <div className="row">

                                                {galleryimgpath && galleryimgpath?.map((galleryimg, index) => {
                                                    if (gallerycount > 0) {
                                                        return (
                                                            <div className="col-lg-3 col-md-6 col-6 mx-auto py-2" key={index} >
                                                                <img src={galleryimg.GalleryPath} key={index} className="img_thumb w-100" alt='' />
                                                                <button className='closebtnimg' onClick={(e) => removeGalleryImage(galleryimg.Id, e)} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }} disabled={disabled ? true : false} >&times;</button>

                                                            </div>

                                                        );
                                                    }
                                                })}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>

                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label className="col-form-label">Active</label>
                                        </div>
                                        <div className="col-auto col-lg-8" disabled={false}>

                                            <Switch
                                                onChange={handleisActive}
                                                checked={activeId}
                                                className="react-switch"
                                                disabled={disabled ? true : false}
                                            />
                                        </div>
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation(false)}
                                            yesconfirm={() => handleConfirmation(true)}
                                        />

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="mb-3 mt-5 row mx-1" style={{ display: isDetailblockshow ? 'block' : 'none' }}>
                        <PackageMenuMaster handleChange={(e) => { handleMenuChange(e) }} menudata={menulist} retriveflag={dataretrivalflag} handleMenuDisable={(e) => handleDisableFlag(e)} editableflag={disabled} />
                        <div className="accordion mb-3" id="accordionPanelsStayOpenExample">
                            {menulist?.map((pareEle) => {

                                if (pareEle.PackageMenuName === "Overview" && pareEle.IsDefault) {
                                    return (
                                        <>

                                            <VendorOverview overViewCity={overviewcity} handleOverViewAdd={(e) => handleOverView(e)} retriveflag={dataretrivalflag} handleOverViewDisable={(e) => handleDisableFlag(e)} Overviewdatalist={formvalue?.PackageOverview[0]} editableflag={disabled} ischecked={overviewchecked} />
                                        </>

                                    )
                                }
                                else if (pareEle.PackageMenuName === "Itinerary" && pareEle.IsDefault) {
                                    return (
                                        <>

                                            <VendorItinerary selectedCityValue={formvalue?.LocationId} handleItineraryDetailAdd={(e) => handleItineraryDetail(e)} retriveflag={dataretrivalflag} editdisable={disabled} handleItineraryDisable={(e) => handleDisableFlag(e)} itinerarydetail={dataitinerary} flightvisibleflag={flightvisible} />
                                        </>

                                    )
                                }
                                else if (pareEle.PackageMenuName === "Inclusion" && pareEle.IsDefault) {
                                    return (
                                        <>
                                            <VendorInclusion handleInclusionAdd={(e) => handleInclusionDetail(e)} retriveflag={dataretrivalflag} handleInclusionDisable={(e) => handleDisableFlag(e)} inclusiondatalist={datainclusiondetails} editableflag={disabled} />
                                        </>

                                    )
                                }
                                else if (pareEle.PackageMenuName === "Exclusion" && pareEle.IsDefault) {
                                    return (
                                        <>

                                            <VendorExclusion handleExclusionAdd={(e) => handleExclusion(e)} retriveflag={dataretrivalflag} handleExclusionDisable={(e) => handleDisableFlag(e)} editableflag={disabled} exclusiondatalist={dataexclusiondetails} />
                                        </>

                                    )
                                }
                                else if (pareEle.PackageMenuName === "Policies" && pareEle.IsDefault) {
                                    return (
                                        <>

                                            <VendorPolicies handlePolicyAdd={(e) => handlePolicyDetail(e)} retriveflag={dataretrivalflag} handlePolicyDisable={(e) => handleDisableFlag(e)} policydatalist={datapolicydetails} editableflag={disabled} ischecked={policychecked} />
                                        </>

                                    )
                                }
                                else if (pareEle.PackageMenuName === "About" && pareEle.IsDefault) {
                                    return (
                                        <>

                                            <VendorAbout handleAboutAdd={(e) => handleAboutDetail(e)} retriveflag={dataretrivalflag} handleAboutPlaceDisable={(e) => handleDisableFlag(e)} handleDeleteList={(e) => handleDeleteFileOfAbout(e)} aboutdatalist={datacitydetails} editableflag={disabled} ischecked={aboutplacechecked} />
                                        </>

                                    )
                                }
                                else if (pareEle.PackageMenuName === "Visibility" && pareEle.IsDefault) {
                                    return (
                                        <>

                                            <VendorVisibility handleVisibility={(e) => handleVisibilityDetail(e)} editableflag={disabled} retriveflag={dataretrivalflag} isHotDeals={formvalue.IsHotDeals} hotOfferPercentage={formvalue.HotOfferPercentage}
                                                isBestDestination={formvalue.IsBestDestination} isInternationalDestination={formvalue.IsInternationalDestination} isTrendingHoliday={formvalue.IsTrendingHoliday} handlevisibilityDisable={(e) => handleDisableFlag(e)} />
                                        </>

                                    )
                                }
                                else if (pareEle.PackageMenuName === "Summary" && pareEle.IsDefault) {
                                    return (
                                        <>

                                            <VendorSummary handleSummaryAdd={(e) => handleSummary(e)} editableflag={disabled} retriveflag={dataretrivalflag} pkgsummarydatalist={PkgSummary} ischecked={aboutplacechecked} />
                                        </>

                                    )
                                }
                                else if (pareEle.PackageMenuName === "Price" && pareEle.IsDefault) {
                                    return (
                                        <>

                                            <VendorPricing handlePricingAdd={(e) => handlePricing(e)} retriveflag={dataretrivalflag} handlePricingDisable={(e) => handleDisableFlag(e)} Pricingdatalist={pricingdetails} editableflag={disabled} ischecked={pricingchecked} />
                                        </>

                                    )
                                }

                            })}
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-4 col-md-12 mb-1">
                                        <label class="col-form-label required">Package Basic price</label>
                                    </div>
                                    <div class="col-lg-8 mb-1 col-md-12">
                                        <input type="text" id="inputPassword6" class="form-control"
                                            name="PackageBasicPrice"
                                            value={formvalue.PackageBasicPrice}
                                            // onChange={onChange}
                                            //disabled={disabled ? true : false}
                                            placeholder="Package Basic price"
                                            readOnly
                                        />
                                        {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-4 col-md-12 mb-1">
                                        <label class="col-form-label required">Add Mark Up</label>
                                    </div>
                                    <div class="col-lg-8 mt-1 mb-1 col-md-12">
                                        <div class="form-check-inline">
                                            <input class="form-check-input" type="radio" name="flexRadioMarkup" value={0} key={0} id="flexRadioMarkup1" onChange={handlefixtypetax} checked={gsttype === 'FIX' ? true : false} disabled={disabled ? true : false} />
                                            <label class="form-check-label ms-1" >
                                                Fix
                                            </label>
                                        </div>
                                        <div class="form-check-inline">
                                            <input class="form-check-input" type="radio" name="flexRadioMarkup" value={1} key={1} id="flexRadioMarkup2" onChange={handlegsttypechange} checked={gsttype === 'GST' ? true : false} disabled={disabled ? true : false} />
                                            <label class="form-check-label ms-1" >
                                                Percentage
                                            </label>
                                        </div>
                                        <div class="col-lg-8 mb-1 col-md-12">
                                            <input type="text" id="inputPassword6" class="form-control"
                                                name="PackageFixPrice"
                                                value={formvalue.PackageFixPrice}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder="Package Fix Price"
                                            //readOnly
                                            />

                                        </div>
                                        {formerror.MarkupvalueError && <p className='text-danger text-start'>{formerror.MarkupvalueError}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div className="col-lg-4 col-md-12 mb-1">
                                        <label className="col-form-label required">Add Discount</label>
                                    </div>
                                    <div className="col-lg-8 mt-1 mb-1 col-md-12">
                                        <label className="form-check-label" htmlFor="PackageDiscount">
                                            Percentage
                                        </label>
                                        <div className="col-lg-8 mb-1 col-md-12">
                                            <input type="text" id="PackageDiscount" className="form-control"
                                                name="PackageDiscount"
                                                value={formvalue.PackageDiscount}
                                                onChange={(e) => {
                                                    if (onlyNumeric(e.target.value) <= 100) {
                                                        onChange(e)
                                                    }
                                                }
                                                }
                                                disabled={disabled ? true : false}
                                                placeholder="Package Discount"
                                            //readOnly
                                            />

                                        </div>
                                        {formerror.PackageDiscount && <p className='text-danger text-start'>{formerror.PackageDiscount}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-4 col-md-12 mb-1">
                                        <label class="col-form-label required">GST</label>
                                    </div>
                                    <div class="col-lg-8 mb-1 col-md-12">
                                        <Select
                                            className="dropdown select_dropdown"
                                            value={gstlist?.filter(function (gstlist) {
                                                return gstlist.value === gstid;
                                            })}// set list of the data
                                            options={gstlist}
                                            // value={suppliernamelist.filter(function (suppliernamelist) {
                                            // return suppliernamelist.value === supplierid;
                                            // })}// set list of the data
                                            // onChange={handlesupplierchange} // assign onChange function
                                            isDisabled
                                            onChange={handlegstonchange}
                                            isSearchable
                                        />
                                        {/* {formErrors.Supplierid && <p className='text-danger text-start'>{formErrors.Supplierid}</p>} */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-4 col-md-12 mb-1">
                                        <label class="col-form-label required">Package Final Price</label>
                                    </div>
                                    <div class="col-lg-8 mb-1 col-md-12">
                                        <input type="text" id="inputPassword6" class="form-control"
                                            name="PackageFinalPrice"
                                            value={pkgfinalprice}
                                            //onChange={onChange}
                                            disabled={disabled ? true : false}
                                            placeholder="Package Final Price"
                                            readOnly
                                        />
                                        {/* {sectionerrorMessage && <div className="text-danger"> {sectionerrorMessage} </div>} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-primary mx-1" id="btnbasicdraft"
                            onClick={(e) => onBasicDraftSubmit(e)} disabled={basicdraftdisabled} style={{ display: isbasicShown ? 'block' : 'none' }}>
                            Save As Draft
                        </button>
                        <button type="button" className="btn btn-primary mx-1" id="btnAdd" style={{ display: isAddDraftShown ? 'block' : 'none' }}
                            onClick={(e) => onDraftSubmit(e)} disabled={editdisabled}
                        >
                            Save As Draft
                        </button>

                        <button type="button" className="btn btn-primary mx-1" id="btnAdd" style={{ display: isAddShown ? 'block' : 'none' }}
                            //disabled={true}//disabled={editdisabled ? false : true}
                            onClick={(e) => onSubmitData(e)} disabled={editdisabled}
                        >
                            Submit
                        </button>

                        <button type="button" className="btn btn-primary mx-1" id="btnClear"
                            onClick={handleclear} disabled={cleardisabled}
                        >Clear</button>

                        <button type="button" className="btn btn-primary mx-1" id="btnVisible"
                            onClick={handleBackButton} disabled={backdisable}
                        >Back</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default VendorPackagemaster