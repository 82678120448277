import React from 'react';
import { useEffect } from "react";
import "../../../../assets/css/master.css";
import "./Hotelmaster.css"
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';
import BasicInfoIndividual from './Individual Hotel/BasicInfoIndividual';
import LocationIndividual from './Individual Hotel/LocationIndividual';
import Rooms from './Individual Hotel/Rooms/Rooms';
import ImageAndVideo from './Individual Hotel/ImagesAndVideos/ImageAndVideo';
import RateAndInventory from './Individual Hotel/rateandinventory/RateAndInventory';
import CreateRoomForm from './Individual Hotel/Rooms/partial/CreateRoomForm';
import HotelAmenities from './Individual Hotel/HotelAmenities';
import PoliciesAndRules from './Individual Hotel/PoliciesAndRules/PoliciesAndRules';
import LegalAndFinance from './Individual Hotel/LegalAndFinance/LegalAndFinance';

const HotelListing = () => {
    const HotelType = useLocation();
    const navigate = useNavigate()
    let type = localStorage.getItem('type')
    const checkNullable = [null, "", undefined]

    const tabValue = HotelType.pathname?.slice(14)

    useEffect(() => {
        if (checkNullable?.includes(type)) {
            navigate("/dashboard")
        }
    }, [])

    const HotelTabSectionRouting = [
        {
            id: 'basicinfo',
            label: 'Basic Info',
            value: `${type}/basicinfo`,
        },
        {
            id: 'location',
            label: 'Location',
            value: `${type}/location`,
        },
        {
            id: 'amenities',
            label: 'Amenities',
            value: `${type}/amenities`,
        },
        {
            id: 'rooms',
            label: 'Rooms',
            value: tabValue === 'individual/rooms/createroom' ? `${type}/rooms/createroom` : `${type}/rooms`,
        },
        {
            id: 'images',
            label: 'Images',
            value: `${type}/images`,
        },
        {
            id: 'rateandinventory',
            label: 'Rates & Inventory',
            value: `${type}/rateandinventory`,
        },
        {
            id: 'policiesandrules',
            label: 'Policies & House Rules',
            value: `${type}/policiesandrules`,
        },
        {
            id: 'financeandlegal',
            label: 'Finance & Legal',
            value: `${type}/financeandlegal`,
        },
    ]

    return (
        <>
            <div class="container-fluid mt-4 heading_color">
                <h3>Hotel Listing</h3>
            </div>

            <div class="container-fluid py-4 my-3">
                <form action="" >
                    <div className='row'>
                        <div className='col-12'>
                            <Box className='Hotel_listing'>
                                <Box sx={{ borderBottom: 2 }}>
                                    <Tabs value={tabValue} aria-label="basic tabs example row row-cols-8">
                                        {
                                            HotelTabSectionRouting?.map((obj, index) => {
                                                return (
                                                    <Tab key={obj.id}
                                                        className='col'
                                                        label={obj.label}
                                                        value={obj.value}
                                                        disabled={sessionStorage?.getItem('latestStep') >= index?.toString() ? false : true}
                                                        onClick={() => navigate(`/HotelListing/${obj?.value}`, {
                                                            state: {
                                                                disabled: HotelType.state?.disabled,
                                                                editdisabled: HotelType?.state?.editdisabled,
                                                                viewtype: HotelType?.state?.viewtype,
                                                                HotelId: HotelType?.state?.HotelId
                                                            }
                                                        })} />
                                                )
                                            })
                                        }
                                    </Tabs>
                                </Box>
                                <Routes>
                                    <Route path={`${type}/basicinfo`} element={<BasicInfoIndividual />} />
                                    <Route path={`${type}/location`} element={<LocationIndividual />} />
                                    <Route path={`${type}/amenities`} element={<HotelAmenities />} />
                                    <Route path={`${type}/rooms`} element={<Rooms></Rooms>} />
                                    <Route path={`${type}/rooms/createroom`} element={<CreateRoomForm />} />
                                    <Route path={`${type}/images`} element={<ImageAndVideo></ImageAndVideo>} />
                                    <Route path={`${type}/rateandinventory`} element={<RateAndInventory />} />
                                    <Route path={`${type}/policiesandrules`} element={
                                        <PoliciesAndRules></PoliciesAndRules>
                                    } />
                                    <Route path={`${type}/financeandlegal`} element={<LegalAndFinance />} />
                                </Routes>
                            </Box>
                        </div>
                    </div>
                </form >
            </div >
        </>
    )
}

export default HotelListing