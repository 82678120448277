import React, { Fragment, useState } from 'react';
import "../../../../../../assets/css/master.css";
import "../../Hotelmaster.css"
import { Autocomplete, Box, Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material';
import { useEffect } from 'react';
import { onlyAlphabets } from '../../../../../commonClass/CommonValidation';

const Others = (props) => {
    const {
        getData,
        hendlechekbox,
        onChangedropdown,
        checkval,
        formValue,
        formError,
        disabled,
        onChange,
        setFormError
    } = props
    const [tags, settags] = useState('');
    const [tagError, setTagError] = useState('')

    const uniqueData = (e, multi) => {
        if (multi === 'multi') {
            const uniqueData = e?.filter((item, index, self) =>
                index === self?.findIndex((t) => (
                    t?.optionName === item?.optionName && t?.optionID === item?.optionID
                ))
            );
            return uniqueData
        }
        else {
            return e
        }
    }
    const onChangetags = (amenitiesId, value, tabid) => {
        setFormError({});
        settags(value);
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (tags?.trim()?.length !== 0) {
                if (!checkval?.HotelAmenitiesoption?.find((op) => op?.AmenitiesId === 160)?.tags?.some(el => el?.toLowerCase()?.trim() === tags?.toLowerCase()?.trim())) {
                    let data = checkval?.HotelAmenitiesoption?.find((op) => op?.AmenitiesId === 160)?.tags ? checkval?.HotelAmenitiesoption?.find((op) => op?.AmenitiesId === 160)?.tags : []
                    let clone = [...data]
                    clone = [...clone, tags]
                    onChange(160, clone, 'vertical-tab-12')
                    settags('');
                }
                else {
                    setTagError('Duplicate Tags Not Allowed.')
                }
            }
        }
    };
    const removetag = (tag) => {
        onChange(160, checkval?.HotelAmenitiesoption?.find((op) => op?.AmenitiesId === 160)?.tags?.filter(el => !(el?.toLowerCase() === tag?.toLowerCase())), 'vertical-tab-12')
    }
    useEffect(() => {
        setTimeout(() => {
            setTagError('')
        }, 2000);
    }, [tagError])

    useEffect(() => {
        if (!checkval?.HotelAmenities?.some((obj) => obj?.AmenitiesId === 160)) {
            settags('')
        }
    }, [checkval?.HotelAmenities])
    return (
        <>
            <div class="mt-2 heading_color input_mui">
                <div className='row'>
                    <div className='col-12'>
                        <h4 className='cursor_pointer'>Indoor Activities</h4>
                    </div>
                </div>
                <form action="" className='mb-2 mt-1'>
                    <Box sx={{ display: 'flex' }}>
                        <div className='row w-100'>
                            {getData?.categoryAmenities?.filter((fit) => fit?.amenitiesId === 161 || fit?.amenitiesId === 151).map((el, index) =>
                                <div className='col-12 col-xl-6 mb-1' key={el?.amenitiesName + index}>
                                    <div className='row'>
                                        <div className='col-6 align-items-center d-flex'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id={'check' + el?.amenitiesId}
                                                        checked={checkval?.HotelAmenities?.some((obj) => obj?.AmenitiesId === el?.amenitiesId)}
                                                        onChange={() => hendlechekbox(el?.amenitiesId, el?.options, '', 'vertical-tab-12')}
                                                        name={el?.amenitiesName}
                                                        disabled={disabled} />
                                                }
                                                label={el?.amenitiesName}
                                            />
                                        </div>
                                        {el?.options?.length > 0 &&
                                            <div className='col-6'>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        filterSelectedOptions
                                                        multiple={el?.isMultipleSelection}
                                                        options={el?.options?.filter(op => !formValue?.HotelAmenitiesCollection?.filter((check) => check?.AmenitiesId === el?.amenitiesId).map(el => el.AmenitiesOptionsId).includes(op.optionID))}
                                                        getOptionLabel={(option) => option.optionName}
                                                        id={'select' + el?.amenitiesId}
                                                        value={el?.isMultipleSelection ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection : [] : checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection : null}
                                                        name={el?.amenitiesName}
                                                        onChange={(e, newValue) => onChangedropdown(el?.amenitiesId, newValue ? uniqueData(newValue, el?.isMultipleSelection ? 'multi' : 'single') : null, 'vertical-tab-12')}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label={checkval?.HotelAmenitiesoption?.filter((check) => check?.AmenitiesId === el?.amenitiesId)?.length > 0 ? el?.amenitiesName : 'Select'}
                                                                className={`w-100  ${(!formValue?.HotelAmenitiesCollection?.filter((er) => er?.AmenitiesId === el?.amenitiesId)?.length > 0 && checkval?.HotelAmenities?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.length > 0) ? 'red_border' : ''}`}
                                                                error={formError?.length > 0 ? formError?.filter(obj => obj?.AmenitiesId === el?.amenitiesId)?.length > 0 ? true : false : null}
                                                                helperText={formError?.length > 0 ? formError?.filter(obj => obj?.AmenitiesId === el?.amenitiesId)?.length > 0 ? 'Inputs are mandatory.' : '' : null}
                                                            />
                                                        )}
                                                        disabled={disabled ? disabled : !checkval?.HotelAmenities?.some((obj) => obj?.AmenitiesId === el?.amenitiesId)}
                                                    />
                                                </FormControl>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </Box>
                </form>
                <div className='row'>
                    <div className='col-12'>
                        <h4 className='cursor_pointer'>Payment Services</h4>
                    </div>
                </div>
                <form action="" className='mb-2 mt-1'>
                    <Box sx={{ display: 'flex' }}>
                        <div className='row w-100'>
                            {getData?.categoryAmenities?.filter((fit) => fit?.amenitiesId === 153 || fit?.amenitiesId === 152).map((el, index) =>
                                <div className='col-12 col-xl-6 mb-1' key={el?.amenitiesName + index}>
                                    <div className='row'>
                                        <div className='col-6 align-items-center d-flex'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id={'check' + el?.amenitiesId}
                                                        checked={checkval?.HotelAmenities?.some((obj) => obj?.AmenitiesId === el?.amenitiesId)}
                                                        onChange={() => hendlechekbox(el?.amenitiesId, el?.options, '', 'vertical-tab-12')}
                                                        name={el?.amenitiesName}
                                                        disabled={disabled} />
                                                }
                                                label={el?.amenitiesName}
                                            />
                                        </div>
                                        {el?.options?.length > 0 &&
                                            <div className='col-6'>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        filterSelectedOptions
                                                        multiple={el?.isMultipleSelection}
                                                        options={el?.options?.filter(op => !formValue?.HotelAmenitiesCollection?.filter((check) => check?.AmenitiesId === el?.amenitiesId).map(el => el.AmenitiesOptionsId).includes(op.optionID))}
                                                        getOptionLabel={(option) => option.optionName}
                                                        id={'select' + el?.amenitiesId}
                                                        value={el?.isMultipleSelection ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection : [] : checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection : null}
                                                        name={el?.amenitiesName}
                                                        onChange={(e, newValue) => onChangedropdown(el?.amenitiesId, newValue ? uniqueData(newValue, el?.isMultipleSelection ? 'multi' : 'single') : null, 'vertical-tab-12')}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label={checkval?.HotelAmenitiesoption?.filter((check) => check?.AmenitiesId === el?.amenitiesId)?.length > 0 ? el?.amenitiesName : 'Select'}
                                                                className={`w-100  ${(!formValue?.HotelAmenitiesCollection?.filter((er) => er?.AmenitiesId === el?.amenitiesId)?.length > 0 && checkval?.HotelAmenities?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.length > 0) ? 'red_border' : ''}`}
                                                                error={formError?.length > 0 ? formError?.filter(obj => obj?.AmenitiesId === el?.amenitiesId)?.length > 0 ? true : false : null}
                                                                helperText={formError?.length > 0 ? formError?.filter(obj => obj?.AmenitiesId === el?.amenitiesId)?.length > 0 ? 'Inputs are mandatory.' : '' : null}
                                                            />
                                                        )}
                                                        disabled={disabled ? disabled : !checkval?.HotelAmenities?.some((obj) => obj?.AmenitiesId === el?.amenitiesId)}
                                                    />
                                                </FormControl>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </Box>
                </form>
                <div className='row'>
                    <div className='col-12'>
                        <h4 className='cursor_pointer'>Family & Kids</h4>
                    </div>
                </div>
                <form action="" className='mb-2 mt-1'>
                    <Box sx={{ display: 'flex' }}>
                        <div className='row w-100'>
                            {getData?.categoryAmenities?.filter((fit) => (fit?.amenitiesId >= 154 && fit?.amenitiesId <= 157))?.map((el, index) =>
                                <div className='col-12 col-xl-6 mb-1' key={el?.amenitiesName + index}>
                                    <div className='row'>
                                        <div className='col-6 align-items-center d-flex'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id={'check' + el?.amenitiesId}
                                                        checked={checkval?.HotelAmenities?.some((obj) => obj?.AmenitiesId === el?.amenitiesId)}
                                                        onChange={() => hendlechekbox(el?.amenitiesId, el?.options, '', 'vertical-tab-12')}
                                                        name={el?.amenitiesName}
                                                        disabled={disabled} />
                                                }
                                                label={el?.amenitiesName}
                                            />
                                        </div>
                                        {el?.options?.length > 0 &&
                                            <div className='col-6'>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        filterSelectedOptions
                                                        multiple={el?.isMultipleSelection}
                                                        options={el?.options?.filter(op => !formValue?.HotelAmenitiesCollection?.filter((check) => check?.AmenitiesId === el?.amenitiesId).map(el => el.AmenitiesOptionsId).includes(op.optionID))}
                                                        getOptionLabel={(option) => option.optionName}
                                                        id={'select' + el?.amenitiesId}
                                                        value={el?.isMultipleSelection ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection : [] : checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection : null}
                                                        name={el?.amenitiesName}
                                                        onChange={(e, newValue) => onChangedropdown(el?.amenitiesId, newValue ? uniqueData(newValue, el?.isMultipleSelection ? 'multi' : 'single') : null, 'vertical-tab-12')}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label={checkval?.HotelAmenitiesoption?.filter((check) => check?.AmenitiesId === el?.amenitiesId)?.length > 0 ? el?.amenitiesName : 'Select'}
                                                                className={`w-100  ${(!formValue?.HotelAmenitiesCollection?.filter((er) => er?.AmenitiesId === el?.amenitiesId)?.length > 0 && checkval?.HotelAmenities?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.length > 0) ? 'red_border' : ''}`}
                                                                error={formError?.length > 0 ? formError?.filter(obj => obj?.AmenitiesId === el?.amenitiesId)?.length > 0 ? true : false : null}
                                                                helperText={formError?.length > 0 ? formError?.filter(obj => obj?.AmenitiesId === el?.amenitiesId)?.length > 0 ? 'Inputs are mandatory.' : '' : null}
                                                            />
                                                        )}
                                                        disabled={disabled ? disabled : !checkval?.HotelAmenities?.some((obj) => obj?.AmenitiesId === el?.amenitiesId)}
                                                    />
                                                </FormControl>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </Box>
                </form>
                <div className='row'>
                    <div className='col-12'>
                        <h4 className='cursor_pointer'>Pet Essentials</h4>
                    </div>
                </div>
                <form action="" className='mb-2 mt-1'>
                    <Box sx={{ display: 'flex' }}>
                        <div className='row w-100'>
                            {getData?.categoryAmenities?.filter((fit) => (fit?.amenitiesId >= 158 && fit?.amenitiesId <= 159))?.map((el, index) =>
                                <div className='col-12 col-xl-6 mb-1' key={el?.amenitiesName + index}>
                                    <div className='row'>
                                        <div className='col-6 align-items-center d-flex'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id={'check' + el?.amenitiesId}
                                                        checked={checkval?.HotelAmenities?.some((obj) => obj?.AmenitiesId === el?.amenitiesId)}
                                                        onChange={() => hendlechekbox(el?.amenitiesId, el?.options, '', 'vertical-tab-12')}
                                                        name={el?.amenitiesName}
                                                        disabled={disabled} />
                                                }
                                                label={el?.amenitiesName}
                                            />
                                        </div>
                                        {el?.options?.length > 0 &&
                                            <div className='col-6'>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        filterSelectedOptions
                                                        multiple={el?.isMultipleSelection}
                                                        options={el?.options?.filter(op => !formValue?.HotelAmenitiesCollection?.filter((check) => check?.AmenitiesId === el?.amenitiesId).map(el => el.AmenitiesOptionsId).includes(op.optionID))}
                                                        getOptionLabel={(option) => option.optionName}
                                                        id={'select' + el?.amenitiesId}
                                                        value={el?.isMultipleSelection ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection : [] : checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection ? checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.[0]?.Optionscollection : null}
                                                        name={el?.amenitiesName}
                                                        onChange={(e, newValue) => onChangedropdown(el?.amenitiesId, newValue ? uniqueData(newValue, el?.isMultipleSelection ? 'multi' : 'single') : null, 'vertical-tab-12')}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label={checkval?.HotelAmenitiesoption?.filter((check) => check?.AmenitiesId === el?.amenitiesId)?.length > 0 ? el?.amenitiesName : 'Select'}
                                                                className={`w-100  ${(!formValue?.HotelAmenitiesCollection?.filter((er) => er?.AmenitiesId === el?.amenitiesId)?.length > 0 && checkval?.HotelAmenities?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.length > 0) ? 'red_border' : ''}`}
                                                                error={formError?.length > 0 ? formError?.filter(obj => obj?.AmenitiesId === el?.amenitiesId)?.length > 0 ? true : false : null}
                                                                helperText={formError?.length > 0 ? formError?.filter(obj => obj?.AmenitiesId === el?.amenitiesId)?.length > 0 ? 'Inputs are mandatory.' : '' : null}
                                                            />
                                                        )}
                                                        disabled={disabled ? disabled : !checkval?.HotelAmenities?.some((obj) => obj?.AmenitiesId === el?.amenitiesId)}
                                                    />
                                                </FormControl>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </Box>
                </form>
                <div className='row'>
                    <div className='col-12'>
                        <h4 className='cursor_pointer'>Any Other to Add</h4>
                    </div>
                    <div className='col-12'>
                        <small className={`text-danger ${tagError ? 'visible' : 'invisible'}`}>{tagError ? tagError : 'e'}</small>
                    </div>
                </div>
                <form action="" className='mb-2 mt-1'>
                    <Box sx={{ display: 'flex' }}>
                        <div className='row w-100'>
                            {getData?.categoryAmenities?.filter((fit) => fit?.amenitiesId === 160)?.map((el, index) =>
                                <Fragment key={el?.amenitiesName + index + 'other'}>
                                    <div className='col-12 col-xl-6 mb-1'>
                                        <div className='row'>
                                            <div className='col-6 align-items-center d-flex'>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            id={'check' + el?.amenitiesId}
                                                            checked={checkval?.HotelAmenities?.some((obj) => obj?.AmenitiesId === el?.amenitiesId)}
                                                            onChange={() => hendlechekbox(el?.amenitiesId, el?.options, 'remark', 'vertical-tab-12')}
                                                            name={el?.amenitiesName}
                                                            disabled={disabled} />
                                                    }
                                                    label={el?.amenitiesName}
                                                />
                                            </div>
                                            <div className='col-6'>
                                                <FormControl fullWidth>
                                                    <FormControlLabel className='mx-0'
                                                        control={
                                                            <TextField
                                                                type='text'
                                                                name='remark'
                                                                inputProps={{ maxLength: 25 }}
                                                                onChange={(e) => onChangetags(el?.amenitiesId, onlyAlphabets(e.target.value), 'vertical-tab-12')}
                                                                onKeyPress={handleKeyPress}
                                                                value={tags}
                                                                className={`w-100  ${(!formValue?.HotelAmenitiesCollection?.filter((er) => er?.AmenitiesId === el?.amenitiesId)?.length > 0 && checkval?.HotelAmenities?.filter((op) => op?.AmenitiesId === el?.amenitiesId)?.length > 0) ? 'red_border' : ''}`}
                                                                id={'text' + el?.amenitiesId}
                                                                label="Please mention here"
                                                                error={formError?.length > 0 ? formError?.filter(obj => obj?.AmenitiesId === el?.amenitiesId)?.length > 0 ? true : false : null}
                                                                helperText={formError?.length > 0 ? formError?.filter(obj => obj?.AmenitiesId === el?.amenitiesId)?.length > 0 ? 'Inputs are mandatory.' : '' : null}
                                                                disabled={disabled ? disabled : !checkval?.HotelAmenities?.some((obj) => obj?.AmenitiesId === el?.amenitiesId)}
                                                            />
                                                        }
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    {checkval?.HotelAmenitiesoption?.filter((op) => op?.AmenitiesId === el?.amenitiesId) &&
                                        <div className='col-12 col-xl-6 mb-1'>
                                            <div className='row'>
                                                {checkval?.HotelAmenitiesoption?.find((op) => op?.AmenitiesId === el?.amenitiesId)?.tags?.map((ele, index) => (
                                                    <span key={index} className='rounded-pill bg_pill ps-2 pe-1 py-auto col-auto mx-1 mb-1 text-center'>{ele} <button disabled={disabled ? disabled : !checkval?.HotelAmenities?.some((obj) => obj?.AmenitiesId === el?.amenitiesId)} className='border-0 bg-body' onClick={() => removetag(ele)} type='button'><i class="fa-solid fa-xmark text-danger"></i></button></span>
                                                ))}
                                            </div>
                                        </div>}
                                </Fragment>
                            )}
                        </div>
                    </Box>
                </form>
            </div>
        </>
    )
}

export default Others