import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { getAllData, postUsersData, verifytoken } from '../../../../services/ApiCallingUserServices';
import { ClearLocalStorage, GetLocalStorage } from '../../../commonClass/Session';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../../../_actions/loader.actions';
import { alphaNumericspace, isImagePngJpegJpg, onlyNumeric } from '../../../commonClass/CommonValidation';
import { ImageDelete, ImageUpload } from '../../../../ImageUpload/ImageCommon';
import CustomTable from '../../../../customControl/CustomTable';
import { useLocation } from 'react-router-dom';


function getDateCombination() {
    var today = new Date();
    let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
    let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
    return date + '_' + time;
}
const InternationalPackage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { useredit, useradd, name, travelCategoryId, HomePageSectionId, folderName, section } = location.state || {};
    const userId = GetLocalStorage();
    const [countryCity, setCountryCity] = useState();
    const [themelist, setThemeList] = useState([]);
    const [city, setCity] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [formError, setFormError] = useState({});
    const [deletedImage, setDeletedImage] = useState([]);
    const [countryId, setCountryId] = useState(0)
    const [editDisable, setEditDisable] = useState(true)

    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('100');
    const [serchdisable, setSerachDisable] = useState(false);
    const [clrshow, setsclshow] = useState(false);

    const [formValue, setFormValue] = useState(
        {
            MerchandisingPackageId: 0,
            HomePageSectionId: HomePageSectionId,
            LocationId: 0,
            ThemeId: 0,
            ImageName: "",
            ImagePath: "",
            ImageFile: "",
            PackageDescription: "",
            PriceStartFrom: "",
            UserId: userId?.userMasterID,
        }
    )

    const getcountrystatecity = async () => {
        const countrystatecity = await getAllData(`/master/v1/getcountrystatecity`);
        if (countrystatecity?.status) {
            setCountryCity({
                countryData: countrystatecity?.data?.countryData?.filter(val => val.CountryId !== 1)?.map((contry) => ({ label: contry?.CountryName, value: contry?.CountryId })),
                cityData: countrystatecity?.data?.cityData?.filter(val => val.CountryId !== 1)?.map((contry) => ({ CountryId: contry?.CountryId, LocationId: contry?.LocationId }))
            });
        }
        dispatch(loaderActions.end());
    }

    const gettheme = async () => {
        const data = await getAllData(`/master/v1/getpackagetheme`);
        if (data?.status) {
            setThemeList(
                data?.data?.map((er) => ({ label: er?.ThemeName, value: er?.ThemeId })),
            );
        }
        dispatch(loaderActions.end());
    }

    const getcity = async (val) => {
        const city = await getAllData(`/master/v1/getcitybythemeid?themeId=${val}`);
        if (city?.status) {
            setCity(city?.data?.filter(er => er.CountryId === countryId)?.map((el) => ({ label: el?.City, value: el?.LocationId })));
        }
        dispatch(loaderActions.end());
    }
    useEffect(() => {
        if (countryId > 0) {
            getcity(formValue?.ThemeId)
        }
    }, [countryId])

    const getdatalist = async (HomePageSectionId) => {
        const data = await getAllData(`/master/v1/getmerchandisingpackagelist?homePageSectionId=${HomePageSectionId}`);
        if (data?.status) {
            setDataList(data?.data)
            setFilterData(data?.data)
        }
        else {
            setDataList([])
            setFilterData([])
        }
        dispatch(loaderActions.end());
    }

    //#region List data
    useEffect(() => {
        dispatch(loaderActions.start());
        const fetchData = async () => {
            let statusoftoken = await verifytoken();
            if (statusoftoken) {
                let token = GetLocalStorage();
                if (token && token.userName && token.token) {
                    getdatalist(HomePageSectionId);
                    getcountrystatecity();
                    gettheme();
                }
            } else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        fetchData();
    }, []);

    const handleDropDown = (name, val) => {
        setEditDisable(false)
        setFormError({});
        if (name === 'CountryId') {
            setFormValue((pre) => ({ ...pre, LocationId: 0 }));
            setCountryId(val?.value)
        }
        if (name === 'ThemeId') {
            setFormValue((pre) => ({ ...pre, [name]: val.value, LocationId: 0 }));
            getcity(val?.value)
        }
        else {
            setFormValue((pre) => ({ ...pre, [name]: val.value }));
        }
    }
    const handleChange = (name, val) => {
        setEditDisable(false)
        setFormError({});
        setFormValue((pre) => ({ ...pre, [name]: val }));
    }

    const handleUploadImageLoad = (name, e, sectionname) => {
        setEditDisable(false)
        setFormError({});
        if (isImagePngJpegJpg(e.target.files[0].name)) {
            const files = e.target.files[0];
            const filecom = getDateCombination();
            const filename = filecom + '_' + '1' + '_' + (files.name.replace(/ /g, "_")).replace(/-/g, '');
            setDeletedImage([...deletedImage, formValue?.ImagePath])
            setFormValue({
                ...formValue,
                ImageName: filename,
                ImagePath: `CMS/Merchandising/${folderName}/${section}/${filename}`,
                ImageFile: files,
            })
        }
        else {
            setFormError({
                ...formError, [name === 'FromImageName' ? 'FromImageName' : name === 'ToImageName' ? 'ToImageName' : '']: 'Please upload valid image such as PNG, JPEG, & JPG only.'
            });
        }
        e.target.value = null
    }

    const checkValidation = () => {
        let errorMessages = {};
        let isError = true;
        if (!countryId) {
            errorMessages['CountryId'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (!formValue?.ThemeId) {
            errorMessages['ThemeId'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (!formValue?.LocationId && formValue?.ThemeId) {
            errorMessages['LocationId'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (!formValue?.ImagePath) {
            errorMessages['ImageName'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        } else if (formValue?.ImageFile) {
            const files = formValue?.ImageFile;
            let fileSize = files.size / 1024;
            fileSize = fileSize / 1024
            if (!isImagePngJpegJpg(files.name) && files) {
                errorMessages['ImageName'] = 'Please upload valid image such as PNG, JPEG, & JPG only.'
                isError = false;
            } else if (fileSize > 5) {
                errorMessages['ImageName'] = 'Cannot upload file that exceeds 5 MB.'
                isError = false;
            }
        }
        if (formValue?.PackageDescription?.trim()?.length === 0) {
            errorMessages['PackageDescription'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (formValue?.PriceStartFrom?.toString()?.trim()?.length === 0) {
            errorMessages['PriceStartFrom'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }

        setFormError(errorMessages);
        return isError;
    }

    const onSubmitData = async () => {
        const isValid = checkValidation();
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            if (isValid) {
                dispatch(loaderActions.start());
                const updateNewsLetter = await postUsersData('/master/v1/insertorupdatemerchandisingpackage', formValue);
                if (updateNewsLetter.status) {
                    formValue?.ImageFile && await ImageUpload(`CMS/Merchandising/${folderName}/${section}/`, formValue?.ImageName, formValue?.ImageFile);
                    deletedImage.length > 0 && deletedImage.forEach(async (deletedImagePath) => await ImageDelete(deletedImagePath));
                    getdatalist(HomePageSectionId);
                    onClearButton();
                    dispatch({ type: 'ALERTING', message: updateNewsLetter.message })
                    dispatch(loaderActions.end());
                }
                else {
                    dispatch(loaderActions.end());
                    dispatch({ type: 'ALERTING', message: updateNewsLetter.message })
                    return;
                }
            }
        } else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
        dispatch(loaderActions.end());
    }

    const columns = [
        {
            name: 'Sr. No.',
            sortable: true,
            float: "left",
            minWidth: "20px",
            maxWidth: "20px",
            selector: (data, index) => index + 1,
        },
        {
            name: 'Country',
            sortable: true,
            float: "left",
            selector: (data) => data?.CountryName,
        },
        {
            name: 'City',
            sortable: true,
            float: "left",
            selector: (data) => data?.City,
        },
        {
            name: 'Theme',
            sortable: true,
            float: "left",
            selector: (data) => data?.ThemeName,
        },
        {
            name: 'Price',
            sortable: true,
            float: "left",
            selector: (data) => data?.PriceStartFrom,
        },
        {
            name: 'Edit',
            selector: data => <button type='button' className='border-0 p-0 bg-body set_disableclr' onClick={() => onEditorView(data, 'edit')} disabled={!useredit}
            ><i className="fa fa-edit eye-icon"></i></button>
        },
        {
            name: 'View',
            selector: data => <button type='button' className='border-0 p-0 bg-body set_disableclr' onClick={() => onEditorView(data, 'view')} ><i className="fa fa-eye" ></i></button>
        }
    ]

    const onEditorView = async (data, type) => {
        if (type === 'view') {
            setSerachDisable(true)
        }
        setEditDisable(true)
        dispatch(loaderActions.start());
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const PFD = await getAllData(`/master/v1/getmerchandisingpackagebyid?merchandisingPackageId=${data?.MerchandisingPackageId}`);
            setFormError({});
            if (PFD?.status) {
                const Country = countryCity.cityData?.filter((city) => city?.LocationId === PFD?.data?.[0]?.LocationId);
                setCountryId(Country?.[0]?.CountryId)
                getcity(PFD?.data?.[0]?.ThemeId)
                setFormValue((form) =>
                ({
                    ...form,
                    MerchandisingPackageId: PFD?.data?.[0]?.MerchandisingPackageId,
                    HomePageSectionId: HomePageSectionId,
                    LocationId: PFD?.data?.[0]?.LocationId,
                    ThemeId: PFD?.data?.[0]?.ThemeId,
                    ImageName: PFD?.data?.[0]?.ImageName,
                    ImagePath: PFD?.data?.[0]?.ImagePath,
                    ImageFile: "",
                    PackageDescription: PFD?.data?.[0]?.PackageDescription,
                    PriceStartFrom: PFD?.data?.[0]?.PriceStartFrom,
                    UserId: userId?.userMasterID,
                    type: type
                }))
            }
        } else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            dispatch(loaderActions.end());
        }
        dispatch(loaderActions.end());
    }


    const onClearButton = () => {
        setFormError({});
        setEditDisable(false);
        setSerachDisable(false);
        setCountryId(0);
        setFormValue(
            {
                HomePageSectionId: HomePageSectionId,
                LocationId: 0,
                ThemeId: 0,
                ImageName: "",
                ImagePath: "",
                ImageFile: "",
                PackageDescription: "",
                PriceStartFrom: "",
                UserId: userId?.userMasterID,
            }
        )
    }
    //#region handle search value
    const serachoption = [
        {
            label: 'Country',
            value: '1'
        },
        {
            label: 'City',
            value: '2'
        },
        {
            label: 'Theme',
            value: '3'
        },
        {
            label: 'Price',
            value: '4'
        },
        {
            label: 'All',
            value: '100'
        },
    ];

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }
    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getdatalist(HomePageSectionId);
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    };

    const matchesSearchValueAll = (issec, fields) => {
        let search = searchvalue
        return fields.some((data) =>
            issec[data]?.toString().toLowerCase().includes(search.toLowerCase())
        );
    };

    const handleSearch = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            var filteritem = filterData;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec?.CountryName?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec?.City?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            if (searchby === '3') {
                filterdata = filteritem?.filter(issec => issec?.ThemeName?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            if (searchby === '4') {
                filterdata = filteritem?.filter(issec => issec?.PriceStartFrom?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            if (searchby === '100') {
                filterdata = filteritem?.filter((issec) => {
                    let keys = [
                        "CountryName",
                        "City",
                        "PriceStartFrom",
                        "ThemeName",
                    ]
                    return matchesSearchValueAll(issec, keys) && issec
                });
            }
            setDataList(filterdata)
        }
    }


    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getdatalist(HomePageSectionId);
    }

    return (
        <>
            <div class="container-fluid mt-4 heading_color">
                <h3>{travelCategoryId === 1 ? 'Flights Merchandising' : 'Holiday Merchandising'}</h3>
            </div>
            <div class="container-fluid form_border pb-5 pt-4 my-3">
                <div className='px-xl-5'>
                    <div className=" heading_color mb-3">
                        <h4>{name}</h4>
                    </div>

                    <form action=" " className=' mb-5' >
                        <div className='row'>
                            <div className='col-6'>
                                <div className='row my-1'>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <label htmlFor="inputPassword6" className="col-form-label required">Country</label>
                                    </div>
                                    <div class=" col-lg-8 col-md-12 col-sm-12">
                                        <div class="input-group">
                                            <Select
                                                id='FromCountryId'
                                                className="dropdown w-100"
                                                options={countryCity?.countryData}
                                                value={countryCity?.countryData?.find((serachoption) => serachoption.value === countryId) || null}
                                                onChange={(val) => handleDropDown("CountryId", val)}
                                                isDisabled={formValue?.type === 'view' ? true : false}
                                            />
                                        </div>
                                        {formError?.CountryId && <div className='text-danger text-start'>{formError?.CountryId}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='row my-1'>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <label htmlFor="inputPassword6" className="col-form-label required">Theme</label>
                                    </div>
                                    <div class=" col-lg-8 col-md-12 col-sm-12">
                                        <div class="input-group">
                                            <Select
                                                id='ThemeId'
                                                className="dropdown w-100"
                                                options={themelist}
                                                value={themelist?.find((serachoption) => serachoption?.value === formValue?.ThemeId) || null}
                                                onChange={(val) => handleDropDown("ThemeId", val)}
                                                isDisabled={formValue?.type === 'view' ? true : false}
                                            />
                                        </div>
                                        {formError?.ThemeId && <div className='text-danger text-start'>{formError?.ThemeId}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='row my-1'>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <label for="inputPassword6" class="col-form-label required">City</label>
                                    </div>

                                    <div class=" col-lg-8 col-md-12 col-sm-12">
                                        <div class="input-group">
                                            <Select
                                                className="dropdown w-100"
                                                id='LocationId'
                                                options={city}
                                                value={city?.find((option) => option.value === formValue?.LocationId) || null}
                                                onChange={(val) => handleDropDown('LocationId', val)}
                                                isDisabled={formValue?.type === 'view' ? true : false || formValue?.ThemeId ? false : true}
                                            // isSearchable={false}
                                            />
                                        </div>
                                        {formError.LocationId && <div className='text-danger text-start'>{formError.LocationId}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='row my-1'>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <label for="inputPassword6" class="col-form-label required">Images</label>
                                    </div>

                                    <div class=" col-lg-8 col-md-12 col-sm-12">
                                        <div class="input-group">
                                            <input
                                                type="file"
                                                name='FromImageName'
                                                id="FromImageName-id"
                                                className="form-control btn_add"
                                                accept=".png,.jpg,.jpeg"
                                                onChange={(e) => handleUploadImageLoad('FromImageName', e, name)}
                                                disabled={formValue?.type === 'view' ? true : false}
                                            />
                                            <label class="input-group-text" htmlFor="FromImageName-id">Browse</label>
                                        </div>
                                        {formValue?.ImageName && <div className=' text-start w-100 text-break'>{formValue?.ImageName}</div>}
                                        {formError?.ImageName && <div className='text-danger text-start'>{formError?.ImageName}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='row my-1'>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <label for="inputPassword6" class="col-form-label required">Package Description</label>
                                    </div>

                                    <div class=" col-lg-8 col-md-12 col-sm-12">
                                        <div class="input-group">
                                            <input
                                                type="text" id="customer-name"
                                                class="form-control btn_add"
                                                placeholder="Package Description"
                                                maxLength={50}
                                                name='PackageDescription'
                                                onChange={(val) => handleChange('PackageDescription', alphaNumericspace(val.target.value))}
                                                value={formValue?.PackageDescription}
                                                disabled={formValue?.type === 'view' ? true : false}
                                            />
                                        </div>
                                        {formError?.PackageDescription && <div className='text-danger text-start'>{formError?.PackageDescription}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='row my-1'>
                                    <div class="col-lg-4 col-md-12 col-sm-12">
                                        <label for="inputPassword6" class="col-form-label required">Price start From</label>
                                    </div>

                                    <div class=" col-lg-8 col-md-12 col-sm-12">
                                        <div class="input-group">
                                            <input
                                                type="text" id="customer-name"
                                                class="form-control btn_add"
                                                placeholder="Price start From"
                                                maxLength={7}
                                                name='PriceStartFrom'
                                                onChange={(val) => handleChange('PriceStartFrom', Number(onlyNumeric(val.target.value)) > 0 ? Number(onlyNumeric(val.target.value)) : "")}
                                                value={formValue?.PriceStartFrom}
                                                disabled={formValue?.type === 'view' ? true : false}
                                            />
                                        </div>
                                        {formError?.PriceStartFrom && <div className='text-danger text-start'>{formError?.PriceStartFrom}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input
                                        type="text"
                                        onKeyDown={onKeyDownHandler}
                                        id="btnSearchText"
                                        className="form-control"
                                        placeholder='Enter keyword'
                                        name="SearchValue"
                                        onChange={handleChangeSearchValue}
                                        value={searchvalue || ''}
                                        disabled={serchdisable ? true : false}
                                        autoComplete='off'
                                    />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div className="p-2 text-center">
                                <button type="button" className="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleSearch}>Search</button>
                            </div>
                        </div>
                    </div>
                    <CustomTable
                        columns={columns}
                        data={dataList}
                        PdfExport={false}
                        ExportToExcel={false}
                        disabled={false}
                    />

                    <div class="d-flex justify-content-center">
                        {
                            formValue?.type === 'edit'
                                ? (<button class="btn btn-primary mx-1" id="btnAdd" type='button'
                                    onClick={() => onSubmitData()}
                                    disabled={editDisable || !useredit}
                                >Update</button>
                                )
                                : (<button class="btn btn-primary mx-1" id="btnAdd" type='button'
                                    onClick={() => onSubmitData()}
                                    disabled={(formValue?.type === 'view' ? editDisable : false) || !useradd}
                                >Add</button>)
                        }

                        <button type="button" class="btn btn-primary mx-1" id="btnClear"
                            onClick={() => onClearButton({})}
                        // disabled={Object.keys(homePageSection).length === 0 ? true : false || homePageSection?.type === 'view' ? true : false}
                        >Clear</button>
                    </div>
                </div >
            </div >
        </>
    )
}

export default InternationalPackage