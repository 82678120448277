import React, { useEffect, useMemo } from 'react';
import { useState } from "react";
import "../../../../../assets/css/master.css";
import "../Hotelmaster.css"
import { useLocation, useNavigate } from 'react-router-dom';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { onlyNumeric } from '../../../../commonClass/CommonValidation';
import { getAllData, postUsersData, verifytoken } from '../../../../../services/ApiCallingUserServices';
import { ClearLocalStorage, GetLocalStorage } from '../../../../commonClass/Session';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../../../../_actions/loader.actions';

const LocationIndividual = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    let type = localStorage.getItem('type')

    const [save, setSave] = useState(false)
    const [next, setNext] = useState(true);
    const [formValue, setFormValue] = useState({
        HotelLocationId: 0,
        HotelBasicId: 0,
        Address1: "",
        Address2: "",
        Country: null,
        State: null,
        LocationId: null,
        Pincode: "",
        Latitude: "",
        Longitude: "",
        NearBy: "",
        UserId: 0
    })
    const [formError, setFormError] = useState({})
    const [locationData, setLocationData] = useState([])

    const handleBack = () => {
        navigate(`/HotelListing/${type}/basicinfo`, {
            state: {
                disabled: location.state?.disabled,
                editdisabled: location?.state?.editdisabled,
                viewtype: location?.state?.viewtype,
                HotelId: location?.state?.HotelId
            }
        })
    }
    const handleNext = () => {
        navigate(`/HotelListing/${type}/amenities`, {
            state: {
                disabled: location.state?.disabled,
                editdisabled: location?.state?.editdisabled,
                viewtype: location?.state?.viewtype,
                HotelId: location?.state?.HotelId
            }
        })
    }

    const onChange = (name, value) => {
        setSave(false)
        setFormError({})
        if (name === "Country") {
            setFormValue(form => ({ ...form, State: "", LocationId: "", Country: value }))
        }
        else if (name === "State") {
            setFormValue(form => ({ ...form, LocationId: "", State: value }))
        }
        else {
            setFormValue({ ...formValue, [name]: value })
        }
    }

    const getcountrystatecity = async () => {
        // dispatch(loaderActions.start())
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const countrystatecity = await getAllData('/master/v1/getcountrystatecity')

            if (countrystatecity.status) {
                let dropdownData = []
                dropdownData.push(countrystatecity?.data)
                setLocationData(dropdownData)
            }
            else {
                dispatch({ type: 'ALERTING', message: countrystatecity.message })
                dispatch(loaderActions.end())
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            dispatch(loaderActions.end())
        }

    }

    const getLocationData = async (hotelid) => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const user = GetLocalStorage()
            const hotellocationbyid = await getAllData(`/master/v1/gethotellocationbyid?hotelBasicId=${hotelid}`)
            formValue.HotelBasicId = hotelid
            formValue.UserId = user.userMasterID
            if (hotellocationbyid?.data) {
                setNext(hotellocationbyid?.data?.LocationId === 0 || hotellocationbyid?.data?.LocationId === null ? true : false)
                setSave(hotellocationbyid?.data?.LocationId === 0 || hotellocationbyid?.data?.LocationId === null ? false : true)
                setFormValue(form => ({
                    ...form,
                    Address1: hotellocationbyid?.data?.Address1,
                    Address2: hotellocationbyid?.data?.Address2,
                    Country: hotellocationbyid?.data?.CountryId,
                    State: hotellocationbyid?.data?.StateId,
                    LocationId: hotellocationbyid?.data?.LocationId,
                    HotelLocationId: hotellocationbyid?.data?.HotelLocationId,
                    Latitude: hotellocationbyid?.data?.Latitude,
                    Longitude: hotellocationbyid?.data?.Longitude,
                    Pincode: hotellocationbyid?.data?.Pincode,
                    NearBy: hotellocationbyid?.data?.NearBy,
                }))
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
    }

    useEffect(() => {
        if (location?.state !== null) {
            dispatch(loaderActions.start())
            getcountrystatecity().then(() => {
                // dispatch(loaderActions.start())
                getLocationData(location?.state?.HotelId).then(() => dispatch(loaderActions.end()))
            })
        }
        else {
            navigate(-1)
        }
    }, [location])

    const locationDropdown = useMemo(() =>

        locationData?.reduce((acc, curr) => {
            curr.countryData?.map((obj) => {
                acc.country.push({ id: obj.CountryId, label: obj.CountryName })
            })
            curr.stateData?.filter(state => state.CountryId === formValue.Country)?.map((obj) => {
                acc.state.push({ id: obj.StateId, label: obj.StateNAme })
            })
            if (formValue.Country !== 1) {
                formValue.State = acc.state[0]?.id
                curr.cityData?.filter(city => city.CountryId === formValue.Country)?.map((obj) => {
                    acc.city.push({ id: obj.LocationId, label: obj.City })
                })
            }
            else {
                curr.cityData?.filter(city => city.StateId === formValue.State)?.map((obj) => {
                    acc.city.push({ id: obj.LocationId, label: obj.City })
                })
            }
            return acc
        }, { country: [], state: [], city: [] })
        , [locationData, formValue.Country, formValue.State])


    const validateData = () => {
        let status = true;
        let error = {};

        if (formValue.Country <= 0) {
            error["Country"] = "inputs are mandatory.";
            status = false;
        }
        if (formValue.Country === 1 && formValue.State <= 0) {
            error["State"] = "inputs are mandatory.";
            status = false;
        }
        if (formValue.LocationId <= 0) {
            error["LocationId"] = "inputs are mandatory.";
            status = false;
        }
        setFormError(error)

        return status;
    }

    const handleSave = async (e) => {
        e.preventDefault();
        if (validateData()) {
            let statusoftoken = await verifytoken();
            if (statusoftoken) {
                dispatch(loaderActions.start())
                const insertorupdatehotellocation = await postUsersData('/master/v1/insertorupdatehotellocation', formValue);
                if (insertorupdatehotellocation.status) {
                    setNext(false)
                    setSave(true)
                    dispatch({ type: 'ALERTING', message: insertorupdatehotellocation.message })
                    sessionStorage.setItem('latestStep', sessionStorage?.getItem('latestStep') >= "1" ? Number(sessionStorage?.getItem('latestStep')) : 1);
                } else {
                    dispatch({ type: 'ALERTING', message: insertorupdatehotellocation.message })
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        dispatch(loaderActions.end())

    }

    return (
        <>

            <div className="container-fluid mt-2 heading_color input_mui">
                <div className='row'>
                    <div className='col-8'>
                        <h3><i onClick={() => handleBack()} className="fa-solid fa-angle-left me-2 cursor_pointer"></i>Location</h3>
                    </div>
                    <div className='col-4 text-end'>
                        <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd" onClick={(e) => handleSave(e)} disabled={save || location?.state?.disabled} >Save</button>
                        {sessionStorage?.getItem('latestStep') !== '8' && <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd" onClick={() => handleNext()} disabled={next} >Next</button>}
                    </div>
                </div>
                <form action="" className='py-3 my-1'>
                    <div className='row'>
                        <div className="col-12 mb-4">
                            <TextField
                                fullWidth
                                id='street-address'
                                label='Street Address'
                                name='Address1'
                                value={formValue.Address1}
                                onChange={(e) => onChange(e.target.name, e.target.value)}
                                inputProps={{ maxLength: 150 }}
                                disabled={location?.state?.disabled}
                            />
                        </div>

                        <div className="col-12 mb-4">
                            <TextField
                                fullWidth
                                id='apartment-suite'
                                label='Apartment, Suite, etc'
                                name='Address2'
                                value={formValue.Address2}
                                onChange={(e) => onChange(e.target.name, e.target.value)}
                                inputProps={{ maxLength: 150 }}
                                disabled={location?.state?.disabled}
                            />
                        </div>

                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-6 col-12 mb-4">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            filterSelectedOptions
                                            options={locationDropdown.country?.filter(ch => ch?.id !== formValue?.Country)}
                                            id="select-country"
                                            value={locationDropdown.country?.find((obj) => obj.id === formValue.Country) || null}
                                            name='Country'
                                            onChange={(e, newValue) => onChange("Country", newValue?.id ? newValue?.id : null)}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Country"
                                                    className={`${formValue.Country <= 0 ? 'red_border' : ''}`}
                                                    error={formError.Country ? true : false}
                                                    helperText={formError.Country}
                                                />
                                            )}
                                            disabled
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-sm-6 col-12 mb-4">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            filterSelectedOptions
                                            options={locationDropdown.state?.filter(ch => ch?.id !== formValue?.State)}
                                            id="select-state"
                                            value={locationDropdown.state?.find((obj) => obj.id === formValue.State) || null}
                                            name='State'
                                            onChange={(e, newValue) => onChange("State", newValue?.id ? newValue?.id : null)}
                                            renderInput={(params) => (
                                                <TextField {...params} label="State"
                                                    className={`${(formValue.Country === 1 && formValue.State <= 0) ? 'red_border' : ''}`}
                                                    error={(formValue.Country === 1 && formError.State) ? true : false}
                                                    helperText={formError.State}
                                                />
                                            )}
                                            disabled={formValue.Country !== 1 || location?.state?.disabled}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-6 col-12 mb-4">

                                    <FormControl fullWidth>
                                        <Autocomplete
                                            filterSelectedOptions
                                            options={locationDropdown.city?.filter(ch => ch?.id !== formValue?.LocationId)}
                                            id="select-city"
                                            value={locationDropdown.city?.find((obj) => obj.id === formValue.LocationId) || null}
                                            name='LocationId'
                                            onChange={(e, newValue) => onChange("LocationId", newValue?.id ? newValue?.id : null)}
                                            renderInput={(params) => (
                                                <TextField {...params} label="City"
                                                    className={`${formValue.LocationId <= 0 ? 'red_border' : ''}`}
                                                    error={formError.LocationId ? true : false}
                                                    helperText={formError.LocationId}
                                                />
                                            )}
                                            disabled={location?.state?.disabled}
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-sm-6 col-12 mb-4">
                                    <TextField
                                        fullWidth
                                        id='pincode'
                                        label='Pincode'
                                        name='Pincode'
                                        value={formValue.Pincode}
                                        onChange={(e) => onChange(e.target.name, onlyNumeric(e.target.value))}
                                        inputProps={{ maxLength: 12 }}
                                        disabled={location?.state?.disabled}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-6 col-12 mb-4">
                                    <TextField
                                        fullWidth
                                        id='latitude'
                                        label='Latitude'
                                        name='Latitude'
                                        value={formValue.Latitude}
                                        onChange={(e) => onChange(e.target.name, e.target.value)}
                                        inputProps={{ maxLength: 26 }}
                                        disabled={location?.state?.disabled}
                                    />
                                </div>
                                <div className="col-sm-6 col-12 mb-4">
                                    <TextField
                                        fullWidth
                                        id='longitude'
                                        label='Longitude'
                                        name='Longitude'
                                        value={formValue.Longitude}
                                        onChange={(e) => onChange(e.target.name, e.target.value)}
                                        inputProps={{ maxLength: 26 }}
                                        disabled={location?.state?.disabled}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mb-4">
                            <TextField
                                fullWidth
                                id='near-by'
                                label='Near by'
                                name='NearBy'
                                value={formValue.NearBy}
                                onChange={(e) => onChange(e.target.name, e.target.value)}
                                disabled={location?.state?.disabled}
                            />
                        </div>
                    </div>
                </form>

            </div>
        </>
    )
}

export default LocationIndividual