import React from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import '../../../assets/css/master.css';
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { generatePDF, isValidImage } from '../../commonClass/CommonValidation'
import { useRef } from 'react';
import { ImageDelete, ImageUpload } from '../../../ImageUpload/ImageCommon';
import { useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../_actions/loader.actions";
import { ExportExcel } from '../../commonClass/ExportExcel';
import { ConfirmationModal } from '../../../customControl/ConfirmationModal';
import CustomTable from '../../../customControl/CustomTable';

const Thememaster = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const inputRef = useRef();
    // var store = useContext(ReactReduxContext).store.getState();
    const [themedata, setThemeData] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [activeId, setActiveId] = useState(0);
    const [imageFileName, setImageFileName] = useState();
    const [disabled, setDisabled] = useState(false);
    const [editdisabled, setEditDisabled] = useState(true);
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('3');
    const [serchdisable, setSerachDisable] = useState(false);
    const [filteritems, setFilterItems] = useState([]);
    const [imagedelete, setImageDelete] = useState('');

    const [imagepath, setImagePath] = useState('');
    //const [bennerFileName , setImageFileName] = useState('');

    const [imageduplicateerrorMessage, setImageDuplicateErrorMessage] = useState('');
    const [clrshow, setsclshow] = useState(false);
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);
    const serachoption = [
        {
            label: 'Theme Name',
            value: '1'
        },
        {
            label: 'Active',
            value: '2'
        },
        // {
        //     label: 'Created Date',
        //     value: '4'
        // },
        {
            label: 'All',
            value: '3'
        },

    ];
    const [formValue, setFormValue] = useState(
        {
            LanguageId: 1,
            ThemeId: 0,
            ThemeName: '',
            ImageFileName: '',
            ImagePath: '',
            IsActive: false
        }
    );
    const columns = [

        {
            name: 'Theme Name',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (image) => image.themeName
        },

        {
            name: 'Active',
            sortable: true,
            float: "left",
            // selector: location => <td style={{ textAlign: "center", width: "5%" }}>
            //     {`${location.inActive ? 'InActive' : 'Active'}`}</td>
            selector: (image) => image.isActive ? 'Active' : 'InActive'
        },
        {
            name: 'Created Date',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (image) => formatDate(image.createdDate)
        },
        {
            name: 'Edit',
            // float:"right",
            selector: (image) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditMode(image.themeId)}></i></button>
        },
        {
            name: 'View',
            // float:"right",
            selector: (image) => <i className="fa fa-eye" onClick={() => handleViewMode(image.themeId)}></i>// onClick={() => handleViewMode(banner.themeId)}
        }
    ];
    const [isSubmit, setIsSubmit] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    //#region handle getbannerdata
    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            //if (Window.$name && Window.$userToken) {
            getImageDataList().then(() => { dispatch(loaderActions.end()) });
            setitems();
        }
        else {
            ClearLocalStorage();
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, []);
    const getImageDataList = async () => {
        const imagelistdata = await getAllData("/master/v1/getalltheme");
        setThemeData(imagelistdata.data);
    }
    useEffect(() => {
        // let MENUID = GetMenuIdFromStorage()
        // let viewrights = false;
        // user?.filter(x => x.MenuId === MENUID).map((obj) => {
        //     viewrights = obj.URightView;
        //     setUserAdd(obj.URightAdd);
        //     setUserEdit(obj.URightEdit);
        //     setUserExport(obj.URightExport);
        //     if (!viewrights) {
        //         navigate('/dashboard');
        //     }
        // });
        // let MENUID = GetMenuIdFromStorage()
        // let mid = user?.find(x => x.MenuId === MENUID);
        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);

        });
    }, [user])
    //#endregion

    //#region image upload

    const [fileselected, setSelectedFile] = useState();
    const [imageError, setImageError] = useState('');

    const handleUploadImageLoad = (e) => {
        if (isValidImage(e.target.files[0].name)) {
            const files = e.target.files;
            const filecom = getDateCombination();
            const filename = filecom + '_' + '1' + '_' + (files[0].name.replace(/ /g, "_")).replace(/-/g, '');
            formValue.ImageFileName = filename
            formValue.ImagePath = 'CMS/Theme/' + filename
            setSelectedFile(e.target.files[0]);
            setEditDisabled(true);
            setImagePath('');
            setImageFileName('');
            setImageError('');
            setImageDuplicateErrorMessage('');
        }
        else {
            document.getElementById("ImagePath").value = "";
            setFormValue({ ...formValue, ImageFileName: '', ImagePath: '' });
            setImagePath('');
            setFormErrors('');
            setImageFileName('');
            setImageError("Please select Only Image")
            setIsSubmit(false);
            setImageDuplicateErrorMessage('');
            return;
        }
        //const file = e.target.files[0];
        //setUploadImage(current => [{ title: file.name, imageurl: URL.createObjectURL(file) }]);
    }
    useEffect(() => {
        //setSelectedFile(fileselected);
        setFormErrors('');
    }, [fileselected]);
    const removeImage = () => {

        //setUploadImage((oldState) => oldState?.filter((item) => item.id !== id));

        document.getElementById("ImagePath").value = "";
        formValue.ImagePath = ''
        formValue.ImageFileName = ''
        setImagePath('');
        setSelectedFile();
        setEditDisabled(true);
        setImageFileName('');
    };
    function getDateCombination() {
        var today = new Date();
        let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
        let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
        return date + '_' + time;
    }

    const handleimageupload = async (pathdirectory, filename, file) => {
        await ImageUpload(pathdirectory, filename, file);
    }
    const handleimageDelete = async (filename) => {
        await ImageDelete(filename);
    }
    // const uploadimagelist = useState([{
    //     title: '',
    //     imageurl: '',
    // }]);
    // const [uploadimage, setUploadImage] = useState(uploadimagelist);
    // useEffect(() => {
    //     //uploadfileimage(uploadimage)
    // }, [uploadimage]);
    //#endregion

    //#region handle change and active value
    const onChange = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
        setImageError('');
        setFormErrors('');
        setImageDuplicateErrorMessage('');
        setEditDisabled(true);
    }
    // const handleisActive = (e) => {
    //     { formValue.IsActive = e }
    //     setActiveId(!activeId)
    //     setImageError('');
    //     setFormErrors('');
    //     setEditDisabled(true);
    //     //setEditDisabled(true);
    // }
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            formValue.IsActive = e
            setActiveId(!activeId)
            setFormErrors('');
            setImageError('')
            setImageDuplicateErrorMessage('');
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        //setYesNoConfirm(e)
        if (e === true) {
            formValue.IsActive = !activeId
            setActiveId(!activeId)
            setImageError('');
            setFormErrors('');
            setImageDuplicateErrorMessage('');
            setEditDisabled(true);
        }
        setModalShow(false);
    }
    //#endregion

    //#region handle Add,Retrive, Edit, View
    const handleSubmitData = async () => {
        dispatch(loaderActions.start());
        let statusoftoken = await verifytoken();
        if (buttonaddeditText === 'Add') {
            if (statusoftoken) {
                let status = false;
                const Themedata = await postUsersData('/master/v1/inserttheme', formValue)
                status = Themedata.status;
                if (status !== 400) {
                    try {
                        if (!Themedata.status && !Themedata.data) {
                            setImageDuplicateErrorMessage(Themedata.message);
                            setDisabled(false);
                            setEditDisabled(true);
                            setIsSubmit(false);
                            return;
                        }
                        if (Themedata.status) {
                            if (Themedata.data > 0) {
                                await handleimageupload('CMS/Theme/', formValue.ImageFileName, fileselected);
                                setitems();
                                dispatch({ type: 'ALERTING', message: Themedata.message })
                                // alert('Record saved successfully.');
                                clear();
                            }
                            else {
                                dispatch({ type: 'ALERTING', message: Themedata.message })
                                // alert('Record not saved successfully')
                                setIsSubmit(false);
                                return;
                            }
                        }

                    }
                    catch (err) {
                        dispatch({ type: 'ALERTING', message: err })
                        // alert(err);
                        setDisabled(false);
                        setEditDisabled(true);
                        dispatch(loaderActions.end());
                        return;
                    }

                }
                else {
                    dispatch({ type: 'ALERTING', message: Themedata.message })
                    // alert('Try again later!');
                    setDisabled(false);
                    setEditDisabled(true);
                    return;
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        else {
            if (statusoftoken) {
                try {
                    let status1 = false;
                    const Themedata = await patchUsersData('/master/v1/updatetheme', formValue)
                    status1 = Themedata.status;
                    if (status1 !== 400 && typeof status1 != 'undefined') {
                        try {
                            if (!Themedata.status && !Themedata.data) {
                                setImageDuplicateErrorMessage(Themedata.message);
                                setDisabled(false);
                                setEditDisabled(true);
                                setIsSubmit(false);
                                return;
                            }
                            if (Themedata.status) {
                                // if (Themedata.data > 0) {

                                if (imagepath?.length === 0) {
                                    await handleimageupload('CMS/Theme/', formValue.ImageFileName, fileselected);
                                }

                                try {
                                    if (imagepath?.length === 0) {
                                        await handleimageDelete(imagedelete);
                                    }
                                }
                                catch (err) {
                                    dispatch({ type: 'ALERTING', message: err })
                                    // alert(err);
                                    setDisabled(false);
                                    setEditDisabled(true);
                                    setIsSubmit(false);
                                    return;
                                }
                                setitems();
                                dispatch({ type: 'ALERTING', message: Themedata.message })
                                // alert('Record updated successfully.');
                                clear();
                                // }
                                // else {
                                //     alert('Record not updated successfully')
                                //     setDisabled(false);
                                //     setEditDisabled(true);
                                //     return;
                                // }
                            }

                        }
                        catch (err) {
                            dispatch({ type: 'ALERTING', message: err })
                            // alert(err);
                            setDisabled(false);
                            setEditDisabled(true);
                            setIsSubmit(false);
                            dispatch(loaderActions.end());
                            return;
                        }
                    }
                    else {
                        dispatch({ type: 'ALERTING', message: Themedata.message })
                        // alert('Try again later!');
                        setDisabled(false);
                        setEditDisabled(true);
                        setIsSubmit(false);
                        return;
                    }
                }
                catch (err) {
                    dispatch({ type: 'ALERTING', message: err })
                    // alert(err);
                    setDisabled(false);
                    setEditDisabled(true);
                    setIsSubmit(false);
                    dispatch(loaderActions.end());
                    return;
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
            
        }
        dispatch(loaderActions.end());

    }
    const onSubmitForm = e => {
        e.preventDefault();
        setFormErrors(validateData(formValue));
        //handleSubmitData();
    };
    const validateData = (values) => {
        setIsSubmit(true);
        const error = {}
        if (!values.ThemeName || values.ThemeName?.trim()?.length === 0) {
            error.ThemeName = "Please enter Theme Name";
            setIsSubmit(false);
        }
        if (values.ThemeName?.length > 50) {
            error.ThemeName = "Maximum length is 50 characters";
            setIsSubmit(false);
        }
        // if (!values.ImagePath) {
        //     error.ImagePath = "Please select Theme Image";
        //     setImageError('');
        //     setIsSubmit(false);
        // }
        return error;
    }
    useEffect(() => {
        if (Object.keys(formErrors)?.length === 0 && isSubmit) {
            setDisabled(true);
            setEditDisabled(false);
            handleSubmitData();
        }
        if (Object.keys(formErrors)?.length > 0) {
            let id = Object.keys(formErrors)[0];


            let doc = document.getElementById(id);
            if (doc !== null)
                doc.focus();
        }

    }, [formErrors]);
    useEffect(() => {

    }, [isSubmit]);

    const handleEditMode = (id) => {
        setDisabled(false);
        setEditDisabled(false);
        setButtonAddEditText("Update");
        setSerachDisable(true);
        RetriveData(id);
    }
    const handleViewMode = (id) => {
        setDisabled(true);
        setEditDisabled(false);
        setSerachDisable(true);
        RetriveData(id);
    }
    function RetriveData(id) {
        document.getElementById("ImagePath").value = "";
        setSelectedFile('');
        setFormErrors('');
        setImageError('');
        setImageDuplicateErrorMessage('');
        themedata?.map(obj => {
            if (obj.themeId === id) {
                // let active = !obj.isActive;
                formValue.ThemeName = obj.themeName
                formValue.ImagePath = obj.imagePath
                formValue.ThemeId = obj.themeId
                formValue.IsActive = obj.isActive
                formValue.ImageFileName = obj.imageFileName
                setActiveId(obj.isActive);
                setImagePath(obj.imagePath ? 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + obj.imagePath : obj.imagePath);
                setImageFileName(obj.imageFileName);
                setImageDelete(obj.imagePath);
            }
        });
        setFormValue({ ...formValue, formValue })
    }

    //#endregion

    //#region handle clear value
    const handleClearClick = () => {
        clear();
    }
    function clear() {
        setIsSubmit(false);
        setFormErrors('');
        setImageError('');
        setImageFileName('');
        setFormValue({ ...formValue, ThemeId: 0, ThemeName: '', ImageFileName: '', ImagePath: '', IsActive: false });
        setActiveId(0);
        setSelectedFile();
        setSerachBy('3');
        setSearchValue('');
        setSerachDisable(false);
        setImageDuplicateErrorMessage('');
        setDisabled(false);
        setEditDisabled(true);
        getImageDataList();
        setImageDelete('');
        setButtonAddEditText('Add');
        setImagePath('');
        document.getElementById("ImagePath").value = "";
        setsclshow(false)
    }
    //#endregion

    //#region handle serach value
    const setitems = async () => {
        const ImageListData = await getAllData("/master/v1/getalltheme");
        setFilterItems(ImageListData.data);
    }

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }
    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getImageDataList();
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    };

    const handleSearch = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            setitems();
            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.themeName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '4') {
                filterdata = filteritem?.filter(issec => formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '2') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                // else {
                //     filterdata = filteritem;
                // }
                else {
                    filterdata = filteritem?.filter(issec => formatDate(issec.createdDate).toLowerCase().includes("%#$&%^"));
                }
            }
            else if (searchby === '3') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.themeName.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.themeName.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.themeName.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.themeName.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || issec.isActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => issec.themeName.toLowerCase().includes(searchvalue.toLowerCase()) || formatDate(issec.createdDate).toLowerCase().includes(searchvalue.toLowerCase()) || 'active'.toLowerCase().includes(searchvalue) || 'inactive'.toLowerCase().includes(searchvalue));
                }
            }
            setThemeData(filterdata)
        }
    }

    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getImageDataList();
    }
    //#endregion

    //#region handle pdf and excel import
    function PdfExport() {
        //print
        const columns = [
            "Theme Name",
            "Active",
            "Created Date"
        ];
        var rows = [];

        for (let i = 0; i < themedata?.length; i++) {
            var temp = [
                themedata[i].themeName,
                `${themedata[i].isActive ? 'Active' : 'InActive'}`,
                formatDate(themedata[i].createdDate)
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "ThemeMaster", "Theme Master")
    }
    // function ExportToExcel() {
    //     const fields = ['themeName', 'isActive'];
    //     const data = themedata;
    //     const fileName = 'Thememasterexcel'
    //     const exportType = 'xls'

    //     exportFromJSON({ data, fileName, fields, exportType })
    // }
    const ExportToExcel = () => {
        const dispalyname = ['Theme Name', 'Active', 'Created Date'];
        const fields = ['themeName', 'isActive', 'createdDate'];
        ExportExcel(dispalyname, fields, themedata, "ThemeMaster", "ThemeMaster.xls");
    }


    //#endregion
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    return (
        <>
            <div class="container-fluid mt-4 heading_color">
                <h3>Theme Master</h3>
            </div>
            <div class="container-fluid form_border py-5 my-3">
                <form action="" >
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                {imageduplicateerrorMessage && <div className="text-danger pb-3 text-center"> {imageduplicateerrorMessage} </div>}
                                <div className='col-md-6'>
                                    <div className='row'>

                                        <div class="col-lg-4 col-md-12 col-sm-12">
                                            <label for="inputPassword6" class="col-form-label required">Theme Name</label>
                                        </div>
                                        <div class=" col-lg-8 col-md-12 col-sm-12">
                                            <input type="text" id="ThemeName" class="form-control"
                                                name="ThemeName"
                                                value={formValue.ThemeName}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder="Theme Name"
                                            />
                                            {formErrors.ThemeName && <p className='text-danger text-start'>{formErrors.ThemeName}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 mb-1">
                                            <label for="inputPassword6" class="col-form-label">Upload Image</label>
                                        </div>
                                        <div class="col-lg-8 col-md-12 col-sm-12 mb-lg-1 mb-md-1">
                                            <div class="input-group">

                                                <input
                                                    ref={inputRef}
                                                    name='ThemeImage'
                                                    type="file"
                                                    id="ImagePath"
                                                    onChange={handleUploadImageLoad}
                                                    class="form-control btn_add"
                                                    accept=".png,.jpg"
                                                    disabled={disabled ? true : false}
                                                />
                                                <label class="input-group-text" for="ImagePath">Browse</label>

                                            </div>
                                            {formErrors.ImagePath && <p className='text-danger text-start'>{formErrors.ImagePath}</p>}
                                            {imageError && <p className='text-danger text-start'>{imageError}</p>}
                                            {/* {imageFileName && <p className='text-start'>{imageFileName}</p>} */}
                                            {imageFileName && <p className='text-start overflow-hidden' data-bs-toggle="tooltip" data-bs-placement="left" title={imageFileName}>
                                                {imageFileName}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='row'>
                                        <div class="col-lg-4 col-md-12 col-sm-12">
                                            <label for="inputPassword6" class="col-form-label">Active</label>
                                        </div>
                                        <div class="col-auto col-lg-8">

                                            <Switch
                                                onChange={handleisActive}
                                                checked={activeId}
                                                className="react-switch"
                                                disabled={disabled ? true : false}
                                            />
                                        </div>
                                        {/* <div class=" col-auto col-lg-4"> */}
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation(false)}
                                            yesconfirm={() => handleConfirmation(true)}
                                        />

                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            {fileselected &&
                                (
                                    <div className="col-lg-2 col-md-3 col-6 mx-auto mt-2">
                                        <img
                                            src={URL.createObjectURL(fileselected)} className="img-fluid w-100" alt=''
                                        />
                                        <button onClick={() => removeImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }} disabled={disabled ? true : false} >&times;</button>
                                    </div>
                                )}
                            {(!fileselected && imagepath?.trim()?.length > 0) && (
                                <div className="col-lg-2 col-md-3 col-6 mx-auto mt-2" >
                                    <img
                                        src={imagepath} className="img-fluid w-100" alt=''
                                    />
                                    <button onClick={() => removeImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }} disabled={disabled ? true : false} >&times;</button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div class="d-flex justify-content-center mb-4">
                        {useradd === false && buttonaddeditText === "Add" ?
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={onSubmitForm} disabled={!useradd}>{buttonaddeditText}</button> :
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={onSubmitForm} disabled={editdisabled ? false : true}>{buttonaddeditText}</button>}
                        <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick} >Clear</button>
                    </div>
                    <div class="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" class="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue || ''} disabled={serchdisable ? true : false} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div class="p-2 text-center">
                                <button type="button" class="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleSearch}>Search</button>
                            </div>
                        </div>

                    </div>
                    <CustomTable
                        columns={columns}
                        data={themedata}
                        PdfExport={PdfExport}
                        ExportToExcel={ExportToExcel}
                        disabled={!userexport}
                    />
                </form>
            </div>
        </>
    )
}

export default Thememaster