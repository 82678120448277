import Modal from 'react-bootstrap/Modal';
import "../../src/assets/css/commoncontrol.css";


export function CustomModal(props) {
    return (

        <Modal
            {...props}
            size={props?.size}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='modelheader bg-primary header_closebtn'>
                <Modal.Title id="contained-modal-title-vcenter" className='header_text mx-auto h5'>
                    {props?.modaltitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='modalbody'>
                {props?.modalbody()}
            </Modal.Body>
            {props?.modalfooter &&
                <Modal.Footer className='modalfooter'>
                    {props?.modalfooter}
                </Modal.Footer>
            }
        </Modal>


    );
}