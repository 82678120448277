import React, { useEffect, useState } from 'react'
import Switch from 'react-switch';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { ClearLocalStorage, GetLocalStorage } from '../../../../commonClass/Session';
import { getAllData, postUsersData, verifytoken } from '../../../../../services/ApiCallingUserServices';
import { loaderActions } from '../../../../../_actions/loader.actions';
import { validateURL } from '../../../../commonClass/CommonValidation';
import { ExportExcel } from '../../../../commonClass/ExportExcel';
import { CustomModal } from '../../../../../customControl/CustomModal';
import { ConfirmationModal } from '../../../../../customControl/ConfirmationModal';
import CustomTable from '../../../../../customControl/CustomTable';

const { RangePicker } = DatePicker;

const APIConfigurationFlight = ({ useradd, useredit, userexport }) => {
    const dispatch = useDispatch();
    const userId = GetLocalStorage();

    const [dropdownData, setDropdownList] = useState([]);
    const [getDataList, setGetListData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [editDisable, setEditDisable] = useState(false);
    const [formError, setFormError] = useState({});
    const [activeId, setActiveId] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [show, setShow] = useState(false);
    const [downloadDate, setDowloadDate] = useState({
        startDate: '',
        endDate: ''
    })

    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('100');
    const [serchdisable, setSerachDisable] = useState(false);
    const [clrshow, setsclshow] = useState(false);

    const [formValue, setFormValue] = useState({
        APIConfigurationId: 0,
        SupplierId: 0,
        URL: '',
        Key: '',
        SecretKey: '',
        IsActive: true,
        UserId: 0,
        OperatorId: [],
        type: 'add'
    })

    const getData = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const data = await getAllData('/master/v1/getoperatorlist');
            if (data.status) {
                setDropdownList({
                    supplierList: data?.data?.supplierList?.map(el => ({
                        label: el?.SupplierName,
                        value: el?.SupplierId
                    })),
                    operatorList: data?.data?.operatorList?.map(el => ({
                        label: el?.AirlineName,
                        value: el?.AirlineID
                    }))
                });
            }
            else {
                dispatch({ type: 'ALERTING', message: data.message })
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
        dispatch(loaderActions.end())
    }

    const getDataAll = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const data = await getAllData(`/master/v1/getapiconfigurationlist`);
            if (data?.status) {
                setGetListData(data?.data)
                setFilterData(data?.data)
            }
            else {
                setGetListData([])
                setFilterData([])
            }
            dispatch(loaderActions.end());
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
        dispatch(loaderActions.end())
    }

    const onChange = (name, value) => {
        setFormError({});
        setEditDisable(false);
        if (name === 'OperatorId') {
            setFormValue({ ...formValue, [name]: value?.map(val => val.value) });
        }
        else {
            setFormValue({ ...formValue, [name]: value });
        }
    }

    const handleisActive = (e) => {
        if (formValue?.type === 'add') {
            formValue.IsActive = e
            setActiveId(!activeId)
            setFormError({});
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        if (e === true) {
            formValue.IsActive = !activeId
            setActiveId(!activeId)
            setFormError({});
            setEditDisable(false);
        }
        setModalShow(false);
    }

    const checkValidation = () => {
        let errorMessages = {};
        let isError = true;

        if (formValue?.SupplierId === 0) {
            errorMessages['SupplierId'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        else if (formValue?.SupplierId > 0 && filterData?.some((val) => val?.SupplierId === formValue?.SupplierId) && formValue?.type === 'add') {
            errorMessages['SupplierId'] = 'Duplicate Supplier. Please enter a unique Supplier.'
            isError = false;
        }
        if (formValue?.OperatorId?.length === 0) {
            errorMessages['OperatorId'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (formValue?.URL?.trim()?.length === 0) {
            errorMessages['URL'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        else if (formValue?.URL?.trim()?.length < 8) {
            errorMessages['URL'] = 'URL must be at least 8 characters long.'
            isError = false;
        }
        if (formValue?.URL?.trim()?.length >= 8 && !validateURL(formValue?.URL)) {
            errorMessages['URL'] = 'Please enter a valid URL.'
            isError = false;
        }
        if (formValue?.Key?.trim()?.length === 0) {
            errorMessages['Key'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        else if (formValue?.Key?.trim()?.length < 8) {
            errorMessages['Key'] = 'Key must be at least 8 characters long.'
            isError = false;
        }
        if (formValue?.SecretKey?.trim()?.length === 0) {
            errorMessages['SecretKey'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        else if (formValue?.SecretKey?.trim()?.length < 8) {
            errorMessages['SecretKey'] = 'Secret Key must be at least 8 characters long.'
            isError = false;
        }
        setFormError(errorMessages);
        return isError;
    }
    const checkvalidate = () => {
        let errorMessages = {};
        let isError = true;
        if (formValue?.URL?.trim()?.length === 0) {
            errorMessages['URL'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        else if (formValue?.URL?.trim()?.length < 8) {
            errorMessages['URL'] = 'URL must be at least 8 characters long.'
            isError = false;
        }
        if (formValue?.URL?.trim()?.length >= 8 && !validateURL(formValue?.URL)) {
            errorMessages['URL'] = 'Please enter a valid URL.'
            isError = false;
        }
        if (formValue?.Key?.trim()?.length === 0) {
            errorMessages['Key'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        else if (formValue?.Key?.trim()?.length < 8) {
            errorMessages['Key'] = 'Key must be at least 8 characters long.'
            isError = false;
        }
        if (formValue?.SecretKey?.trim()?.length === 0) {
            errorMessages['SecretKey'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        else if (formValue?.SecretKey?.trim()?.length < 8) {
            errorMessages['SecretKey'] = 'Secret Key must be at least 8 characters long.'
            isError = false;
        }
        setFormError(errorMessages);
        return isError;
    }

    const validate = () => {
        const isValid = checkvalidate();
        if (isValid) {

        }
    }

    const onSubmitData = async () => {
        const isValid = checkValidation();
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            if (isValid) {
                dispatch(loaderActions.start());
                const data = await postUsersData('/master/v1/insertorupdateapiconfiguration', formValue);
                if (data.status) {
                    onClearButton();
                    getDataAll();
                    dispatch({ type: 'ALERTING', message: data.message })
                    dispatch(loaderActions.end());
                }
                else {
                    dispatch(loaderActions.end());
                    dispatch({ type: 'ALERTING', message: data.message })
                    return;
                }
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
        dispatch(loaderActions.end());
    }
    const onClearButton = () => {
        setFormError({});
        setEditDisable(false);
        setSerachDisable(false);
        setActiveId(true);
        setFormValue(
            {
                APIConfigurationId: 0,
                SupplierId: 0,
                URL: '',
                Key: '',
                SecretKey: '',
                IsActive: true,
                UserId: userId?.userMasterID,
                OperatorId: [],
                type: 'add'
            }
        )
    }
    const onEditorView = async (data, type) => {
        if (type === 'view') {
            setSerachDisable(true)
        }
        setEditDisable(true)
        dispatch(loaderActions.start());
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const el = await getAllData(`/master/v1/getapiconfigurationbyid?apiconfigurationid=${data?.APIConfigurationId}`);
            setFormError({});
            if (el?.status) {
                setFormValue(
                    {
                        APIConfigurationId: el?.data?.apiConfiguration?.[0]?.APIConfigurationId,
                        SupplierId: el?.data?.apiConfiguration?.[0]?.SupplierId,
                        URL: el?.data?.apiConfiguration?.[0]?.URL,
                        Key: el?.data?.apiConfiguration?.[0]?.Key,
                        SecretKey: el?.data?.apiConfiguration?.[0]?.SecretKey,
                        IsActive: el?.data?.apiConfiguration?.[0]?.IsActive,
                        UserId: userId?.userMasterID,
                        OperatorId: el?.data?.operatorList?.length > 0 ? el?.data?.operatorList?.map(id => id.OperatorsId) : [],
                        type: type
                    }
                )
                setActiveId(el?.data?.apiConfiguration?.[0]?.IsActive)
            }
        } else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            dispatch(loaderActions.end());
        }
        dispatch(loaderActions.end());
    }

    const columns = [
        {
            name: 'Supplier',
            sortable: true,
            float: "left",
            selector: (data) => data?.SupplierName,
        },
        {
            name: 'Active',
            sortable: true,
            float: "left",
            selector: (data) => data?.Status,
        },
        {
            name: 'Created Date',
            sortable: true,
            float: "left",
            selector: (data) => formatDate(data?.CreatedDate),
        },
        {
            name: 'Edit',
            selector: data => <button type='button' className='border-0 p-0 bg-body set_disableclr' onClick={() => onEditorView(data, 'edit')} disabled={!useredit}
            ><i className="fa fa-edit eye-icon"></i></button>
        },
        {
            name: 'View',
            selector: data => <button type='button' className='border-0 p-0 bg-body set_disableclr' onClick={() => onEditorView(data, 'view')} ><i className="fa fa-eye" ></i></button>
        },
        {
            name: 'Download',
            selector: data => <button type='button' className='border-0 p-0 bg-body set_disableclr' onClick={() => onDownload(data)} disabled={!userexport}><i class="fa-solid fa-download"></i></button>
        }
    ]

    const serachoption = [
        {
            label: 'Supplier',
            value: '1'
        },
        {
            label: 'Active',
            value: '2'
        },
        {
            label: 'Created Date',
            value: '3'
        },
        {
            label: 'All',
            value: '100'
        },
    ]

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }

    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getDataAll()
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    }

    const matchesSearchValueAll = (issec, fields) => {
        let search = searchvalue
        return fields.some((data) =>
            issec[data]?.toString().toLowerCase().includes(search.toLowerCase())
        );
    }

    const handleSearch = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            var filteritem = filterData;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec?.SupplierName?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec?.Status?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            if (searchby === '3') {
                filterdata = filteritem?.filter(issec => formatDate(issec?.CreatedDate)?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            if (searchby === '100') {
                filterdata = filteritem?.filter((issec) => {
                    let keys = [
                        "SupplierName",
                        "Status",
                        "CreatedDate",
                    ]
                    return matchesSearchValueAll(issec, keys) && issec
                });
            }
            setGetListData(filterdata)
        }
    }


    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getDataAll();
    }


    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return date ? [day, month, year].join('/') : '';
    }

    const onDownload = (data) => {
        setShow(true);
        setDowloadDate({
            startDate: '',
            endDate: ''
        })
    }

    const calenderchange = (e) => {
        setDowloadDate({ ...downloadDate, startDate: formatDate(e?.[0]), endDate: formatDate(e?.[1]) })
    }

    const downloadExcel = () => {
        ExportToExcel(downloadDate?.startDate, downloadDate?.endDate);
        setShow(false);
        setDowloadDate({
            startDate: '',
            endDate: ''
        })
    }

    const ExportToExcel = (startDate, endDate) => {
        if (startDate && endDate) {
            const dispalyname = ['Supplier', 'Status', 'Created Date'];
            const fields = ['SupplierName', 'Status', 'CreatedDate'];
            ExportExcel(dispalyname, fields, filterData?.filter(el => formatDate(el?.CreatedDate) >= downloadDate?.startDate && formatDate(el?.CreatedDate) <= downloadDate?.endDate), "FlightsReport", "FlightsReport.xls");
        }
    }

    const modlabody = () => {
        return (
            <div class="mb-3 row mx-1">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row">
                        <div className='col-lg-12 col-sm-12 '>
                            <div className='row'>
                                <div class="col-lg-5 col-md-6 mb-1">
                                    <label htmlFor="inputPassword6" class="col-form-label">From</label>
                                </div>
                                <div class="col-lg-5 col-md-6 mb-1">
                                    <label htmlFor="inputPassword6" class="col-form-label">To</label>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12 col-sm-12'>
                            <div className='row'>
                                <div class="col-lg-9 mb-1 col-md-12">
                                    <RangePicker
                                        value={[
                                            downloadDate?.startDate ? dayjs(downloadDate.startDate, 'DD/MM/YYYY') : null,
                                            downloadDate?.endDate ? dayjs(downloadDate.endDate, 'DD/MM/YYYY') : null,
                                        ]}
                                        className='antd_input'
                                        format={'DD/MM/YYYY'}
                                        style={{
                                            width: '100%',
                                        }}
                                        onCalendarChange={calenderchange}
                                    />
                                </div>
                                <div class="col-lg-3 mb-1 col-md-12">
                                    <button class="btn btn-primary mx-1 form-control" id="btnAdd" type='button'
                                        onClick={downloadExcel}
                                        disabled={(downloadDate?.startDate === '' ? true : false && downloadDate?.endDate === '' ? true : false) || !useredit}
                                    >Download</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }

    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            formValue.UserId = userId?.userMasterID
            getData();
            getDataAll();
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
    }, [])

    return (
        <>
            <CustomModal
                show={show}
                size='md'
                onHide={() => setShow(false)}
                modaltitle='Download report'
                modalbody={modlabody}
                modalfooter=''
            />
            <form action="" >
                <div class="mb-3 row mx-1">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="row">
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-4 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" class="col-form-label required">Select Supplier</label>
                                    </div>
                                    <div class="col-lg-8 mb-1 col-md-12">
                                        <Select
                                            id="SupplierId"
                                            className="dropdown select_dropdown"
                                            options={dropdownData?.supplierList}
                                            value={dropdownData?.supplierList?.filter((val) => (val.value === formValue?.SupplierId))}
                                            onChange={(e) => onChange('SupplierId', e?.value)}
                                            isDisabled={formValue?.type === 'edit' || formValue?.type === 'view' ? true : false}
                                            isSearchable
                                        />
                                        {formError?.SupplierId && <p className='text-danger text-start mb-0'>{formError?.SupplierId}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-4 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" class="col-form-label required">Operators List</label>
                                    </div>
                                    <div class="col-lg-8 mb-1 col-md-12">
                                        <Select
                                            id="SupplierId"
                                            isMulti
                                            className="dropdown select_dropdown"
                                            options={dropdownData?.operatorList}
                                            value={dropdownData?.operatorList?.filter((val) => formValue?.OperatorId?.includes(val.value)) || []}
                                            onChange={(e) => onChange('OperatorId', e)}
                                            isDisabled={formValue?.type === 'view' ? true : false}
                                            isSearchable
                                        />
                                        {formError?.OperatorId && <p className='text-danger text-start mb-0'>{formError?.OperatorId}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-4 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" class="col-form-label required">API Accessing URL</label>
                                    </div>
                                    <div class="col-lg-8 mb-1 col-md-12">
                                        <input type="text" id="FlightNo" class="form-control"
                                            name="URL"
                                            value={formValue?.URL}
                                            maxLength={100}
                                            onChange={(e) => onChange(e?.target?.name, e?.target?.value)}
                                            disabled={formValue?.type === 'view' ? true : false}
                                            placeholder="API Accessing URL"
                                        />
                                        {formError?.URL && <p className='text-danger text-start mb-0'>{formError?.URL}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-4 col-md-12 mb-1">
                                        <label for="inputPassword6" class="col-form-label">Active</label>
                                    </div>
                                    <div class="col-auto col-lg-8" disabled={false}>
                                        <Switch
                                            onChange={handleisActive}
                                            checked={activeId}
                                            className="react-switch"
                                            disabled={formValue?.type === 'view' ? true : false}
                                        />
                                    </div>
                                    <div class=" col-auto col-lg-4">
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation(false)}
                                            yesconfirm={() => handleConfirmation(true)}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-4 col-md-12 mb-1">
                                        <label for="inputPassword6" class="col-form-label required">API Consumer Key</label>
                                    </div>
                                    <div class="col-lg-8 mb-1 col-md-12">
                                        <input type="text" id="FlightNo" class="form-control"
                                            name="Key"
                                            value={formValue?.Key}
                                            maxLength={100}
                                            onChange={(e) => onChange(e?.target?.name, e?.target?.value)}
                                            disabled={formValue?.type === 'view' ? true : false}
                                            placeholder="API Consumer Key"
                                        />
                                        {formError?.Key && <p className='text-danger text-start mb-0'>{formError?.Key}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '></div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-4 col-md-12 mb-1">
                                        <label for="inputPassword6" class="col-form-label required">API Consumer Secret Key</label>
                                    </div>
                                    <div class="col-lg-8 mb-1 col-md-12">
                                        <input type="text" id="FlightNo" class="form-control"
                                            name="SecretKey"
                                            value={formValue?.SecretKey}
                                            maxLength={100}
                                            onChange={(e) => onChange(e?.target?.name, e?.target?.value)}
                                            disabled={formValue?.type === 'view' ? true : false}
                                            placeholder="API Consumer Secret Key"
                                        />
                                        {formError?.SecretKey && <p className='text-danger text-start mb-0'>{formError?.SecretKey}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-4 col-md-12 mb-1 d-lg-none invisible">
                                        <label for="inputPassword6" class="col-form-label required"></label>
                                    </div>
                                    <div class="col-lg-2 mb-1 col-md-4">
                                        <button class="btn btn-primary mx-1 form-control" id="btnAdd" type='button'
                                            onClick={validate}
                                            disabled={editDisable || !useredit}
                                        >Validate</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-3 d-flex justify-content-center">
                    {
                        formValue?.type === 'edit'
                            ? (<button class="btn btn-primary mx-1" id="btnAdd" type='button'
                                onClick={() => onSubmitData()}
                                disabled={editDisable || !useredit}
                            >Update</button>
                            )
                            : (<button class="btn btn-primary mx-1" id="btnAdd" type='button'
                                onClick={() => onSubmitData()}
                                disabled={(formValue?.type === 'view' ? editDisable : false) || !useradd}
                            >Add</button>)
                    }
                    <button type="button" className="btn btn-primary mx-1" id="btnClear" onClick={onClearButton}>Clear</button>
                </div>
                <div className="row mt-3">
                    <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                        <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                            <Select
                                className="dropdown"
                                options={serachoption}
                                value={serachoption?.filter(function (serachoption) {
                                    return serachoption.value === searchby;
                                })}
                                onChange={handleSearchBy}
                                isDisabled={serchdisable ? true : false}
                                isSearchable={false}

                            />
                        </div>
                        <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                            <div className='clrbutton'>
                                <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" className="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue || ''} disabled={serchdisable ? true : false} />
                                <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                            </div>
                        </div>
                        <div className="p-2 text-center">
                            <button type="button" className="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleSearch} >Search</button>
                        </div>
                    </div>

                </div>
                <CustomTable
                    className={'last_three_column_freeze'}
                    columns={columns}
                    data={getDataList}
                    PdfExport={false}
                    ExportToExcel={false}
                    disabled={!userexport}
                />
            </form>
        </>
    )
}

export default APIConfigurationFlight