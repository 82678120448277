import { FormControlLabel, Radio, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { PaymentPolicyTitleAndTabButton, PolicyTitleAndTabButton } from './PolicyTitleAndTabButton';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers';
import CustomTable from '../../../../../../../customControl/CustomTable';

const Policies = ({ policies, setPolicies, formError, setFormError, setSave, deletedata, disabled, removerError }) => {
  const [cancellationTab, setCancellationTab] = useState('Percent');
  const [paymentTab, setPaymentTab] = useState('Percent');
  const [deletedCancellation, setDeleteCancellation] = useState(true);
  const [deletePayment, setDeletePayment] = useState(true);
  const [duplicateError, setDuplicateError] = useState('')
  const [valCancellation, setValCancellation] = useState({
    IsPaymentPolicy: null,
    PolicyDay: '',
    Amount: '',
    IsPercentage: null
  })
  const [valPayment, setValPayment] = useState({
    IsPaymentPolicy: null,
    PolicyDay: '',
    Amount: '',
    IsPercentage: null
  })

  const changeCancellationTab = (tab) => {
    removerError('policies-tab-0')
    setDuplicateError('');
    setCancellationTab(tab);
  };

  const changePaymentTab = (tab) => {
    removerError('policies-tab-0')
    setDuplicateError('');
    setPaymentTab(tab);
  };

  useEffect(() => {
    const cancellationTabPercent = policies.HotelPolicyCollection.filter((policy) => (policy.IsPaymentPolicy === false && policy.IsPercentage === true));
    const cancellationFixedAmount = policies.HotelPolicyCollection.filter((policy) => (policy.IsPaymentPolicy === false && policy.IsPercentage === false));
    const PaymentTabPercent = policies.HotelPolicyCollection.filter((policy) => (policy.IsPaymentPolicy === true && policy.IsPercentage === true));
    const PaymentTabFixedAmount = policies.HotelPolicyCollection.filter((policy) => (policy.IsPaymentPolicy === true && policy.IsPercentage === false));

    if (cancellationTabPercent.length > 0) {
      setCancellationTab('Percent');
    } else if (cancellationFixedAmount.length > 0) {
      setCancellationTab('FixedAmount');
    }

    if (PaymentTabPercent.length > 0) {
      setPaymentTab('Percent');
    } else if (PaymentTabFixedAmount.length > 0) {
      setPaymentTab('FixedAmount');
    }
  }, [policies.HotelPolicyCollection])

  const handlePoliciesOnChange = (name, PolicyDayAmount, IsPaymentPolicy, IsPercentage) => {
    removerError('policies-tab-0')
    let numvalue = PolicyDayAmount.replace(/[^0-9]/g, '');
    if (IsPaymentPolicy) {
      if (IsPercentage && numvalue <= 100 && name === 'Amount') {
        setValPayment({ ...valPayment, [name]: Number(PolicyDayAmount), IsPercentage: IsPercentage, IsPaymentPolicy: IsPaymentPolicy })
      }
      else if (IsPercentage && name === 'PolicyDay') {
        setValPayment({ ...valPayment, [name]: Number(PolicyDayAmount), IsPercentage: IsPercentage, IsPaymentPolicy: IsPaymentPolicy })
      }
      else if(!IsPercentage) {
        setValPayment({ ...valPayment, [name]: Number(PolicyDayAmount), IsPercentage: IsPercentage, IsPaymentPolicy: IsPaymentPolicy })
      }
    }
    else {
      if (IsPercentage && numvalue <= 100 && name === 'Amount') {
        setValCancellation({ ...valCancellation, [name]: Number(PolicyDayAmount), IsPercentage: IsPercentage, IsPaymentPolicy: IsPaymentPolicy })
      }
      else if (IsPercentage && name === 'PolicyDay') {
        setValCancellation({ ...valCancellation, [name]: Number(PolicyDayAmount), IsPercentage: IsPercentage, IsPaymentPolicy: IsPaymentPolicy })
      }
      else if(!IsPercentage) {
        setValCancellation({ ...valCancellation, [name]: Number(PolicyDayAmount), IsPercentage: IsPercentage, IsPaymentPolicy: IsPaymentPolicy })
      }
    }
    setSave(false);
    setFormError({});
    setDuplicateError('');
  };
  const addcollection = (IsPaymentPolicy) => {
    removerError('policies-tab-0')
    setSave(false);
    setFormError({});
    if (IsPaymentPolicy) {
      let filterpayment = policies.HotelPolicyCollection?.length > 0 ? policies.HotelPolicyCollection.find((policy) => (policy.IsPaymentPolicy === valPayment?.IsPaymentPolicy && policy.IsPercentage === valPayment?.IsPercentage && policy.PolicyDay === valPayment?.PolicyDay)) : false
      if (!filterpayment) {
        setPolicies({ ...policies, HotelPolicyCollection: [...policies.HotelPolicyCollection, valPayment] });
        setValPayment({
          IsPaymentPolicy: null,
          PolicyDay: '',
          Amount: '',
          IsPercentage: null
        })
      }
      else {
        setDuplicateError('Duplicate Days Not Allowed.')
      }
    }
    else {
      let filter = policies.HotelPolicyCollection?.length > 0 ? policies.HotelPolicyCollection.find((policy) => (policy.IsPaymentPolicy === valCancellation?.IsPaymentPolicy && policy.IsPercentage === valCancellation?.IsPercentage && policy.PolicyDay === valCancellation?.PolicyDay)) : false
      if (!filter) {
        setPolicies({ ...policies, HotelPolicyCollection: [...policies.HotelPolicyCollection, valCancellation] });
        setValCancellation({
          IsPaymentPolicy: null,
          PolicyDay: '',
          Amount: '',
          IsPercentage: null
        })
      }

      else {
        setDuplicateError('Duplicate Days Not Allowed.')
      }
    }
  }

  const handleOnChange = (name, value) => {
    removerError('policies-tab-0')
    if (name === 'Is24HrCheckInOut') {
      setPolicies({
        ...policies,
        [name]: value,
        IsSpecificCheckInOut: value === true ? false : policies.IsSpecificCheckInOut,
        StartTime24Hr: value === true ? policies.StartTime24Hr : null,
        EndTime24Hr: value === true ? policies.EndTime24Hr : null,

        SpecificStartTime: value === true ? null : policies.SpecificStartTime,
        SpecificEndTime: value === true ? null : policies.SpecificEndTime,
      });
    } else if (name === 'IsSpecificCheckInOut') {
      setPolicies({
        ...policies,
        [name]: value,
        Is24HrCheckInOut: value === true ? false : policies.Is24HrCheckInOut,
        SpecificStartTime: value === true ? policies.SpecificStartTime : null,
        SpecificEndTime: value === true ? policies.SpecificEndTime : null,
        StartTime24Hr: value === true ? null : policies.StartTime24Hr,
        EndTime24Hr: value === true ? null : policies.EndTime24Hr,
      });
    } else {
      setPolicies((pre) => ({ ...pre, [name]: value }));
    }
    setSave(false);
    setFormError({});
    setDuplicateError('')
  }

  const handlePlicyAmountRemove = async (PolicyDay, IsPaymentPolicy, IsPercentage) => {
    removerError('policies-tab-0')
    const HotelPolicy = getPoliciesAmount(IsPaymentPolicy, IsPercentage, PolicyDay)
    if (IsPaymentPolicy) {
      await setPolicies({ ...policies, HotelPolicyCollection: HotelPolicy });
      deletedata(HotelPolicy);
      setValPayment({
        IsPaymentPolicy: null,
        PolicyDay: '',
        Amount: '',
        IsPercentage: null
      })
      setDeletePayment(true)
    }
    else {
      await setPolicies({ ...policies, HotelPolicyCollection: HotelPolicy });
      deletedata(HotelPolicy);
      setValCancellation({
        IsPaymentPolicy: null,
        PolicyDay: '',
        Amount: '',
        IsPercentage: null
      })
      setDeleteCancellation(true);
    }

    setDuplicateError('');
    setFormError({});
    setSave(false);
  }

  const getPoliciesAmount = (IsPaymentPolicy, IsPercentage, day) => {
    const data = policies.HotelPolicyCollection.filter((policy) => !(policy.IsPaymentPolicy === IsPaymentPolicy && policy.IsPercentage === IsPercentage && policy.PolicyDay === day))
    return data || ''
  }

  const convertTime = (time) => {
    if (time) {
      const inputDate = new Date(time);

      const hours = inputDate.getHours();
      const minutes = inputDate.getMinutes();
      const formattedTime = `1900-01-01T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

      return formattedTime
    }
    return null
  }

  const onedit = (PolicyDay, IsPaymentPolicy) => {
    removerError('policies-tab-0')
    let filterdata = policies?.HotelPolicyCollection?.find(el => el.PolicyDay === PolicyDay && el.IsPaymentPolicy === IsPaymentPolicy)
    if (IsPaymentPolicy) {
      setDeletePayment(false)
      setValPayment({
        IsPaymentPolicy: filterdata.IsPaymentPolicy,
        PolicyDay: filterdata.PolicyDay,
        Amount: filterdata.Amount,
        IsPercentage: filterdata.IsPercentage
      })
    }
    else {
      setDeleteCancellation(false)
      setValCancellation({
        IsPaymentPolicy: filterdata.IsPaymentPolicy,
        PolicyDay: filterdata.PolicyDay,
        Amount: filterdata.Amount,
        IsPercentage: filterdata.IsPercentage
      })
    }
    setFormError({});
    setDuplicateError('');
  }

  const columns = [
    {
      name: 'Days',
      sortable: true,
      selector: (data) => data.PolicyDay,
      format: (data) => data.IsPaymentPolicy ? <span>{data?.PolicyDay} day before departure, {data?.Amount} {data.IsPercentage ? '% of total package cost needs to be paid.' : 'needs to be paid.'} </span>
        : <span>{data?.PolicyDay} day before departure, cancellation charges of {data?.Amount} {data.IsPercentage ? '% of total cost will be deducted' : 'will be deducted.'} </span>,
    },
    {
      name: 'Edit',
      float: "right",
      selector: (data) => <button type='button'
        className='border-0 p-0 bg-body set_disableclr'
        disabled={disabled}>
        <i className="fa fa-edit" onClick={() => {
          onedit(data?.PolicyDay, data?.IsPaymentPolicy)
        }} />
      </button>
    }
  ];

  return (
    <div className='my-2'>
      <div className='policies_border'>
        <small className={`text-danger d-flex justify-content-center ${duplicateError ? 'visible' : 'invisible'}`}>{duplicateError ? duplicateError : 12}</small>
        <h5 className='fw-bold room_header my-3'>Cancellation Policy</h5>

        <PolicyTitleAndTabButton
          title={'You want deduction to be in'}
          ChangeTab={changeCancellationTab}
          value={cancellationTab}
          disabledforpolicy={policies?.HotelPolicyCollection?.filter(el => el.IsPaymentPolicy === false)?.length > 0 ? policies?.HotelPolicyCollection?.filter(el => el.IsPercentage === true && el.IsPaymentPolicy === false)?.length > 0 ? true : false : null}
          disabled={disabled}
        />

        <div className='row align-items-center'>
          {cancellationTab === 'Percent' ? (
            <div className='align-items-center justify-content-between my-2' key={'Percent+Cancellation'}>
              <div className='d-flex my-2 justify-content-between'>
                <div className='d-flex w-100'>
                  <TextField
                    style={{ width: '90px' }}
                    placeholder={`day`}
                    id='cancellation-Percentage-1'
                    name='PolicyDay'
                    inputProps={{ maxLength: 3 }}
                    value={valCancellation?.PolicyDay || ''}
                    onChange={(e) => { handlePoliciesOnChange(e.target.name, e.target.value, false, true) }}
                    disabled={disabled}
                  />
                  <p className='mx-2 mb-0 me-3'>day before departure, cancellation charges of</p>
                  <TextField
                    placeholder={`Type %`}
                    id='cancellation-Percentage-2'
                    name='Amount'
                    inputProps={{ maxLength: 3 }}
                    value={valCancellation?.Amount || ''}
                    onChange={(e) => { handlePoliciesOnChange(e.target.name, e.target.value, false, true) }}
                    disabled={disabled}
                  />
                  <p className='mx-2 mb-0'>% of total cost will be deducted.</p>
                </div>
                <button type='button' disabled={valCancellation?.Amount === '' || valCancellation.PolicyDay === ''} className='border-0 text-danger mx-2 bg-body d-flex' onClick={() => addcollection(false)}><i class="fa-solid fa-plus me-2"></i>Add</button>
                {disabled ?
                  <button type='button' className='border-0 bg-body'><i class="fa fa-trash text-danger" aria-hidden="true" /></button> :
                  <button type='button' disabled={deletedCancellation} className='border-0 bg-body'><i class="fa fa-trash text-danger" aria-hidden="true"
                    onClick={() => handlePlicyAmountRemove(valCancellation.PolicyDay, false, true)} /></button>
                }
              </div>
            </div>
          )
            : (
              <div className='align-items-center justify-content-between my-2' key={'fixed-amount+Cancellation'}>
                <div className='d-flex my-2 justify-content-between'>
                  <div className='d-flex w-100'>
                    <TextField
                      style={{ width: '80px' }}
                      placeholder={`day`}
                      id='cancellation-amount-1'
                      name='PolicyDay'
                      inputProps={{ maxLength: 3 }}
                      value={valCancellation?.PolicyDay || ''}
                      onChange={(e) => { handlePoliciesOnChange(e.target.name, e.target.value, false, false) }}
                      disabled={disabled}
                    />
                    <div className='mx-2 me-3'>day before departure, cancellation charges of</div>
                    <TextField
                      placeholder={`amount`}
                      id='cancellation-amount-2'
                      name='Amount'
                      inputProps={{ maxLength: 6 }}
                      value={valCancellation?.Amount || ''}
                      onChange={(e) => { handlePoliciesOnChange(e.target.name, e.target.value, false, false) }}
                      disabled={disabled}
                    />
                    <div className='mx-2'>will be deducted.</div>
                  </div>
                  <button type='button' disabled={valCancellation?.Amount === '' || valCancellation.PolicyDay === ''} className='border-0 text-danger mx-2 bg-body d-flex' onClick={() => addcollection(false)}><i class="fa-solid fa-plus me-2"></i>Add</button>
                  {disabled ?
                    <button type='button' className='border-0 bg-body'><i class="fa fa-trash text-danger" aria-hidden="true" /></button> :
                    <button type='button' disabled={deletedCancellation} className='border-0 bg-body'><i class="fa fa-trash text-danger" aria-hidden="true"
                      onClick={() => handlePlicyAmountRemove(valCancellation.PolicyDay, false, false)} /></button>
                  }
                </div>
              </div>
            )}
          <CustomTable
            className={"user_role"}
            columns={columns}
            data={policies?.HotelPolicyCollection?.filter(el => el.IsPercentage === (cancellationTab === 'Percent') && el.IsPaymentPolicy === false)}
            PdfExport={false}
            ExportToExcel={false}
            disabled={true}
          />
        </div>
      </div>

      <div className='policies_border'>
        <h5 className='fw-bold room_header my-3'>Payment Policy</h5>
        <PaymentPolicyTitleAndTabButton
          title={'You want Payment to be in'}
          ChangeTab={changePaymentTab}
          value={paymentTab}
          disabledforpayment={policies?.HotelPolicyCollection?.filter(el => el.IsPaymentPolicy === true)?.length > 0 ? policies?.HotelPolicyCollection?.filter(el => el.IsPercentage === true && el.IsPaymentPolicy === true)?.length > 0 ? true : false : null}
          disabled={disabled}
        />

        <div className='row align-items-center'>
          {paymentTab === 'Percent' ? (
            <div className='align-items-center justify-content-between my-2' key={'percent-Payment-Policy'}>
              <div className='d-flex my-2 justify-content-between'>
                <div className='d-flex w-100'>
                  <TextField
                    style={{ width: '80px' }}
                    placeholder={`day`}
                    id='cancellation-amount-1'
                    name='PolicyDay'
                    inputProps={{ maxLength: 3 }}
                    value={valPayment?.PolicyDay || ''}
                    onChange={(e) => { handlePoliciesOnChange(e.target.name, e.target.value, true, true) }}
                    disabled={disabled}
                  />
                  <p className='mx-2 mb-0 me-3'>day before departure, </p>
                  <TextField
                    placeholder={`amount`}
                    id='cancellation-amount-2'
                    name='Amount'
                    inputProps={{ maxLength: 3 }}
                    value={valPayment?.Amount || ''}
                    onChange={(e) => { handlePoliciesOnChange(e.target.name, e.target.value, true, true) }}
                    disabled={disabled}
                  />
                  <p className='mx-2 mb-0'>% of total package cost needs to be paid.</p>
                </div>
                <button type='button' disabled={valPayment?.Amount === '' || valPayment.PolicyDay === ''} className='border-0 text-danger mx-2 bg-body d-flex' onClick={() => addcollection(true)}><i class="fa-solid fa-plus me-2"></i>Add</button>
                {disabled ?
                  <button type='button' className='border-0 bg-body'><i class="fa fa-trash text-danger" aria-hidden="true" /></button> :
                  <button type='button' disabled={deletePayment} className='border-0 bg-body'><i class="fa fa-trash text-danger" aria-hidden="true"
                    onClick={() => handlePlicyAmountRemove(valPayment.PolicyDay, true, true)} /></button>
                }
              </div>
            </div>
          )
            : (
              <div className='align-items-center justify-content-between my-2' key={'fixed-amount-Payment-Policy'} >
                <div className='d-flex my-2 justify-content-between'>
                  <div className='d-flex w-100'>
                    <TextField
                      style={{ width: '80px' }}
                      placeholder={`day`}
                      id='cancellation-amount-1'
                      name='PolicyDay'
                      inputProps={{ maxLength: 3 }}
                      value={valPayment?.PolicyDay || ''}
                      onChange={(e) => { handlePoliciesOnChange(e.target.name, e.target.value, true, false) }}
                      disabled={disabled}
                    />
                    <div className='mx-2 me-3'>day before departure,</div>
                    <TextField
                      placeholder={`amount`}
                      id='cancellation-amount-2'
                      name='Amount'
                      inputProps={{ maxLength: 6 }}
                      value={valPayment?.Amount || ''}
                      onChange={(e) => { handlePoliciesOnChange(e.target.name, e.target.value, true, false) }}
                      disabled={disabled}
                    />
                    <div className='mx-2'> needs to be paid.</div>
                  </div>
                  <button type='button' disabled={valPayment?.Amount === '' || valPayment.PolicyDay === ''} className='border-0 text-danger mx-2 bg-body d-flex' onClick={() => addcollection(true)}><i class="fa-solid fa-plus me-2"></i>Add</button>
                  {disabled ?
                    <button type='button' className='border-0 bg-body'><i class="fa fa-trash text-danger" aria-hidden="true" /></button> :
                    <button type='button' disabled={deletePayment} className='border-0 bg-body'><i class="fa fa-trash text-danger" aria-hidden="true"
                      onClick={() => handlePlicyAmountRemove(valPayment.PolicyDay, true, false)} /></button>
                  }
                </div>
              </div>
            )}
          <CustomTable
            className={"user_role"}
            columns={columns}
            data={policies?.HotelPolicyCollection?.filter(el => el.IsPercentage === (paymentTab === 'Percent') && el.IsPaymentPolicy === true)}
            PdfExport={false}
            ExportToExcel={false}
            disabled={true}
          />
        </div>
      </div>

      <h5 className='fw-bold room_header my-3'>Check-in & Check-out Policies</h5>
      <div className='row mt-3 align-items-center' >
        <div className='col-6'>
          <h6 className='fw-bold room_header'>Do you have a 24 hours check-in & check-out service ?</h6>
        </div>

        <div className='col-6 d-flex'>
          <FormControlLabel className=''
            control={
              <Radio
                checked={policies?.Is24HrCheckInOut === true ? true : false}
                name='Is24HrCheckInOut'
                onChange={(e) => handleOnChange(e.target.name, true)}
                value="true"
                id='Is24HrCheckInOut-yes'
                inputProps={{ 'aria-label': 'true' }}
                disabled={disabled}
              />
            }
            label="Yes"
          />
          <FormControlLabel className=''
            control={
              <Radio
                checked={policies?.Is24HrCheckInOut === false ? true : false}
                onChange={(e) => handleOnChange(e.target.name, false)}
                value="false"
                id='Is24HrCheckInOut-no'
                name='Is24HrCheckInOut'
                inputProps={{ 'aria-label': 'false' }}
                disabled={disabled}
              />
            }
            label="No"
          />
        </div>
      </div>

      <div className='row mt-3'>
        <div className='col-3 mb-1'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <TimePicker
                id="StartTime24Hr"
                label="Start Time"
                value={policies.StartTime24Hr ? dayjs(policies.StartTime24Hr) : null}
                name='StartTime24Hr'
                onChange={(time) => {
                  handleOnChange('StartTime24Hr', convertTime(time));
                  handleOnChange('EndTime24Hr', convertTime(time))
                }}
                ampm={false}
                slotProps={{
                  textField: {
                    className: `w-100  ${policies?.Is24HrCheckInOut && !policies?.StartTime24Hr ? 'red_border' : ''}`,
                    endAdornment: <button type="button" className="border-0 bg-transparent btn pt-0">
                      <img className="pt-0 mt-0" alt="" src={require('../../../../../../../assets/img/calendar_month.png')} />
                    </button>,
                    error: formError?.StartTime24Hr ? true : false,
                    helperText: `${formError?.StartTime24Hr ? formError?.StartTime24Hr : ''}`
                  }
                }}
                disabled={!policies?.Is24HrCheckInOut || disabled}
              />
            </div>
          </LocalizationProvider>


        </div>
        <div className='col-3 mb-1'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <TimePicker
                id="EndTime24Hr"
                label="End Time"
                value={policies.StartTime24Hr ? dayjs(policies.StartTime24Hr) : null}
                name='EndTime24Hr'
                onChange={(time) => {
                  handleOnChange('StartTime24Hr', convertTime(time));
                  handleOnChange('EndTime24Hr', convertTime(time))
                }}
                ampm={false}
                slotProps={{
                  textField: {
                    className: `w-100  ${policies?.Is24HrCheckInOut && !policies?.StartTime24Hr ? 'red_border' : ''}`,
                    endAdornment: <button type="button" className="border-0 bg-transparent btn pt-0">
                      <img className="pt-0 mt-0" alt="" src={require('../../../../../../../assets/img/calendar_month.png')} />
                    </button>,
                    error: formError?.EndTime24Hr ? true : false,
                    helperText: `${formError?.EndTime24Hr ? formError?.EndTime24Hr : ''}`
                  }
                }}
                disabled={true}
              />
            </div>
          </LocalizationProvider>
        </div>
      </div>

      <div className='row mt-3 align-items-center' >
        <div className='col-6'>
          <h6 className='fw-bold room_header'>Do you have a specific check-in & check-out service?</h6>
        </div>
        <div className='col-6 d-flex'>
          <FormControlLabel className=''
            control={
              <Radio
                id='IsSpecificCheckInOut-yes'
                name='IsSpecificCheckInOut'
                value="true"
                checked={policies?.IsSpecificCheckInOut === true}
                onChange={(e) => handleOnChange(e.target.name, true)}
                inputProps={{ 'aria-label': 'true' }}
                disabled={disabled}
              />
            }
            label="Yes"
          />
          <FormControlLabel className=''
            control={
              <Radio
                id='IsSpecificCheckInOut-no'
                name='IsSpecificCheckInOut'
                value="false"
                checked={policies?.IsSpecificCheckInOut === false ? true : false}
                onChange={(e) => handleOnChange(e.target.name, false)}
                inputProps={{ 'aria-label': 'false' }}
                disabled={disabled}
              />
            }
            label="No"
          />
        </div>
      </div>

      <div className='row mt-3'>
        <div className='col-3 mb-1'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              {/* 24-hour TimePicker with filled variant */}
              <TimePicker
                id="SpecificStartTime"
                label="Start Time"
                name='SpecificStartTime'
                value={policies.SpecificStartTime ? dayjs(policies.SpecificStartTime) : null}
                onChange={(time) => {
                  console.log(time, "time")
                  handleOnChange('SpecificStartTime', convertTime(time))
                }}
                ampm={false}
                slotProps={{
                  textField: {
                    className: `w-100  ${policies?.IsSpecificCheckInOut && !policies?.SpecificStartTime ? 'red_border' : ''}`,
                    endAdornment: <button type="button" className="border-0 bg-transparent btn pt-0">
                      <img className="pt-0 mt-0" alt="" src={require('../../../../../../../assets/img/calendar_month.png')} />
                    </button>,
                    error: formError?.SpecificStartTime ? true : false,
                    helperText: `${formError?.SpecificStartTime ? formError?.SpecificStartTime : ''}`
                  }
                }}
                disabled={!policies?.IsSpecificCheckInOut || disabled}
              />
            </div>
          </LocalizationProvider>
        </div>
        <div className='col-3 mb-1'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              {/* 24-hour TimePicker with filled variant */}
              <TimePicker
                id="SpecificEndTime"
                label="End Time"
                name='SpecificEndTime'
                value={policies.SpecificEndTime ? dayjs(policies.SpecificEndTime) : null}
                onChange={(time) => handleOnChange('SpecificEndTime', convertTime(time))}
                ampm={false}
                slotProps={{
                  textField: {
                    className: `w-100  ${policies?.IsSpecificCheckInOut && !policies?.SpecificEndTime ? 'red_border' : ''}`,
                    endAdornment: <button type="button" className="border-0 bg-transparent btn pt-0">
                      <img className="pt-0 mt-0" alt="" src={require('../../../../../../../assets/img/calendar_month.png')} />
                    </button>,
                    error: formError?.SpecificEndTime ? true : false,
                    helperText: `${formError?.SpecificEndTime ? formError?.SpecificEndTime : ''}`
                  }
                }}
                disabled={!policies?.IsSpecificCheckInOut || disabled}
              />
            </div>
          </LocalizationProvider>
        </div>
      </div>
    </div>
  )
}

export default Policies