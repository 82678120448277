import React from 'react';
import { useEffect, useState } from "react";
import "../../../../../assets/css/master.css";
import "../Hotelmaster.css"
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../../../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { isValidEmail, NumericWithDecimal, onlyNumeric, PhoneNmber, isValidImage, isValidVideo, onlyAlphabets, isValidPhonenumber } from '../../../../commonClass/CommonValidation';
import { ImageDelete, ImageUpload } from '../../../../../ImageUpload/ImageCommon';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../../../../commonClass/Session';
import { Rating } from 'react-simple-star-rating';
import { useDispatch } from "react-redux";
import { loaderActions } from "../../../../../_actions/loader.actions";
import { ConfirmationModal } from '../../../../../customControl/ConfirmationModal';
import CustomSelection from '../../../../../customControl/CustomSelection';
import { Box, FormControl, InputLabel, MenuItem, Radio, Select, Tab, Tabs, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import BasicFacility from './HotelAmenities/BasicFacility';
import CommonAreas from './HotelAmenities/CommonAreas';
import StaffFacilty from './HotelAmenities/StaffFacilty';
import OutdoorActivitiesandSports from './HotelAmenities/OutdoorActivitiesandSports';
import FoodandDrinks from './HotelAmenities/FoodandDrinks';
import HealthandWellness from './HotelAmenities/HealthandWellness';
import BusinessCenterandConference from './HotelAmenities/BusinessCenterandConference';
import BeautyandSpa from './HotelAmenities/BeautyandSpa';
import Security from './HotelAmenities/Security';
import TransferFacility from './HotelAmenities/TransferFacility';
import Shopping from './HotelAmenities/Shopping';
import Entertainment from './HotelAmenities/Entertainment';
import Others from './HotelAmenities/Others';

const HotelAmenities = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    let type = localStorage.getItem('type')
    const [value, setValue] = useState(0);
    const [save, setSave] = useState(false);
    const [next, setNext] = useState(true);
    const [getData, setGetData] = useState([])
    const [formError, setFormError] = useState([])
    const [checkval, setcheckVal] = useState({
        HotelAmenities: [],
        HotelAmenitiesoption: []
    })
    const [formValue, setFormValue] = useState({
        HotelBasicId: 0,
        UserId: 0,
        HotelAmenitiesCollection: []
    });

    //#region tabPanle
    const a11yProps = (index) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    //#region Back
    const handleBack = () => {
        navigate(`/HotelListing/${type}/location`, {
            state: {
                disabled: location.state?.disabled,
                editdisabled: location?.state?.editdisabled,
                viewtype: location?.state?.viewtype,
                HotelId: location?.state?.HotelId
            }
        })
    }

    //#region Next
    const handleNext = () => {
        navigate(`/HotelListing/${type}/rooms`, {
            state: {
                disabled: location.state?.disabled,
                editdisabled: location?.state?.editdisabled,
                viewtype: location?.state?.viewtype,
                HotelId: location?.state?.HotelId
            }
        })
    }

    //#region onChange
    const hendlechekbox = (amenitiesId, option, mark, tabid) => {
        removerError(tabid)
        setFormError([])
        setSave(false)
        if (option?.length > 0) {
            if (checkval?.HotelAmenities?.find((obj) => obj?.AmenitiesId === amenitiesId)) {
                setcheckVal({ ...checkval, HotelAmenities: checkval?.HotelAmenities?.filter((obj) => obj?.AmenitiesId !== amenitiesId), HotelAmenitiesoption: checkval?.HotelAmenitiesoption?.filter((obj) => obj?.AmenitiesId !== amenitiesId) });
                setFormValue({ ...formValue, HotelAmenitiesCollection: formValue?.HotelAmenitiesCollection?.filter((obj) => obj?.AmenitiesId !== amenitiesId) });
            }
            else {
                let newElement = {
                    AmenitiesId: amenitiesId,
                    tabid: tabid
                }
                setcheckVal({ ...checkval, HotelAmenities: [...checkval?.HotelAmenities, newElement] });
            }
        }
        else {
            if (checkval?.HotelAmenities?.find((obj) => obj?.AmenitiesId === amenitiesId)) {

                let check = checkval?.HotelAmenities.filter((obj) => obj?.AmenitiesId !== amenitiesId)
                let op = checkval?.HotelAmenitiesoption?.filter((obj) => obj?.AmenitiesId !== amenitiesId)
                setcheckVal({ ...checkval, HotelAmenities: check, HotelAmenitiesoption: op });
                let coll = formValue?.HotelAmenitiesCollection?.filter((obj) => obj?.AmenitiesId !== amenitiesId)
                setFormValue({ ...formValue, HotelAmenitiesCollection: coll });
            }
            else {
                let newElement = {
                    AmenitiesId: amenitiesId,
                    tabid: tabid
                }
                setcheckVal({ ...checkval, HotelAmenities: [...checkval?.HotelAmenities, newElement] });
                if (option?.length === 0 && mark !== 'remark') {
                    let newElementcol = {
                        AmenitiesId: amenitiesId,
                        AmenitiesOptionsId: null
                    }
                    setFormValue({ ...formValue, HotelAmenitiesCollection: [...formValue?.HotelAmenitiesCollection, newElementcol] });
                }
            }
        }

    }

    const onChange = (amenitiesId, value, tabid) => {
        removerError(tabid)
        setFormError([])
        setSave(false)
        if (value !== null) {
            if (checkval?.HotelAmenitiesoption?.find((obj) => obj?.AmenitiesId === amenitiesId)) {
                let option = {
                    AmenitiesId: amenitiesId,
                    Optionscollection: null,
                    remark: null,
                    tags: value
                }
                let tremp = checkval?.HotelAmenitiesoption?.filter((obj) => obj?.AmenitiesId !== amenitiesId)
                if (value !== null && value?.length !== 0) {
                    tremp = [...tremp, option]
                }
                setcheckVal({ ...checkval, HotelAmenitiesoption: tremp })
                let fit = formValue?.HotelAmenitiesCollection?.filter((obj) => obj?.AmenitiesId !== amenitiesId)

                let newElement = {
                    AmenitiesId: amenitiesId,
                    AmenitiesOptionsId: null,
                    Remarks: null,
                    Tags: value
                }
                if (value !== null && value?.length !== 0) {
                    fit = [...fit, newElement]
                }
                setFormValue({ ...formValue, HotelAmenitiesCollection: fit });
            }
            else {
                let option = {
                    AmenitiesId: amenitiesId,
                    Optionscollection: null,
                    remark: null,
                    tags: value
                }
                if (value !== null && value?.length !== 0) {
                    setcheckVal({ ...checkval, HotelAmenitiesoption: [...checkval?.HotelAmenitiesoption, option] })
                }

                let newElement = {
                    AmenitiesId: amenitiesId,
                    AmenitiesOptionsId: null,
                    Remarks: null,
                    Tags: value
                }
                if (value !== null && value?.length !== 0) {
                    setFormValue({ ...formValue, HotelAmenitiesCollection: [...formValue?.HotelAmenitiesCollection, newElement] });
                }
            }
        }

    }

    const onChangedropdown = (amenitiesId, value, tabid) => {
        removerError(tabid)
        setFormError([])
        setSave(false)
        if (value !== null) {
            if (checkval?.HotelAmenitiesoption?.find((obj) => obj?.AmenitiesId === amenitiesId)) {
                let option = {
                    AmenitiesId: amenitiesId,
                    Optionscollection: value
                }
                checkval.HotelAmenitiesoption = checkval?.HotelAmenitiesoption?.filter((obj) => obj?.AmenitiesId !== amenitiesId)
                if (value !== null && value?.length !== 0) {
                    setcheckVal({ ...checkval, HotelAmenitiesoption: [...checkval?.HotelAmenitiesoption, option] })
                }
                else {
                    setcheckVal({ ...checkval, HotelAmenitiesoption: checkval?.HotelAmenitiesoption?.filter((obj) => obj?.AmenitiesId !== amenitiesId) })
                }
            }
            else {
                let option = {
                    AmenitiesId: amenitiesId,
                    Optionscollection: value
                }
                setcheckVal({ ...checkval, HotelAmenitiesoption: [...checkval?.HotelAmenitiesoption, option] })
                if (value?.length > 0) {
                    value?.map((val) => {
                        let newElement = {
                            AmenitiesId: amenitiesId,
                            AmenitiesOptionsId: val?.optionID
                        }
                        setFormValue({ ...formValue, HotelAmenitiesCollection: [...formValue?.HotelAmenitiesCollection, newElement] });
                    })
                }
                else {
                    let newElement = {
                        AmenitiesId: amenitiesId,
                        AmenitiesOptionsId: value?.optionID
                    }
                    setFormValue({ ...formValue, HotelAmenitiesCollection: [...formValue?.HotelAmenitiesCollection, newElement] });
                }
            }
            if (formValue?.HotelAmenitiesCollection?.find((obj) => obj?.AmenitiesId === amenitiesId)) {
                let fit = formValue?.HotelAmenitiesCollection?.filter((obj) => obj?.AmenitiesId !== amenitiesId)
                if (value !== null && value?.length > 0) {
                    value?.map((val) => {
                        let newElement = {
                            AmenitiesId: amenitiesId,
                            AmenitiesOptionsId: val?.optionID
                        }
                        fit = [...fit, newElement]
                    })
                }
                else if (value !== null && value?.optionID) {
                    let newElement = {
                        AmenitiesId: amenitiesId,
                        AmenitiesOptionsId: value?.optionID
                    }
                    fit = [...fit, newElement]
                }
                setFormValue({ ...formValue, HotelAmenitiesCollection: fit });
            }
        }
        else {
            let op = checkval?.HotelAmenitiesoption?.filter((obj) => obj?.AmenitiesId !== amenitiesId)
            setcheckVal({ ...checkval, HotelAmenitiesoption: op })
            let fit = formValue?.HotelAmenitiesCollection?.filter((obj) => obj?.AmenitiesId !== amenitiesId)
            setFormValue({ ...formValue, HotelAmenitiesCollection: fit });
        }
    }


    //#region api
    const getAllAmenities = async (hotelid) => {
        dispatch(loaderActions.start())
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const data = await getAllData('/master/v1/getallamenities')
            if (data?.status) {
                setGetData(data?.data)
            }
            else {
                dispatch({ type: 'ALERTING', message: data?.message })
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

        dispatch(loaderActions.end())
    }

    const validateData = () => {
        const validateobj = checkval?.HotelAmenities?.filter((op) => {
            return !formValue?.HotelAmenitiesCollection?.some((amenity) => amenity?.AmenitiesId === op?.AmenitiesId)
        })
        let status = true;
        let error = {};
        const icons = document.querySelectorAll('.fa-triangle-exclamation');
        if (icons) {
            icons?.forEach(icon => {
                icon?.remove();
            });
        }
        if (validateobj?.length > 0) {
            error = validateobj;
            status = false;
            const groupedByTabId = validateobj?.reduce((acc, item) => {
                if (!acc[item?.tabid]) {
                    acc[item?.tabid] = [];
                }
                acc[item?.tabid]?.push(item);
                return acc;
            }, {});
            Object.keys(groupedByTabId)?.map((el) => {
                const buttons = document?.querySelectorAll(`#${el}`);
                buttons?.forEach(button => {
                    const icon = document?.createElement('i');
                    icon.className = 'fa-solid fa-triangle-exclamation ms-2 text-danger';
                    button?.appendChild(icon);
                });
            })
        }
        setFormError(error)
        return status;
    }
    const handleSave = async (e) => {
        e.preventDefault();
        if (validateData()) {
            let statusoftoken = await verifytoken();
            if (statusoftoken) {
                dispatch(loaderActions.start())
                const data = await postUsersData('/master/v1/inserthotelamenities', formValue);
                if (data?.status) {
                    setFormError([])
                    setNext(false)
                    setSave(true)
                    dispatch({ type: 'ALERTING', message: data?.message })
                    sessionStorage.setItem('latestStep', sessionStorage?.getItem('latestStep') >= "2" ? Number(sessionStorage?.getItem('latestStep')) : 2);
                } else {
                    dispatch({ type: 'ALERTING', message: data?.message })
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        dispatch(loaderActions.end())

    }

    const getdatabyid = async (e) => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            dispatch(loaderActions.start())
            const user = GetLocalStorage()
            formValue.UserId = user?.userMasterID
            formValue.HotelBasicId = location?.state?.HotelId
            const data = await getAllData(`/master/v1/gethotelamenitiesbyhotelbasicid?hotelBasicId=${location?.state?.HotelId}`)
            if (data?.status) {
                let cloneCheckVal = { ...checkval }
                let cloneform = { ...formValue }
                data?.data?.map((set1, index) => {
                    set1?.categoryAmenities?.map((set2) => {
                        if (set2?.isAmenitySelected) {
                            setSave(true)
                            setNext(false)
                            let newElement = {
                                AmenitiesId: set2?.amenitiesId,
                                tabid: `vertical-tab-${index}`
                            }
                            cloneCheckVal = { ...cloneCheckVal, HotelAmenities: [...cloneCheckVal?.HotelAmenities, newElement] }
                            let obg = set2?.options?.filter(ten => ten?.isOptionSelected === true)
                            if (set2?.isMultipleSelection) {
                                if (obg?.length > 0) {
                                    let option = {
                                        AmenitiesId: set2?.amenitiesId,
                                        Optionscollection: obg
                                    }
                                    cloneCheckVal = { ...cloneCheckVal, HotelAmenitiesoption: [...cloneCheckVal?.HotelAmenitiesoption, option] }
                                    obg?.map((op) => {
                                        let collection = {
                                            AmenitiesId: set2?.amenitiesId,
                                            AmenitiesOptionsId: op?.optionID
                                        }
                                        cloneform = { ...cloneform, HotelAmenitiesCollection: [...cloneform?.HotelAmenitiesCollection, collection] }
                                    })
                                }
                            }
                            else {
                                if (obg?.length < 2 && obg?.length > 0) {
                                    let option = {
                                        AmenitiesId: set2?.amenitiesId,
                                        Optionscollection: obg?.[0]
                                    }
                                    cloneCheckVal = { ...cloneCheckVal, HotelAmenitiesoption: [...cloneCheckVal?.HotelAmenitiesoption, option] }
                                    obg?.map((op) => {
                                        let collection = {
                                            AmenitiesId: set2?.amenitiesId,
                                            AmenitiesOptionsId: op?.optionID
                                        }
                                        cloneform = { ...cloneform, HotelAmenitiesCollection: [...cloneform?.HotelAmenitiesCollection, collection] }
                                    })
                                }
                                else {
                                    let option = {
                                        AmenitiesId: set2?.amenitiesId,
                                        Optionscollection: null,
                                        remark: set2?.remarks,
                                        tags: set2?.tags,
                                    }
                                    cloneCheckVal = { ...cloneCheckVal, HotelAmenitiesoption: [...cloneCheckVal?.HotelAmenitiesoption, option] }
                                    // obg?.map((op) => {
                                    let collection = {
                                        AmenitiesId: set2?.amenitiesId,
                                        AmenitiesOptionsId: null,
                                        Remarks: set2?.remarks,
                                        Tags: set2?.tags,
                                    }
                                    cloneform = { ...cloneform, HotelAmenitiesCollection: [...cloneform?.HotelAmenitiesCollection, collection] }
                                    // })
                                }
                            }
                        }
                    })

                })
                setcheckVal(cloneCheckVal);
                setFormValue(cloneform)
            }
            else {
                dispatch({ type: 'ALERTING', message: data?.message })
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
        dispatch(loaderActions.end())

    }
    const removerError = (tabid) => {
        if (tabid) {
            const buttons = document?.querySelectorAll(`#${tabid}`);
            buttons?.forEach(button => {
                const icons = button?.querySelectorAll('.fa-triangle-exclamation');
                icons?.forEach(icon => {
                    icon?.remove();
                });
            })
        }
    }


    useEffect(() => {
        if (location?.state !== null) {
            if (location?.state?.HotelId) {
                getdatabyid(location?.state?.HotelId).then(() => {
                    getAllAmenities(location?.state?.HotelId)
                })
            }
        }
        else {
            navigate(-1)
        }
    }, [])

    return (
        <>

            <div className="container-fluid mt-2 heading_color input_mui">
                <div className='row'>
                    <div className='col-lg-2 col-3'>
                        <Box className='Hotel_listing'
                            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
                        >
                            <Tabs
                                className='w-100'
                                orientation="vertical"
                                value={value}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: 'divider' }}
                            >
                                <Tab label="Basic Facility" iconPosition="end" onClick={() => setValue(0)} {...a11yProps(0)} />
                                <Tab label="Common Areas" iconPosition="end" onClick={() => setValue(1)} {...a11yProps(1)} />
                                <Tab label="Staff Facilty" iconPosition="end" onClick={() => setValue(2)} {...a11yProps(2)} />
                                <Tab label="Outdoor Activities & Sports" iconPosition="end" onClick={() => setValue(3)} {...a11yProps(3)} />
                                <Tab label="Food & Drinks" iconPosition="end" onClick={() => setValue(4)} {...a11yProps(4)} />
                                <Tab label="Health & Wellness" iconPosition="end" onClick={() => setValue(5)} {...a11yProps(5)} />
                                <Tab label="Business Center & Conference" iconPosition="end" onClick={() => setValue(6)} {...a11yProps(6)} />
                                <Tab label="Beauty & Spa" iconPosition="end" onClick={() => setValue(7)} {...a11yProps(7)} />
                                <Tab label="Security" iconPosition="end" onClick={() => setValue(8)} {...a11yProps(8)} />
                                <Tab label="Transfer Facility" iconPosition="end" onClick={() => setValue(9)} {...a11yProps(9)} />
                                <Tab label="Shopping" iconPosition="end" onClick={() => setValue(10)} {...a11yProps(10)} />
                                <Tab label="Entertainment" iconPosition="end" onClick={() => setValue(11)} {...a11yProps(11)} />
                                <Tab label="Others" iconPosition="end" onClick={() => setValue(12)} {...a11yProps(12)} />
                            </Tabs>
                        </Box>
                    </div>
                    <div className='col-lg-10 col-9'>
                        <div className='row'>
                            <div className='col-7 d-flex'>
                                <h3><button type='button' className='border-0 bg-body' onClick={handleBack}>
                                    <i className="fa-solid fa-angle-left me-2"></i></button>Amenities
                                </h3>
                                <div className='position-relative'>
                                    <i class="fa-solid fa-circle-info mx-2" ></i>
                                    <span className='popmes'><small>Kindly fill all available amenities with you in this section before moving forward</small></span>
                                </div>
                            </div>
                            <div className='col-5 text-end'>
                                <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" onClick={(e) => handleSave(e)} disabled={save || location?.state?.disabled} id="btnAdd" >Save</button>
                                {sessionStorage?.getItem('latestStep') !== '8' && <button disabled={next} onClick={handleNext} type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd" >Next</button>}
                            </div>
                        </div>
                        <form action="" className='py-3 my-1'>
                            <div className={`${value === 0 ? '' : 'd-none'}`} value={value} index={0}>
                                <BasicFacility getData={getData[0]} hendlechekbox={hendlechekbox} onChangedropdown={onChangedropdown} checkval={checkval} formValue={formValue} formError={formError} disabled={location?.state?.disabled} />
                            </div>
                            <div className={`${value === 1 ? '' : 'd-none'}`} value={value} index={1}>
                                <CommonAreas getData={getData[1]} hendlechekbox={hendlechekbox} onChangedropdown={onChangedropdown} checkval={checkval} formValue={formValue} formError={formError} disabled={location?.state?.disabled} />
                            </div>
                            <div className={`${value === 2 ? '' : 'd-none'}`} value={value} index={2}>
                                <StaffFacilty getData={getData[2]} hendlechekbox={hendlechekbox} onChangedropdown={onChangedropdown} checkval={checkval} formValue={formValue} formError={formError} disabled={location?.state?.disabled} />
                            </div>
                            <div className={`${value === 3 ? '' : 'd-none'}`} value={value} index={3}>
                                <OutdoorActivitiesandSports getData={getData[3]} hendlechekbox={hendlechekbox} onChangedropdown={onChangedropdown} checkval={checkval} formValue={formValue} formError={formError} disabled={location?.state?.disabled} />
                            </div>
                            <div className={`${value === 4 ? '' : 'd-none'}`} value={value} index={4}>
                                <FoodandDrinks getData={getData[4]} hendlechekbox={hendlechekbox} onChangedropdown={onChangedropdown} checkval={checkval} formValue={formValue} formError={formError} disabled={location?.state?.disabled} />
                            </div>
                            <div className={`${value === 5 ? '' : 'd-none'}`} value={value} index={5}>
                                <HealthandWellness getData={getData[5]} hendlechekbox={hendlechekbox} onChangedropdown={onChangedropdown} checkval={checkval} formValue={formValue} formError={formError} disabled={location?.state?.disabled} />
                            </div>
                            <div className={`${value === 6 ? '' : 'd-none'}`} value={value} index={6}>
                                <BusinessCenterandConference getData={getData[6]} hendlechekbox={hendlechekbox} onChangedropdown={onChangedropdown} checkval={checkval} formValue={formValue} formError={formError} disabled={location?.state?.disabled} />
                            </div>
                            <div className={`${value === 7 ? '' : 'd-none'}`} value={value} index={7}>
                                <BeautyandSpa getData={getData[7]} hendlechekbox={hendlechekbox} onChangedropdown={onChangedropdown} checkval={checkval} formValue={formValue} formError={formError} disabled={location?.state?.disabled} />
                            </div>
                            <div className={`${value === 8 ? '' : 'd-none'}`} value={value} index={8}>
                                <Security getData={getData[8]} hendlechekbox={hendlechekbox} onChangedropdown={onChangedropdown} checkval={checkval} formValue={formValue} formError={formError} disabled={location?.state?.disabled} />
                            </div>
                            <div className={`${value === 9 ? '' : 'd-none'}`} value={value} index={9}>
                                <TransferFacility getData={getData[9]} hendlechekbox={hendlechekbox} onChangedropdown={onChangedropdown} checkval={checkval} formValue={formValue} formError={formError} disabled={location?.state?.disabled} />
                            </div>
                            <div className={`${value === 10 ? '' : 'd-none'}`} value={value} index={10}>
                                <Shopping getData={getData[10]} hendlechekbox={hendlechekbox} onChangedropdown={onChangedropdown} checkval={checkval} formValue={formValue} formError={formError} disabled={location?.state?.disabled} />
                            </div>
                            <div className={`${value === 11 ? '' : 'd-none'}`} value={value} index={11}>
                                <Entertainment getData={getData[11]} hendlechekbox={hendlechekbox} onChangedropdown={onChangedropdown} checkval={checkval} formValue={formValue} formError={formError} disabled={location?.state?.disabled} />
                            </div>
                            <div className={`${value === 12 ? '' : 'd-none'}`} value={value} index={12}>
                                <Others getData={getData[12]} hendlechekbox={hendlechekbox} onChangedropdown={onChangedropdown} checkval={checkval} formValue={formValue} onChange={onChange} formError={formError} disabled={location?.state?.disabled} setFormError={setFormError} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HotelAmenities