import React, { useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../../_actions/loader.actions';
import { postUsersData, verifytoken } from '../../../services/ApiCallingUserServices';
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session';
import { updatebooking } from '../../../_actions/user.action';
import CustomTable from '../../../customControl/CustomTable';

const BookingTable = (props) => {
    const { activeTab, listData, setSearchDropdownList, userRights } = props;
    const dispatch = useDispatch();

    const columns = [
        {
            name: 'Sr. No',
            value: 'SrNo',
            sortable: true,
            reorder: true,
            maxWidth: '50px',
            minWidth: '50px',
            selector: (data) => data?.SrNo,
            format: (data) => <div title={data?.SrNo} >{data?.SrNo} </div>,
        },
        {
            name: 'Booking ID',
            value: 'BookingNo',
            sortable: true,
            reorder: true,
            selector: (data) => data?.BookingNo,
            format: (data) => <div title={data?.BookingNo} >{data?.BookingNo} </div>,
        },
        {
            name: 'Product',
            value: 'Product',
            sortable: true,
            reorder: true,
            selector: (data) => data?.Product,
            format: (data) => <div title={data?.Product} >{data?.Product} </div>,
        },
        {
            name: 'Name',
            value: 'Name',
            sortable: true,
            reorder: true,
            minWidth: '150px',
            selector: (data) => data?.Name,
            format: (data) => <div title={data?.Name} >{data?.Name} </div>,
        },
        {
            name: 'Contact Number',
            value: 'MobileNo',
            sortable: true,
            reorder: true,
            minWidth: '150px',
            selector: (data) => data?.MobileNo,
            format: (data) => <div title={data?.MobileNo} >{data?.MobileNo} </div>,
        },
        {
            name: 'Email ID',
            value: 'EmailId',
            sortable: true,
            reorder: true,
            minWidth: '250px',
            selector: (data) => data?.EmailId,
            format: (data) => <div title={data?.EmailId} >{data?.EmailId} </div>,
        },
        {
            name: 'Payment Status',
            value: 'PaymentStatus',
            sortable: true,
            reorder: true,
            minWidth: '150px',
            selector: (data) => data?.PaymentStatus,
            format: (data) => <div title={data?.PaymentStatus} >{data?.PaymentStatus} </div>,
        },
        {
            name: 'No. Of pax/traveler/Guest',
            value: 'NoOfTraveller',
            sortable: true,
            reorder: true,
            minWidth: '50px',
            maxWidth: '50px',
            selector: (data) => data?.NoOfTraveller,
            format: (data) => <div title={data?.NoOfTraveller} >{data?.NoOfTraveller} </div>,
        },
        {
            name: 'Booking Date',
            value: 'CreatedDateformat',
            sortable: true,
            reorder: true,
            minWidth: '150px',
            selector: (data) => data?.CreatedDateformat,
            format: (data) => <div title={data?.CreatedDateformat} >{data?.CreatedDateformat} </div>,
        },
        {
            name: 'Journey Start date',
            value: 'StartDateformat',
            sortable: true,
            reorder: true,
            minWidth: '180px',
            selector: (data) => data?.StartDateformat,
            format: (data) => <div title={data?.StartDateformat} >{data?.StartDateformat} </div>,
        },
        {
            name: 'Journey End Date',
            value: 'EndDateformat',
            sortable: true,
            reorder: true,
            minWidth: '180px',
            selector: (data) => data?.EndDateformat,
            format: (data) => <div title={data?.EndDateformat} >{data?.EndDateformat} </div>,
        },
        {
            name: activeTab === 'Upcoming' ? 'Booking Status' : 'Cancellation Status',
            value: 'StatusName',
            sortable: true,
            reorder: true,
            selector: (data) => data?.StatusName,
            format: (data) =>
                <div className="d-flex bookingStatus " style={{ height: '60px' }} >
                    <button
                        type='button'
                        className={`btn border status ${activeTab} ${getBookingStatus(data?.BookingCondition)}`}
                        style={{ opacity: 1 }}
                        title={data?.StatusName}
                        onClick={() => handleApprove(data?.BookingId)}
                        disabled={data?.BookingCondition === 0 || (!userRights?.URightAdd && !userRights?.URightEdit)}
                    >
                        {data?.StatusName}
                    </button>
                    {/* <button
                        type='button'
                        className={`btn border status red-background ms-2`}
                        style={{ opacity: 1 }}
                        title={data?.StatusName}
                        onClick={() => handleApprove(data?.BookingId)}
                        disabled={data?.BookingCondition === 0 || (!userRights?.URightAdd && !userRights?.URightEdit)}
                    >
                        X
                    </button> */}
                </div>
            ,
            omit: activeTab === 'Ongoing' || activeTab === "Completed",
        },
    ]

    useEffect(() => {
        setSearchDropdownList(columns?.filter((obj) => !obj?.omit))
    }, [activeTab])



    const getBookingStatus = (status) => {
        switch (status) {
            case 0: return 'green-background'
            case 1: return 'yellow-background'
            default: return ''
        }
    }

    const handleApprove = async (bookingid) => {
        const statusoftoken = await verifytoken();
        if (statusoftoken) {
            const userdata = GetLocalStorage()
            dispatch(loaderActions.start());
            let data = {
                BookingStatus: activeTab === 'Upcoming' ? 2 : 5,
                BookingId: bookingid,
                UserId: userdata?.userMasterID
            }
            const updatebookingstatus = await postUsersData('/master/v1/updatebookingstatus', data)
            if (updatebookingstatus?.status) {
                dispatch(updatebooking())
            }
            dispatch(loaderActions.end());
        }
        else {
            dispatch(loaderActions.end());
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' });
        }

    }

    return (
        <div>
            <CustomTable
                className={`bookings ${activeTab}`}
                columns={columns}
                data={listData}
            />
        </div>
    )
}

export default BookingTable
