import React, { memo, useEffect, useMemo } from 'react';
import { useState } from "react";
import "../../../../../../../assets/css/master.css";
import "../../../Hotelmaster.css"
import { Autocomplete, Box, Checkbox, FormControl, FormControlLabel, Tab, Tabs, TextField } from '@mui/material';
import { onlyAlphabets } from '../../../../../../commonClass/CommonValidation';


const RoomAmenities = ({ roomAmenities, formValue, setFormValue, formError, setFormError, disabled }) => {
    const [value, setValue] = useState('Room Inclusion');
    const [tags, settags] = useState('');
    const [tagError, setTagError] = useState('')

    const othersData = useMemo(() => roomAmenities?.find((amenities) => amenities.roomCategoryName === "Others")?.categoryOptions
        , [roomAmenities])

    const a11yProps = (index) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    const handleRoomAmenitiesCheckBox = (HotelRoomAmenitiesId, type, value, isOption = false, tabid) => {
        removerError(tabid)
        if (type === 'checkbox') {
            if (value) {
                setFormValue((previous) => ({
                    ...previous,
                    HotelRoomAmenitiesCollection: isOption ? [...previous?.HotelRoomAmenitiesCollection, { HotelRoomAmenitiesId, HotelRoomAmenitiesOptionsId: null, tabid: tabid }]
                        : [...previous?.HotelRoomAmenitiesCollection, { HotelRoomAmenitiesId, tabid: tabid }]

                }))
            } else {
                setFormValue(
                    (previous) => (
                        {
                            ...previous, HotelRoomAmenitiesCollection: previous?.HotelRoomAmenitiesCollection.filter(
                                (val) => val.HotelRoomAmenitiesId !== HotelRoomAmenitiesId)
                        })
                )
            }
        }
        setFormError({})
    }

    const handleAreaAndDropDown = (HotelRoomAmenitiesId, type, value, tabid) => {
        removerError(tabid)
        if (type === 'dropdown') {
            setFormValue(
                (previous) => (
                    {
                        ...previous,
                        HotelRoomAmenitiesCollection: previous?.HotelRoomAmenitiesCollection.map(
                            (val) => val.HotelRoomAmenitiesId === HotelRoomAmenitiesId ? ({ ...val, HotelRoomAmenitiesOptionsId: value }) : val)
                    }
                )
            )

        } else if (type === 'textarea') {
            setFormValue(
                (previous) => (
                    {
                        ...previous,
                        HotelRoomAmenitiesCollection: previous?.HotelRoomAmenitiesCollection.map(
                            (val) => val.HotelRoomAmenitiesId === HotelRoomAmenitiesId ? ({ ...val, Remarks: null, Tags: value }) : val)
                    }
                )
            )
        }
        setFormError({})
    }

    const handleMultiSelector = (amenitiesId, type, selectedOption, tabid) => {
        removerError(tabid)
        let cloneAmenities = [...formValue.HotelRoomAmenitiesCollection]

        cloneAmenities = cloneAmenities.filter((am) => am.HotelRoomAmenitiesId !== amenitiesId);
        if (selectedOption.length > 0) {
            let newMultiSelect = selectedOption.map((selectedOption) => ({ HotelRoomAmenitiesId: amenitiesId, HotelRoomAmenitiesOptionsId: selectedOption.optionID }));
            setFormValue({ ...formValue, HotelRoomAmenitiesCollection: [...cloneAmenities, ...newMultiSelect] })
        } else {
            setFormValue({
                ...formValue,
                HotelRoomAmenitiesCollection: [...cloneAmenities, { HotelRoomAmenitiesId: amenitiesId, HotelRoomAmenitiesOptionsId: null, tabid: tabid }]
            })
        }
        setFormError({})
    }

    const getSelectedOption = (options, roomAmenitiesId, type = 'select') => {

        let data;
        const selectedOption = formValue.HotelRoomAmenitiesCollection.find((am) => am.HotelRoomAmenitiesId === roomAmenitiesId)
        if (type === 'select') {
            data = options?.find((op) => op.optionID === selectedOption?.HotelRoomAmenitiesOptionsId)
        } else {
            data = selectedOption
        }

        return data || null
    }

    const getMultiSelectedOption = (amenitiesID, Options) => {
        const selectedOption = formValue.HotelRoomAmenitiesCollection.reduce((acc, selectedOption) => {
            if (selectedOption?.HotelRoomAmenitiesId === amenitiesID) {
                Options.forEach(op => {
                    if (selectedOption.HotelRoomAmenitiesOptionsId === op.optionID) {
                        acc = [...acc, { optionID: op.optionID, optionName: op.optionName, isOptionSelected: true }]
                    }
                });
            }
            return acc
        }, []);
        return selectedOption.length > 0 ? selectedOption : []
    }

    const getOptions = (options) => {
        let option = [];
        options.forEach((op) => {
            const isSelected = formValue?.HotelRoomAmenitiesCollection?.some((selectedOption) => selectedOption.HotelRoomAmenitiesOptionsId === op.optionID)
            if (!isSelected) {
                option = [...option, op]
            }
        })
        return option
    }

    const onChangetags = (amenitiesId, feildtype, value, tabid) => {
        removerError(tabid)
        setFormError({});
        settags(value);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (tags?.trim()?.length !== 0) {
                if (!formValue.HotelRoomAmenitiesCollection?.find((op) => op?.HotelRoomAmenitiesId === 110)?.Tags?.some(el => el?.toLowerCase()?.trim() === tags?.toLowerCase()?.trim())) {
                    let data = formValue.HotelRoomAmenitiesCollection?.find((op) => op?.HotelRoomAmenitiesId === 110)?.Tags ? formValue.HotelRoomAmenitiesCollection?.find((op) => op?.HotelRoomAmenitiesId === 110)?.Tags : []
                    let clone = [...data]
                    clone = [...clone, tags]
                    handleAreaAndDropDown(110, 'textarea', clone, 'vertical-tab-12')
                    settags('');
                }
                else {
                    setTagError('Duplicate Tags Not Allowed.')
                }
            }
        }
    }

    const removetag = (tag) => {
        handleAreaAndDropDown(110, 'textarea', formValue?.HotelRoomAmenitiesCollection?.find((op) => op?.HotelRoomAmenitiesId === 110)?.Tags?.filter(el => !(el?.toLowerCase() === tag?.toLowerCase())), 'vertical-tab-12')
    }

    const removerError = (tabid) => {
        if (tabid) {
            const buttons = document?.querySelectorAll(`#${tabid}`);
            buttons?.forEach(button => {
                const icons = button?.querySelectorAll('.fa-triangle-exclamation');
                icons?.forEach(icon => {
                    icon?.remove();
                });
            })
        }
        else {
            const maintab = document?.querySelectorAll(`#horizontal-tab-1`);
            maintab?.forEach(button => {
                const icons = button?.querySelectorAll('.fa-triangle-exclamation');
                icons?.forEach(icon => {
                    icon?.remove();
                });
            });
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setTagError('')
        }, 2000);
    }, [tagError])

    useEffect(() => {
        if (!formValue?.HotelRoomAmenitiesCollection?.some((obj) => obj?.HotelRoomAmenitiesId === 110)) {
            settags('')
        }
    }, [formValue?.HotelRoomAmenitiesCollection])
    
    return (
        <div className="container-fluid mt-2 heading_color input_mui">
            <div className='row'>
                <div className='col-lg-2 col-3'>
                    <Box className='Hotel_listing'
                        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
                    >
                        <Tabs
                            className='w-100'
                            orientation="vertical"
                            value={value}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                        >
                            {roomAmenities?.length > 0 ? roomAmenities.map((roomamenity, index) => (
                                roomamenity?.roomCategoryName !== 'Others' && (<Tab
                                    iconPosition="end"
                                    onClick={() => setValue(roomamenity?.roomCategoryName)}
                                    label={roomamenity?.roomCategoryName}
                                    value={`${roomamenity?.roomCategoryName}`}
                                    {...a11yProps(index)}
                                />)
                            )) : ""}
                            <Tab label={"Others"} iconPosition="end" value={'others'} onClick={() => setValue("others")} {...a11yProps(roomAmenities?.length - 1)} />
                        </Tabs>
                    </Box>
                </div>

                <div className='col-lg-10 col-9'>
                    <form action="" className='py-3 my-1'>
                        {roomAmenities?.length > 0 ? roomAmenities.map((roomamenity, indexp) => (
                            <>
                                {roomamenity?.roomCategoryName !== 'Others' && roomamenity?.roomCategoryName === value && <div value={value} index={indexp}>
                                    {roomamenity?.roomCategoryName !== 'Others' && <h6 className='mt-3 fw-bold room_header'>{roomamenity?.roomCategoryName}</h6>}
                                    <div className='row'>
                                        {roomamenity.categoryOptions.map((el, index) => (
                                            <>
                                                {roomamenity?.roomCategoryName !== 'Others' && (
                                                    <div className='col-lg-6 col-md-12 my-2' key={el.roomAmenitiesId}>
                                                        <div className='row'>
                                                            <div className='col-6 '>
                                                                <FormControlLabel className='col-4 mx-0 w-100'
                                                                    control={
                                                                        <Checkbox
                                                                            checked={formValue.HotelRoomAmenitiesCollection.some((val) => val.HotelRoomAmenitiesId === el.roomAmenitiesId)}
                                                                            onChange={(e) => handleRoomAmenitiesCheckBox(el.roomAmenitiesId, 'checkbox', e.target.checked, el?.options?.length > 0 ? true : false, `vertical-tab-${indexp}`)}
                                                                            name={el.roomAmenitiesName}
                                                                            disabled={disabled}
                                                                        />
                                                                    }
                                                                    label={el.roomAmenitiesName}
                                                                />
                                                            </div>

                                                            {el?.options?.length > 0 ?
                                                                <div className='col-6 '>
                                                                    <FormControl className='w-100'>
                                                                        <Autocomplete
                                                                            filterSelectedOptions
                                                                            id={`${el.Name}-id`}
                                                                            options={getOptions(el.options)}
                                                                            multiple={el?.isMultipleSelection ? true : false}
                                                                            value={el?.isMultipleSelection ? getMultiSelectedOption(el.roomAmenitiesId, el.options) : getSelectedOption(el.options, el.roomAmenitiesId) || null}
                                                                            getOptionLabel={(options) => options?.optionName}
                                                                            name={el?.Name}
                                                                            onChange={(e, newValue) => {
                                                                                el?.isMultipleSelection ? handleMultiSelector(el.roomAmenitiesId, 'dropdown', newValue, `vertical-tab-${indexp}`) : handleAreaAndDropDown(el.roomAmenitiesId, 'dropdown', newValue?.optionID ? newValue?.optionID : null, `vertical-tab-${indexp}`)
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <TextField {...params}
                                                                                    label={`${el?.roomAmenitiesName}`}
                                                                                    className={`w-100  ${formValue.HotelRoomAmenitiesCollection.some((val) => val.HotelRoomAmenitiesId === el.roomAmenitiesId) && !getSelectedOption(el.options, el.roomAmenitiesId) ? 'red_border' : ''}`}
                                                                                    error={formError[el.roomAmenitiesId] ? true : false}
                                                                                    helperText={formError?.[el.roomAmenitiesId]}
                                                                                />
                                                                            )}
                                                                            disabled={!formValue.HotelRoomAmenitiesCollection.some((val) => val.HotelRoomAmenitiesId === el.roomAmenitiesId) || disabled}
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                                : ""}
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                </div>}
                            </>
                        )) : ""}

                        {value === 'others' && (<div value={value} index={roomAmenities?.length - 1}>
                            <h6 className='mt-3 fw-bold room_header'>Beds & Blankets</h6>
                            <div className='row'>
                                {othersData?.length > 0 && (othersData.map((am) => <>
                                    {[100, 101, 102].includes(am.roomAmenitiesId) && (
                                        <div className='col-6' key={am.roomAmenitiesId}>
                                            <FormControlLabel className='col-4 mx-0 w-100'
                                                control={
                                                    <Checkbox
                                                        checked={formValue.HotelRoomAmenitiesCollection.some((val) => val.HotelRoomAmenitiesId === am.roomAmenitiesId)}
                                                        onChange={(e) => handleRoomAmenitiesCheckBox(am.roomAmenitiesId, 'checkbox', e.target.checked, false, 'vertical-tab-6')}
                                                        name={am.roomAmenitiesName}
                                                        disabled={disabled}
                                                    />
                                                }
                                                label={am?.roomAmenitiesName}
                                            />
                                        </div>
                                    )}

                                </>))}
                            </div>

                            <h6 className='mt-3 fw-bold room_header'>Safety & Security</h6>
                            <div className='row'>
                                {othersData?.length > 0 && (othersData.map((am) => <>
                                    {[103, 104, 105].includes(am.roomAmenitiesId) && (
                                        <div className='col-6 ' key={am.roomAmenitiesId}>
                                            <FormControlLabel className='col-4 mx-0 w-100'
                                                control={
                                                    <Checkbox
                                                        checked={formValue.HotelRoomAmenitiesCollection.some((val) => val.HotelRoomAmenitiesId === am.roomAmenitiesId)}
                                                        onChange={(e) => handleRoomAmenitiesCheckBox(am.roomAmenitiesId, 'checkbox', e.target.checked, false, 'vertical-tab-6')}
                                                        name={am.roomAmenitiesName}
                                                        disabled={disabled}
                                                    />
                                                }
                                                label={am?.roomAmenitiesName}
                                            />
                                        </div>
                                    )}
                                </>))}
                            </div>

                            <h6 className='mt-3 fw-bold room_header'>Childcare</h6>
                            <div className='row'>
                                {othersData?.length > 0 && (othersData.map((am) => <>
                                    {[106, 107, 108, 109].includes(am.roomAmenitiesId) && (
                                        <div className='col-6' key={am.roomAmenitiesId}>
                                            <FormControlLabel className='col-4 mx-0 w-100'
                                                control={
                                                    <Checkbox
                                                        checked={formValue.HotelRoomAmenitiesCollection.some((val) => val.HotelRoomAmenitiesId === am.roomAmenitiesId)}
                                                        onChange={(e) => handleRoomAmenitiesCheckBox(am.roomAmenitiesId, 'checkbox', e.target.checked, false, 'vertical-tab-6')}
                                                        name={am.roomAmenitiesName}
                                                        disabled={disabled}
                                                    />
                                                }
                                                label={am?.roomAmenitiesName}
                                            />
                                        </div>
                                    )}
                                </>))}
                            </div>

                            <div className='col-12'>
                                <h6 className='mt-3 fw-bold room_header'>Any Other to Add</h6>
                            </div>
                            <div className='col-12'>
                                <small className={`text-danger ${tagError ? 'visible' : 'invisible'}`}>{tagError ? tagError : 'e'}</small>
                            </div>
                            <div className='row' key={110}>
                                <div className='col-12 col-xl-6 mb-1'>
                                    <div className='row'>
                                        <div className='col-6 '>
                                            <FormControlLabel className='col-4 mx-0 w-100'
                                                control={
                                                    <Checkbox
                                                        checked={formValue.HotelRoomAmenitiesCollection.some((val) => val.HotelRoomAmenitiesId === 110) ? true : false}
                                                        onChange={(e) => handleRoomAmenitiesCheckBox(110, 'checkbox', e.target.checked, false, 'vertical-tab-6')}
                                                        name={'Yes'}
                                                        disabled={disabled}
                                                    />
                                                }
                                                label={'Yes'}
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <TextField
                                                value={tags}
                                                onChange={(e) =>
                                                    onChangetags(110, 'textarea', onlyAlphabets(e.target.value), 'vertical-tab-6')
                                                }
                                                onKeyPress={handleKeyPress}
                                                label="Other Details"
                                                fullWidth
                                                inputProps={{ maxLength: 25 }}
                                                className={`w-100  ${(!formValue?.HotelRoomAmenitiesCollection?.find((val) => val.HotelRoomAmenitiesId === 110)?.Tags && formValue?.HotelRoomAmenitiesCollection?.some((val) => val?.HotelRoomAmenitiesId === 110)) ? 'red_border' : ''}`}
                                                disabled={disabled || disabled === true ? disabled : formValue?.HotelRoomAmenitiesCollection?.some((val) => val?.HotelRoomAmenitiesId === 110) ? false : true}
                                                error={formError['110'] ? true : false}
                                                helperText={formError?.['110']}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {formValue?.HotelRoomAmenitiesCollection?.find((val) => val?.HotelRoomAmenitiesId === 110) &&
                                    <div className='col-12 col-xl-6 mb-1'>
                                        <div className='row'>
                                            {formValue?.HotelRoomAmenitiesCollection?.find((val) => val?.HotelRoomAmenitiesId === 110)?.Tags?.map((ele, index) => (
                                                <span key={index} className='rounded-pill bg_pill ps-2 pe-1 py-auto col-auto mx-1 mb-1 text-center'>{ele} <button disabled={disabled ? disabled : !formValue?.HotelRoomAmenitiesCollection?.some((op) => op?.HotelRoomAmenitiesId === 110)} className='border-0 bg-body' onClick={() => removetag(ele)} type='button'><i class="fa-solid fa-xmark text-danger"></i></button></span>
                                            ))}
                                        </div>
                                    </div>}
                            </div>
                        </div>)}
                    </form>
                </div>
            </div>
        </div>
    )
}
export default memo(RoomAmenities)