import React, { useEffect, useMemo, useState } from 'react'
import Switch from 'react-switch';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { DatePicker } from 'antd';
import { ClearLocalStorage, GetLocalStorage } from '../../../../commonClass/Session';
import { getAllData, postUsersData, verifytoken } from '../../../../../services/ApiCallingUserServices';
import { loaderActions } from '../../../../../_actions/loader.actions';
import { isImagePngJpegJpg } from '../../../../commonClass/CommonValidation';
import { ConfirmationModal } from '../../../../../customControl/ConfirmationModal';
import CustomTable from '../../../../../customControl/CustomTable';
import { ImageDelete, ImageUpload } from '../../../../../ImageUpload/ImageCommon';
import dayjs from 'dayjs';

const APIMerchandisingFlight = ({ useradd, useredit, userexport }) => {
    const dispatch = useDispatch();
    const userId = GetLocalStorage();

    const [dropdownData, setDropdownList] = useState([]);
    const [getDataList, setGetListData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [editDisable, setEditDisable] = useState(false);
    const [formError, setFormError] = useState({});
    const [activeId, setActiveId] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [countryId, setCountryId] = useState(0);
    const [deletedImage, setDeletedImage] = useState([]);

    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('100');
    const [serchdisable, setSerachDisable] = useState(false);
    const [clrshow, setsclshow] = useState(false);

    const [formValue, setFormValue] = useState({
        FlightMerchandisingId: 0,
        MerchandisingTypeId: 0,
        FromCityId: 932,
        ToCityId: 0,
        MonthYear: null,
        ImageName: '',
        ImagePath: '',
        ImageFile: '',
        LowestPrice: null,
        Active: true,
        UserId: 0,
        type: 'add'
    })

    const [lowestPriceValue, setLowestPriceValue] = useState({
        EndUserIp: '',
        AdultCount: 1,
        ChildCount: 0,
        InfantCount: 0,
        JourneyType: 1,
        Segments: [{
            Origin: '',
            Destination: '',
            FlightCabinClass: 1,
            PreferredDepartureTime: null,
        }]
    })

    const getData = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const data = await getAllData('/master/v1/getflightmerchandisingconfiguration');
            if (data.status) {
                setDropdownList({
                    merchandisingTypeList: data?.data?.merchandisingTypeList?.map(el => ({
                        label: el?.MerchandisingName,
                        value: el?.MerchandisingId
                    })),
                    countryList: data?.data?.countryList?.map(el => ({
                        label: el?.CountryName,
                        value: el?.CountryId
                    })),
                    cityList: data?.data?.cityList?.map(el => ({
                        label: el?.City,
                        value: el?.AirportLocationId,
                        CityCode: el?.CityCode,
                        CountryId: el?.CountryId
                    })),
                    monthList: data?.data?.monthList?.map(el => ({
                        label: el?.MonthName,
                        value: el?.MonthId
                    })),
                });
            }
            else {
                dispatch({ type: 'ALERTING', message: data.message })
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
        dispatch(loaderActions.end())
    }
    const countryList = useMemo(() => formValue?.MerchandisingTypeId === 1 ? dropdownData?.countryList?.filter((el) => el?.value === 1) : dropdownData?.countryList?.filter((el) => el?.value !== 1), [formValue?.MerchandisingTypeId]);
    const cityListTo = useMemo(() => dropdownData?.cityList?.filter(el => el.CountryId === countryId), [countryId]);

    const getDataAll = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const data = await getAllData(`/master/v1/getflightmerchandisinglist`);
            if (data?.status) {
                setGetListData(data?.data)
                setFilterData(data?.data)
            }
            else {
                setGetListData([])
                setFilterData([])
            }
            dispatch(loaderActions.end());
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
        dispatch(loaderActions.end())
    }
    const getPricedata = async () => {
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            dispatch(loaderActions.start());
            const data = await postUsersData('/master/v1/getlowestpriceofflight', lowestPriceValue);
            if (data.status) {
                setFormValue({ ...formValue, LowestPrice: data?.data?.price ? data?.data?.price : null })
                dispatch(loaderActions.end());
            }
            else {
                setFormValue({ ...formValue, LowestPrice: null })
                dispatch(loaderActions.end());
                dispatch({ type: 'ALERTING', message: data.message })
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
        dispatch(loaderActions.end())
    }

    const onChange = (name, value) => {
        setFormError({});
        setEditDisable(false);
        if (name === 'MerchandisingTypeId') {
            setFormValue({ ...formValue, [name]: value, ToCityId: 0, LowestPrice: null });
            setCountryId(value === 1 ? 1 : 0);
        }
        else if (name === 'countryId') {
            setCountryId(value);
            setFormValue({ ...formValue, [name]: value, ToCityId: 0, LowestPrice: null });
        }
        else if (name === 'FromCityId') {
            setFormValue({ ...formValue, [name]: value, LowestPrice: null });
        }
        else if (name === 'ToCityId') {
            setFormValue({ ...formValue, [name]: value, LowestPrice: null });
        }
        else if (name === 'MonthYear') {
            setFormValue({ ...formValue, [name]: value, LowestPrice: null });
            setLowestPriceValue((prevState) => ({
                ...prevState,
                Segments: prevState.Segments.map(segment => ({
                    ...segment,
                    PreferredDepartureTime: value,
                }))
            }));
        }
        else {
            setFormValue({ ...formValue, [name]: value });
        }
    }

    function getDateCombination() {
        var today = new Date();
        let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
        let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
        return date + '_' + time;
    }
    const handleUploadImageLoad = (e) => {
        setEditDisable(false)
        setFormError({});
        if (isImagePngJpegJpg(e.target.files[0].name)) {
            const files = e.target.files[0];
            const filecom = getDateCombination();
            const filename = filecom + '_' + '1' + '_' + (files.name.replace(/ /g, "_")).replace(/-/g, '');
            setDeletedImage([...deletedImage, formValue?.ImagePath])
            setFormValue({
                ...formValue,
                ImageName: filename,
                ImagePath: `CMS/Merchandising/Flight/Domestic&International/${filename}`,
                ImageFile: files,
            })
        }
        else {
            setFormError({
                ...formError, 'ImageName': 'Please upload valid image such as PNG, JPEG, & JPG only.'
            });
        }
        e.target.value = null
    }
    const handleisActive = (e) => {
        if (formValue?.type === 'add') {
            formValue.Active = e
            setActiveId(!activeId)
            setFormError({});
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        if (e === true) {
            formValue.Active = !activeId
            setActiveId(!activeId)
            setFormError({});
            setEditDisable(false);
        }
        setModalShow(false);
    }

    const checkValidation = () => {
        let errorMessages = {};
        let isError = true;

        if (formValue?.MerchandisingTypeId === 0) {
            errorMessages['MerchandisingTypeId'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (formValue?.MerchandisingTypeId > 0 && countryId === 0) {
            errorMessages['countryId'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (formValue?.FromCityId === 0) {
            errorMessages['FromCityId'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (countryId > 0 && formValue?.ToCityId === 0) {
            errorMessages['ToCityId'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (formValue?.MonthYear === null) {
            errorMessages['MonthYear'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        if (!formValue?.ImagePath) {
            errorMessages['ImageName'] = 'Cannot leave blank, input is mandatory.'
            isError = false;
        }
        else if (formValue?.ImageFile) {
            const files = formValue?.ImageFile;
            let fileSize = files.size / 1024;
            fileSize = fileSize / 1024
            if (!isImagePngJpegJpg(files?.name) && files) {
                errorMessages['ImageName'] = 'Please upload valid image such as PNG, JPEG, & JPG only.'
                isError = false;
            } else if (fileSize > 5) {
                errorMessages['ImageName'] = 'Cannot upload file that exceeds 5 MB.'
                isError = false;
            }
        }
        if ((formValue?.LowestPrice === null && formValue?.FromCityId > 0 && formValue?.ToCityId > 0 && formValue?.MonthYear != null)) {
            errorMessages['LowestPrice'] = ' Cannot leave blank, please select valid city.'
            isError = false;
        }
        setFormError(errorMessages);
        return isError;
    }

    const onSubmitData = async () => {
        const isValid = checkValidation();
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            if (isValid) {
                dispatch(loaderActions.start());
                let imageupload = true
                formValue?.ImageFile &&
                    await ImageUpload(`CMS/Merchandising/Flight/Domestic&International/`, formValue?.ImageName, formValue?.ImageFile).then((res) => {
                        if (!res) {
                            dispatch({ type: 'ALERTING', message: 'There was an issue uploading the image. Please try again after some time.' })
                            imageupload = false
                        }
                    });
                if (imageupload) {
                    const data = await postUsersData('/master/v1/insertorupdateflightmerchandising', formValue);
                    if (data.status) {
                        deletedImage?.length > 0 && deletedImage?.forEach(async (deletedImagePath) => await ImageDelete(deletedImagePath));
                        onClearButton();
                        getDataAll();
                        dispatch({ type: 'ALERTING', message: data.message })
                        dispatch(loaderActions.end());
                    }
                    else {
                        dispatch(loaderActions.end());
                        dispatch({ type: 'ALERTING', message: data.message })
                    }
                }
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
        dispatch(loaderActions.end());
    }
    const onClearButton = () => {
        setFormError({});
        setEditDisable(false);
        setSerachDisable(false);
        setActiveId(true);
        setCountryId(0);
        setFormValue(
            {
                FlightMerchandisingId: 0,
                MerchandisingTypeId: 0,
                FromCityId: 932,
                ToCityId: 0,
                MonthYear: null,
                ImageName: '',
                ImagePath: '',
                ImageFile: '',
                LowestPrice: null,
                Active: true,
                UserId: userId?.userMasterID,
                type: 'add'
            }
        )
        lowestPriceValue.AdultCount = 1
        lowestPriceValue.ChildCount = 0
        lowestPriceValue.InfantCount = 0
        lowestPriceValue.JourneyType = 1
        setLowestPriceValue((prevState) => ({
            ...prevState,
            Segments: prevState.Segments.map(segment => ({
                ...segment,
                Origin: '',
                Destination: '',
                FlightCabinClass: 1,
                PreferredDepartureTime: null,
            }))
        }));
    }
    const onEditorView = async (data, type) => {
        if (type === 'view') {
            setSerachDisable(true)
        }
        onClearButton();
        setEditDisable(true)
        dispatch(loaderActions.start());
        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const el = await getAllData(`/master/v1/getflightmerchandisingbyid?flightMerchandisingId=${data?.FlightMerchandisingId}`);
            setFormError({});
            if (el?.status) {
                setFormValue(
                    {
                        FlightMerchandisingId: el?.data?.[0]?.FlightMerchandisingId,
                        MerchandisingTypeId: el?.data?.[0]?.MerchandisingTypeId,
                        FromCityId: el?.data?.[0]?.FromCityId,
                        ToCityId: el?.data?.[0]?.ToCityId,
                        MonthYear: el?.data?.[0]?.MonthYear ? el?.data?.[0]?.MonthYear : null,
                        ImageName: el?.data?.[0]?.ImageName,
                        ImagePath: el?.data?.[0]?.ImagePath,
                        ImageFile: '',
                        LowestPrice: el?.data?.[0]?.LowestPrice,
                        Active: el?.data?.[0]?.Active,
                        UserId: userId?.userMasterID,
                        type: type
                    }
                )
                setCountryId(el?.data?.[0]?.MerchandisingTypeId === 1 ? 1 : dropdownData?.cityList?.find(ele => ele.value === el?.data?.[0]?.ToCityId)?.CountryId);
                setActiveId(el?.data?.[0]?.Active)
                setLowestPriceValue((prevState) => ({
                    ...prevState,
                    Segments: prevState.Segments.map(segment => ({
                        ...segment,
                        PreferredDepartureTime: el?.data?.[0]?.MonthYear ? el?.data?.[0]?.MonthYear : null,
                    }))
                }));
            }
        } else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            dispatch(loaderActions.end());
        }
        dispatch(loaderActions.end());
    }

    const columns = [
        {
            name: 'Merchandising Type',
            sortable: true,
            float: "left",
            selector: (data) => data?.MerchandisingType,
        },
        {
            name: 'From City',
            sortable: true,
            float: "left",
            selector: (data) => data?.FromCity,
        },
        {
            name: 'To City',
            sortable: true,
            float: "left",
            selector: (data) => data?.ToCity,
        },
        {
            name: 'Month & Year',
            sortable: true,
            float: "left",
            selector: (data) => data?.MonthYear,
        },
        {
            name: 'Lowest Price',
            sortable: true,
            float: "left",
            selector: (data) => data?.LowestPrice,
        },
        {
            name: 'Active',
            sortable: true,
            float: "left",
            selector: (data) => data?.Status,
        },
        {
            name: 'Edit',
            selector: data => <button type='button' className='border-0 p-0 bg-body set_disableclr' onClick={() => onEditorView(data, 'edit')} disabled={!useredit}
            ><i className="fa fa-edit eye-icon"></i></button>
        },
        {
            name: 'View',
            selector: data => <button type='button' className='border-0 p-0 bg-body set_disableclr' onClick={() => onEditorView(data, 'view')} ><i className="fa fa-eye" ></i></button>
        }
    ]

    const serachoption = [
        {
            label: 'Merchandising Type',
            value: '1'
        },
        {
            label: 'From City',
            value: '2'
        },
        {
            label: 'To City',
            value: '3'
        },
        {
            label: 'Month & Year',
            value: '4'
        },
        {
            label: 'Lowest Price',
            value: '5'
        },
        {
            label: 'Active',
            value: '6'
        },
        {
            label: 'All',
            value: '100'
        },
    ]

    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }

    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getDataAll()
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    }

    const matchesSearchValueAll = (issec, fields) => {
        let search = searchvalue
        return fields.some((data) =>
            issec[data]?.toString().toLowerCase().includes(search.toLowerCase())
        );
    }

    const handleSearch = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            var filteritem = filterData;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec?.MerchandisingType?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec?.FromCity?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            if (searchby === '3') {
                filterdata = filteritem?.filter(issec => issec?.ToCity?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            if (searchby === '4') {
                filterdata = filteritem?.filter(issec => issec?.MonthYear?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            if (searchby === '5') {
                filterdata = filteritem?.filter(issec => issec?.LowestPrice?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            if (searchby === '6') {
                filterdata = filteritem?.filter(issec => issec?.Status?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            if (searchby === '100') {
                filterdata = filteritem?.filter((issec) => {
                    let keys = [
                        "MerchandisingType",
                        "FromCity",
                        "ToCity",
                        "MonthYear",
                        "LowestPrice",
                        "Status",
                    ]
                    return matchesSearchValueAll(issec, keys) && issec
                });
            }
            setGetListData(filterdata)
        }
    }


    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getDataAll();
    }

    function formatDatefor(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return date ? [year, month, day].join('-') : null;
    }


    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            formValue.UserId = userId?.userMasterID
            getData();
            getDataAll();
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }
    }, [])

    useEffect(() => {
        const fetchIp = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const data = await response.json();
                setLowestPriceValue({ ...lowestPriceValue, EndUserIp: data.ip })
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }
        };
        fetchIp();
    }, []);

    useEffect(() => {
        setLowestPriceValue((prevState) => ({
            ...prevState,
            Segments: prevState.Segments.map(segment => ({
                ...segment,
                Origin: dropdownData?.cityList?.find((el) => el?.value === formValue?.FromCityId ? formValue?.FromCityId : null)?.CityCode || '',
                Destination: dropdownData?.cityList?.find((el) => el?.value === formValue?.ToCityId)?.CityCode || '',
            }))
        }));
    }, [formValue?.FromCityId, formValue?.ToCityId]);

    useEffect(() => {
        if (lowestPriceValue.EndUserIp !== '' && lowestPriceValue.Segments[0].Destination !== '' && lowestPriceValue.Segments[0].Origin !== '' && lowestPriceValue.Segments[0].PreferredDepartureTime !== null) {
            if (formValue.type !== 'view' && !editDisable)
                getPricedata()
        }
    }, [lowestPriceValue])

    return (
        <>
            <form action="" >
                <div class="mb-3 row mx-1">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="row">
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-4 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" class="col-form-label required">Merchandising Type</label>
                                    </div>
                                    <div class="col-lg-8 mb-1 col-md-12">
                                        <Select
                                            id="SupplierId"
                                            className="dropdown select_dropdown"
                                            options={dropdownData?.merchandisingTypeList}
                                            value={dropdownData?.merchandisingTypeList?.filter((val) => (val.value === formValue?.MerchandisingTypeId))}
                                            onChange={(e) => onChange('MerchandisingTypeId', e?.value)}
                                            isDisabled={formValue?.type === 'view' ? true : false || formValue?.type === 'edit'}
                                            isSearchable
                                        />
                                        {formError?.MerchandisingTypeId && <p className='text-danger text-start mb-0'>{formError?.MerchandisingTypeId}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-4 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" class="col-form-label required">Country</label>
                                    </div>
                                    <div class="col-lg-8 mb-1 col-md-12">
                                        <Select
                                            id="SupplierId"
                                            className="dropdown select_dropdown"
                                            options={countryList}
                                            value={countryList?.filter((val) => (val.value === countryId))}
                                            onChange={(e) => onChange('countryId', e?.value)}
                                            isDisabled={formValue?.type === 'view' ? true : false || formValue?.MerchandisingTypeId < 2 || formValue?.type === 'edit'}
                                            isSearchable
                                        />
                                        {formError?.countryId && <p className='text-danger text-start mb-0'>{formError?.countryId}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-4 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" class="col-form-label required">From City</label>
                                    </div>
                                    <div class="col-lg-8 mb-1 col-md-12">
                                        <Select
                                            id="SupplierId"
                                            className="dropdown select_dropdown"
                                            options={dropdownData?.cityList?.filter(el => el.value !== formValue?.ToCityId)}
                                            value={dropdownData?.cityList?.filter((val) => (val.value === formValue?.FromCityId))}
                                            onChange={(e) => onChange('FromCityId', e?.value)}
                                            isDisabled={formValue?.type === 'view' ? true : false}
                                            isSearchable
                                        />
                                        {formError?.FromCityId && <p className='text-danger text-start mb-0'>{formError?.FromCityId}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-4 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" class="col-form-label required">To City</label>
                                    </div>
                                    <div class="col-lg-8 mb-1 col-md-12">
                                        <Select
                                            id="SupplierId"
                                            className="dropdown select_dropdown"
                                            options={cityListTo?.filter(el => el.value !== formValue?.FromCityId)}
                                            value={cityListTo?.filter((val) => (val.value === formValue?.ToCityId))}
                                            onChange={(e) => onChange('ToCityId', e?.value)}
                                            isDisabled={formValue?.type === 'view' ? true : false || countryId > 0 ? false : true}
                                            isSearchable
                                        />
                                        {formError?.ToCityId && <p className='text-danger text-start mb-0'>{formError?.ToCityId}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-4 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" class="col-form-label required">Month & Year</label>
                                    </div>
                                    <div class="col-lg-8 mb-1 col-md-12">
                                        <DatePicker
                                            className='antd_input w-100'
                                            format='MMMM/YYYY'
                                            placeholder="Month/Year"
                                            picker="month"
                                            minDate={dayjs(formatDatefor(new Date()), "YYYY-MM-DD")}
                                            maxDate={dayjs(formatDatefor(new Date(`${new Date().getFullYear() + 1}/${new Date().getMonth()}/${new Date().getDate()}`)), "YYYY-MM-DD")}
                                            value={
                                                formValue?.MonthYear !== null ? dayjs(formatDatefor(formValue?.MonthYear), 'YYYY/MM/DD') : null
                                            }
                                            onChange={(e) => onChange('MonthYear', e ? `${formatDatefor(e?.$d)}T00:00:00` : null)}
                                            disabled={formValue?.type === 'view' ? true : false}
                                        />
                                        {formError?.MonthYear && <p className='text-danger text-start mb-0'>{formError?.MonthYear}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-4 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" class="col-form-label required">Image</label>
                                    </div>
                                    <div class="col-lg-8 mb-1 col-md-12">
                                        <div class="input-group">
                                            <input
                                                type="file"
                                                name='ImageName'
                                                id="ImageName-id"
                                                className="form-control btn_add"
                                                accept=".png,.jpg,.jpeg"
                                                onChange={(e) => handleUploadImageLoad(e)}
                                                disabled={formValue?.type === 'view' ? true : false}
                                            />
                                            <label class="input-group-text" htmlFor="ImageName-id">Browse</label>
                                        </div>
                                        {formValue?.ImageName && <p className='text-start mb-0'>{formValue?.ImageName}</p>}
                                        {formError?.ImageName && <p className='text-danger text-start mb-0'>{formError?.ImageName}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-4 col-md-12 mb-1">
                                        <label htmlFor="inputPassword6" class="col-form-label required">Lowest Price</label>
                                    </div>
                                    <div class="col-lg-8 mb-1 col-md-12">
                                        <input type="text" id="FlightNo" class="form-control"
                                            name="LowestPrice"
                                            defaultValue={formValue?.LowestPrice}
                                            maxLength={8}
                                            disabled
                                            placeholder="Lowest Price"
                                        />
                                        {formError?.LowestPrice && <p className=' text-danger text-start mb-0'>{formError?.LowestPrice}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6 '>
                                <div className='row'>
                                    <div class="col-lg-4 col-md-12 mb-1">
                                        <label for="inputPassword6" class="col-form-label">Active</label>
                                    </div>
                                    <div class="col-auto col-lg-8" disabled={false}>
                                        <Switch
                                            onChange={handleisActive}
                                            checked={activeId}
                                            className="react-switch"
                                            disabled={formValue?.type === 'view' ? true : false}
                                        />
                                    </div>
                                    <div class=" col-auto col-lg-4">
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation(false)}
                                            yesconfirm={() => handleConfirmation(true)}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-3 d-flex justify-content-center">
                    {
                        formValue?.type === 'edit'
                            ? (<button class="btn btn-primary mx-1" id="btnAdd" type='button'
                                onClick={() => onSubmitData()}
                                disabled={editDisable || !useredit}
                            >Update</button>
                            )
                            : (<button class="btn btn-primary mx-1" id="btnAdd" type='button'
                                onClick={() => onSubmitData()}
                                disabled={(formValue?.type === 'view' ? editDisable : false) || !useradd}
                            >Add</button>)
                    }
                    <button type="button" className="btn btn-primary mx-1" id="btnClear" onClick={onClearButton}>Clear</button>
                </div>
                <div className="row mt-3">
                    <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                        <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                            <Select
                                className="dropdown"
                                options={serachoption}
                                value={serachoption?.filter(function (serachoption) {
                                    return serachoption.value === searchby;
                                })}
                                onChange={handleSearchBy}
                                isDisabled={serchdisable ? true : false}
                                isSearchable={false}

                            />
                        </div>
                        <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                            <div className='clrbutton'>
                                <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" className="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue || ''} disabled={serchdisable ? true : false} />
                                <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                            </div>
                        </div>
                        <div className="p-2 text-center">
                            <button type="button" className="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleSearch} >Search</button>
                        </div>
                    </div>

                </div>
                <CustomTable
                    className={'last_two_column_freeze'}
                    columns={columns}
                    data={getDataList}
                    PdfExport={false}
                    ExportToExcel={false}
                    disabled={!userexport}
                />
            </form>
        </>
    )
}

export default APIMerchandisingFlight