import React, { useEffect, useState } from 'react'
import APIConfigurationDetails from './Partial/APIConfigurationDetails'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import APIConfigurationMarkupDetails from './Partial/APIConfigurationMarkupDetails';
import APIMerchandising from './Partial/APIMerchandising';

const APIConfiguration = () => {
  const navigate = useNavigate();
  const user = useSelector(store => store.userstatus.tba);
  const [useradd, setUserAdd] = useState(true);
  const [useredit, setUserEdit] = useState(true);
  const [userexport, setUserExport] = useState(true);

  useEffect(() => {
    let pagelink = user?.find(x => {
      if (x.PageLink === window.location.pathname)
        return x.MenuId
    });
    let mid = user?.find(x => x?.MenuId === pagelink?.MenuId);
    if (mid?.URightView === false && mid?.URightView !== undefined) {
      navigate('/dashboard');
    }
    user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
      setUserAdd(obj.URightAdd);
      setUserEdit(obj.URightEdit);
      setUserExport(obj.URightExport);

    });
  }, [user])

  return (
    <>
      <div className="container-fluid mt-4 heading_color">
        <h3>API Configuration</h3>
      </div>
      <div className="container-fluid my-3 pb-5">
        <div className='container mt-3 mb-2 tab_section px-0'>
          <ul className="nav nav-pills nav-justified mb-3 pt-3 col-lg-6 col-12 mx-auto" id="pills-tab" role="tablist">
            <li className="nav-item mt-1 mx-1" role="presentation">
              <button className="nav-link active" id="pills-APIConfiguration-tab" data-bs-toggle="pill"
                data-bs-target="#pills-APIConfiguration" type="button" role="tab" aria-controls="pills-APIConfiguration"
                aria-selected="true"><strong>API Configuration</strong></button>
            </li>
            <li className="nav-item mt-1 mx-1" role="presentation" >
              <button className="nav-link" id="pills-MarkUp-tab" data-bs-toggle="pill"
                data-bs-target="#pills-MarkUp" type="button" role="tab" aria-controls="pills-MarkUp"
                aria-selected="false"><strong >Mark Up</strong></button>
            </li>
            <li className="nav-item mt-1 mx-1" role="presentation" >
              <button className="nav-link" id="pills-APIMerchandising-tab" data-bs-toggle="pill"
                data-bs-target="#pills-APIMerchandising" type="button" role="tab" aria-controls="pills-APIMerchandising"
                aria-selected="false"><strong >API Merchandising</strong></button>
            </li>
          </ul>
        </div>
        <div className="tab-content form_border" id="pills-tabContent">
          <div className="tab-pane fade show active" id="pills-APIConfiguration" role="tabpanel" aria-labelledby="pills-APIConfiguration-tab">
            <APIConfigurationDetails useradd={useradd} useredit={useredit} userexport={userexport} />
          </div>
          <div className="tab-pane fade show" id="pills-MarkUp" role="tabpanel" aria-labelledby="pills-MarkUp-tab">
            <APIConfigurationMarkupDetails useradd={useradd} useredit={useredit} userexport={userexport} />
          </div>
          <div className="tab-pane fade show" id="pills-APIMerchandising" role="tabpanel" aria-labelledby="pills-APIMerchandising-tab">
            <APIMerchandising useradd={useradd} useredit={useredit} userexport={userexport} />
          </div>
        </div>
      </div>
    </>
  )
}

export default APIConfiguration