import React from 'react';
import { useEffect, useState } from "react";
import { FormControl, Radio, TextField, Autocomplete, FormControlLabel, InputAdornment } from '@mui/material';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../../../../assets/css/master.css";
import "../Hotelmaster.css"
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllData, postUsersData, verifytoken } from '../../../../../services/ApiCallingUserServices';
import { alphaNumericspace, isValidEmail, onlyAlphabets, onlyNumeric } from '../../../../commonClass/CommonValidation';
import { ClearLocalStorage, GetLocalStorage } from '../../../../commonClass/Session';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../../../../_actions/loader.actions';



const BasicInfoIndividual = () => {
    const location = useLocation();
    const userId = GetLocalStorage();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let type = localStorage.getItem('type');

    //#region helper state
    const [save, setSave] = useState(false);
    const [next, setNext] = useState(true);


    //#region drop down value
    const [channel, setchannel] = useState([]);
    const [startYear, setStartYear] = useState([]);
    const [star, setStar] = useState([]);
    const [hotelTypeMaster, setHotelTypeMaster] = useState([]);
    const [countryCode, setCountryCode] = useState([]);

    //#region form value and error
    const basicDefault = {
        AccountName: '',
        BrandName: '',
        AccountOwner: '',
        IsChannelManagers: true,
        IsChainHotel: type === "chain" ? true : false,
        ChannelManagerId: [],
        HotelName: "",
        HotelTypeId: null,
        StartingYear: null,
        AuthorizedStarCategory: null,
        AsPerLocation: null,
        AsPerSize: null,
        AsPerFacilities: null,
        ReservationTeam_Name: "",
        ReservationTeam_Phone: "",
        ReservationTeam_Email: "",
        Management_Name: "",
        Management_Phone: "",
        Management_Email: "",
        IsActive: true,
        CountryCode: null,
        UserId: userId?.userMasterID
    }
    const [formValue, setFormValue] = useState({ ...basicDefault, CountryCode: 1 });
    const [formError, setFormError] = useState(basicDefault);

    //#region API getchannelmanagerlist
    const getChannelData = async () => {
        const channelDetails = await getAllData('/master/v1/getchannelmanagerlist');

        if (channelDetails.status) {
            setchannel(channelDetails?.data.map((cn) => ({ label: cn?.ChannelManagerName, id: cn?.ChannelManagerId })));
        }
    };

    //#region API getstartingyearlist
    const getBussinessStartYear = async () => {
        const year = await getAllData('/master/v1/getstartingyearlist');

        if (year.status) {
            setStartYear(year?.data.map((y) => ({ label: `${y?.Year}`, id: y?.Year })));
        }
    };

    //#region API getallhotelcategorymaster
    const getAllHotelCategoryMaster = async () => {
        const starData = await getAllData('/master/v1/getallhotelcategorymaster');

        if (starData.status) {
            setStar(starData?.data.map((star) => ({ label: star?.HotelCategoryName, id: star?.HotelCategoryID })));
        }
    };

    //#region API getallhoteltypemaster
    const getAllHotelTypeMaster = async () => {
        const hotelTypes = await getAllData('/master/v1/getallhoteltypemaster');

        if (hotelTypes.status) {
            setHotelTypeMaster(hotelTypes?.data.map((hType) => ({ label: hType?.HotelTypeName, id: hType?.HotelTypeID })));
        }
    };


    //#region API getallhoteltypemaster
    const getallcountrycode = async () => {
        const countryCodeData = await getAllData('/master/v1/getallcountrycode');

        if (countryCodeData.status) {
            setCountryCode(countryCodeData?.data)
        }
    };

    //#region API gethotelbasicinfobyid
    const retriveData = async () => {
        try {
            const basicData = await getAllData(`/master/v1/gethotelbasicinfobyid?HotelBasicId=${location?.state?.HotelId}`);
            if (basicData.status) {
                const {
                    HotelBasicId, ChannelManagerId, IsChainHotel, HotelName, HotelTypeId, StartingYear, AuthorizedStarCategory, AsPerLocation,
                    AsPerSize, AsPerFacilities, ReservationTeam_Name, ReservationTeamCountryCodeId, ReservationTeam_Phone, ReservationTeam_Email, Management_Name, Management_Phone,
                    Management_Email, AccountName, AccountOwner, BrandName,
                } = basicData?.data?.[0]

                setNext(false);
                setSave(true);

                setFormValue({
                    AccountName: AccountName ? AccountName : "",
                    AccountOwner: AccountOwner ? AccountOwner : "",
                    BrandName: BrandName ? BrandName : "",
                    HotelBasicId: HotelBasicId,
                    IsChannelManagers: ChannelManagerId ? true : false,
                    ChannelManagerId: ChannelManagerId,
                    IsChainHotel: IsChainHotel,
                    HotelName: HotelName,
                    HotelTypeId: HotelTypeId,
                    StartingYear: StartingYear,
                    AuthorizedStarCategory: AuthorizedStarCategory,
                    AsPerLocation: AsPerLocation,
                    AsPerSize: AsPerSize,
                    AsPerFacilities: AsPerFacilities,
                    CountryCode: ReservationTeamCountryCodeId,
                    ReservationTeam_Name: ReservationTeam_Name,
                    ReservationTeam_Phone: ReservationTeam_Phone,
                    ReservationTeam_Email: ReservationTeam_Email,
                    Management_Name: Management_Name,
                    Management_Phone: Management_Phone,
                    Management_Email: Management_Email,
                    IsActive: true,
                    UserId: userId?.userMasterID
                })
            }
        } catch (error) {
            console.error('Error fetching hotel type master:', error);
        }
    };


    //#region Fetch all API data
    //using Promise.all to run them in parallel
    useEffect(() => {
        if (location?.state?.HotelId === undefined || location?.state?.HotelId === null) {
            navigate(-1);
        }

        const fetchDropDownData = async () => {
            dispatch(loaderActions.start());
            let statusoftoken = await verifytoken();

            if (statusoftoken) {
                try {
                    await Promise.all([
                        getChannelData(),
                        getBussinessStartYear(),
                        getAllHotelCategoryMaster(),
                        getAllHotelTypeMaster(),
                        getallcountrycode(),
                        retriveData(location?.state?.HotelId)
                    ]);
                } catch (error) {
                    console.error('Error during fetching data:', error);
                } finally {
                    dispatch(loaderActions.end());
                }
            } else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' });
            }
        };

        fetchDropDownData();
    }, []);

    //#region onChange Input field
    const handleChange = (name, value) => {
        setFormError(basicDefault);
        setSave(false);
        if (name === "ChannelManagerId") {
            setFormValue((preData) => (
                {
                    ...preData,
                    [name]: value?.map(el => el.id)
                }
            ));
        }
        else {
            setFormValue((preData) => (
                {
                    ...preData,
                    [name]: value
                }
            ));
        }
    };

    //#region Next page function
    const handleNext = () => {
        navigate(`/HotelListing/${type}/location`, {
            state: {
                disabled: location.state?.disabled,
                editdisabled: location?.state?.editdisabled,
                viewtype: location?.state?.viewtype,
                HotelId: location?.state?.HotelId || formValue.HotelBasicId
            }
        })
    }

    //#region data Validation check
    const isValidData = () => {
        let isValis = true;
        const cloneFormError = { ...formError };
        if (formValue?.IsChannelManagers && formValue?.ChannelManagerId?.length === 0) {
            cloneFormError['ChannelManagerId'] = 'Inputs are mandatory.';
            isValis = false;
        }
        if (formValue?.HotelName.trim()?.length <= 0) {
            cloneFormError['HotelName'] = 'Inputs are mandatory.';
            isValis = false;
        }
        if (formValue.HotelTypeId <= 0) {
            cloneFormError['HotelTypeId'] = 'Inputs are mandatory.';
            isValis = false;
        }

        if (formValue.AuthorizedStarCategory <= 0) {
            cloneFormError['AuthorizedStarCategory'] = 'Inputs are mandatory.';
            isValis = false;
        }
        if (formValue.AsPerLocation <= 0) {
            cloneFormError['AsPerLocation'] = 'Inputs are mandatory.';
            isValis = false;
        }
        if (formValue.AsPerSize <= 0) {
            cloneFormError['AsPerSize'] = 'Inputs are mandatory.';
            isValis = false;
        }
        if (formValue.AsPerFacilities <= 0) {
            cloneFormError['AsPerFacilities'] = 'Inputs are mandatory.';
            isValis = false;
        }


        if (formValue?.ReservationTeam_Name.trim()?.length <= 0) {
            cloneFormError['ReservationTeam_Name'] = 'Inputs are mandatory.';
            isValis = false;
        }
        if (formValue?.Management_Name.trim()?.length <= 0) {
            cloneFormError['Management_Name'] = 'Inputs are mandatory.';
            isValis = false;
        }

        if (!formValue?.CountryCode) {
            cloneFormError['CountryCode'] = 'Inputs are mandatory.';
            isValis = false;
        }

        if (formValue?.ReservationTeam_Phone.trim()?.length <= 0) {
            cloneFormError['ReservationTeam_Phone'] = 'Inputs are mandatory.';
            isValis = false;
        }
        else if (formValue?.CountryCode === 1 ? (formValue?.ReservationTeam_Phone?.length < 10 || formValue?.ReservationTeam_Phone?.length > 10) : (formValue?.ReservationTeam_Phone?.length < 5 || formValue?.ReservationTeam_Phone?.length > 20)) {
            cloneFormError['ReservationTeam_Phone'] = 'Please provide correct input';
            isValis = false;
        }

        if (formValue?.Management_Phone.trim()?.length <= 0) {
            cloneFormError['Management_Phone'] = 'Inputs are mandatory.';
            isValis = false;
        }
        else if (formValue?.CountryCode === 1 ? (formValue?.Management_Phone.trim()?.length < 10 || formValue?.Management_Phone.trim()?.length > 10) : (formValue?.Management_Phone.trim()?.length < 5 || formValue?.Management_Phone.trim()?.length > 20)) {
            cloneFormError['Management_Phone'] = 'Please provide correct input';
            isValis = false;
        }

        if (formValue?.ReservationTeam_Email.trim()?.length <= 0) {
            cloneFormError['ReservationTeam_Email'] = 'Inputs are mandatory.';
            isValis = false;
        } else if (!isValidEmail(formValue?.ReservationTeam_Email)) {
            cloneFormError['ReservationTeam_Email'] = 'Please provide correct input';
            isValis = false;
        }

        if (formValue?.Management_Email.trim()?.length <= 0) {
            cloneFormError['Management_Email'] = 'Inputs are mandatory.';
            isValis = false;
        } else if (!isValidEmail(formValue?.Management_Email)) {
            cloneFormError['Management_Email'] = 'Please provide correct input';
            isValis = false;
        }
        if (formValue?.IsChainHotel) {
            if (formValue?.AccountName?.trim()?.length <= 0) {
                cloneFormError['AccountName'] = 'Inputs are mandatory.';
                isValis = false;
            }
            if (formValue?.BrandName?.trim()?.length <= 0) {
                cloneFormError['BrandName'] = 'Inputs are mandatory.';
                isValis = false;
            }
            if (formValue?.AccountOwner?.trim()?.length <= 0) {
                cloneFormError['AccountOwner'] = 'Inputs are mandatory.';
                isValis = false;
            }
        }

        setFormError(cloneFormError);
        return isValis;
    }

    //#region On save 
    const handleSubmit = async (e) => {
        e.preventDefault();

        const validation = isValidData();

        if (validation) {
            let statusoftoken = await verifytoken();
            if (statusoftoken) {
                const hotelBasic = await postUsersData('/master/v1/insertorupdatehotelbasicinfo', {
                    ...formValue,
                    "ReservationTeamCountryCodeId": formValue.CountryCode,
                    "ManagementPhoneCountryCodeId": formValue.CountryCode,
                });

                if (hotelBasic.status) {
                    setNext(false);
                    setSave(true);

                    setFormValue(form => ({ ...form, HotelBasicId: hotelBasic?.data }));
                    dispatch({ type: 'ALERTING', message: hotelBasic.message });
                } else {
                    dispatch({ type: 'ALERTING', message: hotelBasic.message });
                }
            } else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' });
            }
        }
    }
    useEffect(() => {
        console.log("formError", formError)
    }, [formError])

    return (
        <>
            <div className="container-fluid mt-2 heading_color input_mui">
                <div className='row'>
                    <div className='col-8'>
                        <h3 className='cursor_pointer'>
                            <i className="fa-solid fa-angle-left me-2 w-fit" onClick={() => navigate("/Hotellistmaster")} ></i>
                            Basic Information
                        </h3>
                    </div>
                    <div className='col-4 text-end'>
                        <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd"
                            disabled={save || location?.state?.disabled}
                            onClick={(e) => handleSubmit(e)}>
                            Save
                        </button>
                        {sessionStorage?.getItem('latestStep') !== '8' &&
                            <button type="button" className="btn btn-primary mx-1 rounded-pill save_btn" id="btnAdd"
                                onClick={() => handleNext()}
                                disabled={next}>
                                Next
                            </button>}
                    </div>
                </div>

                <form action="" className='py-3 my-1'>
                    {formValue?.IsChainHotel &&
                        <div className='row'>
                            <div className='col-12 mb-1'>
                                <div className='row mt-3'>
                                    <div className='col-lg-4 col-md-6 col-12 mb-1'>
                                        <TextField id="filled-basic" label="Account Name" name='AccountName' type="text"
                                            className={`w-100  ${formValue?.AccountName?.trim()?.length <= 0 ? 'red_border' : ''}`}
                                            inputProps={{ maxLength: 150 }}
                                            error={formError?.AccountName ? true : false}
                                            helperText={formError?.AccountName}
                                            value={formValue?.AccountName}
                                            onChange={(e) => handleChange(e.target.name, alphaNumericspace(e.target.value))}
                                            disabled={location?.state?.disabled}
                                        />
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 mb-1'>
                                        <TextField id="filled-basic" label="Brand Name" name='BrandName' type="text"
                                            className={`w-100  ${formValue?.BrandName?.trim()?.length <= 0 ? 'red_border' : ''}`}
                                            inputProps={{ maxLength: 150 }}
                                            error={formError?.BrandName ? true : false}
                                            helperText={formError?.BrandName}
                                            value={formValue?.BrandName}
                                            onChange={(e) => handleChange(e.target.name, alphaNumericspace(e.target.value))}
                                            disabled={location?.state?.disabled}
                                        />
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 mb-1'>
                                        <TextField id="filled-basic" label="Account Owner" name='AccountOwner' type="text"
                                            className={`w-100  ${formValue?.AccountOwner?.trim()?.length <= 0 ? 'red_border' : ''}`}
                                            inputProps={{ maxLength: 150 }}
                                            error={formError?.AccountOwner ? true : false}
                                            helperText={formError?.AccountOwner}
                                            value={formValue?.AccountOwner}
                                            onChange={(e) => handleChange(e.target.name, alphaNumericspace(e.target.value))}
                                            disabled={location?.state?.disabled}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='row'>
                        <div className='col-12 mb-1'>
                            <div className='row'>
                                <div className='col-sm-4 col-12 align-content-center'>
                                    <h6 className='mb-0 fw-5'>Using Channel Manager? </h6>
                                </div>
                                {/* #region Using Channel Manager */}
                                <div className='col-sm-6 col-12 d-flex'>
                                    <FormControlLabel className=''
                                        control={
                                            <>
                                                <Radio
                                                    checked={formValue?.IsChannelManagers === true}
                                                    name='IsChannelManagers'
                                                    onChange={(e) => handleChange(e.target.name, true)}
                                                    value="true"
                                                    id='channel_yes'
                                                    inputProps={{ 'aria-label': 'true' }}
                                                    disabled={location?.state?.disabled}
                                                />
                                            </>
                                        }
                                        label="Yes"
                                    />
                                    <FormControlLabel className=''
                                        control={
                                            <Radio
                                                checked={formValue?.IsChannelManagers === false}
                                                onChange={(e) => {
                                                    handleChange(e.target.name, false)
                                                    handleChange('ChannelManagerId', [])
                                                }}
                                                value="false"
                                                id='channel_no'
                                                name='IsChannelManagers'
                                                inputProps={{ 'aria-label': 'false' }}
                                                disabled={location?.state?.disabled}
                                            />
                                        }
                                        label="No"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-12 mb-1'>
                            <FormControl className='w-100' >
                                <Autocomplete
                                    filterSelectedOptions
                                    multiple
                                    options={channel.filter((ch) => !formValue?.ChannelManagerId?.includes(ch?.id))}
                                    getOptionLabel={(option) => option.label}
                                    id="Select Channel Managers"
                                    value={channel.filter((ch) => formValue?.ChannelManagerId?.includes(ch?.id)) || null}
                                    name='ChannelManagerId'
                                    onChange={(e, newValue) => handleChange('ChannelManagerId', newValue ? newValue : null)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Select Channel Manager"
                                            className={`w-100  ${formValue?.IsChannelManagers && formValue?.ChannelManagerId?.length === 0 ? 'red_border' : ''}`}
                                            error={formError?.ChannelManagerId?.length !== 0 ? true : false}
                                            helperText={formValue?.IsChannelManagers && formError?.ChannelManagerId?.length === 0}
                                        />
                                    )}
                                    disabled={!formValue?.IsChannelManagers || location?.state?.disabled}
                                />
                            </FormControl>
                        </div>

                        <div className='col-12 mb-1'>
                            <div className='row mt-3'>
                                <div className='col-lg-4 col-md-6 col-12 mb-1'>
                                    <TextField id="filled-basic" label="Hotel Name" name='HotelName' type="text"
                                        className={`w-100  ${formValue.HotelName?.trim()?.length <= 0 ? 'red_border' : ''}`}
                                        inputProps={{ maxLength: 150 }}
                                        error={formError?.HotelName ? true : false}
                                        helperText={formError?.HotelName}
                                        value={formValue?.HotelName}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                        disabled={location?.state?.disabled}
                                    />
                                </div>

                                <div className='col-lg-4 col-md-6 col-12 mb-1'>
                                    <FormControl className='w-100' >
                                        <Autocomplete
                                            filterSelectedOptions
                                            options={hotelTypeMaster?.filter(ch => ch?.id !== formValue?.HotelTypeId)}
                                            id="HotelTypeId-id"
                                            value={hotelTypeMaster.find((ch) => ch.id === formValue?.HotelTypeId) || null}
                                            name='HotelTypeId'
                                            onChange={(e, newValue) => handleChange('HotelTypeId', newValue?.id ? newValue?.id : null)}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Hotel Type"
                                                    className={`w-100  ${formValue.HotelTypeId <= 0 ? 'red_border' : ''}`}
                                                    error={formError?.HotelTypeId ? true : false}
                                                    helperText={formError?.HotelTypeId}
                                                />
                                            )}
                                            disabled={location?.state?.disabled}
                                        />
                                    </FormControl>
                                </div>

                                <div className='col-lg-4 col-md-6 col-12 mb-1'>
                                    <FormControl className='w-100' >
                                        <Autocomplete
                                            filterSelectedOptions
                                            options={startYear?.filter(ch => ch?.id !== formValue?.StartingYear)}
                                            id="StartingYear-id"
                                            value={startYear.find((ch) => ch.id === formValue?.StartingYear) || null}
                                            name='StartingYear'
                                            onChange={(e, newValue) => handleChange('StartingYear', newValue?.id ? newValue?.id : null)}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Business Starting Year" />
                                            )}
                                            disabled={location?.state?.disabled}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-3'>
                        <div className='col-12'>
                            <h6 className='fw-5'>Hotel Category</h6>
                        </div>

                        <div className='col-lg-4 col-md-6 col-12 mb-1'>
                            <FormControl className='w-100' >
                                <Autocomplete
                                    filterSelectedOptions
                                    options={star?.filter(ch => ch?.id !== formValue?.AuthorizedStarCategory)}
                                    autoComplete={false}
                                    id="AuthorizedStarCategory-id"
                                    value={star.find((ch) => ch.id === formValue?.AuthorizedStarCategory) || null}
                                    name='AuthorizedStarCategory'
                                    onChange={(e, newValue) => handleChange('AuthorizedStarCategory', newValue?.id ? newValue?.id : null)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Authorized Star Category"
                                            className={`w-100  ${formValue.AuthorizedStarCategory <= 0 ? 'red_border' : ''}`}
                                            error={formError?.AuthorizedStarCategory ? true : false}
                                            helperText={formError?.AuthorizedStarCategory}
                                        />
                                    )}
                                    disabled={location?.state?.disabled}
                                />
                            </FormControl>
                        </div>

                        <div className='col-lg-4 col-md-6 col-12 mb-2'>
                            <FormControl className='w-100' >
                                <Autocomplete
                                    filterSelectedOptions
                                    options={star?.filter(ch => ch?.id !== formValue?.AsPerLocation)}
                                    id="AsPerLocation-id"
                                    value={star.find((ch) => ch.id === formValue?.AsPerLocation) || null}
                                    name='AsPerLocation'
                                    onChange={(e, newValue) => handleChange('AsPerLocation', newValue?.id ? newValue?.id : null)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="As Per Location"
                                            className={`w-100  ${formValue.AsPerLocation <= 0 ? 'red_border' : ''}`}
                                            error={formError?.AsPerLocation ? true : false}
                                            helperText={formError?.AsPerLocation}
                                        />
                                    )}
                                    disabled={location?.state?.disabled}
                                />
                            </FormControl>
                        </div>

                        <div className='col-12 mb-1 mt-sm-2'>
                            <div className='row'>
                                <div className='col-lg-4 col-md-6 col-12 mb-1'>
                                    <FormControl className='w-100' >
                                        <Autocomplete
                                            filterSelectedOptions
                                            options={star?.filter(ch => ch?.id !== formValue?.AsPerSize)}
                                            id="AsPerSize-id"
                                            value={star.find((ch) => ch.id === formValue?.AsPerSize) || null}
                                            name='AsPerSize'
                                            onChange={(e, newValue) => handleChange('AsPerSize', newValue?.id ? newValue?.id : null)}
                                            renderInput={(params) => (
                                                <TextField {...params} label="As Per Size"
                                                    className={`w-100  ${formValue.AsPerSize <= 0 ? 'red_border' : ''}`}
                                                    error={formError?.AsPerSize ? true : false}
                                                    helperText={formError?.AsPerSize}
                                                />
                                            )}
                                            disabled={location?.state?.disabled}
                                        />
                                    </FormControl>
                                </div>

                                <div className='col-lg-4 col-md-6 col-12 mb-1'>
                                    <FormControl className='w-100' >
                                        <Autocomplete
                                            filterSelectedOptions
                                            options={star?.filter(ch => ch?.id !== formValue?.AsPerFacilities)}
                                            id="AsPerFacilities-id"
                                            value={star.find((ch) => ch.id === formValue?.AsPerFacilities) || null}
                                            name='AsPerFacilities'
                                            onChange={(e, newValue) => handleChange('AsPerFacilities', newValue?.id ? newValue?.id : null)}
                                            renderInput={(params) => (
                                                <TextField {...params} label="As Per Facilities"
                                                    className={`w-100  ${formValue.AsPerFacilities <= 0 ? 'red_border' : ''}`}
                                                    error={formError?.AsPerFacilities ? true : false}
                                                    helperText={formError?.AsPerFacilities}
                                                />
                                            )}
                                            disabled={location?.state?.disabled}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-3'>
                        <div className='col-12'>
                            <h5 className='fw-7'>Contact Details</h5>
                        </div>
                        <div className='col-12'>
                            <h6 className='fw-5'>Reservation Team</h6>
                        </div>

                        <div className='col-lg-3 col-md-6 col-12 mb-1'>
                            <TextField id="filled-basic" label="Name of Contact Person"
                                type="text" name='ReservationTeam_Name'
                                className={`w-100  ${formValue.ReservationTeam_Name?.trim()?.length <= 0 ? 'red_border' : ''}`}
                                value={formValue?.ReservationTeam_Name}
                                inputProps={{ maxLength: 50 }}
                                error={formError?.ReservationTeam_Name ? true : false}
                                helperText={formError?.ReservationTeam_Name}
                                onChange={(e) => handleChange(e.target.name, onlyAlphabets(e.target.value))}
                                disabled={location?.state?.disabled}
                            />
                        </div>

                        <div className='col-lg-3 col-md-6 col-12 mb-1'>
                            <FormControl className='w-100' >
                                <Autocomplete
                                    id="CountryCode-id"
                                    filterSelectedOptions
                                    options={countryCode?.filter(ch => ch?.id !== formValue?.CountryCode)}
                                    value={countryCode.find((ch) => ch.CountryId === formValue?.CountryCode) || null}
                                    getOptionLabel={(option) => (`${option.CountryName} (${option.ISDCode})`)}
                                    name='CountryCode'
                                    onChange={(e, newValue) => handleChange('CountryCode', newValue?.CountryId ? newValue?.CountryId : null)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Country Code"
                                            className={`w-100  ${formValue.CountryCode <= 0 ? 'red_border' : ''}`}
                                            error={formError?.CountryCode ? true : false}
                                            helperText={formError?.CountryCode}
                                        />
                                    )}
                                    disabled={location?.state?.disabled}

                                />
                            </FormControl>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12 mb-1 d-flex'>

                            <TextField id="filled-basic" label="Phone Number"
                                type="text" name='ReservationTeam_Phone'
                                className={`w-100  ${formValue.ReservationTeam_Phone?.trim()?.length <= 0 ? 'red_border' : ''}`}
                                value={formValue?.ReservationTeam_Phone}
                                inputProps={{ maxLength: formValue?.CountryCode === 1 ? 10 : 20 }}
                                error={formError?.ReservationTeam_Phone ? true : false}
                                helperText={formError?.ReservationTeam_Phone}
                                onChange={(e) => handleChange(e.target.name, onlyNumeric(e.target.value))}
                                disabled={location?.state?.disabled}
                                slotProps={formValue?.CountryCode ? {
                                    input: {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <span>{countryCode.find((ch) => ch.CountryId === formValue?.CountryCode)?.ISDCode}</span>
                                            </InputAdornment>
                                        ),
                                    },
                                } : ''}
                            />
                        </div>

                        <div className='col-lg-3 col-md-6 col-12 mb-1'>
                            <TextField id="filled-basic" label="Email"
                                className={`w-100  ${formValue.ReservationTeam_Email?.trim()?.length <= 0 ? 'red_border' : ''}`}
                                type="text" name='ReservationTeam_Email'
                                value={formValue?.ReservationTeam_Email}
                                inputProps={{ maxLength: 50 }}
                                error={formError?.ReservationTeam_Email ? true : false}
                                helperText={formError?.ReservationTeam_Email}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                disabled={location?.state?.disabled}
                            />
                        </div>

                        <div className='col-12 mt-2'>
                            <h6>Management </h6>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12 mb-1'>
                            <TextField id="filled-basic" label="Name of Contact Person"
                                className={`w-100  ${formValue.Management_Name?.trim()?.length <= 0 ? 'red_border' : ''}`}
                                type="text"
                                name='Management_Name'
                                inputProps={{ maxLength: 50 }}
                                error={formError?.Management_Name ? true : false}
                                helperText={formError?.Management_Name}
                                value={formValue?.Management_Name}
                                onChange={(e) => handleChange(e.target.name, onlyAlphabets(e.target.value))}
                                disabled={location?.state?.disabled}
                            />

                        </div>
                        <div className='col-lg-3 col-md-6 col-12 mb-1'>
                            <FormControl className='w-100' >
                                <Autocomplete
                                    id="CountryCode-id"
                                    filterSelectedOptions
                                    options={countryCode?.filter(ch => ch?.id !== formValue?.CountryCode)}
                                    value={countryCode.find((ch) => ch.CountryId === formValue?.CountryCode) || null}
                                    getOptionLabel={(option) => (`${option.CountryName} (${option.ISDCode})`)}
                                    name='CountryCode'
                                    onChange={(e, newValue) => handleChange('CountryCode', newValue?.id ? newValue?.id : null)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Country Code"
                                            className={`w-100 `}
                                        // error={formError?.CountryCode ? true : false}
                                        // helperText={formError?.CountryCode}
                                        />
                                    )}
                                    disabled={true}
                                />
                            </FormControl>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12 mb-1'>
                            <TextField id="filled-basic" label="Phone Number"
                                className={`w-100  ${formValue.Management_Phone?.trim()?.length <= 0 ? 'red_border' : ''}`}
                                type="text"
                                name='Management_Phone'
                                inputProps={{ maxLength: formValue?.CountryCode === 1 ? 10 : 20 }}
                                error={formError?.Management_Phone ? true : false}
                                helperText={formError?.Management_Phone}
                                value={formValue?.Management_Phone}
                                onChange={(e) => handleChange(e.target.name, onlyNumeric(e.target.value))}
                                disabled={location?.state?.disabled}
                                slotProps={formValue?.CountryCode ? {
                                    input: {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <span>{countryCode.find((ch) => ch.CountryId === formValue?.CountryCode)?.ISDCode}</span>
                                            </InputAdornment>
                                        ),
                                    },
                                } : ''}
                            />
                        </div>

                        <div className='col-lg-3 col-md-6 col-12 mb-1'>
                            <TextField id="filled-basic" label="Email"
                                className={`w-100  ${formValue.Management_Email?.trim()?.length <= 0 ? 'red_border' : ''}`}
                                type="text" name='Management_Email'
                                inputProps={{ maxLength: 50 }}
                                error={formError?.Management_Email ? true : false}
                                helperText={formError?.Management_Email}
                                value={formValue?.Management_Email}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                disabled={location?.state?.disabled}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default BasicInfoIndividual