import React from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from 'react-select';
import "../../../assets/css/master.css"
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { generatePDF, isImagePngJpegJpg, isValidImage, onlyAlphabets } from '../../commonClass/CommonValidation'
import { useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../_actions/loader.actions";
import { ExportExcel } from '../../commonClass/ExportExcel';
import { ConfirmationModal } from '../../../customControl/ConfirmationModal';
import CustomTable from '../../../customControl/CustomTable';
import { ImageDelete, ImageUpload } from '../../../ImageUpload/ImageCommon';

function getDateCombination() {
    var today = new Date();
    let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
    let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
    return date + '_' + time;
}

const Inclusionmaster = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [activeId, setActiveId] = useState();
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [disabled, setDisabled] = useState(false);
    const [editdisabled, setEditDisabled] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [formValue, setFormValue] = useState(
        {
            InclusionId: 0,
            InclusionName: '',
            IsActive: false,
            ShortName: '',
            IconName: '',
            IconPath: '',
            IconFile: ''
        }
    );
    const [inclusionlistdata, setInclusionListData] = useState([]);
    const [deleteImage, setDeleteImage] = useState('');
    const [filteritems, setFilterItems] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState({
        label: 'All',
        value: '3',
        fieldName: 'all'
    });
    const [serchdisable, setSerachDisable] = useState(false);
    const [clrshow, setsclshow] = useState(false);
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);

    const serachoption = [
        {
            label: 'Inclusion Name',
            value: '1',
            fieldName: 'inclusionName'
        },
        {
            label: 'Short Name',
            value: '2',
            fieldName: 'shortName'
        },
        {
            label: 'Active',
            value: '3',
            fieldName: 'isActive'
        },
        {
            label: 'All',
            value: '4',
            fieldName: 'all'
        }
    ];

    //#region Get List data
    const getInclusionDataList = async () => {
        const InclusionListdata = await getAllData("/master/v1/getallinclusion");
        setInclusionListData(InclusionListdata.data);
        setFilterItems(InclusionListdata.data);
    }

    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            getInclusionDataList().then(() => { dispatch(loaderActions.end()) });;
            setitems();
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, []);

    useEffect(() => {
        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);

        });
    }, [user])

    const handleUploadImageLoad = (e) => {
        setEditDisabled(true);
        setFormErrors({});
        setErrorMessage('');
        if (isValidImage(e.target.files[0].name)) {
            const files = e.target.files[0];
            const filecom = getDateCombination();
            const filename = filecom + '_' + '1' + '_' + (files.name.replace(/ /g, "_")).replace(/-/g, '');
            !formValue.IconFile && setDeleteImage(formValue.IconPath);
            setFormValue({
                ...formValue,
                IconName: filename,
                IconPath: 'CMS/inclusionMaster/' + filename,
                IconFile: files
            });
        }
        else {
            document.getElementById("select_icon_id").value = "";
            setFormValue({ ...formValue, BannerFileName: '', BannerPath: '' });
            return;
        }
    }

    const columns = [
        {
            name: 'Inclusion Name',
            sortable: true,
            float: "left",
            selector: (inclusion) => inclusion.inclusionName,
        },
        {
            name: 'Short Name',
            sortable: true,
            float: "left",
            selector: (inclusion) => inclusion.shortName,
        },
        {
            name: 'Active',
            sortable: true,
            float: "left",
            selector: (inclusion) => inclusion.isActive ? 'Active' : 'InActive'
        },
        {
            name: 'Created Date',
            sortable: true,
            float: "left",
            selector: (inclusion) => formatDate(inclusion.createdDate),
        },
        {
            name: 'Edit',
            selector: (inclusion) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditMode(inclusion.inclusionId)}></i></button>
        },
        {
            name: 'View',
            selector: (inclusion) => <i className="fa fa-eye" onClick={() => handleViewMode(inclusion.inclusionId)}></i>
        }
    ];

    const handleAddClick = async () => {
        let statusoftoken = await verifytoken();
        if (buttonaddeditText === 'Add') {
            if (statusoftoken) {
                if (validateData()) {
                    let filterdata;
                    filterdata = inclusionlistdata ? inclusionlistdata?.filter(issec => issec.inclusionName.toLowerCase() === formValue.InclusionName.toLowerCase()) : [];
                    if (filterdata?.length > 0) {
                        setErrorMessage('You can not enter duplicate value.');
                        return;
                    }
                    if (statusoftoken) {
                        const InclusionData = await postUsersData("/master/v1/insertinclusion", formValue);
                        if (!InclusionData.status && !InclusionData.data) {
                            setErrorMessage(InclusionData.message);
                            return;
                        }
                        if (InclusionData.status) {
                            formValue.IconFile && await ImageUpload('CMS/inclusionMaster/', formValue.IconName, formValue.IconFile);
                            deleteImage && ImageDelete(deleteImage);
                            if (InclusionData.data > 0) {
                                dispatch({ type: 'ALERTING', message: InclusionData.message })
                                clear();
                            }
                            else {
                                dispatch({ type: 'ALERTING', message: InclusionData.message })
                                return;
                            }
                        }
                        else {
                            dispatch({ type: 'ALERTING', message: InclusionData.message })
                            return;
                        }
                    }
                    else {
                        ClearLocalStorage();
                        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                    }
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        else {
            if (statusoftoken) {
                if (validateData()) {
                    let filterdata;
                    filterdata = inclusionlistdata ? inclusionlistdata?.filter(issec => issec.inclusionId !== formValue.InclusionId && issec.inclusionName.toLowerCase() === formValue.InclusionName.toLowerCase()) : [];
                    if (filterdata?.length > 0) {
                        setErrorMessage('You can not enter duplicate value.');
                        return;
                    }
                    if (statusoftoken) {
                        const InclusionData = await patchUsersData("/master/v1/updateInclusion", formValue);

                        if (!InclusionData.status && !InclusionData.data) {
                            setErrorMessage(InclusionData.message);
                            return;
                        }
                        if (InclusionData.status) {
                            formValue.IconFile && await ImageUpload('CMS/inclusionMaster/', formValue.IconName, formValue.IconFile);
                            deleteImage && ImageDelete(deleteImage)
                            dispatch({ type: 'ALERTING', message: InclusionData.message })
                            clear();
                        }
                        else {
                            dispatch({ type: 'ALERTING', message: InclusionData.message })
                            return;
                        }
                    }
                    else {
                        ClearLocalStorage();
                        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                    }
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
    }

    const validateData = () => {
        let errorMessage = {};
        let isValid = true;
        if (typeof formValue.InclusionName === 'string' && formValue.InclusionName?.trim()?.length === 0) {
            errorMessage['InclusionName'] = 'Please enter Inclusion Name'
            document.getElementById('txtinclusion').focus()
            isValid = false;
        }
        if (typeof formValue.InclusionName === 'string' && formValue.InclusionName?.trim()?.length > 500) {
            errorMessage['InclusionName'] = 'Maximum length allowed for inclusion is 500 characters.'
            document.getElementById('txtinclusion').focus()
            isValid = false;
        }

        if (formValue.IconPath.length > 0 && !formValue.ShortName.trim()) {
            errorMessage['ShortName'] = 'Cannot leave blank, input is mandatory.'
            isValid = false;
        }

        if (formValue?.IconFile) {
            const files = formValue?.IconFile;
            let fileSize = files.size / 1024;
            fileSize = fileSize / 1024
            console.log("formValue",formValue,)
            console.log("isImagePngJpegJpg(files.name)",isImagePngJpegJpg(files.name),files,fileSize)
            if (!isImagePngJpegJpg(files.name) && files) {
                errorMessage['IconPath'] = 'Invalid file type. Kindly use valid image file type such as JPG, JPEG, PNG.'
                isValid = false;
            } else if (fileSize > 5) {
                errorMessage['IconPath'] = 'Cannot upload file that exceeds 5 MB.'
                isValid = false;
            }
        }

        setFormErrors(errorMessage);
        return isValid;
    }

    const handleEditMode = (id) => {
        setDisabled(false);
        setEditDisabled(false);
        setButtonAddEditText("Update");
        setSerachDisable(true);
        setErrorMessage('');
        RetriveData(id);
    }

    const handleViewMode = (id) => {
        setDisabled(true);
        setEditDisabled(false);
        setSerachDisable(true);
        setErrorMessage('');
        RetriveData(id);
    }

    function RetriveData(id) {
        setFormErrors({});

        inclusionlistdata?.map(obj => {
            if (obj.inclusionId === id) {
                let tempObj = {
                    InclusionId: obj.inclusionId ? obj.inclusionId : 0,
                    InclusionName: obj.inclusionName ? obj.inclusionName : '',
                    IsActive: obj.isActive ? obj.isActive : false,
                    ShortName: obj.shortName ? obj.shortName : '',
                    IconName: obj.iconName ? obj.iconName : '',
                    IconPath: obj.iconPath ? obj.iconPath : '',
                }
                setFormValue({ ...formValue, ...tempObj })
                setActiveId(obj.isActive);
            }
        });
    }

    //#region image upload
    const onChange = (name, value) => {
        setEditDisabled(true);
        setFormErrors({});
        setErrorMessage('');
        setFormValue({ ...formValue, [name]: value });
    };

    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            formValue.IsActive = e
            setActiveId(!activeId);
            setFormErrors({});
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        //setYesNoConfirm(e)
        if (e === true) {
            formValue.IsActive = !activeId
            setActiveId(!activeId)
            setEditDisabled(true);
            setFormErrors({});
            setErrorMessage('');
        }
        setModalShow(false);
    }
    //#endregion

    //#region handle clear operation
    const handleClearClick = () => {
        clear();
    };

    function clear() {
        setFormValue({...formValue, InclusionId: 0, InclusionName: '',
             IsActive: false, ShortName: '', IconName: '', IconPath: '', IconFile: '',
        });
        setActiveId(false);
        getInclusionDataList();
        setButtonAddEditText('Add');
        setDisabled(false);
        setEditDisabled(true);
        setFormErrors({});
        setErrorMessage('');
        setSerachDisable(false);
        setSerachBy({label: 'All', value: '3', fieldName: 'all'});
        setSearchValue('');
        setsclshow(false)
    }
    //#endregion

    //#region handle pdf and excel import
    const PdfExport = () => {
        //print
        const columns = [
            "Inclusion Name",
            "Active",
            "Created Date"
        ];
        var rows = [];

        for (let i = 0; i < inclusionlistdata?.length; i++) {
            var temp = [
                inclusionlistdata[i].inclusionName,
                `${inclusionlistdata[i].isActive ? 'Active' : 'InActive'}`,
                formatDate(inclusionlistdata[i].createdDate)
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "InclusionMaster", "Inclusion Master")
    }

    const ExportToExcel = () => {
        const dispalyname = ['Inclusion Name', 'Active', 'Created Date'];
        const fields = ['inclusionName', 'isActive', 'createdDate'];
        ExportExcel(dispalyname, fields, inclusionlistdata, "Inclusion", "InclusionMaster.xls");
    }
    //#endregion

    //#region handle search value
    const setitems = async () => {
        const InclusionListData = await getAllData("/master/v1/getallinclusion");
        setFilterItems(InclusionListData.data);
    }

    const handleSearchBy = (selectedValue) => {
        setSerachBy(selectedValue);
    };

    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            clearsearch();
            setsclshow(false)
        }
    }

    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        setFilterItems(inclusionlistdata)
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    };

    //checl all filed filter
    const matchesSearchValue = (issec, fields) => {
        return fields.some((field) => issec[field]?.toString().toLowerCase().includes(searchvalue.toLowerCase()));
    };

    // get all filter field
    const fieldsToSearch = serachoption.map((option) => option !== 'all' && option.fieldName);

    const handleSearch = () => {
        if (searchvalue.trim().length !== 0) {
            var filterdata = [];
            if (searchby.fieldName === 'all') {
                filterdata = inclusionlistdata?.filter((issec) => {
                    return matchesSearchValue(issec, fieldsToSearch) && issec
                });
            } else if (searchby.fieldName === 'isActive') {
                filterdata = inclusionlistdata?.filter((issec) => searchvalue.toLowerCase() === 'active'.toLowerCase() ? issec?.isActive === true
                    : searchvalue.toLowerCase() === 'inactive'.toLowerCase() ? issec?.isActive === false
                        : 'active'.toLowerCase().includes(searchvalue.toLowerCase()) ? issec?.isActive === true
                            : 'inactive'.toLowerCase().includes(searchvalue.toLowerCase()) ? issec?.isActive === false : "");
            } else {
                filterdata = inclusionlistdata?.filter((issec) => issec?.[searchby?.fieldName]?.toString()?.toLowerCase().includes(searchvalue.toLowerCase()));
            }

            setFilterItems(filterdata.length > 0 ? filterdata : []);
        }
    }


    //#endregion
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    return (
        <>
            <div class="container-fluid mt-4 heading_color">
                <h3>Inclusion Master</h3>
            </div>
            <div class="container-fluid form_border py-5 my-3">
                <form action="" >
                    <div class="mb-3 row mx-1">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row gy-4">
                                <div className='col-md-12'>
                                    <div className='row'>
                                        {errorMessage && <div className="text-danger pb-3 text-center"> {errorMessage} </div>}
                                        <div class="col-lg-2 col-md-12 col-sm-12 mb-lg-1">
                                            <label for="inputPassword6" class="col-form-label required">Inclusion Name</label>
                                        </div>
                                        <div class=" col-lg-10 col-md-12 col-sm-12 mb-lg-1">
                                            <textarea type="Rich Text Box" rows={2} id="txtinclusion" className="form-control"
                                                name="InclusionName"
                                                value={formValue.InclusionName}
                                                onChange={(e) => onChange(e.target.name, e.target.value)}
                                                disabled={disabled ? true : false}
                                                placeholder="Inclusion"
                                            >
                                            </textarea>
                                            {formErrors.InclusionName && <div className="text-danger"> {formErrors.InclusionName} </div>}
                                        </div>

                                        <div class="col-lg-2 col-md-12 col-sm-12 my-2">
                                            <label for="inputPassword6" className="col-form-label" htmlFor='select_icon_id'>Select Icon</label>
                                        </div>

                                        <div class=" col-lg-4 col-md-12 col-sm-12 mb-lg-1 my-2">
                                            <div class="input-group">
                                                <input
                                                    // ref={inputRef}
                                                    name='BannerImage'
                                                    type="file"
                                                    id="select_icon_id"
                                                    class="form-control btn_add"
                                                    accept=".png,.jpg,.jpeg"
                                                    disabled={disabled ? true : false}
                                                    onChange={handleUploadImageLoad}
                                                />
                                                <label class="input-group-text" htmlFor='select_icon_id'>Browse</label>
                                            </div>
                                            {formValue.IconName && <p className='text-start overflow-hidden mb-0 w-100 text-break' data-bs-toggle="tooltip" data-bs-placement="left" title={formValue.IconName}>{formValue.IconName}</p>}
                                            {formErrors.IconPath && <p className='text-danger text-start w-100 text-break'>{formErrors.IconPath}</p>}
                                        </div>

                                        <div class="col-lg-2 col-md-12 col-sm-12 my-2">
                                            <label for="inputPassword6" class="col-form-label">Short Name</label>
                                        </div>

                                        <div class=" col-lg-4 col-md-12 col-sm-12 mb-lg-1 my-2">
                                            <input
                                                className='form-control'
                                                disabled={disabled ? true : false}
                                                name='ShortName'
                                                value={formValue.ShortName}
                                                onChange={(e) => onChange(e.target.name, onlyAlphabets(e.target.value))}
                                                maxLength={15}
                                            />
                                            {formErrors.ShortName && <p className='text-danger text-start'>{formErrors.ShortName}</p>}
                                        </div>

                                        <div class="col-lg-2 col-md-12 col-sm-12">
                                            <label for="inputPassword6" class="col-form-label">Active</label>
                                        </div>
                                        <div class="col-9 col-lg-6" disabled={false}>

                                            <Switch
                                                onChange={handleisActive}
                                                checked={activeId}
                                                className="react-switch"
                                                disabled={disabled ? true : false}
                                            />
                                        </div>
                                        {/* <div class=" col-auto col-lg-4"> */}
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation(false)}
                                            yesconfirm={() => handleConfirmation(true)}
                                        />

                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center mb-4">
                        {useradd === false && buttonaddeditText === "Add" ?
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={!useradd}>{buttonaddeditText}</button> :
                            <button type="button" class="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={editdisabled ? false : true}>{buttonaddeditText}</button>}
                        <button type="button" class="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick} >Clear</button>
                    </div>
                    <div class="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={searchby} // set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}

                                />
                            </div>
                            <div class="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" class="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue || ''} disabled={serchdisable ? true : false} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div class="p-2 text-center">
                                <button type="button" class="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleSearch}>Search</button>
                            </div>
                        </div>

                    </div>

                    <CustomTable
                        columns={columns}
                        data={filteritems}
                        PdfExport={PdfExport}
                        ExportToExcel={ExportToExcel}
                        disabled={!userexport}
                    />
                </form>
            </div >
        </>
    )
}

export default Inclusionmaster