import React, { useEffect, useMemo, useState } from 'react'
import BookingTable from './partial/BookingTable';
import { useDispatch, useSelector } from 'react-redux';
import { loaderActions } from '../../_actions/loader.actions';
import { getAllData, verifytoken } from '../../services/ApiCallingUserServices';
import { ClearLocalStorage } from '../commonClass/Session';
import Select from 'react-select';
import { updatebookingend } from '../../_actions/user.action';
import { useNavigate } from 'react-router-dom';

const AllBookings = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const [activeTab, setActiveTab] = useState('Upcoming');
    const [searchDropdownList, setSearchDropdownList] = useState([]);

    const [activeTab, setActiveTab] = useState(() => {
        const storedValue = localStorage.getItem("tab");
        return storedValue ? storedValue : 'Upcoming';
    });

    const [UpcomingData, setUpcomingData] = useState([])
    const [OngoingData, setOngoingData] = useState([])
    const [CompletedData, setCompletedData] = useState([])
    const [CancelledData, setCancelledData] = useState([])

    const [filterUpcomingData, setFilterUpcomingData] = useState([])
    const [filterOngoingData, setFilterOngoingData] = useState([])
    const [filterCompletedData, setFilterCompletedData] = useState([])
    const [filterCancelledData, setFilterCancelledData] = useState([])
    const isUpdateBooking = useSelector(store => store?.booking?.isUpdateBooking);

    const [searchvalue, setSearchValue] = useState('');
    const [searchby, setSerachBy] = useState('All');
    const [serchdisable, setSerachDisable] = useState(false);
    const [clrshow, setsclshow] = useState(false);

    const user = useSelector(store => store.userstatus.tba);
    const [userRights, setUserRights] = useState({});

    //#region handle Rights
    useEffect(() => {
        let pagelink = user?.find(x => x?.PageLink?.toLowerCase() === window?.location?.pathname?.toLowerCase());
        let mid = user?.find(x => x?.MenuId === pagelink?.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserRights(obj);
        });
    }, [user])
    //#endregion


    useEffect(() => {
        setSearchValue('');
        setSerachBy('All');
        if (activeTab) {
            localStorage.setItem("tab", activeTab?.toString());
        }
    }, [activeTab])

    function formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return date ? [day, month, year].join('/') : '';
    }


    const getCMSupcommingbooking = async () => {
        const CMSupcommingbooking = await getAllData('/master/v1/getCMSupcommingbooking');
        if (CMSupcommingbooking?.status) {
            CMSupcommingbooking?.data?.map((obj, index) => {
                obj['SrNo'] = index + 1;
                obj['PaymentStatus'] = obj?.PaymentStatus === 1 ? 'Successful' : '';
                obj['CreatedDateformat'] = formatDate(obj?.CreatedDate);
                obj['StartDateformat'] = formatDate(obj?.StartDate);
                obj['EndDateformat'] = formatDate(obj?.EndDate);
                obj['StatusName'] = obj.BookingCondition === 1 ? 'Pending' : obj.BookingCondition === 0 ? 'Confirmed' : '';
                return obj;
            });

            setUpcomingData(CMSupcommingbooking?.data ? CMSupcommingbooking?.data : []);
            setFilterUpcomingData(CMSupcommingbooking?.data ? CMSupcommingbooking?.data : []);
            dispatch(updatebookingend());
        }
        else {
            setUpcomingData([]);
            setFilterUpcomingData([]);
        }
    }

    const getCMSongoingbooking = async () => {
        const CMSongoingbooking = await getAllData('/master/v1/getCMSongoingbooking');
        if (CMSongoingbooking?.status) {
            CMSongoingbooking?.data?.map((obj, index) => {
                obj['SrNo'] = index + 1;
                obj['PaymentStatus'] = obj?.PaymentStatus === 1 ? 'Successful' : '';
                obj['CreatedDateformat'] = formatDate(obj?.CreatedDate);
                obj['StartDateformat'] = formatDate(obj?.StartDate);
                obj['EndDateformat'] = formatDate(obj?.EndDate);
                obj['StatusName'] = obj.BookingCondition === 1 ? 'Pending' : obj.BookingCondition === 0 ? 'Confirmed' : '';
                return obj;
            });

            setOngoingData(CMSongoingbooking?.data ? CMSongoingbooking?.data : []);
            setFilterOngoingData(CMSongoingbooking?.data ? CMSongoingbooking?.data : []);
        }
        else {
            setOngoingData([]);
            setFilterOngoingData([]);
        }
    }


    const getCMScompletedbooking = async () => {
        const CMScompletedbooking = await getAllData('/master/v1/getCMScompletedbooking');
        if (CMScompletedbooking?.status) {
            CMScompletedbooking?.data?.map((obj, index) => {
                obj['SrNo'] = index + 1;
                obj['PaymentStatus'] = obj?.PaymentStatus === 1 ? 'Successful' : '';
                obj['CreatedDateformat'] = formatDate(obj?.CreatedDate);
                obj['StartDateformat'] = formatDate(obj?.StartDate);
                obj['EndDateformat'] = formatDate(obj?.EndDate);
                obj['StatusName'] = obj.BookingCondition === 1 ? 'Pending' : obj.BookingCondition === 0 ? 'Confirmed' : '';
                return obj;
            });

            setCompletedData(CMScompletedbooking?.data ? CMScompletedbooking?.data : []);
            setFilterCompletedData(CMScompletedbooking?.data ? CMScompletedbooking?.data : []);
        }
        else {
            setCompletedData([]);
            setFilterCompletedData([]);
        }
    }

    const getCMScancelledbooking = async () => {
        const CMScancelledbooking = await getAllData('/master/v1/getCMScancelledbooking');
        if (CMScancelledbooking?.status) {
            CMScancelledbooking?.data?.map((obj, index) => {
                obj['SrNo'] = index + 1;
                obj['PaymentStatus'] = obj?.PaymentStatus === 1 ? 'Successful' : '';
                obj['CreatedDateformat'] = formatDate(obj?.CreatedDate);
                obj['StartDateformat'] = formatDate(obj?.StartDate);
                obj['EndDateformat'] = formatDate(obj?.EndDate);
                obj['StatusName'] = obj.BookingCondition === 1 ? 'Pending' : obj.BookingCondition === 0 ? ' Cancellation Approved' : '';
                return obj;
            });

            setCancelledData(CMScancelledbooking?.data ? CMScancelledbooking?.data : []);
            setFilterCancelledData(CMScancelledbooking?.data ? CMScancelledbooking?.data : []);
            dispatch(updatebookingend());
        }
        else {
            setCancelledData([]);
            setFilterCancelledData([]);
        }
    }

    //#region Call All API

    useEffect(() => {
        async function fetchData() {
            dispatch(loaderActions.start());
            const statusoftoken = await verifytoken();
            if (statusoftoken) {
                await Promise.all([
                    getCMSupcommingbooking(),
                    getCMSongoingbooking(),
                    getCMScompletedbooking(),
                    getCMScancelledbooking(),
                ])
                dispatch(loaderActions.end());
            }
            else {
                dispatch(loaderActions.end());
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' });
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        if (isUpdateBooking) {
            setSearchValue('');
            setSerachBy('All');
            async function updateData() {
                dispatch(loaderActions.start());
                const statusoftoken = await verifytoken();
                if (statusoftoken) {
                    if (activeTab === 'Upcoming') {
                        getCMSupcommingbooking();
                    }
                    else if (activeTab === 'Cancelled') {
                        getCMScancelledbooking();
                    }
                    dispatch(loaderActions.end());
                }
                else {
                    dispatch(loaderActions.end());
                    ClearLocalStorage();
                    dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' });
                }
            }
            updateData()
        }
    }, [isUpdateBooking, activeTab])
    //#endregion

    //#region handle search value
    const serachoption = useMemo(() => {
        let searchdata = [];
        searchdata = searchDropdownList?.map((search) => ({
            label: search?.name,
            value: search?.value
        }));
        searchdata?.push({ label: 'All', value: 'All' });
        return searchdata;
    }, [searchDropdownList])

    useEffect(() => {
        if (searchvalue?.trim()?.length <= 0) {
            getCMSupcommingbooking();
            getCMSongoingbooking();
            getCMScompletedbooking();
            getCMScancelledbooking();
        }
    }, [searchvalue])


    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }
    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            // getCMSupcommingbooking();
            // getCMSongoingbooking();
            // getCMScompletedbooking();
            // getCMScancelledbooking();
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    };

    const matchesSearchValueAll = (issec, fields) => {
        let search = searchvalue
        return fields.some((data) =>
            issec[data]?.toString()?.toLowerCase()?.includes(search?.toLowerCase())
        );
    };

    const getFilterData = useMemo(() => {
        switch (activeTab) {
            case 'Upcoming': return filterUpcomingData?.length > 0 ? filterUpcomingData : []
            case 'Ongoing': return filterOngoingData?.length > 0 ? filterOngoingData : []
            case 'Completed': return filterCompletedData?.length > 0 ? filterCompletedData : []
            case 'Cancelled': return filterCancelledData?.length > 0 ? filterCancelledData : []

            default: return [];
        }
    }, [activeTab, filterCancelledData, filterCompletedData, filterOngoingData, filterUpcomingData])

    const handleSearch = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby?.length > 0) {
            let filteritem = getFilterData;
            let filterdata;
            if (searchby === 'All') {
                filterdata = filteritem?.filter((issec) => {
                    let keys = serachoption?.filter((fil) => fil?.value !== 'All' ? fil?.value : '')?.map((obj) => obj?.value)
                    return matchesSearchValueAll(issec, keys) && issec
                });
            }
            else {
                filterdata = filteritem?.filter(issec => issec?.[`${searchby}`]?.toString()?.toLowerCase()?.includes(searchvalue?.toLowerCase()));
            }
            filterdata?.map((obj, index) => {
                obj['SrNo'] = index + 1
                return obj
            })

            if (activeTab === 'Upcoming') {
                setUpcomingData(filterdata ? filterdata : []);
            }
            else if (activeTab === 'Ongoing') {
                setOngoingData(filterdata ? filterdata : []);
            }
            else if (activeTab === 'Completed') {
                setCompletedData(filterdata ? filterdata : []);
            }
            else if (activeTab === 'Cancelled') {
                setCancelledData(filterdata ? filterdata : []);
            }
        }
    }

    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);

        getCMSupcommingbooking();
        getCMSongoingbooking();
        getCMScompletedbooking();
        getCMScancelledbooking();
    }
    //#endregion


    return (
        <>
            <div className="container-fluid mt-4 heading_color">
                <h3>All Bookings</h3>
            </div>
            <div className="container-fluid pb-5 mb-3">
                <div className="mb-3 row mx-1">
                    <div className="col-lg-12 col-md-12 col-sm-12 mb-lg-1">
                        <div className="row d-flex justify-content-center align-items-center flex-wrap">
                            <div className='col-12 container-fluid tab_section px-0'>
                                <ul className="nav nav-pills nav-justified mb-3 pt-3" id="pills-tab" role="tablist">

                                    <li className="nav-item mx-lg-3 mx-md-1 mx-5 mt-1" role="presentation">
                                        <button className={`nav-link ${activeTab === 'Upcoming' ? 'active' : ''}`}
                                            id='upcoming-info-tab'
                                            data-bs-toggle="tab"
                                            data-bs-target="#upcomingInfo"
                                            type="button" role="tab" aria-controls="upcomingInfo"
                                            aria-selected="false"
                                            onClick={() => setActiveTab('Upcoming')}
                                        ><strong>Upcoming</strong></button>
                                    </li>

                                    <li className="nav-item mx-lg-3 mx-md-1 mx-5 mt-1" role="presentation">
                                        <button className={`nav-link ${activeTab === 'Ongoing' ? 'active' : ''}`}
                                            id={'ongoing-info-tab'}
                                            data-bs-toggle="tab"
                                            data-bs-target="#ongoingInfo"
                                            type="button" role="tab" aria-controls="ongoingInfo"
                                            aria-selected="false"
                                            onClick={() => setActiveTab('Ongoing')}
                                        ><strong>Ongoing</strong></button>
                                    </li>

                                    <li className="nav-item mx-lg-3 mx-md-1 mx-5 mt-1" role="presentation">
                                        <button className={`nav-link ${activeTab === 'Completed' ? 'active' : ''}`}
                                            id={'completed-info-tab'}
                                            data-bs-toggle="tab"
                                            data-bs-target="#completedInfo"
                                            type="button" role="tab" aria-controls="completedInfo"
                                            aria-selected="false"
                                            onClick={() => setActiveTab('Completed')}
                                        ><strong>Completed</strong></button>
                                    </li>

                                    <li className="nav-item mx-lg-3 mx-md-1 mx-5 mt-1" role="presentation">
                                        <button className={`nav-link ${activeTab === 'Cancelled' ? 'active' : ''}`}
                                            id={'cancelled-info-tab'}
                                            data-bs-toggle="tab"
                                            data-bs-target="#cancelledInfo"
                                            type="button" role="tab" aria-controls="cancelledInfo"
                                            aria-selected="false"
                                            onClick={() => setActiveTab('Cancelled')}
                                        ><strong>Cancelled</strong></button>
                                    </li>

                                </ul>

                                <div className="container-fluid form_border my-3">

                                    <div className="mb-2" >
                                        <div className="my-4" >

                                            <div className="row">
                                                <div className='d-lg-flex d-md-flex d-sm-block'>
                                                    <div className="p-2 col-lg-4 col-md-4 col-sm-12">
                                                        <h4 className='align-content-center'> {activeTab} Bookings </h4>
                                                    </div>
                                                    <div className="col-lg-8 col-md-8 col-sm-12">
                                                        <div className="row">
                                                            <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                                                                <div className="p-2 col-lg-4 col-md-4 col-sm-12">
                                                                    <Select
                                                                        className="dropdown"
                                                                        options={serachoption}
                                                                        value={serachoption?.filter(function (serachoption) {
                                                                            return serachoption.value === searchby;
                                                                        })}// set list of the dataalue 
                                                                        onChange={handleSearchBy} // assign onChange function
                                                                        isDisabled={serchdisable ? true : false}
                                                                        isSearchable={false}

                                                                    />
                                                                </div>
                                                                <div className="p-2 col-lg-4 col-md-4 col-sm-12">
                                                                    <div className='clrbutton'>
                                                                        <input
                                                                            type="text"
                                                                            onKeyDown={onKeyDownHandler}
                                                                            id="btnSearchText"
                                                                            className="form-control"
                                                                            placeholder='Enter keyword'
                                                                            name="SearchValue"
                                                                            onChange={handleChangeSearchValue}
                                                                            value={searchvalue || ''}
                                                                            disabled={serchdisable ? true : false}
                                                                            autoComplete='off'
                                                                        />
                                                                        <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                                                    </div>
                                                                </div>
                                                                <div className="p-2 text-center">
                                                                    <button type="button" className="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleSearch}>Search</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className={`tab-pane fade ${activeTab === 'Upcoming' ? 'active show' : ''}`} id="upcomingInfo" role="tabpanel" aria-labelledby="upcoming-info-tab">
                                        {
                                            activeTab === 'Upcoming' &&
                                            <BookingTable activeTab={activeTab} listData={UpcomingData} setSearchDropdownList={setSearchDropdownList} userRights={userRights} />
                                        }
                                    </div>
                                    <div className={`tab-pane fade ${activeTab === 'Ongoing' ? 'active show' : ''}`} id="ongoingInfo" role="tabpanel" aria-labelledby="ongoing-info-tab">
                                        {
                                            activeTab === 'Ongoing' &&
                                            <BookingTable activeTab={activeTab} listData={OngoingData} setSearchDropdownList={setSearchDropdownList} userRights={userRights} />
                                        }
                                    </div>
                                    <div className={`tab-pane fade ${activeTab === 'Completed' ? 'active show' : ''}`} id="completedInfo" role="tabpanel" aria-labelledby="completed-info-tab">
                                        {
                                            activeTab === 'Completed' &&
                                            <BookingTable activeTab={activeTab} listData={CompletedData} setSearchDropdownList={setSearchDropdownList} userRights={userRights} />
                                        }
                                    </div>
                                    <div className={`tab-pane fade ${activeTab === 'Cancelled' ? 'active show' : ''}`} id="cancelledInfo" role="tabpanel" aria-labelledby="cancelled-info-tab">
                                        {
                                            activeTab === 'Cancelled' &&
                                            <BookingTable activeTab={activeTab} listData={CancelledData} setSearchDropdownList={setSearchDropdownList} userRights={userRights} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllBookings
