import React, { useMemo } from 'react';
import { useEffect, useState } from "react";
import '../../../assets/css/master.css';
import Select from 'react-select';
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../../services/ApiCallingUserServices';
import { onlyAlphabets, generatePDF, isValidImage } from '../../commonClass/CommonValidation'
import Switch from 'react-switch';
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../_actions/loader.actions";
import { ExportExcel } from '../../commonClass/ExportExcel';
import { ConfirmationModal } from '../../../customControl/ConfirmationModal';
import { useNavigate } from 'react-router-dom';
import RichTextBox from '../../../customControl/RichTextBox';
import { htmlToText } from 'html-to-text';
import { ImageDelete, ImageUpload } from '../../../ImageUpload/ImageCommon';
import CustomTable from '../../../customControl/CustomTable';

const Locationmaster = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formError, setFormError] = useState({})

    const [formValue, setFormValue] = useState(
        {
            LocationId: 0,
            ContinentId: 0,
            SubContinentId: 0,
            CountryId: 0,
            RegionId: 0,
            StateId: 0,
            City: '',
            CityDetail: '',
            PlaceImageName: '',
            PlaceImagePath: '',
            Latitude: '',
            Longitude: '',
            IsActive: false,
        }
    );

    // var store = useContext(ReactReduxContext).store.getState();
    //////////////////////////////declaration of variable/////////////////////////////
    //#region declaration of variable///////////////////////////////////////////////
    const [modalShow, setModalShow] = useState(false);
    const [activeId, setActiveId] = useState(false);
    const [locationlistdata, setLocationListData] = useState([]);
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [disabled, setDisabled] = useState(false);
    const [editdisabled, setEditDisabled] = useState(true);
    const [serchdisable, setSerachDisable] = useState(false);
    const [filteritems, setFilterItems] = useState([]);
    const [locationduplicateerrorMessage, setLocationDuplicateErrorMessage] = useState('');
    const [continentData, setContinentData] = useState([]);

    const [countryData, setCountryData] = useState([]);
    const [subcontinentData, setSubcontinentData] = useState([]);

    const [regionData, setRegionData] = useState([]);

    const [stateData, setStateData] = useState([]);
    const [clrshow, setsclshow] = useState(false);
    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);
    const [userexport, setUserExport] = useState(true);

    const serachoption = [
        {
            label: 'Continent',
            value: '1'
        },
        {
            label: 'Sub continent',
            value: '11'
        },
        {
            label: 'Country',
            value: '2'
        },
        {
            label: 'Region',
            value: '3'
        },
        {
            label: 'State',
            value: '4'
        },
        {
            label: 'City',
            value: '5'
        },
        {
            label: 'Latitude',
            value: '6'
        },
        {
            label: 'Longitude',
            value: '7'
        },
        {
            label: 'Active',
            value: '8'
        },
        // {
        //     label: 'Created Date',
        //     value: '10'
        // },
        {
            label: 'All',
            value: '9'
        },

    ];

    const [searchvalue, setSearchValue] = useState();
    const [searchby, setSerachBy] = useState('9');

    //#endregion ////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////use effect section////////////////////////////////
    //#region use effect section

    const getContinentData = async () => {
        const ContinentData = await getAllData("/master/v1/getlocationcontinent");
        setContinentData(ContinentData.data);
    }
    const continentList = continentData?.map(value => ({
        label: value.continentName,
        value: value.continentId
    }));


    const getlocationsubcontinent = async () => {
        const SubcontinentData = await getAllData('/master/v1/getlocationsubcontinent');
        setSubcontinentData(SubcontinentData.data);
    }

    const SubcontinentList = useMemo(() => {
        if (subcontinentData?.length <= 0 || formValue.ContinentId === 0) {
            return [];
        }
        else {
            return subcontinentData?.filter(obj => obj.ContinentId === formValue.ContinentId)?.map(value => ({
                label: value.SubContinentName,
                value: value.SubContinentId
            }));
        }
    }, [formValue.ContinentId, subcontinentData])

    const getCountryData = async () => {
        const CountryData = await getAllData('/master/v1/getlocationcountry');
        setCountryData(CountryData.data);
    }

    const CountryList = useMemo(() => {
        if (countryData?.length <= 0 || formValue.SubContinentId === 0) {
            return [];
        }
        else {
            return countryData?.filter(obj => obj.subContinentId === formValue.SubContinentId)?.map(value => ({
                label: value.countryName,
                value: value.countryId
            }));
        }

    }, [formValue.SubContinentId, countryData])


    countryData?.map(value => ({
        label: value.countryName,
        value: value.countryId
    }));

    const getRegiondata = async () => {
        const Regiondata = await getAllData('/master/v1/getlocationregion');
        setRegionData(Regiondata.data)
    }
    const RegionList = regionData?.map(value => ({
        label: value.regionName,
        value: value.regionId
    }));

    const getStatedata = async () => {
        const Statedata = await getAllData('/master/v1/getlocationstate');
        setStateData(Statedata.data)
    }

    const StateList = useMemo(() => {
        if (stateData?.length <= 0 || formValue.RegionId === 0) {
            return [];
        }
        else {
            return stateData?.filter(obj => obj.regionId === formValue.RegionId)?.map(value => ({
                label: value.stateName,
                value: value.stateId
            }));
        }
    }, [formValue.RegionId, stateData])


    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            //if (Window.$name && Window.$userToken) {
            getContinentData();
            getCountryData();
            getlocationsubcontinent();
            getRegiondata();
            getStatedata();
            getLocationDataList().then(() => { dispatch(loaderActions.end()) });
            setitems();
            //setTimeout(setLoaderEnd(),5000);
        }
        else {
            ClearLocalStorage();
            // alert('Your Session Expired. Please Login Again!');
            // navigate('/');
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, []);
    useEffect(() => {
        let pagelink = user?.find(x => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);

        });
    }, [user])

    const onSelectChange = (name, e) => {
        setEditDisabled(true);
        setFormError({});
        setImageError('');
        setLocationDuplicateErrorMessage('');
        if (name === "ContinentId") {
            setFormValue((form) => ({ ...form, ContinentId: e.value, SubContinentId: 0, CountryId: 0, StateId: 0, RegionId: 0 }))
        }
        else if (name === "SubContinentId") {
            setFormValue((form) => ({ ...form, SubContinentId: e.value, CountryId: 0, StateId: 0, RegionId: 0 }))
        }
        else if (name === "CountryId") {
            setFormValue((form) => ({ ...form, CountryId: e.value, StateId: 0, RegionId: 0 }))
        }
        else if (name === "RegionId") {
            setFormValue((form) => ({ ...form, RegionId: e.value, StateId: 0 }))
        }
        else if (name === "StateId") {
            setFormValue((form) => ({ ...form, StateId: e.value }))
        }
        else {
            setFormValue((form) => ({ ...form, [name]: e.value }))
        }

    }

    //#endregion
    ///////////////////////////////////////////////////////////////////////////////////

    ////////////handle section of table add, edit,retrive/////////////////////////////
    //#region handle section of table add, edit,retrive

    // set form date for add, edit and view

    //#region set form date for add, edit and view

    const validateData = () => {
        let status = true;
        let error = {}
        if (formValue.ContinentId === 0) {
            error["ContinentId"] = 'Please select Continent.';
            status = false;
        }
        if (formValue.ContinentId !== 0 && formValue.SubContinentId === 0) {
            error["SubContinentId"] = 'Please select Sub-Continent.';
            status = false;
        }
        if (formValue.SubContinentId !== 0 && formValue.CountryId === 0) {
            error["CountryId"] = 'Please select Country.';
            status = false;
        }
        if (formValue.CountryId === 1 && formValue.RegionId === 0) {
            error["RegionId"] = 'Please select region.';
            status = false;
        }
        if (formValue.CountryId === 1 && formValue.StateId === 0 && formValue.RegionId !== 0) {
            error["StateId"] = 'Please select State.';
            status = false;
        }
        if (typeof formValue.City === 'string' && formValue.City?.trim()?.length === 0) {
            error["City"] = 'Please enter City name.';
            status = false;
        }
        if (typeof formValue.PlaceImagePath === 'string' && formValue.PlaceImagePath?.trim()?.length === 0) {
            error["CityImage"] = 'Please upload City Image.';
            status = false;
            setImageError('');
        }
        if (formValue.PlaceImagePath === null || formValue.PlaceImagePath === '') {
            error["CityImage"] = 'Please upload City Image.';
            status = false;
            setImageError('');
        }
        if (typeof formValue.Latitude === 'string' && formValue.Latitude?.trim()?.length > 0) {
            if (typeof formValue.Longitude === 'string' && formValue.Longitude?.trim()?.length === 0) {
                error["Longitude"] = 'Please enter Longitude.';
                status = false;
            }

        }
        if (typeof formValue.Longitude === 'string' && formValue.Longitude?.trim()?.length > 0) {
            if (typeof formValue.Latitude === 'string' && formValue.Latitude?.trim()?.length === 0) {
                error["Latitude"] = 'Please enter Latitude.';
                status = false;
            }

        }
        if (typeof formValue.CityDetail === 'string' && formValue.CityDetail?.trim()?.length === 0) {
            error["CityDetail"] = 'Please enter City Details.';
            status = false;
        }

        if (error[0] === "ContinentId" || error[0] === "SubContinentId" || error[0] === "CountryId" || error[0] === "RegionId" || error[0] === "StateId") {
            eval(error[0]).current.focus();
        }
        else {
            let doc = document.getElementById(error[0]);
            if (doc !== null)
                doc.focus();
        }
        setFormError(error)
        return status;
    }

    const handleAddClick = async () => {
        if (validateData()) {
            let statusoftoken = await verifytoken();
            if (statusoftoken) {
                let filterdata = locationlistdata?.filter(issec =>
                    issec.locationId !== formValue.LocationId
                    && issec.continentId === formValue.ContinentId
                    && issec.subContinentId === formValue.SubContinentId
                    && issec.countryId === formValue.CountryId
                    && issec.regionId === formValue.RegionId
                    && issec.stateId === formValue.StateId
                    && issec.city?.toLowerCase()?.trim() === formValue.City?.toLowerCase()?.trim()
                );

                if (filterdata?.length > 0) {
                    setLocationDuplicateErrorMessage('You can not enter duplicate value.');
                    return;
                }
                if (statusoftoken) {
                    dispatch(loaderActions.start());
                    formValue.City = formValue.City?.trim(); // trim city name
                    if (buttonaddeditText === "Add") {
                        const LocationData = await postUsersData("/master/v1/insertlocation", formValue);
                        if (!LocationData.status && !LocationData.data) {
                            setLocationDuplicateErrorMessage(LocationData.message);
                            dispatch(loaderActions.end());
                            return;
                        }
                        if (LocationData.status) {
                            if (LocationData.data > 0) {
                                try {
                                    await handleimageupload('CMS/Location/', formValue.PlaceImageName, fileselected);
                                    setitems();
                                    dispatch({ type: 'ALERTING', message: LocationData.message })
                                    clear();
                                    dispatch(loaderActions.end());
                                }
                                catch (err) {
                                    dispatch({ type: 'ALERTING', message: 'Try again later!', err })
                                    setDisabled(false);
                                    setEditDisabled(true);
                                    dispatch(loaderActions.end());
                                }
                            }
                            else {
                                dispatch({ type: 'ALERTING', message: LocationData.message })
                                dispatch(loaderActions.end());
                            }
                        }
                        else {
                            dispatch({ type: 'ALERTING', message: LocationData.message })
                            dispatch(loaderActions.end());
                        }
                    }
                    else {
                        const LocationData = await patchUsersData("/master/v1/updatelocation", formValue);
                        if (!LocationData.status && !LocationData.data) {
                            setLocationDuplicateErrorMessage(LocationData.message);
                            dispatch(loaderActions.end());
                            return;
                        }

                        if (LocationData.status) {
                            if (LocationData.data > 0) {
                                try {
                                    if (placeimagepath.length === 0) {
                                        await handleimageupload('CMS/Location/', formValue.PlaceImageName, fileselected);
                                    }
                                    try {
                                        if (placeimagepath.length === 0) {
                                            await handleimageDelete(placeimagedelete);
                                        }
                                    }
                                    catch (err) {
                                        dispatch({ type: 'ALERTING', message: 'Image delete transaction failed', err })
                                        setDisabled(false);
                                        setEditDisabled(true);
                                        dispatch(loaderActions.end());
                                        return;
                                    }
                                    setitems();
                                    dispatch({ type: 'ALERTING', message: LocationData.message })
                                    clear();
                                    dispatch(loaderActions.end());
                                }
                                catch (err) {
                                    dispatch({ type: 'ALERTING', message: 'Image save transaction faild!', err })
                                    setDisabled(false);
                                    setEditDisabled(true);
                                    dispatch(loaderActions.end());
                                    return;
                                }
                            }
                            else {
                                dispatch({ type: 'ALERTING', message: LocationData.message })
                                dispatch(loaderActions.end());
                                return;
                            }
                        }
                        else {
                            dispatch({ type: 'ALERTING', message: LocationData.message })
                            dispatch(loaderActions.end());
                        }
                    }
                    dispatch(loaderActions.end());
                }
                else {
                    ClearLocalStorage();
                    dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
    }


    const handleEditMode = (id) => {
        setDisabled(false);
        setEditDisabled(false);
        setSerachDisable(true);
        setButtonAddEditText("Update");
        RetriveData(id);
    }

    const handleViewMode = (id) => {
        setEditDisabled(false);
        setDisabled(true);
        setSerachDisable(true);
        RetriveData(id);
    }

    function RetriveData(id) {
        setFormError({});
        setImageError('');
        setLocationDuplicateErrorMessage('');
        locationlistdata?.map(obj => {
            if (obj.locationId === id) {
                setFormValue((form) => ({
                    ...form,
                    ContinentId: obj.continentId,
                    SubContinentId: subcontinentData?.filter(el => el.ContinentId === obj.continentId && el.SubContinentId === obj.subContinentId)?.length > 0 ? obj.subContinentId : 0,
                    CountryId: countryData?.filter(el => el.subContinentId === obj.subContinentId && el.countryId === obj.countryId)?.length > 0 ? obj.countryId : 0,
                    RegionId: obj.countryId === 1 ? obj.regionId : 0,
                    StateId: stateData?.filter(el => el.regionId === obj.regionId && el.stateId === obj.stateId)?.length > 0 ? obj.stateId : 0,
                    LocationId: obj.locationId,
                    City: obj.city,
                    Latitude: obj.latitude,
                    Longitude: obj.longitude,
                    IsActive: obj.isActive,
                    CityDetail: obj.cityDetail != null ? obj.cityDetail : '',
                    PlaceImageName: obj.placeImageName,
                    PlaceImagePath: obj.placeImagePath
                }))
                setActiveId(obj.isActive);

                setPlaceImagePath('https://static-ethics.sgp1.digitaloceanspaces.com/etm/' + obj.placeImagePath);
                setPlaceImageName(obj.placeImageName);
                setplaceimageDelete(obj.placeImagePath);
                setRetriveComplete(htmlToText(obj.cityDetail).length);
            }
        });
    }
    //#endregion //////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////

    ///////////////////////fill dropdown and handle input value//////////////////////////
    //#region fill dropdown and handle input value
    const getLocationDataList = async () => {
        const LocationListData = await getAllData("/master/v1/getalllocation");
        setLocationListData(LocationListData.data);
    }

    const [richboxheight, setRichBoxHeight] = useState(false)
    const [retrivecomplete, setRetriveComplete] = useState(0);
    const citydetailconfig = useMemo(
        () => ({
            readonly: false, // all options from https://xdsoft.net/jodit/doc/,
            height: '100%',
            maxHeight: richboxheight === true ? 200 : 500,
            width: '100%',
            statusbar: false,
            disablePlugins: ["source"],
            removeButtons: ["about", "link"],
            toolbarAdaptive: false,
            // autofocus: true,
            defaultMode: "1",
            disabled: disabled ? true : false
        }),
        [disabled, richboxheight]
    );

    const handleCityDetailChange = (e) => {
        setFormError({});
        setImageError('');
        setLocationDuplicateErrorMessage('');
        const str = e.replace('<p><br></p>', '')
        formValue.CityDetail = str
        const html = htmlToText(str);
        if (html.length !== (retrivecomplete)) {
            setEditDisabled(true);
        }
    }
    useEffect(() => {
        const richtexteboxheight = document.getElementById("layoutSidenav_content").clientWidth
        if (richtexteboxheight > 1253) {
            setRichBoxHeight(true)
        }
        else {
            setRichBoxHeight(false)
        }
    }, []);

    const [placeimagedelete, setplaceimageDelete] = useState('');
    const [placeimagepath, setPlaceImagePath] = useState('');
    const [placeimagename, setPlaceImageName] = useState();
    const [fileselected, setSelectedFile] = useState();
    const [imageError, setImageError] = useState('');
    const handleUploadImageLoad = (e) => {
        if (isValidImage(e.target.files[0].name)) {
            const files = e.target.files;
            const filecom = getDateCombination();
            const filename = filecom + '_' + '1' + '_' + (files[0].name.replace(/ /g, "_")).replace(/-/g, '');
            formValue.PlaceImageName = filename
            formValue.PlaceImagePath = 'CMS/Location/' + filename
            setSelectedFile(e.target.files[0]);
            setEditDisabled(true);
            setFormError({});
            setImageError('');
            setLocationDuplicateErrorMessage('');
            setPlaceImagePath('');
            setPlaceImageName('');
        }
        else {
            document.getElementById("CityImage").value = "";
            setFormValue({ ...formValue, PlaceImageName: '', PlaceImagePath: '' });
            setEditDisabled(true);

            setFormError({});
            setLocationDuplicateErrorMessage('');
            setImageError("Please select Only Image")
            setPlaceImagePath('');
            setPlaceImageName('');
            return;
        }
    }
    const removeImage = () => {
        document.getElementById("CityImage").value = "";
        formValue.PlaceImagePath = ''
        formValue.PlaceImageName = ''
        setSelectedFile();
        setEditDisabled(true);
        setPlaceImagePath('');
        setPlaceImageName('');

        setFormError({});
        setImageError('');
        setLocationDuplicateErrorMessage('');
    };

    function getDateCombination() {
        var today = new Date();
        let date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
        let time = today.getHours().toString() + (today.getMinutes() + 1).toString() + today.getSeconds().toString();
        return date + '_' + time;
    }
    const handleimageupload = async (pathdirectory, filename, file) => {
        await ImageUpload(pathdirectory, filename, file);
    }
    const handleimageDelete = async (filename) => {
        await ImageDelete(filename);
    }

    const onChange = (e) => {
        var result;
        if ([e.target.name][0] === 'City') {

            result = onlyAlphabets(e.target.value)
        }
        else {
            result = e.target.value
        }

        setFormValue({ ...formValue, [e.target.name]: result });
        setEditDisabled(true);

        setFormError({});
        setImageError('');
        setLocationDuplicateErrorMessage('');
    };
    // const handleisActive = (e) => {
    //     { formValue.IsActive = e }
    //     setActiveId(!activeId)
    //     setEditDisabled(true);
    // }
    const handleisActive = (e) => {
        if (buttonaddeditText === 'Add') {
            { formValue.IsActive = e }
            setActiveId(!activeId)

            setFormError({});
            setImageError('');
            setLocationDuplicateErrorMessage('');
        }
        else {
            setModalShow(true);
        }
    }

    const handleConfirmation = (e) => {
        //setYesNoConfirm(e)
        if (e === true) {
            { formValue.IsActive = !activeId }
            setActiveId(!activeId)
            setEditDisabled(true);

            setFormError({});
            setImageError('');
            setLocationDuplicateErrorMessage('');
        }
        setModalShow(false);
    }

    const handleClearClick = () => {
        clear();
    }
    function clear() {
        setFormError({});
        setImageError('');
        setLocationDuplicateErrorMessage('');
        setFormValue((form) => ({
            ...form,
            LocationId: 0,
            ContinentId: 0,
            SubContinentId: 0,
            CountryId: 0,
            RegionId: 0,
            StateId: 0,
            City: '',
            CityDetail: '',
            PlaceImageName: '',
            PlaceImagePath: '',
            Latitude: '',
            Longitude: '',
            IsActive: false,
        }))
        setActiveId(false);
        getLocationDataList();
        setButtonAddEditText('Add');
        setDisabled(false);
        setEditDisabled(true);
        setSerachDisable(false);
        setSerachBy('9');
        setSearchValue('');
        setsclshow(false)
        document.getElementById("CityImage").value = "";
        setSelectedFile();
        setPlaceImagePath('')
        setPlaceImageName('');
        setplaceimageDelete('');
        if (document.querySelector('.jodit-wysiwyg') !== null) {
            document.querySelector('.jodit-wysiwyg').innerHTML = '';
        }
    }
    //#endregion
    /////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////// handle table pagignation.//////////////////////////////////////////
    //#region table Pagination
    const columns = [
        {
            name: 'Continent',
            sortable: true,
            float: "left",
            selector: (location) => location.continentName,
        },
        {
            name: 'Sub continent',
            sortable: true,
            float: "left",
            selector: (location) => location.subContinentName,
        },

        {
            name: 'Country',
            sortable: true,
            float: "left",
            selector: (location) => location.countryName
        },
        {
            name: 'Region',
            sortable: true,
            float: "left",
            selector: (location) => location.regionName
        },
        {
            name: 'State',
            sortable: true,
            float: "left",
            selector: (location) => location.stateName
        },
        {
            name: 'City',
            sortable: true,
            float: "left",
            selector: (location) => location.city
        },
        {
            name: 'Latitude',
            sortable: true,
            float: "left",
            selector: (location) => location.latitude
        },
        {
            name: 'Longitude',
            sortable: true,
            float: "left",
            selector: (location) => location.longitude
        },
        {
            name: 'Active',
            sortable: true,
            float: "left",
            selector: (location) => location.isActive ? 'Active' : 'InActive'
        },
        {
            name: 'Created Date',
            sortable: true,
            float: "left",
            selector: (location) => formatDate(location.createdDate)
        },
        {
            name: 'Edit',
            // float:"right",
            selector: (location) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditMode(location.locationId)}></i></button>
        },
        {
            name: 'View',
            // float:"right",
            selector: (location) => <i className="fa fa-eye" onClick={() => handleViewMode(location.locationId)}></i>
        }
    ];
    //#endregion
    /////////////////////////////////////////////////////////////////////////////////////

    /////////////////////handle search value//////////////////////////////////////////////
    //#region handle search value////////////////////////////////////////////////////////
    const handleSearchBy = (e) => {
        setSerachBy(e.value);
    }

    const setitems = async () => {
        const LocationListData = await getAllData("/master/v1/getalllocation");
        setFilterItems(LocationListData.data);
    }

    const handleChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getLocationDataList();
            setsclshow(false)
        }
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            handleClickSearchValue();
        }
    };

    const handleClickSearchValue = () => {
        if (typeof searchvalue === 'string' && searchvalue?.trim()?.length !== 0 && searchby > 0) {
            setitems();
            var filteritem = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '11') {
                filterdata = filteritem?.filter(issec => issec.subContinentName?.toLowerCase().includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '2') {
                filterdata = filteritem?.filter(issec => issec.countryName?.toLowerCase().includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '3') {
                filterdata = filteritem?.filter(issec => issec.regionName?.toLowerCase().includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '4') {
                filterdata = filteritem?.filter(issec => issec.stateName?.toLowerCase().includes(searchvalue?.toLowerCase()));
                filterdata = filteritem?.filter(issec => issec.stateName?.toLowerCase().includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '5') {
                filterdata = filteritem?.filter(issec => issec.city?.toLowerCase().includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '6') {
                filterdata = filteritem?.filter(issec => issec.latitude?.toLowerCase().includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '7') {
                filterdata = filteritem?.filter(issec => issec.longitude?.toLowerCase().includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '10') {
                filterdata = filteritem?.filter(issec => formatDate(issec.createdDate)?.toLowerCase().includes(searchvalue?.toLowerCase()));
            }
            else if (searchby === '8') {
                if (searchvalue?.toLowerCase() === 'active'?.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true);
                }
                else if (searchvalue?.toLowerCase() === 'inactive'?.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                else if ('active'?.toLowerCase().includes(searchvalue?.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'?.toLowerCase().includes(searchvalue?.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.isActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => formatDate(issec.createdDate)?.toLowerCase().includes("%#$&%^"));
                }
            }
            else if (searchby === '9') {
                if (searchvalue?.toLowerCase() === 'active'?.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.subContinentName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.countryName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.regionName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.latitude?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.longitude?.toLowerCase().includes(searchvalue?.toLowerCase()) || formatDate(issec.createdDate)?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.isActive === true);
                }
                else if (searchvalue?.toLowerCase() === 'inactive'?.toLowerCase()) {
                    filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.subContinentName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.countryName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.regionName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.latitude?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.longitude?.toLowerCase().includes(searchvalue?.toLowerCase()) || formatDate(issec.createdDate)?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.isActive === false);
                }
                else if ('active'?.toLowerCase().includes(searchvalue?.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.subContinentName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.countryName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.regionName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.latitude?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.longitude?.toLowerCase().includes(searchvalue?.toLowerCase()) || formatDate(issec.createdDate)?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.isActive === true || issec.isActive === false);
                }
                else if ('inactive'?.toLowerCase().includes(searchvalue?.toLowerCase())) {
                    filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.subContinentName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.countryName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.regionName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.latitude?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.longitude?.toLowerCase().includes(searchvalue?.toLowerCase()) || formatDate(issec.createdDate)?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.isActive === false);
                }
                else {
                    filterdata = filteritem?.filter(issec => issec.continentName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.subContinentName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.countryName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.regionName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.stateName?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.city?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.latitude?.toLowerCase().includes(searchvalue?.toLowerCase()) || issec.longitude?.toLowerCase().includes(searchvalue?.toLowerCase()) || formatDate(issec.createdDate)?.toLowerCase().includes(searchvalue?.toLowerCase()) || 'active'?.toLowerCase().includes(searchvalue?.toLowerCase()) || 'inactive'?.toLowerCase().includes(searchvalue?.toLowerCase()));
                }
            }
            setLocationListData(filterdata)
        }
    }
    //#endregion
    //////////////////////////////////////////////////////////////////////////////////////

    ////////////////handle export section///////////////////////////////////////////////
    //#region handle export section ////////////////////////////////////////////////////
    function PdfExport() {
        const columns = [
            "Continent",
            "Sub continent",
            "Country",
            "Region",
            "State",
            "City",
            "Latitude",
            "Longitude",
            "Active",
            "Created Date"
        ];
        var rows = [];

        for (let i = 0; i < locationlistdata?.length; i++) {
            var temp = [
                locationlistdata[i].continentName,
                locationlistdata[i].subContinentName,
                locationlistdata[i].countryName,
                locationlistdata[i].regionName,
                locationlistdata[i].stateName,
                locationlistdata[i].city,
                locationlistdata[i].latitude,
                locationlistdata[i].longitude,
                `${locationlistdata[i].isActive ? 'Active' : 'InActive'}`,
                formatDate(locationlistdata[i].createdDate)
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "LocationMaster", "Location Master")
    }
    // function ExportToExcel() {
    //     const fields = ['continentName', 'countryName', 'regionName', 'stateName', 'city', 'latitude', 'longitude', 'isActive'];
    //     const data = locationlistdata;
    //     const fileName = 'Locationmasterexcel'
    //     const exportType = 'xls'

    //     exportFromJSON({ data, fileName, fields, exportType })
    // }

    const ExportToExcel = () => {
        const dispalyname = ['Continent', 'Sub continent', 'Country', 'Region', 'State', 'City', 'Latitude', 'Longitude', 'Active', 'Created Date'];
        const fields = ['continentName', 'subContinentName', 'countryName', 'regionName', 'stateName', 'city', 'latitude', 'longitude', 'isActive', 'createdDate'];
        ExportExcel(dispalyname, fields, locationlistdata, "LocationMaster", "LocationMaster.xls");
    }


    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getLocationDataList();
    }
    //#endregion ///////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }
    return (
        <>
            <div className="container-fluid mt-4 heading_color">
                <h3>Location Master</h3>
            </div>
            <div className="container-fluid form_border py-5 mb-3">
                <form action="" >
                    <div className="mb-3 row mx-1">
                        <div className="col-lg-12 col-md-12 col-sm-12 pe-lg-5">
                            <div className="row">
                                {locationduplicateerrorMessage && <div className="text-danger pb-3 text-center"> {locationduplicateerrorMessage} </div>}
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label htmlFor="ContinentId" className="ms-lg-5 col-form-label required" >
                                                Continent</label>
                                        </div>
                                        <div className="col-lg-8 mb-1 col-md-12">
                                            <Select
                                                id='ContinentId'
                                                className="dropdown select_dropdown"
                                                options={continentList}
                                                value={continentList?.filter(function (continentList) {
                                                    return continentList.value === formValue.ContinentId;
                                                })}// set list of the data
                                                onChange={(e) => onSelectChange("ContinentId", e)} // assign onChange function
                                                isDisabled={disabled ? true : false}
                                                isSearchable={true}

                                            />
                                            {formError?.ContinentId && <p className="text-danger mb-0"> {formError?.ContinentId} </p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label htmlFor="SubContinentId" className="ms-lg-5 col-form-label required" >
                                                Sub continent</label>
                                        </div>
                                        <div className=" col-lg-8 mb-1 col-md-12">
                                            <Select
                                                id='SubContinentId'
                                                className="dropdown select_dropdown"
                                                options={SubcontinentList}
                                                value={SubcontinentList?.filter(function (data) {
                                                    return data.value === formValue.SubContinentId;
                                                })}// set list of the data
                                                onChange={(e) => onSelectChange("SubContinentId", e)} // assign onChange function
                                                isDisabled={disabled || formValue.ContinentId === 0 ? true : false}
                                                isSearchable={true}
                                            />
                                            {formError?.SubContinentId && <p className="text-danger mb-0"> {formError?.SubContinentId} </p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label htmlFor="CountryId" className="ms-lg-5 col-form-label required">Country</label>
                                        </div>
                                        <div className=" col-lg-8 mb-1 col-md-12">
                                            <Select
                                                id='CountryId'
                                                className="dropdown select_dropdown"
                                                options={CountryList}
                                                value={CountryList?.filter(function (CountryList) {
                                                    return CountryList.value === formValue.CountryId;
                                                })}// set list of the data
                                                onChange={(e) => onSelectChange("CountryId", e)}  // assign onChange function
                                                isDisabled={disabled || formValue.SubContinentId === 0 ? true : false}
                                                isSearchable={true}
                                            />
                                            {formError?.CountryId && <p className="text-danger mb-0"> {formError?.CountryId} </p>}
                                        </div>
                                    </div>
                                </div>



                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label htmlFor="RegionId" className={`ms-lg-5 col-form-label ${formValue.CountryId === 1 ? 'required' : ''} `}>Region</label>
                                        </div>
                                        <div className=" col-lg-8 mb-1 col-md-12">
                                            <Select
                                                id="RegionId"
                                                className="dropdown select_dropdown"
                                                options={RegionList}
                                                value={RegionList?.filter(function (RegionList) {
                                                    return RegionList.value === formValue.RegionId;
                                                })}// set list of the data
                                                onChange={(e) => onSelectChange("RegionId", e)}  // assign onChange function
                                                isDisabled={disabled || formValue.CountryId !== 1 || formValue.CountryId === 0 ? true : false}
                                                isSearchable={true}

                                            />
                                            {formError?.RegionId && <p className="text-danger mb-0"> {formError?.RegionId} </p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label htmlFor="StateId" className={`ms-lg-5 col-form-label ${formValue.CountryId === 1 ? 'required' : ''} `} >State</label>
                                        </div>
                                        <div className=" col-lg-8 mb-1 col-md-12">
                                            <Select
                                                id='StateId'
                                                className="dropdown select_dropdown"
                                                options={StateList}
                                                value={StateList?.filter(function (StateList) {
                                                    return StateList.value === formValue.StateId;
                                                })}// set list of the data
                                                onChange={(e) => onSelectChange("StateId", e)} // assign onChange function
                                                isDisabled={disabled || formValue.CountryId === 0 || formValue.CountryId !== 1 || formValue.RegionId === 0 ? true : false}
                                                isSearchable={true}
                                            />
                                            {formError?.StateId && <p className="text-danger mb-0"> {formError?.StateId} </p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label htmlFor="City" className="ms-lg-5 col-form-label required">City</label>
                                        </div>
                                        <div className=" col-lg-8 mb-1 col-md-12">
                                            <input type="text"
                                                id="City"
                                                placeholder="City"
                                                className="form-control"
                                                name="City"
                                                value={formValue.City}
                                                onChange={onChange}
                                                disabled={disabled} />
                                            {formError?.City && <p className="text-danger mb-0"> {formError?.City} </p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6 col-sm-6'>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label htmlFor="inputPassword6" className="ms-lg-5 col-form-label required">Upload City Image</label>
                                        </div>
                                        <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1 mb-md-1">
                                            <div className="input-group">
                                                <input
                                                    name='CityImage'
                                                    type="file"
                                                    id="CityImage"
                                                    onChange={handleUploadImageLoad}
                                                    className="form-control btn_add"
                                                    accept=".png,.jpg"
                                                    disabled={disabled ? true : false}
                                                />
                                                <label className="input-group-text" htmlFor="CityImage">Browse</label>
                                            </div>
                                            {formError?.CityImage && <p className="text-danger mb-0"> {formError?.CityImage} </p>}
                                            {imageError && <p className='text-danger text-start mb-0'>{imageError}</p>}
                                            {placeimagename && <p className='text-start overflow-hidden' data-bs-toggle="tooltip" data-bs-placement="left" title={placeimagename}>
                                                {placeimagename}</p>}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 pe-lg-5">
                            <div className="row">
                                <div className='col-lg-6 col-sm-6'>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label htmlFor="inputPassword6" className="ms-lg-5 col-form-label">Latitude</label>
                                        </div>
                                        <div className=" col-lg-8 mb-1 col-md-12">
                                            <input type="text" id="Latitude" placeholder="Latitude" className="form-control" name="Latitude" value={formValue.Latitude} onChange={onChange} disabled={disabled} />
                                            {formError?.Latitude && <p className="text-danger mb-0"> {formError?.Latitude} </p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label htmlFor="inputPassword6" className="ms-lg-5 col-form-label">Longitude</label>
                                        </div>
                                        <div className=" col-lg-8 mb-1 col-md-12">
                                            <input type="text" id="Longitude" placeholder="Longitude" className="form-control" name="Longitude" value={formValue.Longitude} onChange={onChange} disabled={disabled} />
                                            {formError?.Longitude && <p className="text-danger mb-0"> {formError?.Longitude} </p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-12 col-sm-12 '>
                                    <div className="row">
                                        <div className="col-lg-2 col-md-12 mb-1">
                                            <label htmlFor="Answer" className="ms-lg-5 col-form-label required">City Details</label>
                                        </div>
                                        <div className="col-lg-10 mb-1 col-md-12">
                                            <RichTextBox field="Answer" fieldvalue={formValue.CityDetail} tabIndex={1} OnChangeofRichBox={handleCityDetailChange} textconfig={citydetailconfig} />
                                            {/* {formError.answer && <div className="text-start text-danger"> {formError.answer} </div>} */}
                                            {formError?.CityDetail && <p className="text-danger mb-0"> {formError?.CityDetail} </p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-6 '>
                                    <div className='row'>
                                        <div className="col-lg-4 col-md-12 mb-1">
                                            <label htmlFor="inputPassword6" className="ms-lg-5 col-form-label">Active</label>
                                        </div>
                                        <div className="col-auto col-lg-2 col-md-7 col-sm-12">
                                            <Switch
                                                onChange={handleisActive}
                                                checked={activeId}
                                                className="react-switch"
                                                disabled={disabled}
                                            />
                                        </div>
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation(false)}
                                            yesconfirm={() => handleConfirmation(true)}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            {fileselected &&
                                (
                                    <div className="col-lg-2 col-md-3 col-6 mx-auto mt-2">
                                        <img
                                            src={URL.createObjectURL(fileselected)} className="img-fluid w-100" alt=''
                                        />
                                        <button onClick={() => removeImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }} disabled={disabled ? true : false} >&times;</button>
                                    </div>
                                )}
                            {(!fileselected && placeimagepath !== 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/null' && placeimagepath !== 'https://static-ethics.sgp1.digitaloceanspaces.com/etm/' && placeimagepath?.trim()?.length > 0) && (
                                <div className="col-lg-2 col-md-3 col-6 mx-auto mt-2" >
                                    <img
                                        src={placeimagepath} className="img-fluid w-100" alt=''
                                    />
                                    <button onClick={() => removeImage()} type="button" data-close style={{ objectposition: "right top;", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }} disabled={disabled ? true : false} >&times;</button>
                                </div>
                            )}

                        </div>

                    </div>

                    <div className=" d-flex justify-content-center mb-4">
                        {useradd === false && buttonaddeditText === "Add" ?
                            <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={!useradd}>{buttonaddeditText}</button> :
                            <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick} disabled={editdisabled ? false : true}>{buttonaddeditText}</button>}
                        <button type="button" className="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick}  >Clear</button>
                    </div>
                    <div className="row">
                        <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                            <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                <Select
                                    className="dropdown"
                                    options={serachoption}
                                    value={serachoption?.filter(function (serachoption) {
                                        return serachoption.value === searchby;
                                    })}// set list of the dataalue 
                                    onChange={handleSearchBy} // assign onChange function
                                    isDisabled={serchdisable ? true : false}
                                    isSearchable={false}
                                />
                            </div>
                            <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                <div className='clrbutton'>
                                    <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" className="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue} disabled={serchdisable ? true : false} />
                                    <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                </div>
                            </div>
                            <div className="p-2 text-center">
                                <button type="button" className="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleClickSearchValue} >Search</button>
                            </div>
                        </div>
                    </div>

                    <CustomTable
                        columns={columns}
                        data={locationlistdata}
                        PdfExport={PdfExport}
                        ExportToExcel={ExportToExcel}
                        disabled={!userexport}
                    />

                </form>
            </div>
        </>
    )
}

export default Locationmaster;