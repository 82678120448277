import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select';
import CustomTable from '../../../customControl/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllData, postUsersData, verifytoken } from '../../../services/ApiCallingUserServices';
import { loaderActions } from '../../../_actions/loader.actions';
import { ClearLocalStorage, GetLocalStorage } from '../../commonClass/Session';

const RibbonSection = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formValue, setFormvalue] = useState(
        {
            RibbonSectionId: 0,
            IsInternational: undefined,
            Continent: 0,
            SubContinent: 0,
            Country: 0,
            Region: 0,
            State: 0,
            City: 0,
            TopRecommendation: false,
            UserId: 0
        }
    )

    const [formError, setFormError] = useState({});
    const [ribbonList, setRibbonList] = useState([])
    const [territoryDetails, setTerritoryDetails] = useState([])

    const [BTNName, setBTNName] = useState("Add")
    const [disabled, setDisabled] = useState(false)
    const [editDisabled, setEditDisabled] = useState(false)

    const RibbonType = [
        { id: 1, title: 'International' },
        { id: 2, title: 'Domestic' }
    ];

    const user = useSelector(store => store.userstatus.tba);
    const [useradd, setUserAdd] = useState(true);
    const [useredit, setUserEdit] = useState(true);

    useEffect(() => {
        let pagelink = user?.find(x => {
            if (x.PageLink?.toLowerCase() === window.location.pathname?.toLowerCase())
                return x.MenuId
        });
        let mid = user?.find(x => x.MenuId === pagelink.MenuId);

        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/dashboard');
        }
        user?.filter(x => x.MenuId === pagelink.MenuId).map((obj) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
        });
    }, [user])

    //#region create function for calling "getterritorydetails" API
    const getterritorydetails = async () => {
        const territorydetails = await getAllData("/master/v1/getterritorydetails");
        if (territorydetails?.status) {
            let dropdownData = []
            dropdownData.push(territorydetails?.data)
            setTerritoryDetails(dropdownData);
        }
        else {
            setTerritoryDetails([]);
        }
    }
    //#endregion

    //#region create function for calling "getribbonsectionlist" API
    const getribbonsectionlist = async () => {
        const ribbonsectionlist = await getAllData("/master/v1/getribbonsectionlist");
        if (ribbonsectionlist?.status) {
            ribbonsectionlist?.data?.map((obj, index) => {
                obj.SrNo = index + 1
                return obj;
            })
            setRibbonList(ribbonsectionlist?.data);
        }
        else {
            setRibbonList([]);
        }
    }
    //#endregion

    //#region check token and call API's function
    useEffect(() => {
        async function fetchData() {
            let statusoftoken = await verifytoken();
            if (statusoftoken) {
                const userdata = GetLocalStorage()
                setFormvalue((form) => ({ ...form, UserId: userdata?.userMasterID }))
                try {
                    dispatch(loaderActions.start());
                    await Promise.all([
                        getribbonsectionlist(),
                        getterritorydetails(),
                    ])
                } catch (error) {
                    console.error(`Error fetching data: ${error}`);
                } finally {
                    dispatch(loaderActions.end());
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' });
                dispatch(loaderActions.end());
            }
        }
        fetchData();
    }, [])
    //#endregion

    //#region  All Dropdown's values
    const locationDropdown = useMemo(() =>
        territoryDetails?.reduce((acc, curr) => {
            curr.continentList?.map((obj) => {
                acc.continent.push({ value: obj.ContinentId, label: obj.ContinentName })
            })
            curr.subContinentList?.filter(data => data?.ContinentId === formValue?.Continent)?.map((obj) => {
                acc.subcontinent.push({ value: obj.SubContinentId, label: obj.SubContinentName })
            })
            curr.countryList?.filter(data => data?.SubContinentId === formValue?.SubContinent)?.map((obj) => {
                acc.country.push({ value: obj.CountryId, label: obj.CountryName })
            })
            if (formValue?.IsInternational === true) {
                acc.country = acc.country?.filter((int) => int.value !== 1)
            }

            if (formValue.Country !== 1) {
                curr.cityList?.filter(data => data.CountryId === formValue?.Country)?.map((obj) => {
                    acc.city.push({ value: obj.LocationId, label: obj.City })
                })
            }
            else {
                curr.regionList?.map((obj) => {
                    acc.region.push({ value: obj.RegionId, label: obj.RegionName })
                })

                if (formValue.Region !== 0) {
                    curr.stateList?.filter(data => data?.RegionId === formValue.Region)?.map((obj) => {
                        acc.state.push({ value: obj.StateId, label: obj.StateNAme })
                    })
                }

                if (formValue?.State !== 0) {
                    curr.cityList?.filter(data => data.StateId === formValue?.State)?.map((obj) => {
                        acc.city.push({ value: obj.LocationId, label: obj.City })
                    })
                }
            }
            return acc
        }, { continent: [], subcontinent: [], country: [], region: [], state: [], city: [] })

        , [territoryDetails, formValue.Continent, formValue.SubContinent, formValue.Country, formValue.Region, formValue.State, formValue.IsInternational])
    //#endregion

    //#region radio and checkbox on change function
    const handleCheck = (name, e, temp) => {
        setEditDisabled(false)
        setFormError({});
        if (temp !== null) {
            if (temp === "International") {
                setFormvalue((form) => ({
                    ...form,
                    Continent: 0,
                    SubContinent: 0,
                    Country: 0,
                    Region: 0,
                    State: 0,
                    City: 0,
                    IsInternational: temp === "International" ? e.target.checked : !e.target.checked
                }))
            }
            else {
                setFormvalue((form) => ({
                    ...form,
                    Continent: 1,
                    SubContinent: 4,
                    Country: 1,
                    Region: 0,
                    State: 0,
                    City: 0,
                    IsInternational: temp === "International" ? e.target.checked : !e.target.checked
                }))
            }
        }
        else {
            setFormvalue((form) => ({ ...form, [name]: e.target.checked }));
        }
    }
    //#endregion

    //#region select dropdown on change function
    const handleSelectChange = (name, e) => {
        setEditDisabled(false)
        setFormError({});
        if (name === "Continent") {
            setFormvalue((form) => ({ ...form, Continent: e.value, SubContinent: 0, Country: 0, Region: 0, State: 0, City: 0 }));
        }
        if (name === "SubContinent") {
            setFormvalue((form) => ({ ...form, SubContinent: e.value, Country: 0, Region: 0, State: 0, City: 0 }));
        }
        if (name === "Country") {
            setFormvalue((form) => ({ ...form, Country: e.value, Region: 0, State: 0, City: 0 }));
        }
        if (name === "Region") {
            setFormvalue((form) => ({ ...form, Region: e.value, State: 0, City: 0 }));
        }
        if (name === "State") {
            setFormvalue((form) => ({ ...form, State: e.value, City: 0 }));
        }
        else {
            setFormvalue((form) => ({ ...form, [name]: e.value }));
        }
    }
    //#endregion

    //#region grid table columns
    const columns = [
        {
            name: 'Sr. No',
            selector: (data) => data?.SrNo,
        },
        {
            name: 'Type',
            selector: (data) => data?.TYPE,
        },
        {
            name: 'Continent',
            selector: (data) => data?.ContinentName,
        },
        {
            name: 'Sub Continent',
            selector: (data) => data?.SubContinentName,
        },
        {
            name: 'Country',
            selector: (data) => data?.CountryName,
        },
        {
            name: 'Region',
            selector: (data) => data?.TYPE === "International" ? '' : data?.RegionName,
        },
        {
            name: 'State',
            selector: (data) => data?.StateName,
        },
        {
            name: 'City',
            selector: (data) => data?.City,
        },
        {
            name: 'Top Recommended',
            selector: (data) => data?.IsTopRecommendation,
        },
        {
            name: 'Edit',
            selector: (data) => <button type='button' className='border-0 p-0 bg-body set_disableclr' onClick={() => onEditView(data.RibbonSectionId, 'edit')} disabled={!useredit} ><i className="fa fa-edit"></i></button>
        },
        {
            name: 'View',
            selector: (data) => <button type='button' className='border-0 p-0 bg-body set_disableclr' onClick={() => onEditView(data.RibbonSectionId, 'view')}><i className="fa fa-eye"></i></button>
        }
    ]
    //#endregion

    //#region edit, view on click and retrive data function
    const onEditView = (id, type) => {
        setFormError({})
        if (type === 'edit') {
            setBTNName('Update')
            setEditDisabled(true)
            setDisabled(false)
        }
        else {
            setBTNName('Add')
            setEditDisabled(false)
            setDisabled(true)
        }

        retriveData(id);
    }

    const retriveData = async (id) => {

        let statusoftoken = await verifytoken();
        if (statusoftoken) {
            const ribbonsectionbyid = await getAllData(`/master/v1/getribbonsectionbyid?ribbonSectionId=${id}`)
            if (ribbonsectionbyid?.status) {
                if (ribbonsectionbyid?.data[0]?.LocationId !== 0 && territoryDetails?.length > 0) {
                    let citydata = territoryDetails[0]?.cityList?.filter((obj => obj.LocationId === ribbonsectionbyid?.data[0]?.LocationId))

                    setFormvalue((form) => ({
                        ...form,
                        Continent: citydata[0]?.ContinentId,
                        SubContinent: citydata[0]?.SubContinentId,
                        Country: citydata[0]?.CountryId,
                        Region: citydata[0]?.RegionId,
                        State: citydata[0]?.StateId,
                        RibbonSectionId: ribbonsectionbyid?.data[0]?.RibbonSectionId,
                        City: ribbonsectionbyid?.data[0]?.LocationId,
                        IsInternational: ribbonsectionbyid?.data[0]?.IsInternational,
                        TopRecommendation: ribbonsectionbyid?.data[0]?.IsTopRecommendation,
                    }))
                }
            }
            else {
                dispatch({ type: 'ALERTING', message: ribbonsectionbyid?.message })
            }
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' });
        }
    }
    //#endregion

    //#region check validation function
    const validateData = () => {
        let status = true;
        let error = {};

        if (formValue?.IsInternational === undefined) {
            error["IsInternational"] = "cannot leave blank.";
            status = false;
        }
        if (formValue?.Continent === 0) {
            error["Continent"] = "cannot leave blank.";
            status = false;
        }

        if (formValue?.SubContinent === 0) {
            error["SubContinent"] = "cannot leave blank.";
            status = false;
        }

        if (formValue?.Country === 0) {
            error["Country"] = "cannot leave blank.";
            status = false;
        }

        if (formValue?.IsInternational === false && formValue?.Region === 0) {
            error["Region"] = "cannot leave blank.";
            status = false;
        }

        if (formValue?.IsInternational === false && formValue?.State === 0) {
            error["State"] = "cannot leave blank.";
            status = false;
        }

        if (formValue?.City === 0) {
            error["City"] = "cannot leave blank.";
            status = false;
        }

        if (ribbonList?.length > 0 && ribbonList?.filter((obj) => obj.RibbonSectionId !== formValue.RibbonSectionId && obj.LocationId === formValue.City)?.length > 0) {
            error["Duplicate"] = "You cannot enter duplicate value.";
            status = false;
        }

        setFormError(error);

        if (Object.keys(error)?.length > 0) {
            document.getElementById(Object.keys(error)[0])?.focus();
        }
        return status;
    }
    //#endregion

    //#region Add / Update function
    const handleAddClick = async () => {
        if (validateData()) {
            let data = {
                RibbonSectionId: formValue.RibbonSectionId,
                IsInternational: formValue.IsInternational,
                LocationId: formValue.City,
                IsTopRecommendation: formValue.TopRecommendation,
                UserId: formValue.UserId
            }
            dispatch(loaderActions.start());

            let statusoftoken = await verifytoken();
            if (statusoftoken) {
                const insertorupdateribbonsection = await postUsersData("/master/v1/insertorupdateribbonsection", data);
                dispatch({ type: 'ALERTING', message: insertorupdateribbonsection?.message })
                handleClearClick();
                getribbonsectionlist();
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' });
            }
            dispatch(loaderActions.end());

        }
    }
    //#endregion

    //#region clear function
    const handleClearClick = () => {
        setFormError({});
        setFormvalue((form) => ({
            ...form,
            IsInternational: undefined,
            RibbonSectionId: 0,
            Continent: 0,
            SubContinent: 0,
            Country: 0,
            Region: 0,
            State: 0,
            City: 0,
            TopRecommendation: false,
        }))
        setEditDisabled(false)
        setDisabled(false)
        setBTNName("Add")
    }
    //#endregion

    return (
        <>
            <div className="container-fluid mt-4 heading_color">
                <h3>Merchandising <span className='fs-5 ms-2' > Ribbon Section</span> </h3>
            </div>
            <div className="container-fluid form_border py-5 my-3">
                <form action="" >
                    <div className="mb-4 row mx-1 ">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                                {formError?.Duplicate && <p className="text-danger text-center mb-0">{formError?.Duplicate} </p>}
                                <div className='col-12'>
                                    <div className='col-md-6 col-12 mb-md-4'>
                                        <div className='row'>
                                            {
                                                RibbonType?.map((obj) => {
                                                    return (
                                                        <div className="col-6">
                                                            <div className="form-check-inline" key={obj?.title}>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="PackageRouting"
                                                                    id={obj?.title}
                                                                    checked={formValue?.IsInternational !== undefined ? (obj?.id === 1 ? formValue?.IsInternational : !formValue?.IsInternational) : undefined}
                                                                    onChange={(e) => handleCheck('IsInternational', e, obj?.title)}
                                                                    disabled={disabled}
                                                                />
                                                                <label className="form-check-label ms-1" htmlFor={obj?.title}>
                                                                    {obj?.title}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {formError?.IsInternational && <p className="text-danger mb-0">{formError?.IsInternational} </p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-4 col-md-6 col-12 mb-1'>
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <label htmlFor="Continent" className={`col-form-label ${formValue?.IsInternational === undefined || formValue?.IsInternational === false ? '' : 'required'} `}>Continent</label>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <Select
                                                className="dropdown select_dropdown"
                                                id="Continent"
                                                name="Continent"
                                                options={locationDropdown?.continent}
                                                value={locationDropdown?.continent?.filter((obj) => obj?.value === formValue?.Continent)}
                                                onChange={(e) => handleSelectChange("Continent", e)}
                                                isDisabled={disabled || formValue?.IsInternational === undefined || formValue?.IsInternational === false}
                                            />
                                            {formError?.Continent && <p className="text-danger mb-0">{formError?.Continent} </p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-4 col-md-6 col-12 mb-1'>
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <label htmlFor="SubContinent" className={`col-form-label ${formValue?.IsInternational === undefined || formValue?.IsInternational === false ? '' : 'required'} `}>Sub Continent</label>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <Select
                                                className="dropdown select_dropdown"
                                                id="SubContinent"
                                                name="SubContinent"
                                                options={locationDropdown?.subcontinent}
                                                value={locationDropdown?.subcontinent?.filter((obj) => obj?.value === formValue?.SubContinent)}
                                                onChange={(e) => handleSelectChange("SubContinent", e)}
                                                isDisabled={disabled || formValue?.IsInternational === undefined || formValue?.IsInternational === false}
                                            />
                                            {formError?.SubContinent && <p className="text-danger mb-0">{formError?.SubContinent} </p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-4 col-md-6 col-12 mb-1'>
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <label htmlFor="Country" className={`col-form-label ${formValue?.IsInternational === undefined || formValue?.IsInternational === false ? '' : 'required'} `}>Country</label>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <Select
                                                className="dropdown select_dropdown"
                                                id="Country"
                                                name="Country"
                                                options={locationDropdown?.country}
                                                value={locationDropdown?.country?.filter((obj) => obj?.value === formValue?.Country)}
                                                onChange={(e) => handleSelectChange("Country", e)}
                                                isDisabled={disabled || formValue?.IsInternational === undefined || formValue?.IsInternational === false}
                                            />
                                            {formError?.Country && <p className="text-danger mb-0">{formError?.Country} </p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-4 col-md-6 col-12 mb-1'>
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <label htmlFor="Region" className={`col-form-label ${formValue?.IsInternational === undefined || formValue?.IsInternational === true ? '' : 'required'} `}>Region</label>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <Select
                                                className="dropdown select_dropdown"
                                                id="Region"
                                                name="Region"
                                                options={locationDropdown?.region}
                                                value={locationDropdown?.region?.filter((obj) => obj?.value === formValue?.Region)}
                                                onChange={(e) => handleSelectChange("Region", e)}
                                                isDisabled={disabled || formValue?.IsInternational === undefined || formValue?.IsInternational === true}
                                            />
                                            {formError?.Region && <p className="text-danger mb-0">{formError?.Region} </p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-4 col-md-6 col-12 mb-1'>
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <label htmlFor="State" className={`col-form-label ${formValue?.IsInternational === undefined || formValue?.IsInternational === true ? '' : 'required'} `}>State</label>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <Select
                                                className="dropdown select_dropdown"
                                                id="State"
                                                name="State"
                                                options={locationDropdown?.state}
                                                value={locationDropdown?.state?.filter((obj) => obj?.value === formValue?.State)}
                                                onChange={(e) => handleSelectChange("State", e)}
                                                isDisabled={disabled || formValue?.IsInternational === undefined || formValue?.IsInternational === true}
                                            />
                                            {formError?.State && <p className="text-danger mb-0">{formError?.State} </p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-4 col-md-6 col-12 mb-1'>
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <label htmlFor="City" className={`col-form-label ${formValue?.IsInternational === undefined ? '' : 'required'} `}>City</label>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <Select
                                                className="dropdown select_dropdown"
                                                id="City"
                                                name="City"
                                                options={locationDropdown?.city}
                                                value={locationDropdown?.city?.filter((obj) => obj?.value === formValue?.City)}
                                                onChange={(e) => handleSelectChange("City", e)}
                                                isDisabled={formValue?.IsInternational === undefined || disabled}
                                            />
                                            {formError?.City && <p className="text-danger mb-0">{formError?.City} </p>}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 mt-2'>
                                    <div className="">
                                        <label className="form-check-label" htmlFor="TopRecommendation">
                                            Display in Top Recommendation
                                        </label>
                                        <input
                                            className="form-check-input ms-2"
                                            type="checkbox"
                                            id="TopRecommendation"
                                            checked={formValue.TopRecommendation}
                                            onChange={(e) => handleCheck('TopRecommendation', e, null)}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="mt-4">
                        <CustomTable
                            columns={columns}
                            data={ribbonList}
                        />
                    </div>

                    <div className="mt-3 d-flex justify-content-center">
                        <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={handleAddClick}
                            disabled={(BTNName === "Add" ? !useradd : !useredit) || editDisabled || disabled}
                        > {BTNName} </button>
                        <button type="button" className="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick} >Clear</button>
                    </div>

                </form>
            </div>
        </>
    )
}

export default RibbonSection
