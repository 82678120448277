import React, { Fragment, useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import UploadButton from './UploadButton';
import Item from 'antd/es/list/Item';
import { Grid } from '@mui/material';
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Lightbox from 'yet-another-react-lightbox';

function HotelAndRoomImages(props) {

  const { imageSection,
    setImageSection,
    roomlist,
    mediaType,
    handleDeleteFile,
    hotelId,
    disabled,
    setSave,
    errorMessage,
    setErrorMessage
  } = props;

  const [expanded, setExpanded] = React.useState([]);
  const [onPhotoHover, setOnPhotoHover] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const [indexs, setIndexs] = useState(1);
  const [src, setSrc] = useState([])

  const handleChange = (event, isExpanded, id) => {
    setErrorMessage({})
    if (isExpanded) {
      setExpanded([...expanded, id]);
    } else {
      setExpanded((pre) => (pre.filter((Aid) => Aid !== id)));
    }
  };
  const handleViewImage = (index, path) => {
    setErrorMessage({})
    setSrc((current) => [...current, { ...src, src: path }])
    setIsOpen(true);
    setIndexs(index)
  }

  return (
    <>
      {roomlist?.length > 0
        && roomlist?.map((list, index) => (
          <div key={list?.HotelRoomId + list?.RoomTypeName}>
            <Accordion
              key={list?.HotelRoomId + list?.RoomTypeName + index}
              className='image_video_list'
              expanded={expanded?.includes(list?.HotelRoomId) ? true : false}
              onChange={(e, isExpanded) => handleChange(e, isExpanded, list?.HotelRoomId)}
            >

              <AccordionSummary
                className='images-accordian'
                expandIcon={expanded.includes(list?.HotelRoomId) ? (
                  <i className="fa-solid fa-minus" style={{ color: "#B22500" }}></i>
                ) : (
                  <i className="fa-solid fa-plus" style={{ color: "#B22500" }}></i>
                )}
                aria-controls="panel1-content"
                id="panel1-header"
                expanded={true}
              >
                <Typography >{list?.RoomTypeName}</Typography>
              </AccordionSummary>

              <AccordionDetails>
                {errorMessage?.[list?.HotelRoomId + mediaType] && <div className="text-danger text-center mb-2"> {errorMessage?.[list?.HotelRoomId + mediaType]} </div>}
                <Typography>
                  <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Item >
                        <div className='h-241px'>
                          <UploadButton
                            hotelRoomId={list?.HotelRoomId}
                            mediaType={mediaType}
                            formValue={imageSection}
                            setFormValue={setImageSection}
                            hotelId={hotelId}
                            disabled={disabled}
                            setSave={setSave}
                            setErrorMessage={setErrorMessage}
                          />
                        </div>
                      </Item>
                    </Grid>
                    {
                      imageSection?.map((img, index) => {
                        return (
                          <Fragment key={img?.HotelRoomId + img?.FileName + index}>
                            {(() => {
                              if (img?.HotelRoomId === list?.HotelRoomId) {
                                return (
                                  <Grid key={img?.HotelRoomId + img?.FileName} item xs={12} sm={6} md={4} lg={3}
                                    onMouseEnter={() => setOnPhotoHover(index + 1)}
                                    onMouseLeave={() => setOnPhotoHover(0)}
                                  >
                                    <Item>
                                      <div className='h-241px photo-images-card position-relative'>
                                        <img
                                          className='rounded  multipleUploader'
                                          src={
                                            img?.needToUpload ?
                                              URL.createObjectURL(img?.File) :
                                              `https://static-ethics.sgp1.digitaloceanspaces.com/etm/${img?.FilePath}`
                                          }
                                          alt={img?.FileName}
                                          height={'100%'}
                                          width={'100%'}
                                          aspect-ratio={3 / 2}
                                        />

                                        {
                                          !disabled && onPhotoHover === index + 1 && (
                                            <>
                                              <div key={index + 1} className="image_video_hover position-absolute rounded-pill py-1 text-center align-content-center">
                                                <i className="fa-solid fa-trash-can mx-1" role='button' onClick={() => handleDeleteFile(index, img?.HotelMediaId, mediaType)}></i>
                                                <i className="fa-solid fa-eye mx-1" role='button' onClick={() => handleViewImage(1, `https://static-ethics.sgp1.digitaloceanspaces.com/etm/${img?.FilePath}`)}></i>
                                              </div>
                                              {isOpen && (
                                                <Lightbox
                                                  slides={src}
                                                  open={indexs >= 0}
                                                  index={indexs}
                                                  close={() => { setIndexs(-1); setSrc([]); }}
                                                  plugins={[Zoom]}
                                                />
                                              )}
                                            </>
                                          )
                                        }

                                      </div>
                                    </Item>
                                  </Grid>
                                )
                              }
                            })()}

                          </Fragment>
                        )
                      })
                    }


                  </Grid>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
    </>
  )
}

export default HotelAndRoomImages