import React, { useEffect, useMemo, useState } from 'react'
import CustomTable from '../../../../customControl/CustomTable'
import { isImagePngJpegJpg, onlyAlphabets } from '../../../commonClass/CommonValidation';
import "../../../../assets/css/master.css"
import Switch from 'react-switch';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../../../_actions/loader.actions';
import { ClearLocalStorage, GetLocalStorage } from '../../../commonClass/Session';
import { deleteUserData, getAllData, postUsersData, verifytoken } from '../../../../services/ApiCallingUserServices';
import { ConfirmationModal } from '../../../../customControl/ConfirmationModal';
import { useNavigate } from 'react-router-dom';
import { ImageDelete, ImageUpload } from '../../../../ImageUpload/ImageCommon';

function getDateCombination() {
  var today = new Date();
  let date =
    today?.getFullYear()?.toString() +
    (today?.getMonth() + 1)?.toString() +
    today?.getDate()?.toString();
  let time =
    today?.getHours()?.toString() +
    (today?.getMinutes() + 1)?.toString() +
    today?.getSeconds()?.toString();
  return date + "_" + time;
}

const HomePageSection = ({ travelCategoryId, useredit, useradd }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = GetLocalStorage();
  const [homePageSection, setHomePageSection] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [sectionList, setSectionList] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [deletImageList, setDeleteImageList] = useState([]);
  const [formError, setFormError] = useState({});

  const maxFileSize = 5;
  const folderName = travelCategoryId === 1 ? 'Flights' : 'Holidays';

  //#region for upload Banner images and add Link field for Other Products sections
  const forPromotionalBannerIds = useMemo(() => {
    return travelCategoryId === 1 ? [1, 2, 8, 9, 10, 11] : travelCategoryId === 2 ? [4, 5, 8, 9, 10, 11] : [];
  }, [travelCategoryId])
  const forOtherProductIds = useMemo(() => {
    return [9, 10, 11];
  }, [])

  const getSubFolderName = (id) => {
    switch (id) {
      case 9: return 'Flights';
      case 10: return 'Holidays';
      case 11: return 'Hotels';
      default:
        break;
    }
  }

  const sections = useMemo(() =>
    [homePageSection]?.reduce((acc, curr) => {
      acc.isPromotionalBanner = forPromotionalBannerIds?.includes(curr?.HomePageSectionId);
      acc.isOtherProduct = forOtherProductIds?.includes(curr?.HomePageSectionId);
      acc.fieldLabel = curr?.HomePageSectionId === 8 ? 'Promotional Banner Type 2' : forOtherProductIds?.includes(curr?.HomePageSectionId) ? curr?.Name : curr?.Section
      acc.subFolderName = forOtherProductIds?.includes(curr?.HomePageSectionId) ? getSubFolderName(curr?.HomePageSectionId) : curr?.Section
      return acc
    }, { isPromotionalBanner: false, isOtherProduct: false, fieldLabel: '', subFolderName: '' })
    , [forOtherProductIds, forPromotionalBannerIds, homePageSection])
  //#endregion

  const getSectionList = async (travelCategoryId) => {
    const section = await getAllData(`/master/v1/gethomepagesectionlistbytravelcategpryid?travelCategoryId=${travelCategoryId}`);
    if (section?.status) {
      setSectionList(section.data);
      dispatch(loaderActions.end());
    }
    else {
      dispatch(loaderActions.end());
      dispatch({ type: 'ALERTING', message: section.message })
      return;
    }
  }

  //#region List data
  useEffect(() => {
    dispatch(loaderActions.start());
    let token = GetLocalStorage();
    if (token && token.userName && token.token) {
      getSectionList(travelCategoryId)
    }
    else {
      ClearLocalStorage();
      dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
    }
  }, []);

  const onEditorView = async (data, type) => {
    setFormError({})
    const gethomepagesectionbyid = await getAllData(`/master/v1/gethomepagesectionbyid?homePageSectionId=${data?.HomePageSectionId}`);
    if (gethomepagesectionbyid?.status) {
      setHomePageSection({ ...gethomepagesectionbyid?.data?.homePageSection[0], type })
      setImageList(gethomepagesectionbyid?.data?.homePageSectionBanner)
      setDeleteImageList(gethomepagesectionbyid?.data?.homePageSectionBanner)
    }
  }
  const onOpen = (section, name, HomePageSectionId) => {
    if (section === "Popular") {
      navigate('/MerchandisingPopular', { state: { useredit: useredit, useradd: useradd, name: name, travelCategoryId: travelCategoryId, HomePageSectionId: HomePageSectionId } });
    }
    if (HomePageSectionId === 4) {
      navigate('/MerchandisingDomestic', { state: { useredit: useredit, useradd: useradd, name: name, travelCategoryId: travelCategoryId, HomePageSectionId: HomePageSectionId, folderName: folderName, section: section } });
    }
    if (HomePageSectionId === 5) {
      navigate('/MerchandisingInternational', { state: { useredit: useredit, useradd: useradd, name: name, travelCategoryId: travelCategoryId, HomePageSectionId: HomePageSectionId, folderName: folderName, section: section } });
    }
    if (HomePageSectionId === 6) {
      navigate('/MerchandisingDomesticPackage', { state: { useredit: useredit, useradd: useradd, name: name, travelCategoryId: travelCategoryId, HomePageSectionId: HomePageSectionId, folderName: folderName, section: section } });
    }
    if (HomePageSectionId === 7) {
      navigate('/MerchandisingInternationalPackage', { state: { useredit: useredit, useradd: useradd, name: name, travelCategoryId: travelCategoryId, HomePageSectionId: HomePageSectionId, folderName: folderName, section: section } });
    }
  }

  const columns = [
    {
      name: 'Sr. No',
      sortable: true,
      float: "left",
      minWidth: "20px",
      maxWidth: "20px",
      selector: (data, index) => index + 1,
    },
    {
      name: 'Section',
      sortable: true,
      float: "left",
      selector: (data) => data.Section,
    },
    {
      name: 'Name',
      sortable: true,
      float: "left",
      selector: (data) => data.Name,
    },
    {
      name: 'Active/Inactive',
      sortable: true,
      float: "left",
      selector: (data) => data.Status,
    },
    {
      name: 'Edit',
      selector: data => <button type='button' className='border-0 p-0 bg-body set_disableclr'
        onClick={() => onEditorView(data, 'edit')}
        disabled={!useredit}
      ><i className="fa fa-edit eye-icon"></i></button>
    },
    {
      name: 'View',
      selector: data => <button type='button' className='border-0 p-0 bg-body set_disableclr' onClick={() => onEditorView(data, 'view')} ><i className="fa fa-eye" ></i></button>
    },
    {
      name: 'Open',
      selector: data => <button class="btn btn-primary mx-1 home-page-section-btn" id="btnAdd" onClick={() => onOpen(data?.Section, data?.Name, data?.HomePageSectionId)} type='button'>open</button>
    },
  ]

  const handleOnChange = (e) => {
    setFormError({});
    let result;
    if (e.target.name === 'Link') {
      result = e.target.value
    }
    else {
      result = onlyAlphabets(e.target.value)
    }
    setHomePageSection({
      ...homePageSection,
      [e.target.name]: result
    })
  }

  const handleisActive = (e) => {
    if (!homePageSection?.IsActive) {
      setFormError({});
      setHomePageSection({ ...homePageSection, IsActive: !homePageSection?.IsActive })
    }
    else {
      setModalShow(true);
    }
  }

  const handleConfirmation = (e) => {
    if (e === true) {
      setFormError({});
      setHomePageSection({ ...homePageSection, IsActive: !homePageSection?.IsActive })
    }
    setModalShow(false);
  }

  const validateData = () => {
    let status = true;
    let error = {}
    if (sections?.isPromotionalBanner && imageList?.length <= 0) {
      error.imageUpload = 'Cannot leave blank, input is mandatory.';
      status = false;
    }
    if (sections?.isOtherProduct && ((homePageSection?.Link)?.trim() === "" || homePageSection?.Link === null)) {
      error.Link = 'Cannot leave blank, input is mandatory.';
      status = false;
    }
    setFormError(error);
    return status;
  }

  const onSubmitData = async () => {
    if (validateData()) {
      dispatch(loaderActions.start());
      let statusoftoken = await verifytoken();
      if (statusoftoken) {
        let newImageList = imageList?.filter((obj) => obj?.needToUpload)
        const updateSection = await postUsersData('/master/v1/insertorupdatehomepagesection',
          {
            HomePageSectionId: homePageSection?.HomePageSectionId,
            TravelCategoryId: travelCategoryId,
            Link: homePageSection?.Link,
            Name: homePageSection?.Name,
            IsActive: homePageSection.IsActive,
            UserId: userId?.userMasterID,
            ImageList: newImageList
          }
        );

        if (updateSection.status) {
          await Promise.all(
            newImageList?.map(async (ele) => {
              if (ele?.needToUpload) {
                try {
                  let uploaded = await ImageUpload(ele?.Path, ele?.ImageName, ele?.File);
                  if (sections.isOtherProduct && deletImageList?.length > 0) {
                    await deletehomepagesectionbyid(deletImageList[0]?.HomePageSectionBannerId, 'newImage');
                  }
                  ele.uploaded = uploaded
                } catch (error) {
                  console.error(`Error Upload Image/Video: ${error}`)
                  dispatch(loaderActions.end())
                }
              }
              return ele;
            })
          )
          setHomePageSection({});
          getSectionList(travelCategoryId);
          dispatch({ type: 'ALERTING', message: updateSection.message })
          dispatch(loaderActions.end());
        }
        else {
          dispatch({ type: 'ALERTING', message: updateSection.message })
          dispatch(loaderActions.end());
          return;
        }
      } else {
        ClearLocalStorage();
        dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        dispatch(loaderActions.end());
      }
    }
  }

  const filterValidImage = (newFiles, type) => {
    if (type === "filetype") {
      return Object.entries(newFiles)?.map((obj) => obj.pop(0))?.filter((file) => !isImagePngJpegJpg(file?.name));
    }
    else if (type === "filesize") {
      return Object.entries(newFiles)?.map((obj) => obj.pop(0))?.filter((file) => ((file?.size / 1024) / 1024) > maxFileSize);
    }
  };

  const handleUploadDoc = (e, sectionId) => {
    let newFileType = filterValidImage(e?.target?.files, 'filetype')
    let newFileSize = filterValidImage(e?.target?.files, 'filesize')
    let error = {}
    if (newFileType?.length > 0) {
      error.imageUpload = 'Please upload valid image such as PNG, JPEG, & JPG only.';
      setFormError(error)
    }
    else if (newFileSize?.length > 0) {
      error.imageUpload = 'Cannot upload file that exceeds 5 MB.';
      setFormError(error)
    }
    else {
      setFormError({})
      const tempImageList = Object.entries(e?.target?.files)?.map((obj) => obj.pop(0))?.reduce((acc, file, index) => {
        const filecom = getDateCombination();
        let filename = filecom + "_1_" + file?.name
        acc.push({
          HomePageSectionBannerId: 0,
          ImageName: filename,
          ImagePath: `CMS/Merchandising/${folderName}/HomePage/${sections?.subFolderName}/${filename}`,
          Path: `CMS/Merchandising/${folderName}/HomePage/${sections?.subFolderName}/`,
          File: file,
          needToUpload: true,
          uploaded: false,
        });

        return acc;
      }, []);
      if (sections?.isOtherProduct) {
        setImageList(tempImageList)
      }
      else {
        setImageList((prev) => [...prev, ...tempImageList])
      }
    }
  }

  const handleDeleteFile = (mediaId, indexId) => {
    setFormError({})
    if (mediaId === 0) {
      setImageList((prev) => prev.filter((item, index) => index !== indexId));
    }
    else {
      deletehomepagesectionbyid(mediaId, 'oldImage')
      setImageList((prev) => prev.filter((item, index) => index !== indexId));
    }
  }

  const deletehomepagesectionbyid = async (mediaid, type) => {
    dispatch(loaderActions.start())
    let statusoftoken = await verifytoken();
    if (statusoftoken) {
      const deleteData = await deleteUserData(`/master/v1/deletehomepagesectionbyid?homePageSectionBannerId=${mediaid}`)
      if (deleteData?.status) {
        let delColl = (type === 'newImage' && sections.isOtherProduct) ? deletImageList : imageList;
        let deleteImage = delColl?.find((obj) => obj?.HomePageSectionBannerId === mediaid);
        try {
          const deleted = await ImageDelete(deleteImage?.ImagePath);
          if (deleted) {
            dispatch(loaderActions.end())
            dispatch({ type: 'ALERTING', message: deleteData?.message })
          }
        } catch (error) {
          console.error(`Error Delete Image: ${error}`)
        }
      }
      else {
        dispatch(loaderActions.end())
      }
    }
    else {
      ClearLocalStorage();
      dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
      dispatch(loaderActions.end());
    }

  }

  return (
    <div className='px-xl-5 py-4 p-xl-4'>
      <div className=" heading_color mb-3">
        <h4>Home Page Section</h4>
      </div>

      <form action=" " >
        <div class="row mb-3">
          <div className='col-md-6'>
            <div className='row'>

              <div class="col-lg-4 col-md-12 col-sm-12 my-2">
                <label for="inputPassword6" class="col-form-label required">Section</label>
              </div>

              <div class=" col-lg-8 col-md-12 col-sm-12">
                <input
                  type="text"
                  id="Section"
                  class="form-control"
                  name="Section"
                  value={homePageSection.Section || ''}
                  onChange={handleOnChange}
                  placeholder="Section"
                  disabled={true}
                />
              </div>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='row'>

              <div class="col-lg-4 col-md-12 mb-1">
                <label for="inputPassword6" class="col-form-label required">Name</label>
              </div>

              <div class="col-lg-8 col-md-12 col-sm-12 mb-lg-1 mb-md-1">
                <div class="input-group">
                  <input
                    type="text"
                    id="section-ui-name"
                    class="form-control btn_add"
                    placeholder="Name"
                    maxLength={sections?.isOtherProduct ? 20 : 50}
                    name='Name'
                    value={homePageSection.Name || ''}
                    onChange={handleOnChange}
                    disabled={homePageSection?.type === 'view' ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='row align-items-center'>
              <div class="col-xl-4 col-6 my-2">
                <label for="inputPassword6" class="col-form-label">Active/Inactive</label>
              </div>

              <div class="col-xl-8 col-6 mb-lg-1 mb-md-1">
                <div class="input-group">
                  <Switch
                    id='active'
                    onChange={handleisActive}
                    checked={homePageSection?.IsActive}
                    className="react-switch"
                    disabled={homePageSection?.type === 'view' ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
          {
            homePageSection?.HomePageSectionId !== undefined && sections?.isPromotionalBanner &&
            (
              <>
                <div className="heading_color">
                  <h5>Promotional Banner</h5>
                </div>

                <div className="col-6">
                  <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1 mb-md-1">
                      <label htmlFor="imageUpload" className="col-form-label required">{sections?.fieldLabel}</label>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1 mb-md-1">
                      <div className="input-group">
                        {
                          sections?.isOtherProduct ?
                            <input
                              type="file"
                              className="form-control btn_add"
                              id="imageUpload"
                              name='imageUpload'
                              accept='.jpg,.jpeg,.png'
                              disabled={homePageSection?.type === 'view' ? true : false}
                              onChange={(e) => handleUploadDoc(e)}
                            />
                            :
                            <input type="file"
                              multiple
                              className="form-control btn_add"
                              id="imageUpload"
                              name='imageUpload'
                              accept='.jpg,.jpeg,.png'
                              disabled={homePageSection?.type === 'view' ? true : false}
                              onChange={(e) => handleUploadDoc(e)}
                            />
                        }
                        <label className="input-group-text" htmlFor="imageUpload">Browse</label>
                      </div>
                      {formError?.imageUpload && <p className="text-start text-danger mb-0"> {formError?.imageUpload} </p>}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  {
                    sections?.isOtherProduct &&
                    <div className="row">
                      <div className="col-lg-4 col-md-12 col-sm-12 mb-lg-1 mb-md-1">
                        <label htmlFor="Link" className="col-form-label required">URL</label>
                      </div>
                      <div className="col-lg-8 col-md-12 col-sm-12 mb-lg-1 mb-md-1">
                        <input
                          type='text'
                          className="form-control"
                          id="Link"
                          name='Link'
                          placeholder='URL'
                          value={homePageSection.Link || ''}
                          disabled={homePageSection?.type === 'view' ? true : false}
                          onChange={handleOnChange}
                        />
                        {formError?.Link && <p className="text-start text-danger mb-0"> {formError?.Link} </p>}
                      </div>
                    </div>
                  }
                </div>

                {
                  imageList?.map((img, index) => {
                    return (
                      <div className={` ${sections?.isOtherProduct ? 'text-center col-6' : 'col-xl-2 col-lg-3 col-md-6 col-12'} py-2`} key={index} >
                        <img
                          src={
                            img?.needToUpload ?
                              URL.createObjectURL(img?.File) :
                              `https://static-ethics.sgp1.digitaloceanspaces.com/etm/${img?.ImagePath}`
                          }
                          key={index}
                          className={` ${sections?.isOtherProduct ? 'home_other_img' : 'home_banner_img'} `}
                          alt={img?.ImageName}
                          loading='lazy'
                        />
                        <button className='closebtnimg'
                          onClick={(e) => handleDeleteFile(img?.HomePageSectionBannerId, index)}
                          type="button"
                          data-close style={{ objectposition: "right top", position: "absolute", fontSize: "36px", background: 'none', border: 'none' }}
                          disabled={homePageSection?.type === 'view' ? true : false}
                        >&times;</button>

                      </div>
                    )
                  })
                }

              </>
            )
          }


        </div>

        <CustomTable
          columns={columns}
          data={sectionList}
          PdfExport={false}
          ExportToExcel={false}
          disabled={false}
          className={'Homepage-section'}
        />
      </form>

      <div class="d-flex justify-content-center">
        <button class="btn btn-primary mx-1" id="btnAdd" type='button'
          onClick={() => onSubmitData()}
          disabled={!homePageSection?.HomePageSectionId ? true : false || homePageSection?.type === 'view' ? true : false || !useredit}
        >Update</button>
        <button type="button" class="btn btn-primary mx-1" id="btnClear"
          onClick={() => {
            setHomePageSection({});
            setImageList([]);
            setFormError({})
          }}
          // disabled={Object.keys(homePageSection).length === 0 ? true : false || homePageSection?.type === 'view' ? true : false}
        >Clear</button>
      </div>

      <ConfirmationModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        noconfirm={() => handleConfirmation(false)}
        yesconfirm={() => handleConfirmation(true)}
      />
    </div>
  )
}

export default HomePageSection