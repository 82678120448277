import { FormControl, TextField } from '@mui/material'
import React from 'react'
import { onlyNumeric } from '../../../../../../commonClass/CommonValidation'

const RoomDefaultRates = ({ HotelRoomRatesCollection, setFormValue, isExtraBeddingAllowed, disabled, formError,setFormError }) => {
    const handleDefaultRoomRate = (roomMealID) => {
        switch (roomMealID) {
            case 1:
                return 'Room Only'
            case 2:
                return 'With Breakfast (CPAI)'
            case 3:
                return 'With Breakfast Lunch or Dinner (MAPAI)'
            case 4:
                return 'With Breakfast, Lunch & Dinner (APAI)'
        }
    }

    const handleChange = (name, value, id) => {
        setFormValue((prevs) => ({
            ...prevs,
            HotelRoomRatesCollection: prevs.HotelRoomRatesCollection.map((roomMeal) => {
                if (roomMeal.RateTypeId === id) {
                    roomMeal = { ...roomMeal, [name]: value }
                }
                return roomMeal
            })
        }))
        setFormError({})
    }

    return (
        <div className='row mt-3'>
            <div className='col-lg-12 col-md-12 col-12 mb-1'>
                <h5 className='mt-2 fw-bold room_header'>Default Room Rates</h5>
            </div>
            <div className='col-xl-8 col-12'>
                <table>
                    <thead>
                        <th className='mx-1'></th>
                        <th className='mx-1' >1 Adult</th>
                        <th className='mx-1' >2 Adults</th>
                        <th className='mx-1' >Extra Adult with Bed/Mattress</th>
                        <th className='mx-1' >Extra Child with Bed/Mattress</th>
                        <th className='mx-1' >Extra Child without Bed/Mattress</th>
                    </thead>
                    <tbody>
                        {HotelRoomRatesCollection?.length > 0 && (
                            HotelRoomRatesCollection?.map((roomMeal) => (
                                <tr key={handleDefaultRoomRate(roomMeal.RateTypeId)} className=''>
                                    <th className='mx-1 '>{handleDefaultRoomRate(roomMeal.RateTypeId)}</th>
                                    <td className='cot_m'>
                                        <FormControl className='w-100 default-rate my-1'>
                                            <TextField
                                                id={`${roomMeal.RateTypeId}-1Adults`}
                                                // label={`${el?.Header}`}
                                                className={`w-100  ${!roomMeal?.Adults1 ? 'red_border' : ''}`}
                                                type="text"
                                                name={'Adults1'}
                                                inputProps={{ maxLength: 6 }}
                                                value={roomMeal['Adults1'] || ''}
                                                error={formError?.["Adults1"+roomMeal.RateTypeId] ? true : false}
                                                helperText={formError?.["Adults1"+roomMeal.RateTypeId]}
                                                onChange={(e) => handleChange(
                                                    e.target.name,
                                                    onlyNumeric(e.target.value) === '' ? "" : Number(onlyNumeric(e.target.value)),
                                                    roomMeal.RateTypeId
                                                )}
                                                disabled={disabled}
                                            />
                                        </FormControl>
                                    </td>
                                    <td className='cot_m'>
                                        <FormControl className='w-100'>
                                            <TextField
                                                // id={`${el.Name}-id`}
                                                // label={`${el?.Header}`}
                                                className={`w-100  ${!roomMeal?.Adults2 ? 'red_border' : ''}`}
                                                type="text"
                                                name={'Adults2'}
                                                inputProps={{ maxLength: 6 }}
                                                value={roomMeal['Adults2'] || ''}
                                                error={formError?.["Adults2"+roomMeal.RateTypeId] ? true : false}
                                                helperText={formError?.["Adults2"+roomMeal.RateTypeId]}
                                                onChange={(e) => handleChange(
                                                    e.target.name,
                                                    onlyNumeric(e.target.value) === '' ? "" : Number(onlyNumeric(e.target.value)),
                                                    roomMeal.RateTypeId
                                                )}
                                                disabled={disabled}
                                            />
                                        </FormControl>
                                    </td>
                                    <td className='cot_m'>
                                        <FormControl className='w-100'>
                                            <TextField
                                                // id={`${el.Name}-id`}
                                                // label={`${el?.Header}`}
                                                className={`w-100  ${!roomMeal?.ExtraAdult && isExtraBeddingAllowed ? 'red_border' : ''}`}
                                                type="text"
                                                name={'ExtraAdult'}
                                                inputProps={{ maxLength: 6 }}
                                                value={roomMeal['ExtraAdult'] || ''}
                                                error={formError?.["ExtraAdult"+roomMeal.RateTypeId] ? true : false}
                                                helperText={formError?.["ExtraAdult"+roomMeal.RateTypeId]}
                                                disabled={!isExtraBeddingAllowed || disabled}
                                                onChange={(e) => handleChange(
                                                    e.target.name,
                                                    onlyNumeric(e.target.value) === '' ? "" : Number(onlyNumeric(e.target.value)),
                                                    roomMeal.RateTypeId
                                                )}
                                            />
                                        </FormControl>
                                    </td>
                                    <td className='cot_m'>
                                        <FormControl className='w-100'>
                                            <TextField
                                                // id={`${el.Name}-id`}
                                                // label={`${el?.Header}`}
                                                className={`w-100  ${!roomMeal?.ExtraChildWithBed && isExtraBeddingAllowed ? 'red_border' : ''}`}
                                                type="text"
                                                name={'ExtraChildWithBed'}
                                                inputProps={{ maxLength: 6 }}
                                                value={roomMeal['ExtraChildWithBed'] || ''}
                                                error={formError?.["ExtraChildWithBed"+roomMeal.RateTypeId] ? true : false}
                                                helperText={formError?.["ExtraChildWithBed"+roomMeal.RateTypeId]}
                                                disabled={!isExtraBeddingAllowed || disabled}
                                                onChange={(e) => handleChange(
                                                    e.target.name,
                                                    onlyNumeric(e.target.value) === '' ? "" : Number(onlyNumeric(e.target.value)),
                                                    roomMeal.RateTypeId)}
                                            />
                                        </FormControl>
                                    </td>
                                    <td className='cot_m'>
                                        <FormControl className='w-100'>
                                            <TextField
                                                className={`w-100  ${!roomMeal?.ExtraChildWithoutBed ? 'red_border' : ''}`}
                                                type="text"
                                                name={'ExtraChildWithoutBed'}
                                                inputProps={{ maxLength: 6 }}
                                                error={formError?.["ExtraChildWithoutBed"+roomMeal.RateTypeId] ? true : false}
                                                helperText={formError?.["ExtraChildWithoutBed"+roomMeal.RateTypeId]}
                                                value={roomMeal['ExtraChildWithoutBed'] || ''}
                                                onChange={(e) => handleChange(
                                                    e.target.name,
                                                    onlyNumeric(e.target.value) === '' ? "" : Number(onlyNumeric(e.target.value)),
                                                    roomMeal.RateTypeId)}
                                                disabled={disabled}
                                            />
                                        </FormControl>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default RoomDefaultRates